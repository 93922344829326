import { Component } from '@angular/core';

export interface QA {
  question: string;
  answers: string;
  expanded: boolean;
}
@Component({
  selector: 'khatapana-faq',
  templateUrl: './faq.component.html',
})
export class FaqComponent {
  FAQS?: QA[] = [
    {
      question: 'referral.faqs.question1',
      answers: `referral.faqs.answer1`,
      expanded: false,
    },
    {
      question: 'referral.faqs.question2',
      answers: `referral.faqs.answer2`,
      expanded: false,
    },
    {
      question: 'referral.faqs.question3',
      answers: `referral.faqs.answer3`,
      expanded: false,
    },
    {
      question: 'referral.faqs.question4',
      answers: `referral.faqs.answer4`,
      expanded: false,
    },
    {
      question: 'referral.faqs.question5',
      answers: `referral.faqs.answer5`,
      expanded: false,
    },
    {
      question: 'referral.faqs.question6',
      answers: `referral.faqs.answer6`,
      expanded: false,
    },
  ];
}
