import { SubscriptionDetail } from 'src/app/modules/account/shared/models/subscription.detail';

export const STANDARD_DETAILS: SubscriptionDetail[] = [
  { title: 'Add all your cash transactions' },
  { title: 'Language options (available in both English and Nepali)' },
  { title: 'Choose your calendar (BS or AD)' },
  { title: 'Transaction summary and reports' },
];

export const PRO_DETAILS: SubscriptionDetail[] = [
  { title: 'Add cash, bank, credit, wallet and others transactions' },
  { title: 'Send reminders to parties for payments and due settlements' },
  { title: 'Downloadable reports for all modes of transactions' },
  { title: 'Attachment documents, bills and photos with each transactions' },
];
export const PLUS_DETAILS: SubscriptionDetail[] = [
  { title: 'Let khatapana follow up and collect payments from your customers' },
  { title: 'Know how your business is doing anytime, all the time.' },
  {
    title:
      'Get real time access to your business data to make decision on the go!',
  },
  { title: 'Manage and grow your business with ease.' },
];
