<khatapana-modal>
  <!-- Header -->
  <div class="py-3 px-4">
    <h4
      class="text-center text-xl font-semibold w-full flex justify-between items-center gap-x-8"
    >
      <i class="fa fa-times cursor-pointer" (click)="close.emit()"></i>
      <p class="max-[420px]:text-lg">
        {{ "payment_method.choose_payment_method" | translate }}
      </p>
      <khatapana-button variant="extra-small" color="basic" border="circle">
        <div class="flex gap-2 text-sm">
          <img src="assets/icons/auth/headphone.svg" />
          <p class="max-md:hidden">{{ "common.get_help" | translate }}</p>
        </div>
      </khatapana-button>
    </h4>
  </div>
  <khatapana-divider></khatapana-divider>
  <!-- Body -->
  <div class="p-6 flex flex-col gap-5">
    <div
      class="flex justify-between items-center py-5 px-4 gap-4 bg-[#7B61FF1F] rounded-lg"
    >
      <div class="flex gap-2 items-center">
        <img class="h-7" src="assets/icons/account/pro.svg" />
        <h2 class="font-semibold text-xl text-[#141414] max-[420px]:text-base">
          {{ selectedPlan?.subscription_type }}
        </h2>
      </div>
      <h2 class="font-normal text-lg text-[#7b61ff]">
        {{ selectedPlan?.price! }}/{{
          selectedPlan?.subscription_plan.split("ly")[0]
        }}
      </h2>
    </div>
    <p
      *ngIf="
        (user$ | async)?.active_subscription?.is_trial == false ||
        (user$ | async)?.active_subscription == null
      "
      (click)="takeTrial()"
      class="text-[#7B61FF] font-semibold text-lg text-center py-2 cursor-pointer"
    >
      {{ "payment_method.get_trial" | translate }}
    </p>
    <p class="text-[#545454] text-lg">
      {{ "payment_method.choose_pay_wallet" | translate }}
    </p>
    <div>
      <div *ngFor="let payMode of paymentOptions">
        <div
          class="flex items-center justify-between p-3 cursor-pointer"
          (click)="payOption.emit(payMode)"
        >
          <div class="flex items-center gap-3">
            <img
              src="assets/icons/account/{{ payMode.name | lowercase }}.svg"
            />
            <p class="font-semibold text-[#141414] text-lg">
              {{ payMode.name | titlecase }}
            </p>
          </div>
          <i class="fa-solid fa-chevron-right text-[#757575]"></i>
        </div>
        <khatapana-divider></khatapana-divider>
      </div>
    </div>
  </div>
</khatapana-modal>
