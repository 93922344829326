import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/modules/auth/shared/services/auth.service';
import { LocalStorageService } from 'src/app/services/local.storage.service';
import { ACCOUNT_ROUTES } from '../../../account-routing.module';
import { BUSINESS_ACCOUNT } from '../../../shared/models/business.account';
import { Invoice } from '../../../shared/models/invoice';
import { Subscribe } from '../../../shared/models/subscriptions.model';
import { AccountService } from '../../../shared/services/account.service';

@Component({
  selector: 'khatapana-choose-plus-type',
  templateUrl: './choose-plus-type.component.html',
})
export class ChoosePlusTypeComponent {
  businessSelected: boolean = false;
  alertService: any;

  constructor(
    private router: Router,
    private localStorage: LocalStorageService,
    private accountService: AccountService,
    private authService: AuthService
  ) {}

  takeTrial() {
    const PRO = 5;
    const PLUS = 9;
    let subscibe: Subscribe = {
      subscription_plan: this.businessSelected ? PLUS : PRO,
      is_trial: true,
    };
    this.accountService.subscribeToKhatapana(subscibe).subscribe({
      next: (res: { invoice: Invoice }) => {
        this.authService.loadUserToLocalStorage();
      },
      error: (err: HttpErrorResponse) => {},
    });
  }

  accountTypeChoosed() {
    this.localStorage.plus_type = this.businessSelected
      ? BUSINESS_ACCOUNT.BUSINESS_ONLY
      : BUSINESS_ACCOUNT.PERSONAL;
    this.takeTrial();
    if (this.businessSelected) {
      this.router.navigateByUrl(ACCOUNT_ROUTES.CREATE_STORE);
    } else {
      this.router.navigateByUrl(ACCOUNT_ROUTES.COMPLETED);
    }
  }
}
