import { Component, EventEmitter, Input, Output } from '@angular/core';
import { LocalStorageService } from 'src/app/services/local.storage.service';
import { PartyResponse } from '../../../shared/models/party';
import {
  PurposeCategory,
  PurposeSubCategory,
} from '../../../shared/models/purpose';
import { PurposeService } from '../../../shared/services/purpose.service';

@Component({
  selector: 'khatapana-choose-purpose',
  templateUrl: './choose-purpose.component.html',
})
export class ChoosePurposeComponent {
  @Input() transactionType = 'in';
  @Input() isCredit: boolean = false;
  @Input() selectedPurpose: any = 0;
  @Input() businessTransaction: boolean = false;

  @Output() purposeSelected = new EventEmitter<any>();
  @Output() close = new EventEmitter<boolean>();

  loader = false;
  selectedPanel = -1;
  panelExpanded: boolean = false;
  categories: PurposeCategory[] = [];
  sub_type = '';

  constructor(
    private purposeService: PurposeService,
    private localStorage: LocalStorageService
  ) {}

  ngOnInit() {
    this.transactionType = this.transactionType ?? 'in';
    this.isCredit = this.isCredit;
    this.selectedPurpose = this.selectedPurpose;
    this.sub_type =
      this.localStorage.user.active_subscription.subscription_plan.subscription_type;
    this.getPurposeCategory();
  }

  getPurposeCategory() {
    this.loader = true;
    this.purposeService
      .getPurposeCategories(
        this.transactionType,
        false,
        this.businessTransaction,
        this.sub_type
      )
      .subscribe({
        next: (res: PurposeCategory[]) => {
          this.loader = false;
          // Sorted output
          this.categories = res.reverse();
          // For Credit Mode remove credit settlement
          if (this.isCredit) {
            this.categories = this.categories.filter(
              (x) =>
                x.name.toLowerCase() !== 'credit settlement' &&
                x.name.toLowerCase() !== 'loans & advances' &&
                x.name.toLowerCase() !== 'deposit to bank account' &&
                x.name.toLowerCase() !== 'withdraw from bank' &&
                x.name.toLowerCase() !== 'transfers' &&
                x.name.toLowerCase() !== 'others'
            );

            let income_category: PurposeCategory | undefined =
              this.categories.find(
                (x) => x.name.toLocaleLowerCase() === 'income'
              );

            let sub_categories: PurposeSubCategory[] | undefined =
              income_category!.sub_categories;

            sub_categories = sub_categories.filter(
              (x) => x.name.toLocaleLowerCase() === 'sales of household goods'
            );

            income_category!.sub_categories = sub_categories;
          }
        },
        error: (err) => (this.loader = false),
      });
  }

  getCombinedSubCategory(subCategories: PurposeSubCategory[]) {
    return subCategories.map((u: PurposeSubCategory) => u.name).join(', ');
  }

  tooglePanelSelection(i: number) {
    if (this.selectedPanel === i) {
      this.panelExpanded = !this.panelExpanded;
      this.selectedPanel = -1;
      return;
    }
    this.panelExpanded = true;
    this.selectedPanel = i;
  }

  savePurpose(selected: any, party_mandatory: boolean) {
    this.selectedPurpose = selected.id;
    this.close.emit(true);
    this.purposeSelected.emit({
      purpose: selected,
      party_mandatory: party_mandatory,
    });
  }
}
