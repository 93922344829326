import { animate, style, transition, trigger } from '@angular/animations';
import { Component, Injectable, Input, OnDestroy } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
@Component({
  selector: 'khatapana-toaster',
  animations: [
    trigger('fade', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate(500, style({ opacity: 1 })),
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate(500, style({ opacity: 0 })),
      ]),
    ]),
  ],
  templateUrl: './toaster.component.html',
})
export class ToasterComponent implements OnDestroy {
  message = 'This field is required.';
  type: 'error' | 'check_circle' | 'info' = 'info';
  ngOnDestroy() {}
}
