export const CALENDAR_TYPES = [
  {
    id: 'en',
    name: 'English Calendar (A.D)',
  },
  {
    id: 'np',
    name: 'Nepali Calendar (B.S)',
  },
];
