<khatapana-card
  *ngIf="getScreenWidth > 700; else mobileTemplate"
  class="khatapana-transaction-table"
  [border]="giveBorder"
  [class]="'bg-white overflow-hidden'"
>
  <!-- List Filters and all -->
  <nav *ngIf="!dashboardPage" class="p-5 flex flex-col gap-5">
    <!-- Type Filters -->
    <div class="flex justify-between items-center">
      <ng-container
        *ngIf="!businessTransaction || inOut; else listTitleTemplate"
      >
        <div class="flex gap-3">
          <div
            *ngFor="let mode of modeTypes"
            [ngClass]="
              selectedModeType === mode
                ? 'bg-[#C2B6FF]'
                : 'hover:bg-[#c2b6ffa4]'
            "
            (click)="modeChanged(mode)"
            class="px-4 py-2 rounded-full font-semibold text-sm cursor-pointer"
          >
            {{ mode.value }}
          </div>
        </div>
      </ng-container>
      <ng-template #listTitleTemplate>
        <h1 class="text-xl px-2 font-semibold text-[#000000]">
          {{ transactionType | titlecase }}
          {{ "common.transactions" | translate }}
        </h1>
      </ng-template>
      <!-- Place to add Search input -->
      <div
        class="flex items-center justify-between border-[1px] border-[#CBCBCB] rounded-md px-3 py-2"
      >
        <div class="flex gap-2">
          <span class="material-symbols-outlined mt-1"> search </span>
          <khatapana-input
            [border]="false"
            [(ngModel)]="params.search"
            (ngModelChange)="getAllTransactions()"
            [placeholder]="'common.search' | translate"
          ></khatapana-input>
        </div>
        <span
          *ngIf="params.search"
          (click)="clearSearch()"
          class="material-symbols-outlined cursor-pointer"
        >
          close
        </span>
      </div>
    </div>

    <!-- Date Filters and download button -->
    <div class="flex justify-between items-center">
      <!-- Date -->
      <div class="flex gap-3">
        <khatapana-select
          [selectClass]="'px-2'"
          [list]="transactionTypes"
          [selected]="selectedtransactionType"
          [border]="'circle'"
          [fullWidth]="true"
          (onChange)="typeChanged($event)"
        >
        </khatapana-select>

        <!--  Place to add Date range Filter -->
        <div
          class="flex gap-3 border-[1px] px-3 rounded-full border-[#E2E2E2] items-center"
        >
          <khatapana-date-picker
            class="flex items-center"
            (onChange)="changedFromDate($event)"
          >
            <p class="flex items-center cursor-pointer">
              {{ fromDate ?? "Start Date" }}
            </p>
          </khatapana-date-picker>
          <khatapana-divider orientation="v" class="h-6"></khatapana-divider>
          <khatapana-date-picker
            class="flex items-center"
            (onChange)="changedToDate($event)"
          >
            <p class="flex items-center cursor-pointer">
              {{ toDate ?? "End Date" }}
            </p>
          </khatapana-date-picker>

          <span class="material-symbols-outlined text-[#a6a6a6] text-lg ml-4">
            calendar_month
          </span>
        </div>
      </div>
      <khatapana-button
        (onClick)="downloadReport()"
        variant="extra-small"
        color="basic"
        border="circle"
        ><span class="flex gap-2 items-center"
          ><span class="material-symbols-outlined"> download </span
          >{{ "common.download_report" | translate }}</span
        ></khatapana-button
      >
    </div>
  </nav>
  <khatapana-divider *ngIf="!dashboardPage"></khatapana-divider>
  <!-- List Table -->
  <table>
    <thead>
      <tr>
        <th [ngClass]="dashboardPage ? 'w-3/12' : 'w-2/12'">
          {{ "transaction.th_entry_date" | translate }}
        </th>
        <th [ngClass]="partyPage ? 'w-7/12' : 'w-5/12'">
          {{ "transaction.th_descriptions" | translate }}
        </th>
        <th [ngClass]="dashboardPage ? 'w-3/12' : 'w-2/12'" *ngIf="!partyPage">
          {{ "transaction.th_party" | translate }}
        </th>
        <ng-container
          *ngIf="!businessTransaction || inOut; else businessDueAmountTemplate"
        >
          <th class="w-2/12" *ngIf="!dashboardPage">
            {{ "transaction.th_in" | translate }}
          </th>
          <th class="w-2/12" *ngIf="!dashboardPage">
            {{ "transaction.th_out" | translate }}
          </th>
        </ng-container>
        <ng-template #businessDueAmountTemplate>
          <th class="w-2/12" *ngIf="!dashboardPage">
            {{ "transaction.th_due" | translate }}
          </th>
          <th class="w-2/12" *ngIf="!dashboardPage">
            {{ "transaction.th_total_amount" | translate }}
          </th>
        </ng-template>
        <th class="w-2/12" *ngIf="dashboardPage">
          {{ "transaction.th_amount" | translate }}
        </th>
        <th width="10px" *ngIf="!dashboardPage"></th>
      </tr>
    </thead>
    <tbody *ngIf="transactionList.length > 0; else emptyTableTemplate">
      <ng-container *ngFor="let transaction of transactionList">
        <khatapana-divider class="w-full"></khatapana-divider>
        <tr>
          <td
            class="text-start text-[#545454]"
            [ngClass]="dashboardPage ? 'w-3/12' : 'w-2/12'"
          >
            {{ transaction.created_date | date }}
          </td>
          <td
            class="text-start text-[#545454]"
            [ngClass]="partyPage ? 'w-7/12' : 'w-5/12'"
          >
            <div class="flex flex-col gap-1 justify-center">
              <div class="flex gap-3 font-semibold text-[#333333] items-center">
                <span>{{ transaction.transaction_mode | titlecase }}</span
                ><span class="h-2 w-2 rounded-full bg-[#545454]"></span
                ><span>
                  {{
                    transaction.is_transfer
                      ? transaction.to_bank
                        ? "Transfer to bank"
                        : transaction.to_wallet
                        ? "Transfer to wallet"
                        : "Transfer to cash"
                      : (transaction.purpose?.name | titlecase)
                  }}</span
                >
              </div>
              <div *ngIf="transaction.description" class="text-sm">
                {{ transaction.description }}
              </div>
              <div *ngIf="transaction.documents.length > 0" class="flex gap-3">
                <khatapana-button
                  *ngFor="let doc of transaction.documents; let i = index"
                  color="basic"
                  [outline]="true"
                  variant="no-padding"
                  [border]="'circle'"
                  ><a
                    class="font-normal text-xs px-3 py-1"
                    target="_blank"
                    [href]="doc"
                    >{{ i + 1 + "." + getDocumentInfo(doc).extension }}</a
                  ></khatapana-button
                >
              </div>
            </div>
          </td>
          <td
            *ngIf="!partyPage"
            [ngClass]="{
              'w-3/12': dashboardPage,
              'w-2/12': !dashboardPage,
              'pointer-events-none': transaction.is_transfer
            }"
            [routerLink]="HOME_ROUTES.PARTY_DETAIL"
            [queryParams]="{ id: transaction.party?.id }"
            routerLinkActive="router-link-active"
            class="text-start text-[#333333]"
          >
            {{
              transaction.is_transfer
                ? "Self"
                : (transaction.party?.name | titlecase)
            }}
          </td>
          <ng-container *ngIf="!dashboardPage; else dashboardTemplate">
            <ng-container
              *ngIf="
                !businessTransaction || inOut;
                else businessDueAmountTemplate
              "
            >
              <td class="text-start font-semibold w-2/12">
                <span
                  class="text-[#00AA6C]"
                  *ngIf="
                    transaction.transaction_type == 'in' ||
                      (transactionType === 'in' && transaction.is_transfer);
                    else emptyAmountTemplate
                  "
                  >{{ transaction.amount | currency }}</span
                >
              </td>
              <td class="text-start font-semibold w-2/12">
                <span
                  [ngClass]="
                    transaction.is_transfer ? 'text-gray-500' : 'text-[#E11900]'
                  "
                  *ngIf="
                    transaction.transaction_type == 'out' &&
                      transactionType != 'in';
                    else emptyAmountTemplate
                  "
                  >{{ transaction.amount | currency }}</span
                >
              </td>
            </ng-container>
            <ng-template #businessDueAmountTemplate>
              <td class="text-start font-semibold w-2/12">
                <span
                  [class]="
                    transactionType === 'sales'
                      ? 'text-[#107651]'
                      : 'text-[#A01200]'
                  "
                  *ngIf="
                    transaction.transaction_type == transactionType;
                    else emptyAmountTemplate
                  "
                  >{{ transaction.amount | currency }}</span
                >
              </td>
              <td class="text-start font-semibold w-2/12">
                <span
                  [class]="
                    transactionType === 'sales'
                      ? 'text-[#00AA6C]'
                      : 'text-[#E11900]'
                  "
                  *ngIf="
                    transaction.transaction_type == transactionType;
                    else emptyAmountTemplate
                  "
                  >{{ transaction.amount | currency }}</span
                >
              </td>
            </ng-template>
          </ng-container>
          <ng-template #dashboardTemplate>
            <td class="w-2/12">
              <span
                [ngClass]="
                  transaction.transaction_type == 'in' ||
                  transaction.transaction_type == 'sales'
                    ? 'text-[#00AA6C]'
                    : transaction.is_transfer
                    ? 'text-gray-600'
                    : 'text-[#E11900]'
                "
                >{{ transaction.amount | currency }}</span
              >
            </td>
          </ng-template>
          <!-- Menu Items -->
          <td width="10px" (click)="this.selectedTransaction = transaction.id">
            <span class="action-menu material-symbols-outlined text-[#545454]">
              more_vert
            </span>
          </td>
          <khatapana-menu-items
            *ngIf="selectedTransaction === transaction.id"
            (clickedEdit)="
              transaction.is_transfer
                ? editTransfer(transaction.id)
                : editTransaction(transaction.id)
            "
            (clickedDelete)="deleteTransaction(transaction.id)"
          ></khatapana-menu-items>
        </tr>
      </ng-container>
    </tbody>
  </table>
  <!-- Pagination -->
  <khatapana-pagination
    *ngIf="totalPages > 1! && !dashboardPage"
    [totalData]="transactionResponse?.count"
    [hasPrev]="transactionResponse?.previous ? true : false"
    [hasNext]="transactionResponse?.next ? true : false"
    [selectedPage]="{ id: selectedPage, name: selectedPage }"
    [totalPage]="totalPages"
    [totalLabel]="'common.transactions' | translate | titlecase"
    (onPageChanged)="changedPage($event)"
    (onNextClicked)="changedPage(selectedPage + 1)"
    (onPrevClicked)="changedPage(selectedPage - 1)"
  ></khatapana-pagination>
</khatapana-card>

<ng-template #emptyAmountTemplate>
  <span>-</span>
</ng-template>

<!-- If table doesn't have any value -->
<ng-template #emptyTableTemplate>
  <khatapana-empty-container></khatapana-empty-container>
</ng-template>

<khatapana-modal *ngIf="showEditModal">
  <khatapana-transaction-form-content
    (close)="showEditModal = false"
    (edited)="getAllTransactions()"
    [businessTransaction]="businessTransaction"
    [toEditTransactionDetail]="toEditTransactionDetail"
  ></khatapana-transaction-form-content>
</khatapana-modal>

<khatapana-modal *ngIf="showEditTransferModal">
  <khatapana-transfer-form-content
    (close)="showEditTransferModal = false"
    (edited)="getAllTransactions()"
    [toEditTransactionDetail]="toEditTransactionDetail"
  ></khatapana-transfer-form-content>
</khatapana-modal>

<khatapana-loader *ngIf="loading" [full]="true" [size]="100"></khatapana-loader>

<!-- Mobile Template -->
<ng-template #mobileTemplate>
  <khatapana-card
    class="khatapana-transaction-table"
    [border]="giveBorder"
    [class]="'bg-white overflow-hidden'"
  >
    <!-- List Filters and all -->
    <nav *ngIf="!dashboardPage" class="p-5 flex flex-col gap-5">
      <!-- Filter -->
      <div class="flex justify-between items-center overflow-x-auto gap-4">
        <div
          class="flex items-center justify-between border-[1px] border-[#CBCBCB] rounded-md px-3 py-2"
        >
          <div class="flex gap-2 w-52">
            <span class="material-symbols-outlined mt-1"> search </span>
            <khatapana-input
              [border]="false"
              [(ngModel)]="params.search"
              (ngModelChange)="getAllTransactions()"
              [placeholder]="'common.search' | translate"
            ></khatapana-input>
          </div>
          <span
            *ngIf="params.search"
            (click)="clearSearch()"
            class="material-symbols-outlined cursor-pointer"
          >
            close
          </span>
        </div>
        <ng-container
          *ngIf="!businessTransaction || inOut; else listTitleTemplate"
        >
          <div class="flex gap-3">
            <div
              *ngFor="let mode of modeTypes"
              [ngClass]="
                selectedModeType === mode
                  ? 'bg-[#C2B6FF]'
                  : 'hover:bg-[#c2b6ffa4]'
              "
              (click)="modeChanged(mode)"
              class="px-4 py-2 rounded-full font-semibold text-sm cursor-pointer"
            >
              {{ mode.value }}
            </div>
          </div>
        </ng-container>
        <ng-template #listTitleTemplate>
          <h1 class="text-xl px-2 font-semibold text-[#000000]">
            {{ transactionType | titlecase }}
            {{ "common.transactions" | translate }}
          </h1>
        </ng-template>
        <!-- Place to add Search input -->
      </div>
      <!--  Date range Filter -->
      <div
        class="flex gap-3 border-[1px] px-3 rounded-full border-[#E2E2E2] items-center w-fit"
      >
        <khatapana-date-picker
          class="flex items-center"
          (onChange)="changedFromDate($event)"
        >
          <p class="flex items-center cursor-pointer">
            {{ fromDate ?? "Start Date" }}
          </p>
        </khatapana-date-picker>
        <khatapana-divider orientation="v" class="h-6"></khatapana-divider>
        <khatapana-date-picker
          class="flex items-center"
          (onChange)="changedToDate($event)"
        >
          <p class="flex items-center cursor-pointer">
            {{ toDate ?? "End Date" }}
          </p>
        </khatapana-date-picker>

        <span class="material-symbols-outlined text-[#a6a6a6] text-lg ml-4">
          calendar_month
        </span>
      </div>
      <!-- Type Filters and download button -->
      <div class="flex items-center gap-6">
        <!-- Date -->
        <khatapana-select
          [selectClass]="'px-2'"
          [list]="transactionTypes"
          [selected]="selectedtransactionType"
          [border]="'circle'"
          [fullWidth]="true"
          (onChange)="typeChanged($event)"
        >
        </khatapana-select>
        <khatapana-button
          (onClick)="downloadReport()"
          variant="extra-small"
          color="basic"
          border="circle"
          ><span class="flex gap-2 items-center pr-3"
            ><span class="material-symbols-outlined"> download </span>
            {{ "common.download" | translate }}</span
          ></khatapana-button
        >
      </div>
    </nav>
    <khatapana-divider *ngIf="!dashboardPage"></khatapana-divider>
    <!-- List Table -->
    <ng-container *ngIf="transactionList.length > 0; else emptyTableTemplate">
      <div
        *ngFor="let transaction of transactionList"
        class="flex w-full justify-between p-3 border-b border-[#E2E2E2] last:border-none"
      >
        <!-- Detail -->
        <div class="flex flex-col w-full">
          <div class="flex justify-between">
            <div class="flex gap-3 text-sm text-[#333333] items-center">
              <span>{{ transaction.transaction_mode | titlecase }}</span
              ><span class="h-2 w-2 rounded-full bg-[#545454]"></span
              ><span>{{ transaction.created_date | date : "yyyy/MM/dd" }}</span>
            </div>
            <!-- Amount -->
            <div class="text-start font-semibold flex-col gap-2 items-end">
              <span
                [ngClass]="
                  transaction.transaction_type == 'in'
                    ? 'text-[#00AA6C]'
                    : transaction.is_transfer
                    ? 'text-gray-500'
                    : 'text-[#E11900]'
                "
                >{{ transaction.amount | currency }}</span
              >
              <div
                class="float-right"
                (click)="this.selectedTransaction = transaction.id"
              >
                <span
                  class="action-menu material-symbols-outlined text-[#545454] w-3"
                >
                  more_vert
                </span>
              </div>
              <khatapana-menu-items
                *ngIf="selectedTransaction === transaction.id"
                (clickedEdit)="
                  transaction.is_transfer
                    ? editTransfer(transaction.id)
                    : editTransaction(transaction.id)
                "
                (clickedDelete)="deleteTransaction(transaction.id)"
              ></khatapana-menu-items>
            </div>
          </div>
          <div class="flex gap-3 font-semibold text-[#333333] items-center">
            <span>{{
              transaction.is_transfer
                ? transaction.to_bank
                  ? "Transfer to bank"
                  : transaction.to_wallet
                  ? "Transfer to wallet"
                  : "Transfer to cash"
                : (transaction.purpose?.name | titlecase)
            }}</span>
          </div>
          <div
            [routerLink]="HOME_ROUTES.PARTY_DETAIL"
            [queryParams]="{ id: transaction.party?.id }"
            class="flex gap-3 text-[#7b61ffe2] items-center text-sm"
          >
            <span>{{
              transaction.is_transfer
                ? "Self"
                : (transaction.party?.name | titlecase)
            }}</span>
          </div>
          <div class="flex gap-3 text-[#333333] items-center text-sm">
            <span> {{ transaction.description | titlecase }}</span>
          </div>
          <div *ngIf="transaction.documents.length > 0" class="flex gap-3">
            <khatapana-button
              *ngFor="let doc of transaction.documents; let i = index"
              color="basic"
              [outline]="true"
              variant="no-padding"
              [border]="'circle'"
              ><a
                class="font-normal text-xs px-3 py-1"
                target="_blank"
                [href]="doc"
                >{{ i + 1 + "." + getDocumentInfo(doc).extension }}</a
              ></khatapana-button
            >
          </div>
        </div>
      </div>
      <!-- Pagination -->
      <khatapana-pagination
        *ngIf="totalPages > 1! && !dashboardPage"
        [totalData]="transactionResponse?.count"
        [hasPrev]="transactionResponse?.previous ? true : false"
        [hasNext]="transactionResponse?.next ? true : false"
        [selectedPage]="{ id: selectedPage, name: selectedPage }"
        [totalPage]="totalPages"
        [totalLabel]="'common.transactions' | translate | titlecase"
        (onPageChanged)="changedPage($event)"
        (onNextClicked)="changedPage(selectedPage + 1)"
        (onPrevClicked)="changedPage(selectedPage - 1)"
      ></khatapana-pagination>
    </ng-container>
  </khatapana-card>
</ng-template>
