import { SocialAuthService } from '@abacritt/angularx-social-login';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { NgOtpInputConfig } from 'ng-otp-input';
import { ACCOUNT_ROUTES } from 'src/app/modules/account/account-routing.module';
import { HOME_ROUTE } from 'src/app/modules/home/home-routing.module';
import { AlertService } from 'src/app/modules/shared/services/alert.service';
import { DEFAULT_ERROR_MESSAGE } from 'src/constants';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from '../../../../services/local.storage.service';
import { AUTH_ROUTES } from '../../auth-routing.module';
import { LoginRequest, SocialRequest } from '../../shared/models/login-request';
import { VerifyOTPResponse, VerifyPhone } from '../../shared/models/verify-otp';
import { AuthService } from '../../shared/services/auth.service';
declare var AppleID: any;

@Component({
  selector: 'khatapana-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  loginForm!: FormGroup;
  emailForm!: FormGroup;
  isLoading = false;
  jwtHelper: any;

  showPhoneNumberModal = false;
  showOTPModal = false;

  // OTP & Phone number verification
  phoneForm!: FormGroup;
  otpConfig: NgOtpInputConfig = {
    length: 6,
    containerClass: 'flex gap-6 justify-center flex-wrap',
  };

  otpForm!: FormGroup;

  disableNext = true;
  startTimimg = false;
  timeCountdown = 14;

  phoneSelected = true;
  emailSelected = false;

  googleLogin = false;
  authSubscription: any;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private socialAuthService: SocialAuthService,
    private alertService: AlertService,
    private localStorage: LocalStorageService,
    private router: Router
  ) {
    this.loginForm = this.fb.group({
      username: ['', [Validators.required, Validators.pattern('^[0-9]{10}$')]],
    });
    this.emailForm = this.fb.group({
      username: ['', [Validators.required, Validators.email]],
    });
    this.phoneForm = this.fb.group({
      username: ['', [Validators.required, Validators.pattern('^[0-9]{10}$')]],
    });
    this.otpForm = this.fb.group({
      otp: ['', [Validators.required, Validators.minLength(6)]],
    });
  }

  ngOnInit() {
    // if (this.localStorage.social_user) {
    //   if (this.localStorage.social_user.provider === 'google') {
    //     this.loginWithGoogle(this.localStorage.social_user);
    //   }
    //   if (this.localStorage.social_user.provider === 'apple') {
    //     this.loginWithApple(this.localStorage.social_user);
    //   }
    // }
    this.authSubscription = this.socialAuthService.authState.subscribe(
      (user) => {
        user.provider = 'google';
        // console.log(user);
        this.localStorage.social_user = user;
        if (user) {
          this.loginWithGoogle(user);
        }
      }
    );

    this.loginForm.valueChanges.subscribe((value) => {
      value = value?.username;
      if (value.length > 10) {
        value = value.slice(-10);
        this.loginForm.setValue({ username: value });
      }
    });
  }

  get username() {
    return this.loginForm.get('username');
  }

  get phone() {
    return this.phoneForm.get('username');
  }

  get email() {
    return this.emailForm.get('username');
  }

  get otp() {
    return this.otpForm.get('otp') as FormControl;
  }

  changeSelectedPhoneOrEmail(type: string) {
    if (type === 'phone') {
      this.phoneSelected = true;
      this.emailSelected = false;
    } else {
      this.phoneSelected = false;
      this.emailSelected = true;
    }
  }

  sendOTP() {
    if (this.isLoading) {
      return;
    }
    if (this.phoneSelected ? this.loginForm.invalid : this.emailForm.invalid) {
      this.phoneSelected
        ? this.loginForm.markAllAsTouched()
        : this.emailForm.markAllAsTouched();
      return;
    }
    let username = '';
    if (this.phoneSelected) {
      const countryCode =
        document
          .getElementsByClassName('selected-flag')[0]
          ?.getAttribute('title')
          ?.split('+')[1] || '977';
      username = `+${countryCode}${this.username?.value}`;
    } else {
      username = this.email?.value;
    }

    const loginRequest: LoginRequest = {
      username: username,
    };
    this.isLoading = true;
    this.authService.sendOTP(loginRequest).subscribe({
      next: (res) => {
        this.isLoading = false;
        this.localStorage.username = username;
        if (res.new_user) {
          this.alertService.success('OTP sent successfully');
          this.router.navigateByUrl(AUTH_ROUTES.OTP);
        } else {
          this.router.navigateByUrl(AUTH_ROUTES.PASSWORD);
        }
      },
      error: (err: HttpErrorResponse) => {
        this.isLoading = false;
        this.alertService.error(
          this.alertService.getFormattedErrorMessage(err.error) ??
            DEFAULT_ERROR_MESSAGE
        );
      },
    });
  }

  loginWithGoogle(user: any) {
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;

    const socialRequest: SocialRequest = {
      auth_token: user?.idToken,
      mode: user?.provider,
      first_name: user?.firstName,
      last_name: user?.lastName,
    };
    this.authService.continueWithGoogle(socialRequest).subscribe({
      next: (res: VerifyOTPResponse) => {
        this.localStorage.token = res.token;
        this.localStorage.refresh_token = res.refresh_token;
        this.localStorage.removeItem('social_user');
        this.localStorage.user = res.user;
        this.localStorage.social = true;
        this.socialLogout();
        // this.socialAuthService.signOut();
        if (!res.new_user) {
          this.router.navigateByUrl(HOME_ROUTE.DASHBOARD);
          this.isLoading = false;
          return;
        }
        this.router.navigateByUrl(ACCOUNT_ROUTES.CHOOSE);
        this.isLoading = false;
      },
      error: (err: HttpErrorResponse) => {
        this.alertService.error(
          this.alertService.getFormattedErrorMessage(err.error)
        );
        this.isLoading = false;
      },
    });
  }

  async appleButton() {
    const CLIENT_ID = 'com.khatapana.apple';
    const REDIRECT_API_URL = `${environment.webURL}/accounts/callbacks/apple`;
    try {
      AppleID.auth.init({
        clientId: CLIENT_ID,
        scope: 'name email',
        redirectURI: REDIRECT_API_URL,
        state: 'init',
        nonce: 'khata_login',
        usePopup: true, //or false defaults to false
      });
      const data = await AppleID.auth.signIn();
      var social_user = {
        provider: 'apple',
        idToken: data.authorization.code,
        firstName: data?.user?.firstName,
        lastName: data?.user?.lastName,
      };
      this.loginWithApple(social_user);
    } catch (error) {
      this.alertService.error('Something went wrong!');
    }
  }

  loginWithApple(user: any) {
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;
    const socialRequest: SocialRequest = {
      auth_token: user?.idToken,
      mode: user?.provider,
      first_name: user?.firstName,
      last_name: user?.lastName,
      web: true,
    };

    this.authService.continueWithApple(socialRequest).subscribe({
      next: (res: VerifyOTPResponse) => {
        this.localStorage.token = res.token;
        this.localStorage.refresh_token = res.refresh_token;
        this.localStorage.user = res.user;
        this.localStorage.social = true;
        this.socialLogout();
        // this.socialAuthService.signOut();
        if (!res.new_user) {
          this.router.navigateByUrl(HOME_ROUTE.DASHBOARD);
          this.isLoading = false;
          return;
        }
        this.router.navigateByUrl(ACCOUNT_ROUTES.CHOOSE);
        this.isLoading = false;
      },
      error: (err: HttpErrorResponse) => {
        this.alertService.error(
          this.alertService.getFormattedErrorMessage(err.error)
        );
        this.isLoading = false;
      },
    });
  }

  // Phone number verification steps
  sendOTPPhoneVerification() {
    if (this.phoneForm.invalid) {
      this.phoneForm.markAllAsTouched();
      return;
    }
    const countryCode = document
      .getElementsByClassName('selected-flag')[0]
      .getAttribute('title')
      ?.split('+')[1];
    const finalNumber = `+${countryCode}${this.phone?.value}`;
    const loginRequest: LoginRequest = {
      username: finalNumber,
    };
    this.authService.sendOTPForUpdate(loginRequest).subscribe({
      next: (res) => {
        this.showOTPModal = true;
        this.showPhoneNumberModal = false;
      },
      error: (err: HttpErrorResponse) => {
        this.alertService.error(
          this.alertService.getFormattedErrorMessage(err.error) ??
            DEFAULT_ERROR_MESSAGE
        );
      },
    });
  }

  startTimer() {
    this.startTimimg = true;
    this.timeCountdown = 16;
    const interval = setInterval(() => {
      this.timeCountdown--;
      if (this.timeCountdown == 0) {
        this.startTimimg = false;
        this.timeCountdown = 16;
        clearInterval(interval);
      }
    }, 1000);
  }

  otpChanged(value: any) {
    this.disableNext = value.length == 6 ? false : true;
  }

  resendOTP() {
    const countryCode = document
      .getElementsByClassName('selected-flag')[0]
      .getAttribute('title')
      ?.split('+')[1];
    const finalNumber = `+${countryCode}${this.username?.value}`;
    const loginRequest: LoginRequest = {
      username: finalNumber,
    };
    this.authService.sendOTPForUpdate(loginRequest).subscribe({
      next: (res: any) => {
        this.alertService.success('OTP resent successfully');
        this.startTimer();
      },
      error: (err: HttpErrorResponse) => {
        this.alertService.error(
          this.alertService.getFormattedErrorMessage(err.error) ??
            DEFAULT_ERROR_MESSAGE
        );
      },
    });
  }

  verifyPhone() {
    if (this.otpForm.invalid) return;

    const countryCode = document
      .getElementsByClassName('selected-flag')[0]
      .getAttribute('title')
      ?.split('+')[1];

    const finalNumber = `+${countryCode}${this.phone?.value}`;
    const verifyOTPRequest: VerifyPhone = {
      otp: this.otp?.value,
      mobile_number: finalNumber,
    };
    this.disableNext = true;
    this.authService.verifyNumber(verifyOTPRequest).subscribe({
      next: (res) => {
        this.authService.loadUserToLocalStorage();
        this.router.navigateByUrl(ACCOUNT_ROUTES.CHOOSE);
      },
      error: (err: HttpErrorResponse) => {
        this.alertService.error(
          this.alertService.getFormattedErrorMessage(err.error)
        );
      },
    });
  }

  async socialLogout() {
    try {
      await this.socialAuthService.signOut();
    } catch (e) {}
  }

  ngOnDestroy() {
    this.googleLogin = false;
    // console.log(this.socialAuthService.authState);
    // await this.socialLogout();
    this.authSubscription.unsubscribe();
  }
}
