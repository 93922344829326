import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { DashboardData } from 'src/app/modules/home/shared/models/dashboard.data';
import { DashboardService } from 'src/app/modules/home/shared/services/dashboard.service';
import { AlertService } from 'src/app/modules/shared/services/alert.service';
import { DEFAULT_ERROR_MESSAGE } from 'src/constants';
import { BUSINESS_HOME_ROUTE } from '../../business-routing.module';
import { NavigationTopService } from '../../shared/services/navigation-top.service';

@Component({
  selector: 'khatapana-dashboard',
  templateUrl: './dashboard.component.html',
})
export class DashboardComponent implements OnInit {
  dashboardData$?: Observable<DashboardData>;
  transactionType: 'sales' | 'purchase' | 'inout' = 'sales';
  constructor(
    private navigationTopService: NavigationTopService,
    private dashboardService: DashboardService,
    private alertService: AlertService,
    private router: Router
  ) {
    this.navigationTopService.type$.next('none');
  }

  ngOnInit(): void {
    this.loadDashboard();
  }

  viewMoreTransactions() {
    switch (this.transactionType) {
      case 'sales':
        this.router.navigateByUrl(BUSINESS_HOME_ROUTE.SALES);
        break;
      case 'purchase':
        this.router.navigateByUrl(BUSINESS_HOME_ROUTE.PURCHASES);
        break;
      case 'inout':
        this.router.navigateByUrl(BUSINESS_HOME_ROUTE.TRANSACTIONS);
        break;
    }
  }
  loadDashboard() {
    this.dashboardData$ = this.dashboardService.getDashboardData(true);
    this.dashboardData$.subscribe({
      next: () => {},
      error: (err: HttpErrorResponse) => {
        this.alertService.error(
          this.alertService.getFormattedErrorMessage(err.error) ??
            DEFAULT_ERROR_MESSAGE
        );
      },
    });
  }
}
