import { Component } from '@angular/core';
import { CALENDAR_TYPES } from 'src/app/model/calendar.type';
import {
  LanguageLabel,
  I18nService,
} from 'src/app/modules/core/i18n/i18n.service';
import { keyValue } from 'src/app/modules/shared/models/keyvalue';
import { AlertService } from 'src/app/modules/shared/services/alert.service';
import { LocalStorageService } from 'src/app/services/local.storage.service';

@Component({
  selector: 'khatapana-account-setting',
  templateUrl: './account-setting.component.html',
})
export class AccountSettingComponent {
  languages: LanguageLabel[] = [];
  calendars = CALENDAR_TYPES;

  selectedLanguage?: LanguageLabel;
  selectedCalendar?: keyValue;

  constructor(
    private langService: I18nService,
    private alertService: AlertService,
    private localStorageService: LocalStorageService
  ) {}

  ngOnInit(): void {
    this.languages = this.langService.languages;
    this.selectedLanguage = this.languages.find(
      ({ id }) => id === this.langService.language
    );
    this.selectedCalendar = this.calendars.find(
      ({ id }) => id === this.localStorageService.calendar
    );
  }

  handleLanguageChange(language: any) {
    this.selectedLanguage = this.languages.find((x) => x.id == language);
  }

  handleCalendarChange(calendar: any) {
    this.selectedCalendar = this.calendars.find((x) => x.id == calendar);
  }

  updateLanguauge() {
    this.localStorageService.calendar = this.selectedCalendar?.id;
    this.langService.language = this.selectedLanguage?.id;
    this.alertService.success('Settings updated successfully');
  }
}
