<div class="flex w-full gap-8 p-2">
  <div class="w-[40%] max-lg:hidden">
    <h1 class="text-xl font-semibold">
      {{ "setting.profile_info" | translate }}
    </h1>
    <p>{{ "setting.profile_change_subtitle" | translate }}</p>
  </div>
  <div
    class="w-[60%] flex flex-col gap-8 max-lg:w-full"
    [formGroup]="basicForm"
  >
    <div class="flex justify-between items-center max-sm:justify-center">
      <div class="flex gap-3 items-center">
        <!-- Image Show -->
        <div
          class="rounded-full relative bg-[#EEEEEE] h-16 w-16 flex items-center justify-center"
        >
          <img
            [ngClass]="
              (user$ | async)?.profile_image
                ? 'h-16 w-16 object-cover'
                : 'h-10 w-10'
            "
            class="overflow-hidden rounded-full"
            [src]="
              (user$ | async)?.profile_image ?? 'assets/icons/home/profile.svg'
            "
          />
          <khatapana-profile-image-upload
            class="absolute -bottom-3 -right-3 min-[640px]:hidden"
            [mobile]="true"
            [profileImage]="(user$ | async)?.profile_image"
            (imageUpdated)="loadProfileData()"
          ></khatapana-profile-image-upload>
        </div>
        <div class="flex flex-col max-sm:hidden">
          <h1 class="text-lg font-semibold">
            {{ "setting.upload_profile_image" | translate }}
          </h1>
          <p>{{ (user$ | async)?.email }}</p>
        </div>
      </div>
      <khatapana-profile-image-upload
        class="max-sm:hidden"
        [profileImage]="(user$ | async)?.profile_image"
        (imageUpdated)="loadProfileData()"
      ></khatapana-profile-image-upload>
    </div>
    <khatapana-input
      formControlName="full_name"
      label="Full name"
    ></khatapana-input>
    <khatapana-input
      formControlName="email"
      [label]="'common.email' | translate"
    ></khatapana-input>
    <khatapana-button
      [fullWidth]="true"
      border="little"
      variant="big"
      (onClick)="
        basicForm.value['email'] != oldEmail
          ? sendOTPEmailVerification()
          : updateProfile()
      "
      color="secondary"
      ><span class="flex justify-center w-full">{{
        "common.save" | translate
      }}</span></khatapana-button
    >
  </div>
</div>

<!-- Email verification Modal -->
<khatapana-modal *ngIf="showOTPModal">
  <!-- Header -->
  <div class="py-3 px-4">
    <h4
      class="text-center text-xl font-semibold w-full flex justify-between items-center gap-x-8"
    >
      <p>{{ "setting.verify_email" | translate }}</p>
      <span
        (click)="showOTPModal = false"
        class="material-symbols-outlined cursor-pointer"
      >
        close
      </span>
    </h4>
  </div>
  <khatapana-divider></khatapana-divider>
  <!-- Body -->
  <form class="p-6 flex flex-col gap-5" [formGroup]="otpForm">
    <label class="text-center font-semibold text-[#141414]">{{
      "otp.enter_otp" | translate
    }}</label>
    <ng-otp-input
      [config]="otpConfig"
      (onInputChange)="otpChanged($event)"
      [formCtrl]="otp"
    ></ng-otp-input>
  </form>
  <div class="flex flex-col gap-6 px-6 pb-6">
    <khatapana-button
      [color]="'basic'"
      [border]="'circle'"
      [variant]="'medium'"
      [disabled]="startTimimg"
      (onClick)="resendOTP()"
      type="submit"
      ><span *ngIf="!startTimimg; else timer" class="text-center">{{
        "otp.resend_code" | translate
      }}</span>
      <ng-template #timer
        >{{ "common.resend" | translate }} 00:{{ timeCountdown < 10 ? "0" : ""
        }}{{ timeCountdown }}</ng-template
      >
    </khatapana-button>
    <div class="flex justify-between">
      <khatapana-button
        [color]="'basic'"
        [border]="'circle'"
        [variant]="'big'"
        [disabled]="disableNext"
        type="submit"
        (onClick)="verifyEmail()"
        ><span class="text-center"
          >{{ "common.next" | translate }}
          <i class="fa-solid fa-arrow-right text-base pl-1"></i></span
      ></khatapana-button>
    </div>
  </div>
</khatapana-modal>
