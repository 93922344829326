<khatapana-modal
  *ngIf="upgradePlan; else changePlanTemplate"
  [width]="showPro ? 1000 : 500"
>
  <div class="p-6 flex flex-col gap-8">
    <!-- Header -->
    <div class="text-2xl font-semibold">
      <h4 class="text-center">
        {{ showPro ? "Choose khatapana plan" : "Upgrade to Plus" }}
      </h4>
      <span
        (click)="close()"
        class="material-symbols-outlined cursor-pointer fixed right-5 top-5"
      >
        cancel
      </span>
    </div>

    <!-- Plan Chooser -->
    <div class="flex justify-center w-full">
      <khatapana-plan-type-tab
        [ngClass]="showPro ? 'w-3/6' : 'w-full'"
        [selectedTab]="proSelectedTab"
        (changeTab)="changePaymentPlan($event)"
        [subscriptionPlans]="proSubscriptionPlans"
      ></khatapana-plan-type-tab>
    </div>

    <!-- BODY FOR SUBSCRIPTION PLANS -->
    <div class="flex w-full gap-6">
      <!-- PRO -->
      <div
        *ngIf="showPro"
        class="border-[#E2E2E2] border-[1px] w-3/6 rounded-md p-5 flex flex-col gap-1 items-center"
      >
        <img src="assets/illustrations/pro-upgrade.svg" />
        <h1 class="text-lg font-semibold">Khatapana- Pro</h1>
        <p class="text-sm text-[#757575]">
          All in one plan to make you a personal finance Pro
        </p>
        <div class="flex w-full justify-center py-4">
          <khatapana-button
            border="circle"
            [color]="'primary'"
            (onClick)="subscribe(PRO_SUBSRICPTION_ID)"
            variant="extra-small"
          >
            <span class="flex w-full gap-2 py-1 px-10 text-sm">
              Continue with {{ proSelectedTab?.price | currency }}/{{
                proSelectedTab?.subscription_plan
              }}</span
            >
          </khatapana-button>
        </div>
        <div class="item-start flex flex-col">
          <khatapana-plan-detail-item
            [small]="true"
            *ngFor="let detail of proDetails"
            [space]="'little'"
            [bottomLine]="false"
            [subtitle]="detail.title"
            color="primary"
          ></khatapana-plan-detail-item>
        </div>
      </div>
      <!-- PLUS -->
      <div
        [ngClass]="showPro ? 'w-3/6' : 'w-full'"
        class="border-[#E2E2E2] border-[1px] rounded-md p-5 flex flex-col gap-1 items-center"
      >
        <img src="assets/illustrations/plus-upgrade.svg" />
        <h1 class="text-lg font-semibold">Khatapana- Plus</h1>
        <p class="text-sm text-[#757575]">
          Use Plus and grow your business with us!
        </p>
        <div class="flex w-full justify-center py-4">
          <khatapana-button
            border="circle"
            [color]="'primary'"
            (onClick)="subscribe(PLUS_SUBSRICPTION_ID)"
            variant="extra-small"
          >
            <span class="flex w-full gap-2 py-1 px-10 text-sm">
              Continue with {{ plusSelectedTab?.price | currency }}/{{
                plusSelectedTab?.subscription_plan
              }}</span
            >
          </khatapana-button>
        </div>
        <div class="item-start flex flex-col">
          <khatapana-plan-detail-item
            [small]="true"
            *ngFor="let detail of plusDetails"
            [space]="'little'"
            [bottomLine]="false"
            [subtitle]="detail.title"
            color="primary"
          ></khatapana-plan-detail-item>
        </div>
      </div>
    </div>
  </div>
</khatapana-modal>

<!-- Change Plan Modal -->
<ng-template #changePlanTemplate>
  <khatapana-modal [width]="500">
    <div class="p-4 flex flex-col">
      <!-- Header -->
      <div class="text-2xl font-semibold pb-4">
        <h4 class="text-center">Change Plan</h4>
        <span
          (click)="close()"
          class="material-symbols-outlined cursor-pointer fixed right-5 top-5"
        >
          close
        </span>
      </div>
      <khatapana-divider></khatapana-divider>

      <!-- BODY FOR SUBSCRIPTION PLANS -->
      <div class="flex flex-col w-full gap-6 py-4">
        <div
          *ngFor="
            let plan of currentSubscription.subscription_plan
              .subscription_type === 'Khatapana Pro'
              ? proSubscriptionPlans
              : plusSubscriptionPlans
          "
          (click)="selectedSubscription = plan"
          [ngClass]="
            selectedSubscription?.id == plan.id
              ? 'border-[#7B61FF]'
              : 'border-[#E2E2E2]'
          "
          class="flex justify-between gap-[2px] p-3 border-2 cursor-pointer w-full rounded-md hover:bg-[#e9e5ff]"
        >
          <div class="flex flex-col">
            <div class="text-sm">{{ plan?.subscription_plan | titlecase }}</div>
            <div class="font-semibold">{{ plan?.price | currency }}</div>
            <div class="text-xs text-[#7B61FF]">
              Billing {{ plan?.subscription_plan }}
            </div>
          </div>
          <span
            *ngIf="selectedSubscription?.id == plan.id"
            class="material-symbols-outlined fill text-[#7B61FF]"
          >
            check_circle
          </span>
        </div>

        <khatapana-button
          border="circle"
          class="flex justify-center w-full"
          (onClick)="showPayModal = true"
          [disabled]="!selectedSubscription"
          ><span class="text-sm px-3 py-1"
            >Continue with {{ selectedSubscription?.price | currency }}</span
          ></khatapana-button
        >
      </div>
    </div>
  </khatapana-modal>
</ng-template>

<!-- Payment Modes Modal -->
<khatapana-payment-modal
  *ngIf="showPayModal"
  [selectedPlan]="selectedSubscription"
  (close)="showPayModal = false"
  (payOption)="payWithMode($event)"
></khatapana-payment-modal>
