import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { AlertService } from 'src/app/modules/shared/services/alert.service';
import { UserCoin } from '../../../shared/models/coin';

@Component({
  selector: 'khatapana-referral-card',
  templateUrl: './referral-card.component.html',
})
export class ReferralCardComponent implements OnChanges {
  @Input() referralCode: string = '';
  @Input() userCoin?: UserCoin;
  referralLink = '';

  showModal = false;

  constructor(
    private clipboard: Clipboard,
    private alertService: AlertService
  ) {}

  ngOnChanges(change: SimpleChanges) {
    if (change['referralCode']) {
      this.referralLink =
        window.location.origin + '/referral?referrer=' + this.referralCode;
    }
  }

  copyLink() {
    this.clipboard.copy(this.referralLink);
    this.alertService.success('Link copied successfully');
  }
}
