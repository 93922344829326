import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { ExpenseReportComponent } from './pages/expense-report/expense-report.component';
import { FeedbackComponent } from './pages/feedback/feedback.component';
import { NotificationComponent } from './pages/notification/notification.component';
import { PartyDetailComponent } from './pages/party-detail/party-detail.component';
import { PartyListComponent } from './pages/party-list/party-list.component';
import { ReferralComponent } from './pages/referral/referral.component';
import { SettingComponent } from './pages/setting/setting.component';
import { SubscriptionPlanComponent } from './pages/subscription-plan/subscription-plan.component';
import { TransactionFormComponent } from './pages/transaction-form/transaction-form.component';
import { TransactionListComponent } from './pages/transaction-list/transaction-list.component';
import { TransferComponent } from './pages/transfer/transfer.component';

export const HOME_ROUTE = {
  DASHBOARD: '/app/dashboard',
  TRANSACTION_LIST: '/app/transaction',
  TRANSACTION_ADD: '/app/transaction-form',
  TRANSFER: '/app/transfer',
  PARTY_LIST: '/app/party',
  PARTY_DETAIL: '/app/party-detail',
  EXPENSE_REPORT: '/app/expense',
  FEEDBACK: '/app/feedback',
  SETTING: '/app/setting',
  REFERRAL: '/app/referral',
  NOTIFICATION: '/app/notification',
  SUBSCRIPTION: '/app/subscription',
};

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    children: [
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      {
        path: 'dashboard',
        component: DashboardComponent,
      },
      {
        path: 'transaction',
        component: TransactionListComponent,
      },
      {
        path: 'transaction-form',
        component: TransactionFormComponent,
      },
      {
        path: 'transfer',
        component: TransferComponent,
      },
      {
        path: 'party',
        component: PartyListComponent,
      },
      {
        path: 'party-detail',
        component: PartyDetailComponent,
      },
      {
        path: 'setting',
        loadChildren: () =>
          import('./pages/setting/setting.module').then((m) => m.SettingModule),
      },
      {
        path: 'referral',
        component: ReferralComponent,
      },
      {
        path: 'feedback',
        component: FeedbackComponent,
      },
      {
        path: 'subscription',
        component: SubscriptionPlanComponent,
      },
      {
        path: 'expense',
        component: ExpenseReportComponent,
      },
      {
        path: 'notification',
        component: NotificationComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class HomeRoutingModule {}
