import { Component, OnInit } from '@angular/core';
import { AmountService } from '../../../shared/services/amount.service';

@Component({
  selector: 'khatapana-opening-balance-reminder',
  templateUrl: './opening-balance-reminder.component.html',
})
export class OpeningBalanceReminderComponent implements OnInit {
  cashBalance = 0;

  onOkClicked!: () => any;
  onCancelClicked!: () => any;

  constructor(private amountService: AmountService) {}

  ngOnInit() {
    this.loadCashOpening();
  }

  loadCashOpening() {
    this.amountService.getCashBalance().subscribe({
      next: (cash) => {
        this.cashBalance = cash.results[0]?.balance || 0;
      },
    });
  }
}
