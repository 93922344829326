<khatapana-modal>
  <!-- Header -->
  <div class="py-3 px-4">
    <h4
      class="text-center text-xl font-semibold w-full flex justify-center items-center"
    >
      <p>Add new bank</p>
    </h4>
  </div>
  <!-- Body -->
  <div class="px-6 pt-4 flex flex-col gap-5" [formGroup]="bankForm">
    <khatapana-select
      [list]="banks"
      [fullWidth]="true"
      [fontWeight]="400"
      border="little"
      [errorControl]="payment_gateway!"
      [required]="true"
      (onChange)="changeGateway($event)"
      formControlName="payment_gateway"
      [disabled]="payment_gateway?.disabled!"
      [height]="55"
      variant="dark"
      [label]="'account.choose_bank' | translate"
      [placeHolder]="'account.choose_bank_placeholder' | translate"
    >
    </khatapana-select>
    <khatapana-input
      label="{{ 'account.account_holder_name_label' | translate }}"
      formControlName="account_holder"
      [errorControl]="account_holder!"
    ></khatapana-input>
    <khatapana-input
      label="{{ 'account.current_bank_balance' | translate }}"
      formControlName="balance"
      [errorControl]="balance!"
      [preIcon]="'fa fa-rupee-sign'"
      type="number"
    ></khatapana-input>
  </div>

  <!-- Footer -->
  <div class="flex flex-col gap-4 p-6">
    <khatapana-button
      [fullWidth]="true"
      color="secondary"
      variant="big"
      border="little"
      (onClick)="saveBank()"
      ><span class="flex justify-center w-full">{{
        "common.save" | translate
      }}</span></khatapana-button
    >
    <khatapana-button
      [fullWidth]="true"
      color="basic"
      variant="big"
      border="little"
      (click)="closeBankModal()"
      ><span class="flex justify-center w-full">{{
        "common.cancel" | translate
      }}</span></khatapana-button
    >
  </div>
</khatapana-modal>
