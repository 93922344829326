import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AlertService } from 'src/app/modules/shared/services/alert.service';
import { HOME_ROUTE } from '../../home-routing.module';
import { FeedbackService } from '../../shared/services/feedback.service';
import { PageTitleService } from '../../shared/services/page-title.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'khatapana-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss'],
})
export class FeedbackComponent {
  HOME_ROUTES = HOME_ROUTE;

  comment: string = '';

  // File related
  transactionFile: File[] | null = [];
  transactionFileName: string = 'Attachments';
  fileSelected: boolean = false;

  constructor(
    private router: Router,
    private pageTitleService: PageTitleService,
    private feedbackService: FeedbackService,
    private translate: TranslateService,
    private alertService: AlertService
  ) {
    this.translate
      .get('navbar.feedback')
      .subscribe((v) => this.pageTitleService.pageTitle$.next(v));
    this.pageTitleService.showBackButton$.next(false);
  }

  // File selection
  fileBrowseHandler(ev: any) {
    this.transactionFile?.push(...ev.target.files);
    this.fileSelected = true;
  }

  removeFile(file: File) {
    this.transactionFile?.splice(
      this.transactionFile.findIndex((x) => x == file),
      1
    );
  }

  sendFeedback() {
    const formData = new FormData();
    // Creating Payload //
    if (this.transactionFile!.length > 0) {
      this.transactionFile?.forEach((file: File) => {
        formData.append('documents', file);
      });
    }
    formData.append('comments', this.comment);

    this.feedbackService.sendFeedback(formData).subscribe({
      next: (res) => {
        this.alertService.success(
          'Your feedback have been successfully submitted'
        );
        this.router.navigateByUrl(HOME_ROUTE.DASHBOARD);
      },
      error: (err: HttpErrorResponse) => {
        this.alertService.error(
          this.alertService.getFormattedErrorMessage(err.error)
        );
      },
    });
  }
}
