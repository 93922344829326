<khatapana-card [border]="true" [class]="'bg-white'"
  ><header class="p-5 text-xl font-semibold">
    {{ "referral.how_redeem_work_part1" | translate }}
    <br />
    {{ "referral.how_redeem_work_part2" | translate }}
  </header>
  <khatapana-divider></khatapana-divider>
  <section class="flex flex-col p-6 gap-4">
    <div class="gap-2 flex flex-col">
      <p class="font-semibold text-sm text-[#545454]">
        {{ "referral.for_in_app" | translate }}
      </p>
      <div
        class="h-12 flex border-[#E2E2E2] border-[1px] rounded-lg items-center justify-between px-6"
      >
        <p>1 {{ "common.khata_coin" | translate }}</p>
        <p>=</p>
        <p>Rs. 1</p>
      </div>
    </div>
    <div class="gap-2 flex flex-col">
      <p class="font-semibold text-sm text-[#545454]">
        {{ "referral.for_out_app" | translate }}
      </p>
      <div
        class="h-12 flex border-[#E2E2E2] border-[1px] rounded-lg items-center justify-between px-6"
      >
        <p>1 {{ "common.khata_coin" | translate }}</p>
        <p>=</p>
        <p>Rs. 0.10</p>
      </div>
    </div>
  </section>
</khatapana-card>
