import { HttpErrorResponse } from '@angular/common/http';
import { Component, HostListener, Input } from '@angular/core';
import { Router } from '@angular/router';
import { HOME_ROUTE } from 'src/app/modules/home/home-routing.module';
import { Party, PartyResponse } from 'src/app/modules/home/shared/models/party';
import { PageTitleService } from 'src/app/modules/home/shared/services/page-title.service';
import { PartyService } from 'src/app/modules/home/shared/services/party.service';
import { DEFAULT_ERROR_MESSAGE } from 'src/constants';
import { AlertService } from '../../services/alert.service';
import { ConfirmModalService } from '../../services/confirm.modal.service';
import { PartyFormComponent } from '../party-form/party-form.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'khatapana-party-list-table',
  templateUrl: './party-list-table.component.html',
})
export class PartyListTableComponent {
  public getScreenWidth: any;
  public getScreenHeight: any;

  HOME_ROUTES = HOME_ROUTE;

  showForm = false;

  selectedParty: any;

  partyList: PartyResponse[] = [];

  @Input() businessTransaction = false;
  @Input() transactionType: string = '';

  constructor(
    private partyService: PartyService,
    private router: Router,
    private translate: TranslateService,
    private confirmModalService: ConfirmModalService,
    private alertService: AlertService,
    private pageTitleService: PageTitleService
  ) {
    this.translate
      .get('navbar.parties')
      .subscribe((v) => this.pageTitleService.pageTitle$.next(v));
    this.pageTitleService.showBackButton$.next(false);
  }

  ngOnInit() {
    this.getScreenWidth = window.innerWidth;
    this.getScreenHeight = window.innerHeight;
    this.loadParties();
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.getScreenWidth = window.innerWidth;
    this.getScreenHeight = window.innerHeight;
  }

  loadParties() {
    this.partyService.getParties(this.businessTransaction).subscribe({
      next: (result) => {
        this.partyList = result;
      },
      error: (err: HttpErrorResponse) => {
        this.alertService.error(
          this.alertService.getFormattedErrorMessage(err.error) ??
            DEFAULT_ERROR_MESSAGE
        );
      },
    });
  }

  //#region MENU
  @HostListener('document:click', ['$event', '$event.target'])
  public onClick(event: MouseEvent, targetElement: HTMLElement): void {
    if (!targetElement) {
      return;
    }
    const clickedInside = targetElement.className.includes('action-menu');
    if (!clickedInside) {
      this.selectedParty = undefined;
    }
  }
  //#endregion

  partyAdded(party: Party) {
    this.loadParties();
  }

  viewParty(id: number) {
    this.router.navigateByUrl(`${HOME_ROUTE.PARTY_DETAIL}?id=${id}`);
  }

  deleteParty(id: number) {
    // Miscellaneous party can't be deleted.
    if (
      this.partyList.find((x) => x.id == id)?.name?.toLowerCase() ===
      'miscellaneous'
    ) {
      this.alertService.error(`Oops! Miscellaneous party can't be deleted`);
      return;
    }
    this.confirmModalService.error({
      showHeader: false,
      content: 'Are you sure you want to delete this party?',
      okText: 'Delete',
      onOkClicked: () => {
        this.confirmModalService.clear();
        this.partyService.deleteParty(id).subscribe({
          next: () => {
            this.alertService.success('Party deleted successfully.');
            this.loadParties();
          },
          error: (err: HttpErrorResponse) => {
            this.alertService.error(
              this.alertService.getFormattedErrorMessage(err.error) ??
                DEFAULT_ERROR_MESSAGE
            );
          },
        });
      },
    });
  }

  gotoDetail(id: number) {
    if (!this.selectedParty)
      this.router.navigateByUrl(`${HOME_ROUTE.PARTY_DETAIL}?id=${id}`);
  }

  editParty(id: number) {
    // Miscellaneous party can't be edited.
    if (
      this.partyList.find((x) => x.id == id)?.name?.toLowerCase() ===
      'miscellaneous'
    ) {
      this.alertService.error(`Oops! Miscellaneous party can't be edited`);
      return;
    }
    var res =
      this.confirmModalService.viewContainerRef.createComponent(
        PartyFormComponent
      );
    res.instance.toEdit = true;
    res.instance.businessTransaction = this.businessTransaction;
    res.instance.editValue = this.partyList.find((x) => x.id === id)!;
    res.instance.added.subscribe(() => {
      this.loadParties();
      this.confirmModalService.clear();
      this.alertService.success('Party updated successfully');
    });
    res.instance.closed.subscribe(() => {
      this.confirmModalService.clear();
    });
  }
}
