import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { LocalStorageService } from 'src/app/services/local.storage.service';

@Injectable({
  providedIn: 'root',
})
export class PurposeService {
  constructor(
    private api: ApiService,
    private localStorage: LocalStorageService
  ) {}

  getPurposeCategories(
    transactionType = 'in',
    isCredit = false,
    businessTransaction = false,
    sub_type: string
  ) {
    const params = new HttpParams()
      .set('transaction_type', transactionType)
      .set('is_credit', isCredit.toString())
      .set(
        'business_account',
        businessTransaction ? this.localStorage.business_account : ''
      )
      .set(
        'sub_type',
        sub_type == 'Khatapana Pro' ||
          (sub_type === 'Khatapana Plus' && !businessTransaction)
          ? 'Khatapana Pro,Khatapana Standard'
          : sub_type
      );
    return this.api.get('/api/transaction/purpose/category/', params);
  }
}
