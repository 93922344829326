import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private api: ApiService) {}
  updatePassword(oldPassword: any, newPassword: any) {
    return this.api.post('/password/change/', {
      old_password: oldPassword,
      new_password: newPassword,
    });
  }
  updateProfileImage(file: any) {
    const formData = new FormData();
    formData.append('profile_image', file);
    return this.api.patchFile('/profile/update/', formData);
  }
}
