<khatapana-modal [width]="1200">
  <div class="p-6 flex flex-col gap-8 max-md:overflow-auto">
    <!-- Header -->
    <div class="text-2xl font-semibold">
      <h4 class="text-center">Choose khatapana plan</h4>
      <span
        (click)="close()"
        class="material-symbols-outlined cursor-pointer fixed right-5 top-5"
      >
        cancel
      </span>
    </div>

    <!-- Plan Chooser -->
    <div class="flex justify-center w-full">
      <khatapana-plan-type-tab
        class="w-3/6 text-sm"
        [selectedTab]="proSelectedTab"
        (changeTab)="changePaymentPlan($event)"
        [subscriptionPlans]="proSubscriptionPlans"
      ></khatapana-plan-type-tab>
    </div>

    <!-- BODY FOR SUBSCRIPTION PLANS -->
    <div class="flex w-full gap-6 max-md:flex-col">
      <!-- STANDARD  -->
      <div
        class="border-[#E2E2E2] border-[1px] w-2/6 rounded-md p-5 flex flex-col gap-2 items-center max-md:w-full"
      >
        <img src="assets/icons/account/standard.svg" />
        <h1 class="text-lg font-semibold">Khatapana- Standard</h1>
        <p class="text-sm text-[#333333]">Free Forever</p>
        <div class="flex w-full justify-center py-4">
          <khatapana-button
            border="circle"
            [outline]="true"
            color="basic"
            [disabled]="true"
            variant="extra-small"
          >
            <span class="flex w-full px-4">{{
              currentPlan === KHATAPANA_ACCOUNT.STANDARD
                ? "Current Plan"
                : "Already Features"
            }}</span>
          </khatapana-button>
        </div>
        <div class="item-start flex flex-col">
          <khatapana-plan-detail-item
            *ngFor="let detail of standardDetails"
            [space]="'little'"
            [bottomLine]="false"
            [subtitle]="detail.title"
            color="primary"
          ></khatapana-plan-detail-item>
        </div>
      </div>
      <!-- PRO -->
      <div
        class="border-[#E2E2E2] border-[1px] w-2/6 rounded-md p-5 flex flex-col gap-2 items-center max-md:w-full"
      >
        <img src="assets/icons/account/standard.svg" />
        <h1 class="text-lg font-semibold">Khatapana- Pro</h1>
        <p class="text-sm text-[#333333]">Recommended</p>
        <div class="flex w-full justify-center py-4">
          <khatapana-button
            border="circle"
            [outline]="
              currentPlan === KHATAPANA_ACCOUNT.PRO ||
              currentPlan === KHATAPANA_ACCOUNT.PLUS
            "
            [color]="
              currentPlan === KHATAPANA_ACCOUNT.PRO ||
              currentPlan === KHATAPANA_ACCOUNT.PLUS
                ? 'basic'
                : 'primary'
            "
            [disabled]="
              currentPlan === KHATAPANA_ACCOUNT.PRO ||
              currentPlan === KHATAPANA_ACCOUNT.PLUS
            "
            (onClick)="
              currentPlan === KHATAPANA_ACCOUNT.PRO
                ? null
                : subscribe(PRO_SUBSRICPTION_ID)
            "
            variant="extra-small"
          >
            <span class="flex w-full gap-2 px-4">
              <span
                *ngIf="
                  currentPlan === KHATAPANA_ACCOUNT.PRO ||
                    currentPlan === KHATAPANA_ACCOUNT.PLUS;
                  else proPriceButton
                "
                >{{
                  currentPlan === KHATAPANA_ACCOUNT.PRO
                    ? "Current Plan"
                    : "Already Features"
                }}</span
              >
              <ng-template #proPriceButton>
                <span class="line-through">{{
                  "Rs. " + (proSelectedTab?.price! + 200)
                }}</span>

                {{ " Rs. " + proSelectedTab?.price }}
              </ng-template></span
            >
          </khatapana-button>
        </div>
        <div class="item-start flex flex-col">
          <khatapana-plan-detail-item
            *ngFor="let detail of proDetails"
            [space]="'little'"
            [bottomLine]="false"
            [subtitle]="detail.title"
            color="primary"
          ></khatapana-plan-detail-item>
        </div>
      </div>
      <!-- PLUS -->
      <div
        class="border-[#E2E2E2] border-[1px] w-2/6 rounded-md p-5 flex flex-col gap-2 items-center max-md:w-full"
      >
        <img src="assets/icons/account/standard.svg" />
        <h1 class="text-lg font-semibold">Khatapana- Plus</h1>
        <p class="text-sm text-[#333333]">Best For Business</p>
        <div class="flex w-full justify-center py-4">
          <khatapana-button
            border="circle"
            [outline]="currentPlan === KHATAPANA_ACCOUNT.PLUS"
            [color]="
              currentPlan === KHATAPANA_ACCOUNT.PLUS ? 'basic' : 'primary'
            "
            [disabled]="currentPlan === KHATAPANA_ACCOUNT.PLUS"
            (onClick)="
              currentPlan === KHATAPANA_ACCOUNT.PLUS
                ? null
                : subscribe(PLUS_SUBSRICPTION_ID)
            "
            variant="extra-small"
          >
            <span class="flex w-full gap-2 px-4">
              <span
                *ngIf="
                  currentPlan === KHATAPANA_ACCOUNT.PLUS;
                  else plusPriceButton
                "
                >Current Plan</span
              >
              <ng-template #plusPriceButton>
                <span class="line-through">{{
                  "Rs. " + (plusSelectedTab?.price! + 200)
                }}</span>

                {{ " Rs. " + plusSelectedTab?.price }}
              </ng-template></span
            >
          </khatapana-button>
        </div>
        <div class="item-start flex flex-col">
          <khatapana-plan-detail-item
            *ngFor="let detail of plusDetails"
            [space]="'little'"
            [bottomLine]="false"
            [subtitle]="detail.title"
            color="primary"
          ></khatapana-plan-detail-item>
        </div>
      </div>
    </div>
  </div>
</khatapana-modal>

<!-- Payment Modes Modal -->
<khatapana-payment-modal
  *ngIf="showPayModal"
  [selectedPlan]="selectedSubscription"
  (close)="showPayModal = false"
  (payOption)="payWithMode($event)"
></khatapana-payment-modal>
