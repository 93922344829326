import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { AlertService } from 'src/app/modules/shared/services/alert.service';
import { ConfirmModalService } from 'src/app/modules/shared/services/confirm.modal.service';
import { LocalStorageService } from 'src/app/services/local.storage.service';
import { DEFAULT_ERROR_MESSAGE } from 'src/constants';
import { HOME_ROUTE } from '../../../home-routing.module';
import { SubscriptionDialogComponent } from '../../../shared/components/subscription-dialog/subscription-dialog.component';
import { BankResponse } from '../../../shared/models/bank';
import { PartyResponse } from '../../../shared/models/party';
import { TransactionResponse } from '../../../shared/models/transaction';
import { TRANSACTION_MODES_ACCOUNT } from '../../../shared/models/transaction-type-form';
import { WalletResponse } from '../../../shared/models/wallet';
import { AmountService } from '../../../shared/services/amount.service';
import { PartyService } from '../../../shared/services/party.service';
import { TransactionService } from '../../../shared/services/transaction.service';

@Component({
  selector: 'khatapana-transaction-form-content',
  templateUrl: './transaction-form-content.component.html',
  styleUrls: ['./transaction-form-content.component.scss'],
})
export class TransactionFormContentComponent implements OnInit {
  isStandard = false;
  HOME_ROUTES = HOME_ROUTE;

  // Transaction Type
  selectedTransactionType = 'out';
  transactionModes = TRANSACTION_MODES_ACCOUNT;
  selectedTransactionMode = TRANSACTION_MODES_ACCOUNT[0].id;

  // Transaction Mode
  userBanks: BankResponse[] = [];
  bankBalance = 0;
  selectedBankId!: number;
  showBanks = false;
  userWallets: WalletResponse[] = [];
  walletBalance = 0;
  showWallets = false;
  selectedWalletId!: number;

  // Purpose related
  showPurposeModal = false;
  selectedPurpose: any;

  // Party related
  showPartyModal = false;
  selectedPartyDue = false;
  selectedParty!: PartyResponse;
  miscParty!: PartyResponse;
  genParty!: PartyResponse;
  @ViewChild('partyModal') partyModal!: ElementRef;

  // File related
  transactionFile: File[] | null = [];
  transactionFileName: string = 'Upload photos of bills/invoice/reciept';
  fileSelected: boolean = false;

  // Transactions
  transactionDate = new Date();
  transactionDetail: string = '';
  transactionAmount?: number;
  totalBalance = 0;

  // Credit
  isCredit = false;
  showDueDateModal = false;
  remindForDue = false;
  today = new Date();
  creditDueDate: any = `${this.today.getFullYear()}-${
    this.today.getMonth() + 1
  }-${this.today.getDate()}`;

  formData!: FormData;

  // Edit
  @Input() toEditTransactionDetail?: TransactionResponse;
  @Output() close = new EventEmitter<boolean>();
  @Input() businessTransaction = false;
  @Output() edited = new EventEmitter<boolean>();

  // Settle Due
  showCreditSettlementModal = false;
  isDueSettlement: any = false;
  isPayable = false;

  // Bank, Wallet modal
  showBankAddEditModal = false;
  showWalletAddEditModal = false;

  constructor(
    private amountService: AmountService,
    private transactionService: TransactionService,
    private router: Router,
    private route: ActivatedRoute,
    private partyService: PartyService,
    private alertService: AlertService,
    private localStorage: LocalStorageService,
    private modalService: ConfirmModalService
  ) {}

  ngOnInit() {
    this.isStandard = this.localStorage.isStandard;

    //#region For Settle
    this.isDueSettlement = this.route.snapshot.queryParams?.['isDueSettlement'];
    this.transactionAmount = this.route.snapshot.queryParams?.['amount'];
    this.selectedParty = this.route.snapshot.queryParams?.['selectedParty'];
    this.isPayable =
      this.route.snapshot.queryParams?.['isPayable'] === 'false' ? false : true;
    if (this.isPayable === false) {
      this.changeTransactionType('in');
    } else {
      this.changeTransactionType('out');
    }
    if (this.isDueSettlement === 'true') {
      var temp = JSON.parse(JSON.stringify(this.transactionModes));
      temp.splice(3, 2);
      this.transactionModes = JSON.parse(JSON.stringify(temp));
    }
    //#endregion
    this.loadCash();
    this.loadBanks();
    this.loadWallets();
    this.loadMiscParty();
    this.loadGeneralParty();
    if (this.toEditTransactionDetail) {
      this.patchEditTransaction();
    }
  }

  clearForm() {
    this.transactionAmount = undefined;
    this.selectedParty = undefined!;
    this.selectedPurpose = null;
    this.transactionDetail = '';
  }

  patchEditTransaction() {
    // Type
    this.selectedTransactionType =
      this.toEditTransactionDetail!.transaction_type;
    // Mode
    this.selectedTransactionMode =
      this.toEditTransactionDetail?.transaction_mode!;
    // Amount
    this.transactionAmount = this.toEditTransactionDetail?.amount;
    // Bank, Wallet
    this.selectedBankId = this.toEditTransactionDetail?.user_bank?.id;
    this.selectedWalletId = this.toEditTransactionDetail?.user_wallet?.id;
    // Purpose
    this.selectedPurpose = this.toEditTransactionDetail?.purpose;
    // Party
    this.selectedParty = this.toEditTransactionDetail?.party!;
    // Detail
    this.transactionDetail = this.toEditTransactionDetail?.description!;
    // Attachment
  }

  changeTransactionType(type: string) {
    this.selectedTransactionType = type;
  }

  changeTransactionMode(txnMode: string) {
    this.isCredit = false;
    switch (txnMode) {
      case 'cash':
        // Cash
        this.selectedTransactionMode = txnMode;
        this.loadCash();
        break;
      case 'bank':
        // Bank
        this.showBanks = true;
        if (this.userBanks.length > 0) {
          this.selectedTransactionMode = txnMode;
          this.totalBalance = this.userBanks[0].balance;
        }
        break;
      case 'wallet':
        // Wallet
        this.showWallets = true;
        if (this.userWallets.length > 0) {
          this.selectedTransactionMode = txnMode;
          this.totalBalance = this.userWallets[0].balance;
        }
        break;
      case 'credit':
        // Credit
        this.selectedTransactionMode = txnMode;
        this.isCredit = true;
        this.totalBalance = 0;
        break;
    }
  }

  newBankAdded() {
    this.showBankAddEditModal = false;
    this.loadBanks();
    this.selectedTransactionMode = 'bank';
  }

  newWalletAdded() {
    this.showWalletAddEditModal = false;
    this.loadWallets();
    this.selectedTransactionMode = 'wallet';
  }

  changeCreditDueDate(event: any) {
    this.creditDueDate = `${event.year}-${event.month}-${event.day}`;
  }

  // Cash Bank and wallets

  loadCash() {
    this.amountService.getCashBalance(this.businessTransaction).subscribe({
      next: (res) => {
        this.totalBalance = res.results[0].balance;
      },
    });
  }

  loadBanks() {
    this.amountService.getBankBalance(this.businessTransaction).subscribe({
      next: (res: { results: BankResponse[]; total_bank_balance: any }) => {
        this.bankBalance = res.total_bank_balance.total;
        this.userBanks = res.results;
        this.userBanks.length > 0
          ? (this.selectedBankId = this.userBanks[0].id)
          : null;
      },
    });
  }

  loadWallets() {
    this.amountService.getWalletBalance(this.businessTransaction).subscribe({
      next: (res: { results: WalletResponse[]; total_wallet_balance: any }) => {
        this.walletBalance = res.total_wallet_balance.total;
        this.userWallets = res.results;
        this.userWallets.length > 0
          ? (this.selectedWalletId = this.userWallets[0].id)
          : null;
      },
    });
  }

  loadMiscParty() {
    this.partyService.getParties().subscribe({
      next: (items: PartyResponse[]) => {
        this.miscParty = items.find((x) => x.name === 'miscellaneous')!;
      },
    });
  }

  loadGeneralParty() {
    this.partyService.getParties(true).subscribe({
      next: (items: PartyResponse[]) => {
        this.genParty = items.find(
          (x) => x.name?.toLowerCase() === 'general party'
        )!;
      },
    });
  }

  bankChanged(bank: BankResponse) {
    this.selectedBankId = bank.id;
    this.showBanks = false;
    this.totalBalance = bank.balance;
  }

  walletChanged(wallet: WalletResponse) {
    this.selectedWalletId = wallet.id;
    this.showWallets = false;
    this.totalBalance = wallet.balance;
  }

  // Purpose
  savePurpose(event: any) {
    this.selectedPurpose = event.purpose;
    if (!event.party_mandatory && !this.isCredit) {
      // Auto select Miscallenous as party.
      if (this.businessTransaction) {
        this.selectedParty = this.genParty;
      } else {
        this.selectedParty = this.miscParty;
      }
    }
  }

  // Party
  saveParty(party: any) {
    this.selectedParty = party;
  }

  @HostListener('document:click', ['$event', '$event.target'])
  public onClick(event: MouseEvent, targetElement: HTMLElement): void {
    if (!targetElement) {
      return;
    }
    const clickedInside =
      this.partyModal?.nativeElement.contains(targetElement);
    if (!clickedInside) {
      this.showPartyModal = false;
    }
  }

  // File selection
  fileBrowseHandler(ev: any) {
    this.transactionFile?.push(...ev.target.files);
    this.fileSelected = true;
  }

  removeFile(file: File) {
    this.transactionFile?.splice(
      this.transactionFile.findIndex((x) => x == file),
      1
    );
  }

  saveTransaction() {
    if (this.isDueSettlement ? this.isAmountValid() : this.isFormInvalid()) {
      return;
    }
    const formData = new FormData();
    // Creating Payload //
    if (this.transactionFile!.length > 0) {
      this.transactionFile?.forEach((file: File) => {
        formData.append('documents', file);
      });
    }
    formData.append(
      'party',
      this.isDueSettlement
        ? this.selectedParty.toString()
        : this.selectedParty?.id!.toString()
    );
    if (!this.isDueSettlement)
      formData.append('purpose', this.selectedPurpose?.id!.toString());
    formData.append('transaction_type', this.selectedTransactionType);
    formData.append('amount', this.transactionAmount!.toString());
    formData.append('description', this.transactionDetail.toString());
    formData.append(
      'created_date',
      moment(new Date()).format('YYYY-MM-DD HH:mm')
    );
    switch (this.selectedTransactionMode) {
      case 'cash':
        formData.append('transaction_mode', 'cash');
        formData.append('is_cash', 'true');
        break;
      case 'bank':
        formData.append('transaction_mode', 'bank');
        formData.append('user_bank', this.selectedBankId.toString());
        break;
      case 'wallet':
        formData.append('transaction_mode', 'wallet');
        formData.append('user_wallet', this.selectedWalletId.toString());
        break;
      case 'credit':
        formData.append('transaction_mode', 'credit');
        break;
    }
    this.formData = formData;
    // Credit Due Settlement
    if (
      this.selectedParty!.transaction_amount?.due_amount! > 0 &&
      this.selectedTransactionMode !== 'credit' &&
      this.selectedTransactionMode !== 'transfer' &&
      !this.isDueSettlement &&
      this.selectedPurpose.name.toLowerCase() != 'credit settlement'
    ) {
      this.showCreditSettlementModal = true;
      return;
    }

    if (this.isDueSettlement) {
      this.settleDue();
      return;
    }
    // Incase of Credit Transaction, show Popup to add due date.
    this.selectedTransactionMode === 'credit'
      ? (this.showDueDateModal = true)
      : this.callTransactionApi();
  }

  AddCreditDueDate() {
    if (this.creditDueDate) {
      this.formData.append('due_date', this.creditDueDate);
      this.formData.append('remind_me', this.remindForDue.toString());
      this.callTransactionApi();
    } else {
      this.alertService.error('Due date is required.');
    }
  }

  addNewTransaction() {
    this.showCreditSettlementModal = false;
    this.callTransactionApi();
  }

  callTransactionApi() {
    if (this.toEditTransactionDetail) {
      this.editApi();
    } else {
      this.addApi();
    }
  }

  addApi() {
    // If user have selected Credit settlement
    if (this.selectedPurpose.name.toLowerCase() == 'credit settlement') {
      console.log(this.selectedPurpose.name);
      // this.formData.append('is_settlement', true.toString());
      this.transactionService.doCreditSettlement(this.formData).subscribe({
        next: (res) => {
          this.alertService.success('Your due has been settled');
          this.router.navigateByUrl(this.HOME_ROUTES.DASHBOARD);
        },
        error: (err: HttpErrorResponse) => {
          this.alertService.error(
            this.alertService.getFormattedErrorMessage(err.error)
          );
        },
      });
      return;
    }
    if (this.businessTransaction) {
      this.formData.append(
        'business_account',
        this.localStorage.business_account.toString()
      );
    }
    this.transactionService.createTransaction(this.formData).subscribe({
      next: (res) => {
        this.alertService.success('Transaction added successfully.');
        this.showDueDateModal = false;
        this.router.navigateByUrl(HOME_ROUTE.TRANSACTION_LIST);
      },
      error: (err: HttpErrorResponse) => {
        this.alertService.error(
          this.alertService.getFormattedErrorMessage(err.error) ??
            DEFAULT_ERROR_MESSAGE
        );
      },
    });
  }

  editApi() {
    this.transactionService
      .editTransaction(this.toEditTransactionDetail?.id!, this.formData)
      .subscribe({
        next: (res) => {
          this.alertService.success('Transaction updated successfully.');
          this.showDueDateModal = false;
          this.edited.emit(true);
          this.close.emit(true);
        },
        error: (err: HttpErrorResponse) => {
          this.alertService.error(
            this.alertService.getFormattedErrorMessage(err.error) ??
              DEFAULT_ERROR_MESSAGE
          );
        },
      });
  }

  isAmountValid() {
    if (this.transactionAmount) {
      return false;
    }
    var errorMessage = `
    ${!this.transactionAmount ? 'Amount, ' : ''}
     is required.
    `;
    this.alertService.error(errorMessage);
    return true;
  }

  isFormInvalid() {
    if (
      this.selectedParty?.id &&
      this.selectedPurpose?.id &&
      this.transactionAmount
    ) {
      return false;
    }
    var errorMessage = `
    ${!this.transactionAmount ? 'Amount, ' : ''}
    ${!this.selectedPurpose?.id ? 'Purpose, ' : ''}
    ${!this.selectedParty?.id ? 'Party' : ''} is required.
    `;
    this.alertService.error(errorMessage);
    return true;
  }

  openSubscriptionDialog() {
    this.modalService.viewContainerRef.createComponent(
      SubscriptionDialogComponent
    );
  }

  gotoTransfer() {
    this.router.navigateByUrl(HOME_ROUTE.TRANSFER);
  }

  settleDue() {
    this.showCreditSettlementModal = false;
    if (!this.isDueSettlement) {
      this.router
        .navigateByUrl('/', { skipLocationChange: true })
        .then(() =>
          this.router.navigateByUrl(
            `${HOME_ROUTE.TRANSACTION_ADD}?isDueSettlement=true&selectedParty=${
              this.selectedParty?.id
            }&isPayable=${
              this.selectedParty?.transaction_amount?.receivable_due! > 0
                ? true
                : false
            }&amount=${
              this.selectedParty?.transaction_amount?.receivable_due! > 0
                ? this.selectedParty?.transaction_amount?.receivable_due!
                : this.selectedParty?.transaction_amount?.payable_due!
            }`
          )
        );
      return;
    }
    // this.formData.append('is_settlement', true.toString());
    this.transactionService.doCreditSettlement(this.formData).subscribe({
      next: (res) => {
        this.alertService.success('Your due has been settled');
        this.router.navigateByUrl(this.HOME_ROUTES.DASHBOARD);
      },
      error: (err: HttpErrorResponse) => {
        this.alertService.error(
          this.alertService.getFormattedErrorMessage(err.error)
        );
      },
    });
  }
}
