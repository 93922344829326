import { Component, ViewContainerRef } from '@angular/core';
import { environment } from 'src/environments/environment';
import { I18nService } from './modules/core/i18n/i18n.service';
import { AlertService } from './modules/shared/services/alert.service';
import { ConfirmModalService } from './modules/shared/services/confirm.modal.service';
import { ToasterService } from './modules/shared/services/toaster.service';

@Component({
  selector: 'khatapana-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'khatapana-v2';

  constructor(
    private toasterService: ToasterService,
    private confirmModalService: ConfirmModalService,
    private alertService: AlertService,
    private i18nService: I18nService,
    private viewContainerRef: ViewContainerRef
  ) {
    this.toasterService.setviewContainerRef = this.viewContainerRef;
    this.confirmModalService.setviewContainerRef = this.viewContainerRef;
    this.alertService.setviewContainerRef = this.viewContainerRef;
    this.i18nService.init(
      environment.defaultLanguage,
      environment.supportedLanguages
    );
  }
}
