import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { LocalStorageService } from 'src/app/services/local.storage.service';

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  constructor(
    private api: ApiService,
    private localStorage: LocalStorageService
  ) {}

  getDashboardData(isBusiness = false) {
    const params = new HttpParams().set(
      'business_account',
      isBusiness ? this.localStorage.business_account : ''
    );
    return this.api.get(
      '/api/dashboard/',
      isBusiness ? params : new HttpParams()
    );
  }

  getSummaryData(transactionMode: string, duration: string) {
    return this.api.get(
      `/api/dashboard/balance/?transaction_mode=${transactionMode}&query=${duration}`
    );
  }

  getGraphData(
    transactionMode: string,
    duration: string,
    start_date: string,
    end_date: string
  ) {
    duration = duration === 'daily' ? 'day' : duration;
    duration = duration.split('ly')[0];

    return this.api.get(
      `/api/dashboard/graph/?transaction_mode=${transactionMode}&graph_type=${duration}&start_date=${start_date}&end_date=${end_date}`
    );
  }

  getExpenseReport(startDate: string, endDate: string) {
    return this.api.get(
      `/api/dashboard/expense/report/?start_date=${startDate}&end_date=${endDate}`
    );
  }

  //#region Empty dashboard api's

  getCashBalance(isBusiness = false) {
    const params = new HttpParams().set(
      'business_account',
      isBusiness ? this.localStorage.business_account : ''
    );
    return this.api.get('/api/payment/cash/balance/', params);
  }

  addCashBalance(balance: number, business_account: null | number = null) {
    return this.api.post('/api/payment/cash/balance/', {
      balance,
      business_account,
    });
  }

  adjustCashBalance(amount: number) {
    return this.api.post('/api/transaction/adjust-cash-balance/', {
      amount,
      is_cash: true,
    });
  }

  //#endregion
}
