export const TRANSACTIONTYPESFILTER = [
  {
    id: 'inout',
    name: 'Both',
  },
  {
    id: 'in',
    name: 'In',
  },
  {
    id: 'out',
    name: 'Out',
  },
];
