<div
  class="fixed top-4 z-[100] m-auto"
  style="left: 50%; transform: translateX(-50%)"
>
  <div @fade class="bg-[white] shadow-lg rounded-full p-2">
    <h1 class="text-[#141414] px-4 items-center flex gap-5 font-semibold">
      <span
        class="material-symbols-outlined"
        [ngClass]="{
          'text-[red]': type === 'error',
          'text-[green]': type === 'check_circle',
          'text-[blue]': type === 'info'
        }"
      >
        {{ type }}
      </span>
      {{ message }}
    </h1>
  </div>
</div>
