<khatapana-card [border]="true" [class]="'bg-white'">
  <section class="p-6">
    <div
      class="h-48 bg-cover flex p-6 rounded-lg justify-between items-start"
      [style.backgroundImage]="'url(assets/icons/home/referral_back.svg)'"
    >
      <div class="flex flex-col gap-1 font-semibold">
        <p class="text-[#545454]">
          {{ "referral.my_khatapana_coin" | translate }}
        </p>
        <p class="text-4xl">
          {{ userCoin?.balance }} {{ "common.coins" | translate }}
        </p>
        <p class="text-xl pt-8 text-[#686868]">
          {{ "referral.how_kp_coin" | translate }}
        </p>
      </div>
      <img width="65" src="assets/icons/home/referral_badge.svg" />
    </div>
  </section>
  <khatapana-divider></khatapana-divider>
  <section class="p-6 flex flex-col gap-4">
    <h1 class="text-xl font-semibold">
      {{ "referral.referral_code" | translate }}
    </h1>
    <div class="flex gap-6">
      <div
        class="border-[1px] w-full rounded-md justify-between border-[#b6b6b6] flex items-center pl-4 py-1"
      >
        <h1 class="text-lg font-semibold text-[#8b8b8b] max-xl:text-sm">
          {{ referralLink }}
        </h1>
        <khatapana-button
          [link]="true"
          variant="small"
          (onClick)="copyLink()"
          >{{ "common.copy_link" | translate }}</khatapana-button
        >
      </div>
      <khatapana-button
        border="little"
        variant="small"
        (onClick)="showModal = true"
        >{{ "common.share" | translate }}</khatapana-button
      >
    </div>
  </section>
</khatapana-card>

<khatapana-reffer-modal
  (closed)="showModal = false"
  [referralLink]="referralLink"
  *ngIf="showModal"
>
</khatapana-reffer-modal>
