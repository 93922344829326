import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { KHATAPANA_ACCOUNT } from 'src/app/model/khatapana.account';
import { BusinessAccountResponse } from 'src/app/modules/account/shared/models/business.account';
import { AccountService } from 'src/app/modules/account/shared/services/account.service';
import { BUSINESS_HOME_ROUTE } from 'src/app/modules/business/business-routing.module';
import { HOME_ROUTE } from 'src/app/modules/home/home-routing.module';
import { SubscriptionUpgradeDialogComponent } from 'src/app/modules/home/shared/components/subscription-dialog-for-subs/subscription-dialog.component';
import { LocalStorageService } from 'src/app/services/local.storage.service';
import { ConfirmModalService } from '../../services/confirm.modal.service';
import { CreateBusinessStoreComponent } from '../create-business-store/create-business-store.component';

@Component({
  selector: 'khatapana-account-switcher',
  templateUrl: './account-switcher.component.html',
})
export class AccountSwitcherComponent implements OnInit {
  @Input() type: 'business' | 'personal' = 'personal';
  plan = '';
  noOfAccounts = 0;
  KHATAPANA_ACCOUNT = KHATAPANA_ACCOUNT;
  constructor(
    private router: Router,
    private accountService: AccountService,
    private modalService: ConfirmModalService,
    private localStorage: LocalStorageService
  ) {}

  ngOnInit(): void {
    // After just subscribing it is required.
    this.accountService.getBusinessAccount().subscribe({
      next: (res: BusinessAccountResponse[]) => {
        this.noOfAccounts = res.length;
      },
    });
    setTimeout(() => {
      this.plan = this.localStorage.plan;
    }, 100);
  }

  changedType(type: string) {
    if (type == 'business') {
      if (this.plan === this.KHATAPANA_ACCOUNT.PLUS) {
        // Check If bussinesss account exists or not, if not open create popup
        this.accountService.getBusinessAccount().subscribe({
          next: (res: BusinessAccountResponse[]) => {
            this.noOfAccounts = res.length;
            if (res.length == 0) {
              // Show Add Business Modal
              let res = this.modalService.viewContainerRef.createComponent(
                CreateBusinessStoreComponent
              );
              res.instance.fromDashboard = true;
            } else {
              this.localStorage.business_account = res[0].id;
              this.router.navigateByUrl(BUSINESS_HOME_ROUTE.DASHBOARD);
            }
          },
        });
      } else {
        this.openSubscriptionDialog();
      }
    } else {
      this.router.navigateByUrl(HOME_ROUTE.DASHBOARD);
    }
  }

  openSubscriptionDialog() {
    var ref = this.modalService.viewContainerRef.createComponent(
      SubscriptionUpgradeDialogComponent
    );
    ref.instance.showPro = false;
  }

  createNewStore() {
    let res = this.modalService.viewContainerRef.createComponent(
      CreateBusinessStoreComponent
    );
    res.instance.fromDashboard = true;
  }
}
