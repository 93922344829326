export const TRANSACTION_MODES_ACCOUNT = [
  {
    id: 'cash',
    icon: 'local_atm',
    label: 'Cash',
  },
  {
    id: 'bank',
    icon: 'account_balance',
    label: 'Bank',
  },
  {
    id: 'wallet',
    icon: 'wallet',
    label: 'Wallet',
  },
  {
    id: 'credit',
    icon: 'approval_delegation',
    label: 'Credit',
  },
];
