import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { map } from 'rxjs';
import { AUTH_ROUTES } from 'src/app/modules/auth/auth-routing.module';
import { HOME_ROUTE } from 'src/app/modules/home/home-routing.module';
import { SETTING_ROUTES } from 'src/app/modules/home/pages/setting/setting-routing.module';
import { LocalStorageService } from 'src/app/services/local.storage.service';
import { selectUserDetails, selectUserState } from 'src/app/store/user/user.actions';

@Component({
  selector: 'khatapana-profile-dropdown-tab',
  templateUrl: './profile-dropdown-tab.component.html',
})
export class ProfileDropdownTabComponent {
  // user$ = this.localStorage.currentUser$;
  user$ = this.store.select(selectUserDetails);
  showProfileDropdownTab = false;
  @ViewChild('modal') modal!: ElementRef;

  SETTING_ROUTES = SETTING_ROUTES;
  HOME_ROUTES = HOME_ROUTE;
  constructor(
    private localStorage: LocalStorageService,
    private router: Router,
    private store: Store
  ) {}

  openProfileNav() {
    this.showProfileDropdownTab = !this.showProfileDropdownTab;
  }

  @HostListener('document:click', ['$event', '$event.target'])
  public onClick(event: MouseEvent, targetElement: HTMLElement): void {
    if (!targetElement) {
      return;
    }
    const clickedInside = this.modal?.nativeElement.contains(targetElement);
    if (!clickedInside) {
      this.showProfileDropdownTab = false;
    }
  }

  signOut() {
    this.localStorage.resetLocalStorage();
    // localStorage.clear();
    document.cookie.split(';').forEach(function (c) {
      document.cookie = c
        .replace(/^ +/, '')
        .replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/');
    });
    window.location.reload();
    this.router.navigate([AUTH_ROUTES.LOGIN]);
  }

  GOTO(route: string) {
    this.showProfileDropdownTab = false;
    this.router.navigate([route]);
  }
}
