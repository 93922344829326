<div class="flex w-full h-full overflow-x-clip">
  <!-- Sidebar -->
  <khatapana-layout
    class="fixed z-[1000] h-full max-lg:bottom-0 max-lg:h-auto"
  ></khatapana-layout>
  <div class="flex flex-col ml-64 w-full max-lg:ml-0">
    <!-- Navbar -->
    <khatapana-nav-bar
      class="fixed z-[1000] top-0 right-0 left-64 max-lg:left-0"
    ></khatapana-nav-bar>
    <!-- Content Screen -->
    <router-outlet class="mt-20 overflow-y-scroll"></router-outlet>
  </div>
</div>
