import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/modules/auth/shared/services/auth.service';
import { SharedModule } from 'src/app/modules/shared/shared.module';
import { LocalStorageService } from 'src/app/services/local.storage.service';
import { ACCOUNT_ROUTES } from '../../../account-routing.module';
import { Invoice } from '../../models/invoice';
import { Subscribe, SUBSCRIPTION_ID } from '../../models/subscriptions.model';
import { AccountService } from '../account.service';
import { MERCHANT_CODE } from './ESEWA.CONSTANTS';
import { AlertService } from 'src/app/modules/shared/services/alert.service';
import { HttpErrorResponse } from '@angular/common/http';
import { DEFAULT_ERROR_MESSAGE } from 'src/constants';
declare function successToFlutter(): any;

@Component({
  selector: 'app-esewa',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './esewa.component.html',
})
export class EsewaComponent implements OnDestroy {
  // From Can be from Pay/Success/Failure
  from: string = 'pay';
  token?: string;
  // Catch when start esewa payment
  amount?: number;
  id?: string; // Product Id
  invoiceId?: number;
  subscriptionId: any;
  subscriptionPlan: any;
  paymentGatyewayId: any;

  // Catch from successfull esewa payment
  oid?: string;
  pid?: string;
  refId?: string;
  amt?: number;

  constructor(
    private _route: ActivatedRoute,
    private router: Router,
    private alertService: AlertService,
    private authService: AuthService,
    private localStorage: LocalStorageService,
    private accountService: AccountService
  ) {
    this.from = this._route.snapshot.queryParams['q'];
    if (this.from == 'pay') {
      this.id = this._route.snapshot.queryParams['id'];
      this.token = this._route.snapshot.queryParams['token'];
      this.amount = this._route.snapshot.queryParams['amount'];
      this.subscriptionId = Number(
        this._route.snapshot.queryParams['subscription_id']
      );
      this.subscriptionPlan = Number(
        this._route.snapshot.queryParams['subscription_plan']
      );
      this.paymentGatyewayId =
        this._route.snapshot.queryParams['payment_gateway_id'];
      if (this.token) {
        this.localStorage.token = this.token;
      }
      this.accountService.payWithEsewa(
        {
          productId: this.id!,
          amount: this.amount!,
        },
        this.subscriptionId,
        this.paymentGatyewayId,
        this.token
      );
    }
    if (this.from == 'su') {
      this.oid = this._route.snapshot.queryParams['oid'];
      this.token = this._route.snapshot.queryParams['token'];
      this.refId = this._route.snapshot.queryParams['refId'];
      this.amt = this._route.snapshot.queryParams['amt'];
      this.subscriptionId = this._route.snapshot.queryParams['subscription_id'];
      this.subscriptionPlan = Number(
        this._route.snapshot.queryParams['subscription_plan']
      );
      this.paymentGatyewayId =
        this._route.snapshot.queryParams['payment_gateway_id'];
      this.pid = this._route.snapshot.queryParams['pid'];
      this.confirmPayment();
    }
    if (this.from == 'fu') {
      var redirectUrlFromESEWA = localStorage.getItem('redirectUrlFromESEWA');
      this.router.navigateByUrl(
        redirectUrlFromESEWA ? redirectUrlFromESEWA : `/account`
      );
      localStorage.removeItem('redirectUrlFromESEWA');
    }
  }

  confirmPayment() {
    let subscibe: Subscribe = {
      subscription_plan: Number(this.subscriptionId),
      is_trial: false,
    };
    this.accountService.subscribeToKhatapana(subscibe).subscribe({
      next: (res: { invoice: Invoice }) => {
        this.invoiceId = res.invoice.id;
        var pay = {
          invoice: this.invoiceId,
          payment_gateway: this.paymentGatyewayId,
          pg_response: {
            pid: this.pid,
            amt: Number(this.amt),
            scd: MERCHANT_CODE,
            rid: this.refId,
          },
        };
        this.accountService.confirmPayment(pay).subscribe({
          next: (res: any) => {
            // if from mobile
            this.authService.loadUserToLocalStorage();
            if (this.token) {
              successToFlutter();
            } else {
              var redirectUrlFromESEWA = localStorage.getItem(
                'redirectUrlFromESEWA'
              );
              this.router.navigateByUrl(
                redirectUrlFromESEWA
                  ? redirectUrlFromESEWA
                  : this.subscriptionPlan == SUBSCRIPTION_ID.plus
                  ? ACCOUNT_ROUTES.PLUS_TYPE
                  : ACCOUNT_ROUTES.TRANSACTION
              );
              localStorage.removeItem('redirectUrlFromESEWA');
            }
          },
        });
      },
      error: (error: HttpErrorResponse) => {
        this.alertService.error(
          this.alertService.getFormattedErrorMessage(error.error) ??
            DEFAULT_ERROR_MESSAGE
        );
      },
    });
  }

  ngOnDestroy(): void {}
}
