import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService } from 'src/app/modules/shared/services/alert.service';
import { DEFAULT_ERROR_MESSAGE } from 'src/constants';
import { HOME_ROUTE } from '../../home-routing.module';
import { PartyResponse } from '../../shared/models/party';
import { PageTitleService } from '../../shared/services/page-title.service';
import { PartyService } from '../../shared/services/party.service';
import { TablePrintComponent } from '../transaction-list/table-print/table-print.component';

@Component({
  selector: 'khatapana-party-detail',
  templateUrl: './party-detail.component.html',
  styleUrls: ['./party-detail.component.scss'],
})
export class PartyDetailComponent implements OnInit {
  partyId: number = 0;
  party?: PartyResponse;
  transactionList: any;
  loading = false;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private confirmModalService: AlertService,
    private alertService: AlertService,
    private pageTitleService: PageTitleService,
    private partyService: PartyService
  ) {}

  ngOnInit(): void {
    this.pageTitleService.pageTitle$.next('Party - Transactions');
    this.pageTitleService.showBackButton$.next(true);
    this.partyId = Number(this.route.snapshot.queryParamMap.get('id'));
    this.loadPartyDetails();
  }

  loadPartyDetails() {
    if (this.partyId) {
      this.partyService.getSingleParty(this.partyId).subscribe({
        next: (result) => {
          this.party = result;
          this.pageTitleService.pageTitle$.next(result.name);
        },
        error: (err: HttpErrorResponse) => {
          this.alertService.error(
            this.alertService.getFormattedErrorMessage(err.error) ??
              DEFAULT_ERROR_MESSAGE
          );
        },
      });
    }
  }

  settleDue(payable: boolean, amount: number) {
    this.router.navigateByUrl(
      `${HOME_ROUTE.TRANSACTION_ADD}?isDueSettlement=true&isPayable=${payable}&selectedParty=${this.party?.id}&amount=${amount}`
    );
  }

  downloadReport() {
    if (this.transactionList.length === 0) {
      this.alertService.error(
        `You don't have any transactions report to download.`
      );
      return;
    }
    this.loading = true;
    const ref =
      this.confirmModalService.viewContainerRef.createComponent(
        TablePrintComponent
      );
    ref.instance.transactionList = this.transactionList;
    setTimeout(() => {
      this.loading = false;
      window.print();
    }, 2000);
  }
}
