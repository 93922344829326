import { DEFAULT_CURRENCY_CODE, NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { CookieService } from 'ngx-cookie-service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppleCallbackComponent } from './components/apple-callback/apple-callback.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';
import { tokenInterceptor } from './interceptor/refresh-token.interceptor';
import { CoreModule } from './modules/core/core.module';
import { I18nModule } from './modules/core/i18n/i18n.module';
import { SharedModule } from './modules/shared/shared.module';
import { userReducer } from './store/user/user.reducer';

@NgModule({
  declarations: [
    AppComponent,
    AppleCallbackComponent,
    UnauthorizedComponent,
    NotFoundComponent,
  ],
  imports: [
    CoreModule,
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    NgSelectModule,
    I18nModule,
    SharedModule,
    BrowserAnimationsModule,
    NgbDatepickerModule,
    StoreModule.forRoot({ user: userReducer }),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: !isDevMode() }),
  ],
  providers: [
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'Rs. ' },
    tokenInterceptor,
    CookieService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
