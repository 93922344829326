import { Component, Injectable, OnDestroy } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
@Component({
  selector: 'khatapana-alert',
  templateUrl: './alert.component.html',
})
export class AlertComponent implements OnDestroy {
  title = 'Success';
  message = 'Title Updated Successfully';
  type: 'error' | 'check_circle' | 'info' = 'info';
  removeAlert?: () => any;

  ngOnDestroy() {}
}
