import { Component } from '@angular/core';
import { PageTitleService } from '../../shared/services/page-title.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'khatapana-transaction-form',
  templateUrl: './transaction-form.component.html',
})
export class TransactionFormComponent {
  constructor(
    private pageTitleService: PageTitleService,
    private translate: TranslateService
  ) {
    this.translate
      .get('navbar.add_transaction')
      .subscribe((v) => this.pageTitleService.pageTitle$.next(v));
    this.pageTitleService.showBackButton$.next(true);
  }
}
