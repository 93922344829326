import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { KHATAPANA_ACCOUNT } from 'src/app/model/khatapana.account';
import { Invoice } from 'src/app/modules/account/shared/models/invoice';
import { PaymentOptions } from 'src/app/modules/account/shared/models/payment-option';
import { Purchase } from 'src/app/modules/account/shared/models/purchase';
import { SUBSCRIPTION_ID } from 'src/app/modules/account/shared/models/subscriptions.model';
import { AccountService } from 'src/app/modules/account/shared/services/account.service';
import { AuthService } from 'src/app/modules/auth/shared/services/auth.service';
import { AlertService } from 'src/app/modules/shared/services/alert.service';
import { ConfirmModalService } from 'src/app/modules/shared/services/confirm.modal.service';
import { LocalStorageService } from 'src/app/services/local.storage.service';
import { DEFAULT_ERROR_MESSAGE } from 'src/constants';
import {
  Subscribe,
  SubscriptionPlan,
  SubscriptionType,
} from '../../../../shared/models/subscription.model';
import { PLUS_DETAILS, PRO_DETAILS, STANDARD_DETAILS } from './Plan.constants';

@Component({
  selector: 'khatapana-subscription-dialog',
  templateUrl: './subscription-dialog.component.html',
})
export class SubscriptionUpgradeDialogComponent {
  showPro = true;
  upgradePlan = true;
  currentSubscription: any;

  currentPlan = '';
  KHATAPANA_ACCOUNT = KHATAPANA_ACCOUNT;
  //Standard Details
  standardDetails = STANDARD_DETAILS;
  proDetails = PRO_DETAILS;
  plusDetails = PLUS_DETAILS;

  proSubscriptionPlans: SubscriptionPlan[] = [];
  plusSubscriptionPlans: SubscriptionPlan[] = [];
  proSelectedTab: SubscriptionPlan | undefined;
  plusSelectedTab: SubscriptionPlan | undefined;

  selectedSubscription: any;
  invoiceId: any;
  payementGatewayId: any;

  PLUS_SUBSRICPTION_ID: number = SUBSCRIPTION_ID.plus;
  PRO_SUBSRICPTION_ID: number = SUBSCRIPTION_ID.pro;
  showPayModal = false;
  // User account
  currentUser$ = this.localStorage.currentUser$;
  constructor(
    private khatapanaService: AccountService,
    private router: Router,
    private authService: AuthService,
    private alertService: AlertService,
    private modalService: ConfirmModalService,
    private localStorage: LocalStorageService
  ) {}

  ngOnInit(): void {
    this.currentPlan = this.localStorage.plan;
    this.getSubscriptionType();
    if (this.upgradePlan == false) {
      this.currentUser$.subscribe({
        next: (res) => {
          this.currentSubscription = res.active_subscription;
        },
      });
    }
  }

  close() {
    this.modalService.viewContainerRef.clear();
  }

  getSubscriptionType() {
    this.khatapanaService.getSubscriptionTypes().subscribe({
      next: (res: { results: SubscriptionType[] }) => {
        res.results.forEach((subscription) => {
          this.getSubscriptionPlanDetail(subscription.id);
        });
      },
    });
  }

  getSubscriptionPlanDetail(id: any) {
    var sth = this.khatapanaService.getSubscriptionPlanDetail(id).subscribe({
      next: (res: { results: SubscriptionPlan[] }) => {
        if (id == this.PRO_SUBSRICPTION_ID) {
          this.proSubscriptionPlans = res.results;
          if (this.upgradePlan == false) {
            this.proSubscriptionPlans.splice(
              this.proSubscriptionPlans.findIndex(
                (x) => x.id == this.currentSubscription.subscription_plan.id
              ),
              1
            );
          }
        }
        if (id == this.PLUS_SUBSRICPTION_ID) {
          this.plusSubscriptionPlans = res.results;
        }
        this.proSelectedTab =
          this.proSubscriptionPlans[this.proSubscriptionPlans.length - 1];
        this.plusSelectedTab =
          this.plusSubscriptionPlans[this.plusSubscriptionPlans.length - 1];
        sth.unsubscribe();
      },
    });
  }

  changePaymentPlan(plan: SubscriptionPlan) {
    this.proSelectedTab = this.proSubscriptionPlans.find(
      (x) => x.activation_period == plan.activation_period
    );
    this.plusSelectedTab = this.plusSubscriptionPlans.find(
      (x) => x.activation_period == plan.activation_period
    );
  }

  subscribe(subscriptionId: number) {
    if (subscriptionId == this.PRO_SUBSRICPTION_ID) {
      this.showPayModal = true;
      this.selectedSubscription = this.proSelectedTab;
    }
    if (subscriptionId == this.PLUS_SUBSRICPTION_ID) {
      this.showPayModal = true;
      this.selectedSubscription = this.plusSelectedTab;
    }
  }

  payWithMode(paymode: PaymentOptions) {
    this.showPayModal = false;
    if (paymode.name.toLowerCase() == 'e-sewa') {
      this.payWithEsewa(paymode.id);
    } else if (paymode.name.toLowerCase() == 'khalti') {
      this.payWithKhalti(paymode.id);
    } else if (paymode.name == 'trial') {
      this.takeTrial();
    }
  }

  payWithEsewa(paymentGatewayId: number) {
    var productId = this.selectedSubscription?.id.toString();
    var productPrice = this.selectedSubscription?.price;
    localStorage.setItem('redirectUrlFromESEWA', window.location.pathname);
    this.router.navigateByUrl(
      `/account/esewa?q=pay&amount=${productPrice}&id=${productId!}&subscription_id=${
        this.selectedSubscription?.id
      }
        &subscription_type=${
          this.selectedSubscription.subscription_type.id
        }&payment_gateway_id=${paymentGatewayId}`
    );
  }

  payWithKhalti(paymentGatewayId: number) {
    var productId = this.selectedSubscription?.id.toString();
    var productName = `${this.selectedSubscription!.subscription_type}-${this
      .selectedSubscription!.subscription_plan!}-${
      this.selectedSubscription!.price
    }`;
    var productPrice = this.selectedSubscription?.price;
    this.payementGatewayId = paymentGatewayId;
    let purchase: Purchase = {
      productId: productId,
      amount: productPrice,
      productName: productName,
      productUrl: 'http://localhost:4200/choose-account/pro',
    };
    this.khatapanaService.payWithKhalti(purchase).then((payload) => {
      if (payload) {
        let subscibe: Subscribe = {
          subscription_plan: this.selectedSubscription.id,
          is_trial: false,
        };
        this.khatapanaService.subscribeToKhatapana(subscibe).subscribe({
          next: (res: { invoice: Invoice }) => {
            this.invoiceId = res.invoice.id;
            this.confirmPayment(payload);
          },
        });
      }
    });
  }

  takeTrial() {
    let subscibe: Subscribe = {
      subscription_plan: this.selectedSubscription?.id!,
      is_trial: true,
    };
    this.khatapanaService.subscribeToKhatapana(subscibe).subscribe({
      next: (res: { invoice: Invoice }) => {
        this.invoiceId = res.invoice.id;
        this.showPayModal = false;
        this.authService.loadUserToLocalStorage();
        this.close();
        setTimeout(() => {
          location.reload();
        }, 1000);
      },
      error: (err: HttpErrorResponse) => {
        this.alertService.error(
          this.alertService.getFormattedErrorMessage(err.error) ??
            DEFAULT_ERROR_MESSAGE
        );
      },
    });
  }

  confirmPayment(payload: any) {
    var pay = {
      invoice: this.invoiceId,
      payment_gateway: this.payementGatewayId,
      pg_response: {
        idx: payload.idx,
        token: payload.token,
        amount: payload.amount,
        productName: payload.productName,
        productIdentity: payload.productIdentity,
        mobile: payload.mobile,
        additionalData: payload.additionalData,
      },
    };
    this.khatapanaService.confirmPayment(pay).subscribe({
      next: (res) => {
        this.showPayModal = false;
      },
    });
  }
}
