<button
  routerLinkActive="active"
  [routerLink]="link"
  *ngIf="!upgradeButton"
  [ngClass]="{
    'p-4': variant === 'big',
    'py-2 px-4': variant === 'small',
    'flex-col gap-1 text-sm': mobile,
    ' hover:bg-[#7b61ff14] rounded-full gap-5': mobile == false
  }"
  class="flex w-full items-center font-semibold text-[#141414]"
>
  <span
    *ngIf="icon"
    routerLinkActive="fill"
    class="material-symbols-outlined font-semibold"
  >
    {{ icon }} </span
  >{{ label }}
</button>

<button
  *ngIf="upgradeButton"
  class="upgrade-button flex gap-3 items-center py-3 px-4 w-full rounded-xl justify-center"
>
  <img src="assets/icons/home/upgrade_plan.svg" />
  <span class="flex items-center font-semibold">Upgrade</span>
</button>
