<div
  class="flex flex-col py-8 gap-8 w-4/6 max-xl:w-[80%] mx-auto max-md:w-[85%]"
>
  <h4 class="text-2xl max-md:text-xl font-semibold max-md:text-center">
    {{
      "otp.header"
        | translate
          : {
              username: username.substring(0, 3) + "******" + username.slice(-3)
            }
    }}
  </h4>

  <form class="flex flex-col gap-4" [formGroup]="otpForm">
    <label class="text-center font-semibold text-[#141414]">{{
      "otp.enter_otp" | translate
    }}</label>
    <ng-otp-input
      [config]="otpConfig"
      (onInputChange)="otpChanged($event)"
      [formCtrl]="otp"
    ></ng-otp-input>
  </form>

  <div class="flex flex-col gap-6">
    <khatapana-button
      [color]="'basic'"
      [border]="'circle'"
      [variant]="'medium'"
      [disabled]="startTimimg || isLoading"
      (onClick)="resendOTP()"
      type="submit"
      ><span *ngIf="!startTimimg; else timer" class="text-center">{{
        "otp.resend_code" | translate
      }}</span>
      <ng-template #timer
        >{{ "common.resend" | translate }} 00:{{ timeCountdown < 10 ? "0" : ""
        }}{{ timeCountdown }}</ng-template
      >
    </khatapana-button>
  </div>

  <div class="flex justify-between">
    <khatapana-button
      [color]="'basic'"
      [border]="'circle'"
      (onClick)="pressBackButton()"
      [variant]="'big'"
      [disabled]="isLoading"
      type="submit"
      ><i class="fa-solid fa-arrow-left text-base"></i
    ></khatapana-button>
    <khatapana-button
      [color]="'basic'"
      [border]="'circle'"
      [variant]="'big'"
      [disabled]="disableNext || isLoading"
      [loading]="isLoading"
      type="submit"
      (onClick)="verifyOTP()"
      ><span class="text-center"
        >{{ "common.next" | translate }}
        <i class="fa-solid fa-arrow-right text-base pl-1"></i></span
    ></khatapana-button>
  </div>
</div>
