<khatapana-button
  *ngIf="!mobile; else mobileButton"
  class="max-lg:scale-[0.8]"
  color="basic"
  border="circle"
  variant="big"
  (onClick)="showProfileImageUploadModal = true"
  ><span class="px-4">{{ "common.upload" | translate }}</span></khatapana-button
>

<ng-template #mobileButton>
  <khatapana-button
    color="basic"
    border="circle"
    variant="no-padding"
    [outline]="true"
    (onClick)="showProfileImageUploadModal = true"
    ><span class="flex w-9 h-9 items-center justify-center"
      ><span class="material-symbols-outlined"> photo_camera </span></span
    ></khatapana-button
  >
</ng-template>

<khatapana-modal *ngIf="showProfileImageUploadModal" [width]="480">
  <section class="flex flex-col">
    <!-- Modal Header -->
    <header class="flex px-6 py-3 items-center text-2xl font-semibold">
      <i
        class="fa-solid fa-close mr-10 cursor-pointer"
        (click)="showProfileImageUploadModal = false"
      ></i>
      {{ "setting.upload_profile_image" | translate }}
    </header>
    <khatapana-divider></khatapana-divider>
    <!-- Modal Body -->
    <div class="flex justify-center p-6">
      <img
        class="w-52 h-52 rounded-full object-cover"
        [src]="
          selectedProfileImage == null
            ? profileImage == null
              ? 'assets/icons/home/empty_image.svg'
              : profileImage
            : imageSrc
        "
      />
    </div>
    <khatapana-divider></khatapana-divider>
    <!-- Modal Footer -->
    <footer class="flex px-6 py-3 items-center justify-between">
      <khatapana-button
        [disabled]="!selectedProfileImage"
        (onClick)="RemoveProfileImageModal()"
        border="little"
        color="basic"
      >
        <span class="flex gap-3 items-center px-3">
          <i class="fa-solid fa-trash-can"></i>
          {{ "common.remove" | translate }}</span
        ></khatapana-button
      >
      <khatapana-button
        color="secondary"
        border="little"
        (onClick)="
          !selectedProfileImage ? btnUpload.click() : updateProfilePic()
        "
      >
        <span class="flex gap-3 items-center px-3">
          <i
            class="{{ selectedProfileImage ? 'fa-solid' : 'fa-regular' }} {{
              selectedProfileImage ? 'fa-check' : 'fa-image'
            }}"
          ></i>
          {{
            selectedProfileImage
              ? ("common.save_changes" | translate)
              : ("common.change" | translate)
          }}</span
        >
      </khatapana-button>
    </footer>
  </section>
</khatapana-modal>

<!-- File Upload -->
<input
  type="file"
  class="hidden"
  #btnUpload
  (change)="fileBrowseHandler($event)"
/>
