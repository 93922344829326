import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { AlertService } from 'src/app/modules/shared/services/alert.service';
import { DEFAULT_ERROR_MESSAGE } from 'src/constants';
import { HOME_ROUTE } from '../../../home-routing.module';
import { PageTitleService } from '../../../shared/services/page-title.service';
import { TransactionService } from '../../../shared/services/transaction.service';
import { TransferMedium } from '../transfer-source-destination-dropdown/transfer-source-destination-dropdown.component';
import { TransactionResponse } from '../../../shared/models/transaction';
import { AmountService } from '../../../shared/services/amount.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'khatapana-transfer-form-content',
  templateUrl: './transfer-form-content.component.html',
  styleUrls: [
    '../../transaction-form/transaction-form-content/transaction-form-content.component.scss',
  ],
})
export class TransferFormContentComponent implements OnInit {
  HOME_ROUTES = HOME_ROUTE;

  // From To
  showSourceModal = false;
  showDestinationModal = false;

  // File related
  transactionFile: File[] | null = [];
  transactionFileName: string = 'Upload photos of bills/invoice/reciept';
  fileSelected: boolean = false;

  // Sending Transfer Medium
  fromMedium?: TransferMedium;
  toMedium?: TransferMedium;

  @ViewChild('sourceModal') sourceModal!: ElementRef;
  @ViewChild('destinationModal') destinationModal!: ElementRef;

  // Needed Models
  transferDetail: string = '';
  transactionAmount?: number;

  // For display in UI
  from?: string;
  to?: string;
  fromBalance: number = 0;
  toBalance: number = 0;
  cashBalance: number = 0;

  showBankAddEditModal = false;
  showWalletAddEditModal = false;

  // Edit
  @Input() toEditTransactionDetail?: TransactionResponse;
  @Output() close = new EventEmitter<boolean>();
  @Output() edited = new EventEmitter<boolean>();

  constructor(
    private transactionService: TransactionService,
    private router: Router,
    private translate: TranslateService,
    private amountService: AmountService,
    private pageTitleService: PageTitleService,
    private alertService: AlertService
  ) {
    this.translate
      .get('navbar.transfer')
      .subscribe((v) => this.pageTitleService.pageTitle$.next(v));
    this.pageTitleService.showBackButton$.next(true);
  }

  ngOnInit(): void {
    if (this.toEditTransactionDetail) {
      this.patchEditTransaction();
    }
  }

  patchEditTransaction() {
    console.log(this.toEditTransactionDetail);
    this.amountService.getCashBalance().subscribe({
      next: (res) => {
        this.cashBalance = res.results[0].balance;

        // Description
        this.transferDetail = this.toEditTransactionDetail!.description;
        // Amount
        this.transactionAmount = this.toEditTransactionDetail?.amount;
        const from: TransferMedium = {
          isCash:
            this.toEditTransactionDetail?.transaction_mode === 'cash'
              ? true
              : false,
          isBank:
            this.toEditTransactionDetail?.transaction_mode === 'bank'
              ? true
              : false,
          isWallet:
            this.toEditTransactionDetail?.transaction_mode === 'wallet'
              ? true
              : false,
          selectedBank: this.toEditTransactionDetail?.user_bank,
          selectedWallet: this.toEditTransactionDetail?.user_wallet,
          cashBalance: this.cashBalance,
        };

        const to: TransferMedium = {
          isCash:
            !this.toEditTransactionDetail?.to_bank &&
            !this.toEditTransactionDetail?.to_wallet
              ? true
              : false,
          isBank: this.toEditTransactionDetail?.to_bank ? true : false,
          isWallet: this.toEditTransactionDetail?.to_wallet ? true : false,
          selectedBank: this.toEditTransactionDetail?.to_bank,
          selectedWallet: this.toEditTransactionDetail?.to_wallet,
          cashBalance: this.cashBalance,
        };

        this.fromSourceMedium(from);
        this.toSourceMedium(to);
      },
      error: (err: HttpErrorResponse) => {
        this.alertService.error(
          this.alertService.getFormattedErrorMessage(err.error) ??
            DEFAULT_ERROR_MESSAGE
        );
      },
    });
  }

  // File selection
  fileBrowseHandler(ev: any) {
    this.transactionFile?.push(...ev.target.files);
    this.fileSelected = true;
  }

  removeFile(file: File) {
    this.transactionFile?.splice(
      this.transactionFile.findIndex((x) => x == file),
      1
    );
  }

  fromSourceMedium(medium: TransferMedium) {
    this.fromMedium = medium;
    this.from = this.fromMedium.isCash
      ? 'cash'
      : this.fromMedium.isBank
      ? 'bank'
      : 'wallet';
    this.fromBalance = this.fromMedium.isCash
      ? this.fromMedium.cashBalance
      : this.fromMedium.isBank
      ? this.fromMedium.selectedBank.balance
      : this.fromMedium.selectedWallet.balance;
  }

  toSourceMedium(medium: TransferMedium) {
    this.toMedium = medium;
    this.to = this.toMedium.isCash
      ? 'cash'
      : this.toMedium.isBank
      ? 'bank'
      : 'wallet';
    this.toBalance = this.toMedium.isCash
      ? this.toMedium.cashBalance
      : this.toMedium.isBank
      ? this.toMedium.selectedBank.balance
      : this.toMedium.selectedWallet.balance;
  }

  @HostListener('document:click', ['$event', '$event.target'])
  public onClick(event: MouseEvent, targetElement: HTMLElement): void {
    if (!targetElement) {
      return;
    }
    const clickedInsideSource =
      this.sourceModal?.nativeElement.contains(targetElement);
    const clickedInsideDestination =
      this.destinationModal?.nativeElement.contains(targetElement);

    if (!clickedInsideSource) {
      this.showSourceModal = false;
    }
    if (!clickedInsideDestination) {
      this.showDestinationModal = false;
    }
  }

  createPayload() {
    const formData = new FormData();
    // Creating Payload //

    //#region Attaching Docs
    if (this.transactionFile!.length > 0) {
      this.transactionFile?.forEach((file: File) => {
        formData.append('documents', file);
      });
    }
    //#endregion

    formData.append('transaction_type', 'out'); // Just added
    formData.append(
      'is_cash',
      this.fromMedium?.isCash || this.toMedium?.isCash ? 'true' : 'false'
    ); // FROM OR TO CASH
    formData.append('transaction_mode', this.from?.toString()!); // FROM MEDIUM
    formData.append('is_transfer', 'true'); // FOR TRANSFER
    formData.append('amount', this.transactionAmount!.toString()); // FOR AMOUNT
    formData.append('description', this.transferDetail.toString()); // FOR DESCRIPTION
    formData.append(
      'created_date',
      moment(new Date()).format('YYYY-MM-DD HH:mm')
    );

    //#region FROM BANK OR WALLET
    if (this.fromMedium?.isBank) {
      formData.append('user_bank', this.fromMedium.selectedBank.id.toString());
    }
    if (this.fromMedium?.isWallet) {
      formData.append(
        'user_wallet',
        this.fromMedium.selectedWallet.id.toString()
      );
    }
    //#endregion

    //#region TO BANK OR CASH
    if (this.toMedium?.isBank) {
      formData.append('to_bank', this.toMedium.selectedBank.id.toString());
    }
    if (this.toMedium?.isWallet) {
      formData.append('to_wallet', this.toMedium.selectedWallet.id.toString());
    }

    //#endregion
    return formData;
  }

  isTransferValid() {
    if (
      this.fromMedium?.isCash ||
      this.fromMedium?.isBank ||
      this.fromMedium?.isWallet
    ) {
      if (this.transactionAmount) {
        return true;
      } else {
        this.alertService.error('Amount is a required field.');
        return false;
      }
    } else {
      this.alertService.error('From and to are required for transfer.');
      return false;
    }
  }

  saveTransfer() {
    if (!this.isTransferValid()) {
      return;
    }
    const payload = this.createPayload();
    // Edit
    if (this.toEditTransactionDetail) {
      this.transactionService
        .editTransfer(this.toEditTransactionDetail?.id, payload)
        .subscribe({
          next: () => {
            this.alertService.success('Transfer updated successfully.');
            this.router.navigate([HOME_ROUTE.TRANSACTION_LIST]);
            this.edited.emit(true);
            this.close.emit(true);
          },
          error: (err: HttpErrorResponse) => {
            this.alertService.error(err.error.detail ?? DEFAULT_ERROR_MESSAGE);
          },
        });
    }
    // Add
    else {
      this.transactionService.createTransfer(payload).subscribe({
        next: () => {
          this.alertService.success('Transfer completed successfully.');
          this.router.navigate([HOME_ROUTE.TRANSACTION_LIST]);
        },
        error: (err: HttpErrorResponse) => {
          this.alertService.error(err.error.detail ?? DEFAULT_ERROR_MESSAGE);
        },
      });
    }
  }

  newBankAdded() {
    this.showBankAddEditModal = true;
    this.showSourceModal = false;
    this.showDestinationModal = false;
  }

  newWalletAdded() {
    this.showWalletAddEditModal = true;
    this.showSourceModal = false;
    this.showDestinationModal = false;
  }
}
