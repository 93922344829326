import { Injectable } from '@angular/core';
import {
  ExpenseReport,
  Expense,
  ExpenseViewModel,
} from '../models/expense.report';

import { DashboardService } from './dashboard.service';

@Injectable({
  providedIn: 'root',
})
export class ExpenseReportService {
  pieChartData: any = [];
  today = new Date();
  startDate = '';
  endDate = '';
  expenseReport!: ExpenseReport;
  expense_percentage_difference = 0;

  constructor(private dashbaordService: DashboardService) {}
  getExpenseReport(filterType: string) {
    return new Promise((resolve, reject) => {
      this.pieChartData = [];

      var choosedDate = new Date();
      // Finding out start date and end time
      switch (filterType) {
        case 'daily':
          this.startDate = new Date(choosedDate).toLocaleDateString();
          this.endDate = new Date(
            new Date(choosedDate).setDate(choosedDate.getDate() + 1)
          ).toLocaleDateString();
          break;
        case 'weekly':
          var day = choosedDate.getDay();
          var firstday = choosedDate.getDate() - day;
          var lastday = choosedDate.getDate() - (day - 1) + 5;
          this.startDate = new Date(
            choosedDate.setDate(firstday)
          ).toLocaleDateString();
          this.endDate = new Date(
            choosedDate.setDate(lastday)
          ).toLocaleDateString();
          break;
        case 'monthly':
          this.startDate = new Date(
            choosedDate.setDate(1)
          ).toLocaleDateString();
          const fullday = new Date(
            choosedDate.getFullYear(),
            choosedDate.getMonth() + 1,
            0
          );
          this.endDate = new Date(
            choosedDate.setDate(fullday.getDate())
          ).toLocaleDateString();
          break;
        case 'yearly':
          const currentYear = new Date(choosedDate).getFullYear();
          this.startDate = new Date(currentYear, 0, 1).toLocaleDateString();
          this.endDate = new Date(currentYear, 11, 31).toLocaleDateString();
          break;
      }

      // Calling Api
      this.dashbaordService
        .getExpenseReport(this.startDate, this.endDate)
        .subscribe({
          next: (result) => {
            this.expenseReport = result;
            this.expenseReport.expenses = this.expenseReport.expenses?.sort(
              (a, b) => b.total_amount - a.total_amount
            );

            // Adding all remaining to OTHERS
            if (this.expenseReport.expenses!.length > 5) {
              let i = 5;
              let total = 0;
              for (i; i < this.expenseReport.expenses!.length; i++) {
                total += this.expenseReport.expenses![i].total_amount;
              }
              this.expenseReport.expenses!.splice(
                5,
                this.expenseReport.expenses!.length - 1
              );
              this.expenseReport.expenses!.push({
                purpose__name: 'Others',
                total_amount: total,
              });
            }

            // Population Expense Dongought Chart
            this.expenseReport.expenses?.forEach((element: Expense) => {
              this.pieChartData.push({
                name: element.purpose__name,
                value: element.total_amount,
              });
            });
            if (this.expenseReport.expenses?.length === 0) {
              this.pieChartData.push({
                name: 'Nothing',
                value: 100,
              });
            }

            this.pieChartData = [...this.pieChartData];

            // Finding Expense difference to previous month,week,year
            var start = '';
            var end = '';
            var prevChoosedDate = new Date();
            switch (filterType) {
              case 'daily':
                start = new Date(
                  new Date(choosedDate).setDate(choosedDate.getDate() - 1)
                ).toLocaleDateString();
                end = new Date(
                  new Date(choosedDate).setDate(choosedDate.getDate())
                ).toLocaleDateString();
                break;
              case 'weekly':
                var prevDay = prevChoosedDate.getDay();
                var prevFirstday = prevChoosedDate.getDate() - prevDay;
                var prevLastday = prevChoosedDate.getDate() - (prevDay - 1) + 6;
                start = new Date(
                  prevChoosedDate.setDate(prevFirstday - 7)
                ).toLocaleDateString();
                end = new Date(
                  prevChoosedDate.setDate(prevLastday - 7)
                ).toLocaleDateString();
                break;
              case 'monthly':
                start = new Date(
                  new Date(prevChoosedDate.setDate(1)).setMonth(
                    new Date().getMonth() - 1
                  )
                ).toLocaleDateString();
                end = new Date(
                  new Date(prevChoosedDate.setDate(0)).setMonth(
                    new Date().getMonth() - 1
                  )
                ).toLocaleDateString();
                break;
              case 'yearly':
                const currentYear = new Date(prevChoosedDate).getFullYear() - 1;
                start = new Date(currentYear, 0, 1).toLocaleDateString();
                end = new Date(currentYear, 11, 31).toLocaleDateString();
                break;
            }

            this.dashbaordService.getExpenseReport(start, end).subscribe({
              next: (result: ExpenseReport) => {
                this.expense_percentage_difference =
                  this.expenseReport.expense_percentage! -
                  result.expense_percentage!;

                // EXPENSE VIEW MODEL
                let expenseReportViewModel: ExpenseViewModel = {
                  expense_percentage: this.expenseReport.expense_percentage!,
                  expense_percentage_difference:
                    result.total_net_expenses == 0
                      ? 0
                      : ((result.total_net_expenses! -
                          this.expenseReport.total_net_expenses!) /
                          result.total_net_expenses!) *
                        100,
                  expense_report: this.expenseReport,
                  pie_chart_data: this.pieChartData,
                  start_date: this.startDate,
                  end_date: this.endDate,
                };
                resolve(expenseReportViewModel);
              },
            });
          },
          error: () => {
            reject(null);
          },
        });
    });
  }
}
