import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { keyValue } from '../../models/keyvalue';

@Component({
  selector: 'khatapana-pagination',
  templateUrl: './pagination.component.html',
})
export class PaginationComponent implements OnInit {
  @Input() totalData? = 0;
  @Input() totalLabel = 'Items';
  @Input() hasPrev = false;
  @Input() hasNext = false;
  @Input() selectedPage = { id: 1, name: 1 };
  @Input() totalPage = 1;
  @Input() prevLabel = 'common.prev';
  @Input() nextLabel = 'common.next';

  @Output() onNextClicked = new EventEmitter<boolean>();
  @Output() onPrevClicked = new EventEmitter<boolean>();
  @Output() onPageChanged = new EventEmitter<number>();

  pageList: keyValue[] = [];

  ngOnInit() {
    let i = 1;
    for (i; i <= this.totalPage; i++) {
      this.pageList.push({ id: i, name: i });
    }
  }

  changePage(page: number) {
    this.onPageChanged.emit(page);
  }
}
