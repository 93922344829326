import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DashboardService } from 'src/app/modules/home/shared/services/dashboard.service';
import { amount_accepting_zero_not_starting_with_zero } from 'src/app/modules/shared/regex/amount.regex';
import { LocalStorageService } from 'src/app/services/local.storage.service';

@Component({
  selector: 'khatapana-edit-cash-modal',
  templateUrl: './edit-cash-modal.component.html',
})
export class EditCashModalComponent implements OnInit {
  @Input() businessTransaction = false;

  @Output() close = new EventEmitter<boolean>();
  @Output() updatedOpening = new EventEmitter<boolean>();
  cashForm!: FormGroup;
  prevBalance = 0;

  constructor(
    private localStorage: LocalStorageService,
    private dashboardService: DashboardService,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.loadCash();
  }

  initForm() {
    this.cashForm = this.fb.group({
      amount: [
        0,
        [
          Validators.required,
          Validators.pattern(amount_accepting_zero_not_starting_with_zero),
        ],
      ],
    });
  }

  get amount() {
    return this.cashForm?.get('amount');
  }

  loadCash() {
    this.dashboardService.getCashBalance(this.businessTransaction).subscribe({
      next: (cash) => {
        this.prevBalance = cash.results[0]?.balance || 0;
        this.amount?.setValue(this.prevBalance);
      },
    });
  }

  saveModal() {
    if (this.cashForm.invalid) {
      this.cashForm.markAllAsTouched();
      return;
    }
    this.dashboardService
      .addCashBalance(
        this.amount?.value,
        this.businessTransaction ? this.localStorage.business_account : null
      )
      .subscribe({
        next: () => {
          this.updatedOpening.emit(true);
          this.close.emit(true);
        },
        error: () => {},
      });
  }

  closeModal() {
    this.amount?.setValue(this.prevBalance);
    this.close.emit(true);
  }
}
