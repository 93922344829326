import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnDestroy } from '@angular/core';
import { ConfirmModal } from './confirm.modal';

@Component({
  selector: 'khatapana-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  animations: [
    trigger('fade', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate(500, style({ opacity: 1 })),
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate(500, style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class ConfirmModalComponent implements OnDestroy {
  modal? = new ConfirmModal();
  ngOnDestroy() {}
}
