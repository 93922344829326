<khatapana-card [border]="true" [class]="'bg-white max-xl:h-full'"
  ><header class="p-5 text-xl font-semibold">
    {{ "referral.coin_balance" | translate }}
  </header>
  <khatapana-divider></khatapana-divider>

  <!-- Transaction Coins lists -->
  <section class="p-5 px-7 gap-8 flex items-center">
    <!-- Icon -->
    <div
      class="h-10 w-10 bg-[#7B61FF] flex rounded-full items-center justify-center text-white"
    >
      <span class="material-symbols-outlined">output</span>
    </div>
    <!-- Container -->
    <div class="flex w-[80%] justify-between items-center">
      <div>
        <p class="text-sm">{{ "referral.transactions" | translate }}</p>
        <h1 class="text-xl font-semibold">
          {{ transactionCoin }} {{ "common.coins" | translate }}
        </h1>
      </div>
      <div class="w-[8%]" *ngIf="transactionList.length > 0">
        <span
          class="material-symbols-outlined cursor-pointer"
          (click)="showTransactions = !showTransactions"
          >{{ showTransactions ? "expand_less" : "expand_more" }}</span
        >
      </div>
    </div>
  </section>

  <ng-container *ngIf="showTransactions">
    <section
      *ngFor="let transaction of transactionList"
      class="p-5 px-7 gap-8 flex items-center"
    >
      <!-- Container -->
      <div class="flex w-[80%] justify-between items-center">
        <div>
          <p class="text-sm">
            {{ "common.earned_from" | translate }}
            {{ transaction.earning_type | titlecase }}
            {{ "common.on" | translate }}
            {{ transaction.created | date }}
          </p>
          <h1 class="text-xl font-semibold">
            {{ transaction.coin }} {{ "common.coins" | translate }}
          </h1>
        </div>
      </div>
    </section>
  </ng-container>

  <khatapana-divider></khatapana-divider>

  <!-- Referrals Coins lists -->
  <section class="p-5 px-7 gap-8 flex items-center">
    <!-- Icon -->
    <div
      class="h-10 w-10 bg-[#7B61FF] flex rounded-full items-center justify-center text-white"
    >
      <span class="material-symbols-outlined">output</span>
    </div>
    <!-- Container -->
    <div class="flex w-[80%] justify-between items-center">
      <div>
        <p class="text-sm">{{ "referral.refers" | translate }}</p>
        <h1 class="text-xl font-semibold">
          {{ referralCoin }} {{ "common.coins" | translate }}
        </h1>
      </div>
      <div class="w-[8%]" *ngIf="referralList.length > 0">
        <span
          class="material-symbols-outlined cursor-pointer"
          (click)="showReferrals = !showReferrals"
          >{{ showReferrals ? "expand_less" : "expand_more" }}</span
        >
      </div>
    </div>
  </section>

  <ng-container *ngIf="showReferrals">
    <section
      *ngFor="let referral of referralList"
      class="p-5 px-7 gap-8 flex items-center"
    >
      <!-- Container -->
      <div class="flex w-[80%] justify-between items-center">
        <div>
          <p class="text-sm">
            {{ "common.earned_from" | translate }}
            {{ referral.earning_type | titlecase }}
            {{ "common.on" | translate }}
            {{ referral.created | date }}
          </p>
          <h1 class="text-xl font-semibold">
            {{ referral.coin }} {{ "common.coins" | translate }}
          </h1>
        </div>
      </div>
    </section>
  </ng-container>
</khatapana-card>
