import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PaymentOptions } from 'src/app/modules/account/shared/models/payment-option';
import { SubscriptionPlan } from 'src/app/modules/account/shared/models/subscriptions.model';
import { AccountService } from 'src/app/modules/account/shared/services/account.service';
import { LocalStorageService } from 'src/app/services/local.storage.service';

@Component({
  selector: 'khatapana-payment-modal',
  templateUrl: './payment-modal.component.html',
})
export class PaymentModalComponent implements OnInit {
  @Input() selectedPlan?: SubscriptionPlan;
  @Output() payOption = new EventEmitter<PaymentOptions>();
  @Output() close = new EventEmitter<boolean>();

  paymentOptions: PaymentOptions[] = [];
  user$ = this.localStorage.currentUser$;
  constructor(
    private accountService: AccountService,
    private localStorage: LocalStorageService
  ) {}

  ngOnInit(): void {
    this.getPaymentMethod();
  }

  getPaymentMethod() {
    this.accountService.getWalletPaymentOptions().subscribe({
      next: (res: { results: PaymentOptions[] }) => {
        if (res.results.length > 0) {
          this.paymentOptions = res.results;
          this.paymentOptions.splice(2, this.paymentOptions.length);
        }
      },
    });
  }

  takeTrial() {
    this.payOption.emit({
      country_code: '+977',
      created_date: new Date().toString(),
      id: 1111111,
      is_active: true,
      name: 'trial',
      updated_date: new Date().toString(),
    });
  }
}
