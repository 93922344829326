import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AlertService } from 'src/app/modules/shared/services/alert.service';
import { DEFAULT_ERROR_MESSAGE } from 'src/constants';
import { BankResponse } from '../../../shared/models/bank';
import { WalletResponse } from '../../../shared/models/wallet';
import { AmountService } from '../../../shared/services/amount.service';

export interface TransferMedium {
  isCash: boolean;
  isBank: boolean;
  isWallet: boolean;
  cashBalance: number;
  selectedBank: BankResponse;
  selectedWallet: WalletResponse;
}

@Component({
  selector: 'khatapana-transfer-source-destination-dropdown',
  templateUrl: './transfer-source-destination-dropdown.component.html',
})
export class TransferSourceDestinationDropdownComponent implements OnInit {
  showBanks = false;
  showWallets = false;

  userBanks: BankResponse[] = [];
  userWallets: WalletResponse[] = [];
  cashBalance: number = 0;

  selectedBankId: any;
  selectedWalletId: any;

  @Input() medium?: TransferMedium;
  @Input() destination: boolean = false;
  @Output() closed = new EventEmitter<boolean>();
  @Output() selected = new EventEmitter<any>();

  @Output() bankAdd = new EventEmitter<boolean>();
  @Output() walletAdd = new EventEmitter<boolean>();
  constructor(
    private amountService: AmountService,
    private alertService: AlertService
  ) {}

  ngOnInit(): void {
    this.loadBanks();
    this.loadWallets();
    this.loadCashBalance();
  }

  loadCashBalance() {
    this.amountService.getCashBalance().subscribe({
      next: (res) => {
        this.cashBalance = res.results[0].balance;
      },
      error: (err: HttpErrorResponse) => {
        this.alertService.error(
          this.alertService.getFormattedErrorMessage(err.error) ??
            DEFAULT_ERROR_MESSAGE
        );
      },
    });
  }

  loadBanks() {
    this.amountService.getBankBalance().subscribe({
      next: (res: { results: BankResponse[]; total_bank_balance: any }) => {
        this.userBanks = res.results;
      },
      error: (err: HttpErrorResponse) => {
        this.alertService.error(
          this.alertService.getFormattedErrorMessage(err.error) ??
            DEFAULT_ERROR_MESSAGE
        );
      },
    });
  }

  loadWallets() {
    this.amountService.getWalletBalance().subscribe({
      next: (res: { results: WalletResponse[]; total_wallet_balance: any }) => {
        this.userWallets = res.results;
      },
      error: (err: HttpErrorResponse) => {
        this.alertService.error(
          this.alertService.getFormattedErrorMessage(err.error) ??
            DEFAULT_ERROR_MESSAGE
        );
      },
    });
  }

  selectTransferMedium(
    isCash = false,
    isBank = false,
    isWallet = false,
    selectedBank?: BankResponse,
    selectedWallet?: WalletResponse
  ) {
    let TransferMedium: TransferMedium = {
      isCash: isCash,
      isBank: isBank,
      isWallet: isWallet,
      cashBalance: this.cashBalance,
      selectedBank: selectedBank!,
      selectedWallet: selectedWallet!,
    };
    this.selected.emit(TransferMedium);
    this.closed.emit(true);
  }
}
