export class ConfirmModal {
  title? = 'This is title';
  content? = 'This is Content';
  okText? = 'OK';
  cancelText? = 'Cancel';
  showCancel? = true;
  showHeader? = true;
  closable? = false;
  onOkClicked?: () => any;
  onCancelClicked?: () => any;
  type?: 'error' | 'check_circle' | 'info' | 'warning' = 'info';
}
