import { Component, Input } from '@angular/core';

@Component({
  selector: 'khatapana-navigation-button',
  templateUrl: './navigation-button.component.html',
  styleUrls: ['./navigation-button.component.scss'],
})
export class NavigationButtonComponent {
  @Input() label = '';
  @Input() icon = '';
  @Input() link = '';
  @Input() variant: 'small' | 'big' = 'big';
  @Input() upgradeButton = false;
  @Input() mobile = false;
}
