import { Component } from '@angular/core';
import { NavigationTopService } from '../../shared/services/navigation-top.service';

@Component({
  selector: 'khatapana-in-out',
  templateUrl: './in-out.component.html',
})
export class InOutComponent {
  constructor(private navigationTopService: NavigationTopService) {
    this.navigationTopService.type$.next('none');
  }
}
