<div class="p-8">
  <div class="flex flex-col gap-1">
    <h1 class="text-xl font-semibold">
      {{ "referral.how_it_work_title" | translate }}
    </h1>
    <p class="w-96">
      {{ "referral.how_it_work_subtitle" | translate }}
    </p>
  </div>

  <div class="flex gap-5 w-full items-center pt-8">
    <img src="assets/icons/home/referral_hand.svg" />
    <p>{{ "referral.earn_coin_by_transaction" | translate }}</p>
  </div>

  <div class="flex gap-5 w-full items-center pt-5">
    <img src="assets/icons/home/referral_hand.svg" />
    <p>{{ "referral.earn_coin_by_code" | translate }}</p>
  </div>
</div>
