<div class="flex w-full p-6 gap-6 max-xl:flex-col max-lg:mb-[70px]">
  <div class="flex flex-col gap-6 w-4/6 max-xl:w-full">
    <!-- Left Top cards -->
    <khatapana-referral-card
      [referralCode]="referralCode"
      [userCoin]="userCoin"
    ></khatapana-referral-card>

    <!-- Responsive Design -->
    <div class="flex gap-6 flex-row w-full min-[1280px]:hidden">
      <!-- Right Top cards -->
      <khatapana-coin-balance
        class="w-3/6"
        [coinTransactions]="coinTransactions"
      ></khatapana-coin-balance>
      <!-- Right Bottom cards -->
      <khatapana-how-redem-works class="w-3/6"></khatapana-how-redem-works>
    </div>

    <!-- Left Bottom cards -->
    <khatapana-card [border]="true" [class]="'bg-white'">
      <khatapana-how-it-works></khatapana-how-it-works>
      <khatapana-divider></khatapana-divider>
      <khatapana-faq></khatapana-faq>
    </khatapana-card>
  </div>

  <div class="flex flex-col gap-6 w-2/6 max-xl:hidden">
    <!-- Right Top cards -->
    <khatapana-coin-balance
      [coinTransactions]="coinTransactions"
    ></khatapana-coin-balance>
    <!-- Right Bottom cards -->
    <khatapana-how-redem-works></khatapana-how-redem-works>
  </div>
</div>
