import { Component } from '@angular/core';
import { AlertService } from 'src/app/modules/shared/services/alert.service';
import { LocalStorageService } from 'src/app/services/local.storage.service';
import { SubscriptionUpgradeDialogComponent } from '../../shared/components/subscription-dialog-for-subs/subscription-dialog.component';
import {
  PLUS_DETAILS,
  PRO_DETAILS,
  STANDARD_DETAILS,
} from '../../shared/components/subscription-dialog/Plan.constants';
import { PageTitleService } from '../../shared/services/page-title.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'khatapana-subscription-plan',
  templateUrl: './subscription-plan.component.html',
})
export class SubscriptionPlanComponent {
  user$ = this.localStorage.currentUser$;

  standardDetails = STANDARD_DETAILS;
  proDetails = PRO_DETAILS;
  plusDetails = PLUS_DETAILS;

  constructor(
    private pageTitleService: PageTitleService,
    private localStorage: LocalStorageService,
    private translate: TranslateService,
    private alertService: AlertService
  ) {
    this.translate
      .get('navbar.subscription_plan')
      .subscribe((v) => this.pageTitleService.pageTitle$.next(v));
    this.pageTitleService.showBackButton$.next(true);
  }

  showUpgradePlans() {
    this.alertService.viewContainerRef.createComponent(
      SubscriptionUpgradeDialogComponent
    );
  }

  showChangePlans() {
    var res = this.alertService.viewContainerRef.createComponent(
      SubscriptionUpgradeDialogComponent
    );
    res.instance.upgradePlan = false;
  }
}
