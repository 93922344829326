<div class="flex">
  <khatapana-button
    variant="extra-small"
    [color]="type === 'personal' ? 'secondary' : 'basic'"
    border="little"
    (click)="changedType('personal')"
    ><span class="px-2">Personal</span></khatapana-button
  >
  <khatapana-button
    variant="extra-small"
    [disabled]="plan != KHATAPANA_ACCOUNT.PLUS"
    [color]="type === 'business' ? 'secondary' : 'basic'"
    border="little"
    (click)="changedType('business')"
    ><span class="px-2">Business</span></khatapana-button
  >
  <!-- <div
    *ngIf="noOfAccounts < 3 && plan && plan == KHATAPANA_ACCOUNT.PLUS"
    class="bg-gray-100 cursor-pointer absolute -mt-3 mr-3 right-0 h-5 w-5 p-2 rounded-full flex justify-center items-center shadow-2xl"
    (click)="createNewStore()"
  >
    <span class="material-symbols-outlined">add</span>
  </div> -->
  <div
    class="absolute right-0 -mt-2"
    *ngIf="plan && plan != KHATAPANA_ACCOUNT.PLUS"
  >
    <khatapana-capsule
      [capsuleClass]="'scale-75'"
      label="Plus"
    ></khatapana-capsule>
  </div>
</div>
