import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Party, PartyResponse } from '../../../home/shared/models/party';
import { PartyService } from '../../../home/shared/services/party.service';

@Component({
  selector: 'khatapana-choose-party',
  templateUrl: './choose-party.component.html',
})
export class ChoosePartyComponent implements OnInit {
  @Input() selectedParty?: any;
  @Input() businessTransaction?: any;
  @Input() isCredit = false;

  isDueSettlement = false;
  partyList: PartyResponse[] = [];
  showForm = false;

  @Output() partySelected = new EventEmitter<PartyResponse>();
  @Output() closed = new EventEmitter<boolean>();

  constructor(private partyService: PartyService) {}

  ngOnInit(): void {
    this.loadParties();
  }

  loadParties() {
    return this.partyService.getParties(this.businessTransaction).subscribe({
      next: (items: PartyResponse[]) => {
        this.partyList = items;
        if (this.isCredit) {
          this.partyList = this.partyList.filter(
            (x) => x.name !== 'miscellaneous'.toLowerCase()
          );
        }
      },
    });
  }

  partyAdded(party: Party) {
    this.showForm = false;
    this.selectedParty = party;
    this.loadParties();
  }

  changeParty(party: PartyResponse) {
    this.selectedParty = party;
    this.partySelected.emit(this.selectedParty);
    this.closed.emit(true);
  }
}
