// ESEWA ENVIRONMENT
export const ESEWA_ENV: string =
  window.origin === 'http://localhost:4200' ? 'UAT' : 'PROD';

// ESEWA URL
export const ESEWA_URL = `https://${
  ESEWA_ENV === 'PROD' ? '' : 'uat.'
}esewa.com.np/epay/main`;

// ESEWA MERCHANT CODE
export const MERCHANT_CODE =
  ESEWA_ENV === 'PROD' ? 'NP-ES-FINNOVE' : 'EPAYTEST';
