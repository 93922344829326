import { Component } from '@angular/core';
import { TransactionResponse } from '../../../shared/models/transaction';

@Component({
  selector: 'khatapana-table-print',
  templateUrl: './table-print.component.html',
})
export class TablePrintComponent {
  transactionList: TransactionResponse[] = [];

  constructor() {
    console.log('I am print');
  }
}
