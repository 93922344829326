<div class="flex flex-col py-8 gap-8 w-4/6 mx-auto max-md:w-[85%]">
  <h4 class="text-2xl font-semibold max-md:hidden">
    {{ "login.phone_label" | translate }}
  </h4>
  <khatapana-loader *ngIf="isLoading" [full]="true"></khatapana-loader>
  <form
    *ngIf="phoneSelected"
    class="flex flex-col gap-6 w-full"
    [formGroup]="loginForm"
    (ngSubmit)="sendOTP()"
  >
    <khatapana-input
      [label]="'common.phone' | translate"
      [type]="'tel'"
      [id]="'phoneintl'"
      [errorControl]="username!"
      formControlName="username"
    ></khatapana-input>
    <p class="text-[#545454] text-sm font-[450]">
      {{ "login.accept_terms" | translate }}
    </p>
    <khatapana-button
      [color]="'secondary'"
      [fullWidth]="true"
      [border]="'little'"
      [variant]="'big'"
      type="submit"
      [disabled]="!loginForm.valid || isLoading"
      ><span class="flex w-full justify-center items-center relative"
        >{{ "common.continue" | translate }}
        <i class="fa fa-arrow-right absolute right-[2%]"></i></span
    ></khatapana-button>
  </form>

  <form
    *ngIf="!phoneSelected"
    class="flex flex-col gap-6 w-full"
    [formGroup]="emailForm"
    (ngSubmit)="sendOTP()"
  >
    <khatapana-input
      [label]="'common.email' | translate"
      [type]="'email'"
      [placeholder]="'Enter your email'"
      [errorControl]="email!"
      formControlName="username"
    ></khatapana-input>
    <p class="text-[#545454] text-sm font-[450]">
      {{ "login.accept_terms" | translate }}
    </p>
    <khatapana-button
      [color]="'secondary'"
      [fullWidth]="true"
      [border]="'little'"
      [variant]="'big'"
      type="submit"
      [loading]="isLoading"
      [disabled]="!emailForm.valid || isLoading"
      ><span class="flex w-full justify-center items-center relative"
        >{{ "common.continue" | translate }}
        <i class="fa fa-arrow-right absolute right-[2%]"></i></span
    ></khatapana-button>
  </form>

  <!-- Social sign in commented -->

  <div class="flex flex-row items-center w-[400px] mx-auto">
    <div class="w-6/12">
      <khatapana-divider></khatapana-divider>
    </div>
    <div class="px-4 font-semibold">{{ "common.or" | translate }}</div>
    <div class="w-6/12">
      <khatapana-divider></khatapana-divider>
    </div>
  </div>

  <div class="flex w-full flex-col gap-4 items-center">
    <asl-google-signin-button
      [locale]="'en'"
      type="standard"
      size="medium"
      width="400"
      [text]="'continue_with'"
      disabled="isLoading"
    ></asl-google-signin-button>

    <button [disabled]="isLoading" class="social-btn" (click)="appleButton()">
      <div class="flex w-full items-center">
        <div class="relative">
          <img src="assets/icons/auth/social/apple.svg" />
        </div>
        <div class="w-full font-styled font-semibold">
          {{ "login.continue_with_apple" | translate }}
        </div>
      </div>
    </button>
    <button
      *ngIf="phoneSelected"
      class="social-btn"
      (click)="changeSelectedPhoneOrEmail('email')"
    >
      <div class="flex w-full items-center">
        <div class="relative">
          <i class="fa-solid fa-envelope"></i>
        </div>
        <div class="w-full font-styled font-semibold">
          {{ "login.continue_with_email" | translate }}
        </div>
      </div>
    </button>
    <button
      *ngIf="!phoneSelected"
      class="social-btn"
      (click)="changeSelectedPhoneOrEmail('phone')"
    >
      <div class="flex w-full items-center">
        <div class="relative">
          <i class="fa-solid fa-phone"></i>
        </div>
        <div class="w-full font-styled font-semibold">
          {{ "login.continue_with_phone" | translate }}
        </div>
      </div>
    </button>
  </div>
</div>

<!-- Phone Number verification Modal -->
<khatapana-modal *ngIf="showPhoneNumberModal || showOTPModal">
  <!-- Header -->
  <div class="py-3 px-4">
    <h4
      class="text-center text-xl font-semibold w-full flex justify-between items-center gap-x-8"
    >
      <p>Verify your mobile number</p>
    </h4>
  </div>
  <khatapana-divider></khatapana-divider>
  <!-- Body -->
  <div
    *ngIf="showPhoneNumberModal; else otpTemplate"
    class="p-6 flex flex-col gap-5"
    [formGroup]="phoneForm"
  >
    <khatapana-input
      [label]="'common.phone' | translate"
      [type]="'tel'"
      [id]="'phoneintl'"
      [errorControl]="phone!"
      formControlName="username"
    ></khatapana-input>

    <khatapana-button
      [color]="'secondary'"
      [fullWidth]="true"
      [border]="'little'"
      [variant]="'big'"
      type="submit"
      (onClick)="sendOTPPhoneVerification()"
      ><span class="flex w-full justify-center items-center"
        >{{ "Send OTP" | translate }}
        <i class="fa fa-arrow-right absolute right-28"></i></span
    ></khatapana-button>
  </div>
  <ng-template #otpTemplate>
    <form class="p-6 flex flex-col gap-5" [formGroup]="otpForm">
      <label class="text-center font-semibold text-[#141414]">{{
        "otp.enter_otp" | translate
      }}</label>
      <ng-otp-input
        [config]="otpConfig"
        (onInputChange)="otpChanged($event)"
        [formCtrl]="otp"
      ></ng-otp-input>
    </form>
    <div class="flex flex-col gap-6 px-6 pb-6">
      <khatapana-button
        [color]="'basic'"
        [border]="'circle'"
        [variant]="'medium'"
        [disabled]="startTimimg"
        (onClick)="resendOTP()"
        type="submit"
        ><span *ngIf="!startTimimg; else timer" class="text-center">{{
          "otp.resend_code" | translate
        }}</span>
        <ng-template #timer
          >{{ "common.resend" | translate }} 00:{{
            timeCountdown < 10 ? "0" : ""
          }}{{ timeCountdown }}</ng-template
        >
      </khatapana-button>
      <div class="flex justify-between">
        <khatapana-button
          [color]="'basic'"
          [border]="'circle'"
          (onClick)="showPhoneNumberModal = true"
          [variant]="'big'"
          type="submit"
          ><i class="fa-solid fa-arrow-left text-base"></i
        ></khatapana-button>
        <khatapana-button
          [color]="'basic'"
          [border]="'circle'"
          [variant]="'big'"
          [disabled]="disableNext"
          type="submit"
          (onClick)="verifyPhone()"
          ><span class="text-center"
            >{{ "common.next" | translate }}
            <i class="fa-solid fa-arrow-right text-base pl-1"></i></span
        ></khatapana-button>
      </div>
    </div>
  </ng-template>
</khatapana-modal>
