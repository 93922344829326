<div class="flex bg-[#EEEEEE] rounded-full p-1">
  <div
    class="flex w-1/2 justify-center p-1 py-2 cursor-pointer font-semibold text-[#545454] rounded-full"
    *ngFor="let plan of subscriptionPlans"
    (click)="changeTab.emit(plan)"
    [ngClass]="selectedTab == plan ? 'bg-white' : ''"
  >
    {{ plan.subscription_plan | titlecase }}
  </div>
</div>
