<div
  class="flex scale-90 origin-top flex-col bg-white rounded-lg border-[#E2E2E2] border-[1px]"
>
  <div class="p-6 pr-0">
    <h1 class="text-2xl font-semibold flex gap-5 flex-col">
      <!-- <i
        routerLink="/account/pro"
        class="fa-solid fa-arrow-left cursor-pointer"
      ></i> -->
      <p class="font-semibold text-3xl">
        {{ "account.choose_type" | translate }}
      </p>
      <p class="text-base text-[#545454]">
        {{ "account.choose_type_description" | translate }}
      </p>
    </h1>
  </div>
  <khatapana-divider></khatapana-divider>
  <div class="flex flex-col p-6 gap-6">
    <khatapana-plus-account-type
      [title]="'account.choose_type_personal_label' | translate"
      icon="person"
      [selected]="!businessSelected"
      (select)="businessSelected = false"
      [subTitle]="'account.choose_type_personal_description' | translate"
    ></khatapana-plus-account-type>
    <khatapana-plus-account-type
      icon="storefront"
      [selected]="businessSelected"
      (select)="businessSelected = true"
      [title]="'account.choose_type_business_label' | translate"
      [subTitle]="'account.choose_type_business_description' | translate"
    ></khatapana-plus-account-type>
  </div>
  <khatapana-divider></khatapana-divider>
  <div class="p-6">
    <khatapana-button
      [fullWidth]="true"
      variant="big"
      color="secondary"
      (onClick)="accountTypeChoosed()"
      border="little"
      ><span class="flex w-full justify-center items-center"
        >{{ "common.continue" | translate }}
        <i class="fa fa-arrow-right absolute right-10"></i> </span
    ></khatapana-button>
  </div>
</div>
