import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import KhaltiCheckout from 'khalti-checkout-web';

import { ApiService } from 'src/app/services/api.service';
import { LocalStorageService } from 'src/app/services/local.storage.service';
import { AddBankBalance } from '../models/add.balance';
import { AddWalletBalance } from '../models/add.wallet';
import { BusinessAccount } from '../models/business.account';
import { Purchase } from '../models/purchase';
import { Subscribe } from '../models/subscriptions.model';
import { ESEWA_ENV, ESEWA_URL, MERCHANT_CODE } from './esewa/ESEWA.CONSTANTS';

export const getKeyValue =
  <U extends keyof T, T extends object>(key: U) =>
  (obj: T) =>
    obj[key];

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  constructor(
    private http: HttpClient,
    private api: ApiService,
    private localStorage: LocalStorageService
  ) {}

  getBusinessCategory() {
    return this.api.get('/api/payment/list-business-category/');
  }

  createBusinessAccount(business: BusinessAccount) {
    return this.api.post('/api/payment/create-business/', business);
  }

  getBusinessAccount() {
    return this.api.get('/api/payment/list-business/');
  }

  addCashBalance(balance: number, business_account: null | number = null) {
    return this.api.post('/api/payment/cash/balance/', {
      balance,
      business_account,
    });
  }

  addBankBalance(payload: AddBankBalance) {
    return this.api.post('/api/payment/bank/balance/', payload);
  }

  editBankBalance(payload: AddBankBalance) {
    return this.api.patch(`/api/payment/bank/balance/${payload.id}/`, payload);
  }

  addWalletBalance(payload: AddWalletBalance) {
    return this.api.post('/api/payment/wallet/balance/', payload);
  }

  editWalletBalance(payload: AddWalletBalance) {
    return this.api.patch(
      `/api/payment/wallet/balance/${payload.id}/`,
      payload
    );
  }

  getSubscriptionTypes() {
    return this.api.get('/api/subscription/type');
  }

  getSubscriptionPlanDetail(subscriptionTypeId: number) {
    return this.api.get(
      `/api/subscription/plan?subscription_type=${subscriptionTypeId}`
    );
  }

  subscribeToKhatapana(subscibe: Subscribe) {
    subscibe.referral_code = this.localStorage.referral_code;
    return this.api.post('/api/subscription/user/', subscibe);
  }

  getWalletPaymentOptions() {
    return this.api.get(
      `/api/payment/gateway/?country_code=NP&mode=${WALLET_MODE_ID}`
    );
  }

  payWithEsewa(
    data: Purchase,
    subscriptionId: any,
    paymentGatewayId: any,
    token: any
  ) {
    data.productId =
      ESEWA_ENV === 'PROD'
        ? data.productId + new Date().toDateString()
        : data.productId + new Date().toDateString();
    var params: Object = {
      amt: data.amount,
      psc: 0,
      pdc: 0,
      txAmt: 0,
      tAmt: data.amount,
      pid: data.productId,
      scd: MERCHANT_CODE,
      su: `${
        window.location.origin
      }/account/esewa?subscription_id=${subscriptionId}&payment_gateway_id=${paymentGatewayId}&pid=${
        data.productId
      }${token ? `&token=${token}` : ''}&q=su`,
      fu: `${window.location.origin}/account/esewa?q=fu`,
    };
    var form = document.createElement('form');
    form.setAttribute('method', 'POST');
    form.setAttribute('action', ESEWA_URL);

    for (var key in params) {
      var hiddenField = document.createElement('input');
      hiddenField.setAttribute('type', 'hidden');
      hiddenField.setAttribute('name', key);
      hiddenField.setAttribute(
        'value',
        getKeyValue<keyof any, any>(key)(params)
      );
      form.appendChild(hiddenField);
    }
    document.body.appendChild(form);
    form.submit();
  }

  payWithKhalti(data: Purchase) {
    let promise = new Promise<any>((resolve, rejects) => {
      let config = {
        publicKey: 'live_public_key_03928b7456964a65a7582ff59bf14cf4',
        productIdentity: data.productId,
        productName: data.productName,
        productUrl: data.productUrl,
        eventHandler: {
          onSuccess(payload: any) {
            resolve(payload);
          },
        },
        paymentPreference: [
          'KHALTI',
          'MOBILE_BANKING',
          'EBANKING',
          'CONNECT_IPS',
          'SCT',
        ],
      };
      let checkout = new KhaltiCheckout(config);
      return checkout.show({ amount: data.amount * 100 });
    });
    return promise;
  }

  payWithKhaltiNew(data: Purchase) {
    // let promise = new Promise<any>((resolve, rejects) => {
    //   let config = {
    //     publicKey: 'live_public_key_03928b7456964a65a7582ff59bf14cf4',
    //     productIdentity: data.productId,
    //     productName: data.productName,
    //     productUrl: data.productUrl,
    //     eventHandler: {
    //       onSuccess(payload: any) {
    //         resolve(payload);
    //       },
    //     },
    //     paymentPreference: [
    //       'MOBILE_BANKING',
    //       'KHALTI',
    //       'EBANKING',
    //       'CONNECT_IPS',
    //       'SCT',
    //     ],
    //   };
    //   let checkout = new KhaltiCheckout(config);
    //   return checkout.show({ amount: 1000 });
    // });
    const payload = {
      return_url: 'https://example.com/payment/',
      website_url: 'https://example.com/',
      amount: data.amount,
      purchase_order_id: data.productId,
      purchase_order_name: data.productName,
      customer_info: {
        name: 'Ashim Upadhaya',
        email: 'example@gmail.com',
        phone: '9811496763',
      },
      amount_breakdown: [
        {
          label: 'Mark Price',
          amount: 1000,
        },
        {
          label: 'VAT',
          amount: 300,
        },
      ],
      product_details: [
        {
          identity: '1234567890',
          name: 'Khalti logo',
          total_price: data.amount,
          quantity: 1,
          unit_price: data.amount,
        },
      ],
    };
    return this.http.post(
      'https://khalti.com/api/v2/epayment/initiate/',
      payload,
      {
        headers: new HttpHeaders().set(
          'Authorization',
          'key live_public_key_03928b7456964a65a7582ff59bf14cf4'
        ),
      }
    );
  }

  confirmPayment(payModel: any) {
    return this.api.post('/api/payment/invoice/', payModel);
  }

  // Load Bank names if mode=3, so on.
  gettPaymentOptionsAccordingToTransactionMode(mode: number) {
    return this.api.get(`/api/payment/gateway/?country_code=NP&mode=${mode}`);
  }
}

export const BANK_MODE_ID = 3;
export const WALLET_MODE_ID = 2;
