<khatapana-modal [width]="576">
  <!-- Body -->
  <div class="p-6 flex flex-col gap-5">
    <div class="flex flex-col gap-1 py-3 px-4 bg-[#F6F6F6] rounded-md">
      <span class="text-[#757575] text-sm font-semibold"
        >{{ "dashboard.opening_balance_title" | translate }}
      </span>
      <h1 class="text-3xl font-semibold">{{ cashBalance | currency }}</h1>
    </div>

    <div class="flex flex-col px-4 gap-2">
      <span class="text-xl font-semibold"
        >{{ "dashboard.opening_balance_question" | translate }}
      </span>
      <h1 class="text-[#545454]">
        {{ "dashboard.opening_balance_question_helper" | translate }}
      </h1>
    </div>
  </div>
  <!-- Footer -->
  <div class="flex flex-col gap-2 p-6">
    <khatapana-button
      [fullWidth]="true"
      color="secondary"
      variant="big"
      border="little"
      (onClick)="onOkClicked()!"
      ><span class="flex justify-center w-full">{{
        "dashboard.opening_balance_edit_button" | translate
      }}</span></khatapana-button
    >
    <khatapana-button
      [fullWidth]="true"
      color="basic"
      variant="big"
      border="little"
      (onClick)="onCancelClicked()!"
      ><span class="flex justify-center w-full">{{
        "dashboard.opening_balance_edit_skip" | translate
      }}</span></khatapana-button
    >
  </div>
</khatapana-modal>
