<div class="main-screen bg-white max-md:flex-col">
  <div
    class="illustration-screen bg-black flex md:max-lg:w-[50%] lg:w-[55%] max-md:bg-white max-md:mt-24"
  >
    <img
      class="w-auto max-md:w-[200px] max-md:h-[200px] max-[500px]:w-[150px] max-[500px]:h-[150px]"
      src="assets/illustrations/auth-illustration.svg"
    />
    <h1 class="title text-3xl text-white max-md:text-2xl max-md:text-black">
      {{ "general.khatapana_account_title" | translate }}
    </h1>
    <p class="subtitle max-md:hidden">
      {{ "general.khatapana_account_subtitle" | translate }}
    </p>
  </div>
  <div
    class="flex content-screen flex-col md:max-lg:w-[50%] lg:w-[45%] bg-white"
  >
    <khatapana-help-bar
      class="max-md:fixed max-md:top-0 max-md:flex max-md:w-full max-md:shadow-sm"
    ></khatapana-help-bar>
    <router-outlet></router-outlet>
  </div>
</div>
