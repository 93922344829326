import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/modules/auth/shared/services/auth.service';
import { AlertService } from 'src/app/modules/shared/services/alert.service';
import { ConfirmModalService } from 'src/app/modules/shared/services/confirm.modal.service';
import { LocalStorageService } from 'src/app/services/local.storage.service';
import { DEFAULT_ERROR_MESSAGE } from 'src/constants';
import { HOME_ROUTE } from '../../home-routing.module';
import { DashboardData } from '../../shared/models/dashboard.data';
import {
  REPORTING_TYPES,
  TRANSACTION_MODES,
} from '../../shared/models/reports.data';
import { DashboardService } from '../../shared/services/dashboard.service';
import { PageTitleService } from '../../shared/services/page-title.service';
import { TablePrintComponent } from '../transaction-list/table-print/table-print.component';
import { OpeningBalanceReminderComponent } from './opening-balance-reminder/opening-balance-reminder.component';
import { AdjustBalanceModalComponent } from './adjust-balance-modal/adjust-balance-modal.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'khatapana-dashboard',
  templateUrl: './dashboard.component.html',
})
export class DashboardComponent implements OnInit {
  HOME_ROUTES = HOME_ROUTE;

  transactionList = [];

  dashboardData$?: Observable<DashboardData>;
  loading = false;
  dashboardForm!: FormGroup;
  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private alertService: AlertService,
    private translate: TranslateService,
    private localStorage: LocalStorageService,
    private confirmModalService: ConfirmModalService,
    private pageTitleService: PageTitleService,
    private dashboardService: DashboardService
  ) {
    this.translate
      .get('navbar.dashboard')
      .subscribe((v) => this.pageTitleService.pageTitle$.next(v));
    this.pageTitleService.showBackButton$.next(false);
  }

  ngOnInit(): void {
    this.authService.loadUserToLocalStorage();
    this.initDashboardForm();
    this.loadDashboard();
    this.remindForBalanceAdjustement();
  }

  loadDashboard() {
    this.dashboardData$ = this.dashboardService.getDashboardData();
    this.dashboardData$.subscribe({
      next: () => {},
      error: (err: HttpErrorResponse) => {
        this.alertService.error(
          this.alertService.getFormattedErrorMessage(err.error) ??
            DEFAULT_ERROR_MESSAGE
        );
      },
    });
  }

  initDashboardForm() {
    this.dashboardForm = this.fb.group({
      reportingType: [REPORTING_TYPES[0]],
      transactionMode: [TRANSACTION_MODES[0]],
    });
  }

  get reportingType() {
    return this.dashboardForm.get('reportingType');
  }
  get transactionMode() {
    return this.dashboardForm.get('transactionMode');
  }

  downloadReport() {
    if (this.transactionList.length === 0) {
      this.alertService.error(
        `You don't have any transactions report to download.`
      );
      return;
    }
    this.loading = true;
    const ref =
      this.confirmModalService.viewContainerRef.createComponent(
        TablePrintComponent
      );
    console.log(this.transactionList);
    ref.instance.transactionList = [...this.transactionList];
    setTimeout(() => {
      this.loading = false;
      window.print();
    }, 2000);
  }

  remindForBalanceAdjustement() {
    if (
      !this.localStorage.opening_balance_reminder ||
      new Date(
        JSON.parse(this.localStorage.opening_balance_reminder)
      ).toDateString() != new Date().toDateString()
    ) {
      setTimeout(() => {
        let openingComp =
          this.confirmModalService.viewContainerRef.createComponent(
            OpeningBalanceReminderComponent
          );
        openingComp.instance.onOkClicked = () => {
          this.confirmModalService.clear();
          this.adjustOpeningBalance();
        };
        openingComp.instance.onCancelClicked = () => {
          this.confirmModalService.clear();
          this.localStorage.opening_balance_reminder = JSON.stringify(
            new Date()
          );
        };
      }, 1000);
    }
  }

  adjustOpeningBalance() {
    let adjustComp = this.confirmModalService.viewContainerRef.createComponent(
      AdjustBalanceModalComponent
    );
    adjustComp.instance.onOkClicked = () => {
      this.confirmModalService.clear();
      this.dashboardService
        .adjustCashBalance(adjustComp.instance.amount?.value)
        .subscribe({
          next: () => {
            this.alertService.success('Opening adjusted successfully.');
            this.localStorage.opening_balance_reminder = JSON.stringify(
              new Date()
            );
          },
          error: (err: HttpErrorResponse) => {
            this.alertService.error(
              this.alertService.getFormattedErrorMessage(err.error)
            );
          },
        });
    };
    adjustComp.instance.onCancelClicked = () => {
      this.confirmModalService.clear();
      this.localStorage.opening_balance_reminder = JSON.stringify(new Date());
    };
  }
}
