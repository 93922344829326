<div
  class="flex scale-90 origin-top flex-col bg-white rounded-lg border-[#E2E2E2] border-[1px]"
>
  <div class="p-6">
    <!-- <h1 class="text-2xl font-semibold">
      <i
        routerLink="/auth/login"
        class="fa-solid fa-arrow-left cursor-pointer"
      ></i>
    </h1> -->
    <div class="flex flex-col items-center w-full gap-10">
      <img class="mt-12" src="assets/icons/account/completed.svg" />
      <h1 class="text-2xl font-semibold text-center">
        {{ "account_setup_complete_message" | translate }}
      </h1>
      <khatapana-button
        class="w-full"
        [fullWidth]="true"
        variant="big"
        [routerLink]="HOME_ROUTE.DASHBOARD"
        color="secondary"
        border="little"
        ><span class="flex w-full justify-center items-center">{{
          "common.continue" | translate
        }}</span>
      </khatapana-button>
    </div>
  </div>
</div>
