import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'khatapana-divider',
  templateUrl: './divider.component.html',
})
export class DividerComponent implements OnInit {
  @Input() color = '#EEEEEE';
  @Input() orientation: 'h' | 'v' = 'h';
  constructor() {}

  ngOnInit(): void {}
}
