import { createReducer, on } from '@ngrx/store';
import { clearUser, getUser, resetUserDetails, setUser, setUserDetails } from './user.actions';
import { UserState } from './user.state';
// import { initialState } from './user.state';
export const initialState: UserState = {
  currentUser: null,
};
export const userReducer = createReducer(
  initialState,
  // on(getUser, (state) => ({ ...state })),
  // on(setUserDetails, (state, { user }) => ({ user })),
  // on(resetUserDetails, (state) => ({}))
  on(setUser, (state, { user }) => ({ ...state, currentUser: user })),
  on(clearUser, (state) => ({ ...state, currentUser: null }))
);
