import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UserService } from 'src/app/modules/home/shared/services/user.service';
import { AlertService } from 'src/app/modules/shared/services/alert.service';
import { DEFAULT_ERROR_MESSAGE } from 'src/constants';

@Component({
  selector: 'khatapana-profile-image-upload',
  templateUrl: './image-upload.component.html',
})
export class ImageUploadComponent implements OnInit {
  selectedProfileImage!: File | null;

  showProfileImageUploadModal = false;
  imageSrc: any;

  @Input() profileImage: any;
  @Input() mobile = false;
  @Output() imageUpdated = new EventEmitter<boolean>();

  constructor(
    private userService: UserService,
    private alertService: AlertService
  ) {}

  ngOnInit(): void {}

  fileBrowseHandler(ev: any) {
    if (!this.selectedProfileImage) {
      const file = ev.target.files[0];
      this.selectedProfileImage = file;

      const reader = new FileReader();
      reader.onload = (e) => (this.imageSrc = reader.result);

      reader.readAsDataURL(file);
    }
  }

  RemoveProfileImageModal() {
    this.selectedProfileImage = null;
  }

  updateProfilePic() {
    this.userService.updateProfileImage(this.selectedProfileImage).subscribe({
      next: (res: any) => {
        this.showProfileImageUploadModal = false;
        this.alertService.success('Profile Pic Updated successfully.');
        this.imageUpdated.emit(true);
        this.selectedProfileImage = null;
      },
      error: (err: HttpErrorResponse) => {
        this.showProfileImageUploadModal = false;
        this.alertService.error(
          this.alertService.getFormattedErrorMessage(err.error) ??
            DEFAULT_ERROR_MESSAGE
        );
      },
    });
  }
}
