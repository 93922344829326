<div
  *ngIf="!fromDashboard"
  class="flex scale-90 origin-top flex-col bg-white rounded-lg border-[#E2E2E2] border-[1px]"
>
  <div class="p-6 pr-0">
    <h1 class="text-2xl font-semibold flex gap-5 flex-col">
      <!-- <i
        routerLink="/account/pro"
        class="fa-solid fa-arrow-left cursor-pointer"
      ></i> -->
      <p>{{ "account.business.create_store_title" | translate }}</p>
    </h1>
  </div>
  <khatapana-divider></khatapana-divider>
  <ng-container *ngTemplateOutlet="formTemplate"></ng-container>
  <khatapana-divider></khatapana-divider>
  <div class="p-6 flex flex-col gap-6">
    <khatapana-button
      [fullWidth]="true"
      variant="big"
      color="secondary"
      border="little"
      (onClick)="createBusinessStore()"
      [disabled]="isLoading || storeForm.invalid"
      [loading]="isLoading"
      ><span class="flex w-full justify-center items-center">{{
        "common.continue" | translate
      }}</span></khatapana-button
    >
    <khatapana-button
      [fullWidth]="true"
      variant="big"
      color="basic"
      border="little"
      [disabled]="isLoading"
      (onClick)="skipModal()"
      ><span class="flex w-full justify-center items-center">{{
        "common.skip_for_now" | translate
      }}</span></khatapana-button
    >
  </div>
</div>

<!-- If from Dashboard -->
<khatapana-modal *ngIf="fromDashboard">
  <div class="p-6 py-3 pr-0 justify-center flex">
    <h1 class="text-2xl font-semibold flex gap-5 flex-col">Add your store</h1>
  </div>
  <khatapana-divider></khatapana-divider>
  <ng-container *ngTemplateOutlet="formTemplate"></ng-container>
  <khatapana-divider></khatapana-divider>
  <div class="p-6 flex flex-col gap-6">
    <khatapana-button
      [fullWidth]="true"
      variant="big"
      color="secondary"
      border="little"
      (onClick)="createBusinessStore()"
      [disabled]="isLoading || storeForm.invalid"
      [loading]="isLoading"
      ><span class="flex w-full justify-center items-center">{{
        "common.save" | translate
      }}</span></khatapana-button
    >
    <khatapana-button
      [fullWidth]="true"
      variant="big"
      color="basic"
      border="little"
      [disabled]="isLoading"
      (onClick)="closeModal()"
      ><span class="flex w-full justify-center items-center">{{
        "common.cancel" | translate
      }}</span></khatapana-button
    >
  </div></khatapana-modal
>

<!-- Form Template -->
<ng-template #formTemplate>
  <div class="p-6 flex flex-col gap-6" [formGroup]="storeForm">
    <!-- <khatapana-select
      [list]="categoryList"
      [fullWidth]="true"
      [fontWeight]="400"
      border="little"
      [errorControl]="category!"
      [required]="true"
      formControlName="category"
      [height]="55"
      variant="dark"
      [label]="'account.business.store_type_label' | translate"
      [placeHolder]="'account.business.store_type_label' | translate"
    >
    </khatapana-select> -->
    <khatapana-input
      [label]="'account.business.store_name_label' | translate"
      formControlName="name"
      [errorControl]="name!"
    ></khatapana-input>
    <khatapana-input
      [label]="'account.business.store_type_label' | translate"
      formControlName="business_type"
      [errorControl]="business_type!"
      placeholder="What do you sell?"
    ></khatapana-input>
    <khatapana-input
      [label]="'account.business.store_address_label' | translate"
      formControlName="address"
      [errorControl]="address!"
      placeholder="Where is your business located?"
    ></khatapana-input>
  </div>
</ng-template>
