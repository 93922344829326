<khatapana-modal>
  <!-- Header -->
  <div class="py-3 px-4">
    <h4
      class="text-center text-xl font-semibold w-full flex justify-center items-center"
    >
      <p>{{ "dashboard.adjust_balance_title" | translate }}</p>
    </h4>
  </div>
  <!-- Body -->
  <div class="px-6 pt-4 flex flex-col gap-2" [formGroup]="cashForm!">
    <khatapana-input
      [label]="'dashboard.adjust_balance_title' | translate"
      formControlName="amount"
      [errorControl]="amount!"
      [preIcon]="'fa fa-rupee-sign'"
      type="number"
    ></khatapana-input>
    <div class="flex justify-between text-[#757575]">
      <span> {{ "dashboard.adjust_balance_prev" | translate }} </span
      ><span>{{ prevBalance | currency }}</span>
    </div>
  </div>
  <!-- Footer -->
  <div class="flex flex-col gap-4 p-6">
    <khatapana-button
      [fullWidth]="true"
      color="secondary"
      variant="big"
      border="little"
      (onClick)="onOkClicked()"
      ><span class="flex justify-center w-full">{{
        "common.save" | translate
      }}</span></khatapana-button
    >
    <khatapana-button
      [fullWidth]="true"
      color="basic"
      variant="big"
      border="little"
      (onClick)="onCancelClicked()"
      ><span class="flex justify-center w-full">{{
        "common.cancel" | translate
      }}</span></khatapana-button
    >
  </div>
</khatapana-modal>
