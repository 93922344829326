<div class="flex gap-6 m-6 max-xl:flex-col-reverse max-lg:mb-[90px]">
  <div class="w-[70%] max-xl:w-full">
    <khatapana-transaction-table
      [partyPage]="true"
      [partyId]="partyId"
      (transactionListChanged)="transactionList = $event"
    ></khatapana-transaction-table>
  </div>
  <div class="w-[30%] flex flex-col gap-3 max-xl:w-full">
    <khatapana-card [class]="'bg-white '" [border]="true">
      <!-- Name and info -->
      <section class="flex flex-col gap-3 p-6 pb-0">
        <div class="flex justify-center items-center flex-col gap-3">
          <div
            class="text-2xl font-semibold rounded-full flex items-center justify-center bg-[#F2EFFF] w-16 h-16"
          >
            {{ party?.name?.[0] | titlecase }}
          </div>
          <div class="text-2xl font-semibold flex gap-2 items-center">
            {{ party?.name | titlecase }}
            <img
              class="h-7"
              *ngIf="party?.khata_user"
              src="../../../../../assets/icons/home/khatapana-party.svg"
            />
          </div>
        </div>
        <div
          *ngIf="party?.phone || party?.email"
          class="p-3 gap-2 flex flex-col"
        >
          <p *ngIf="party?.phone" class="flex items-center gap-2">
            <span class="material-symbols-outlined"> call </span
            >{{ party?.phone }}
          </p>
          <p *ngIf="party?.email" class="flex items-center gap-2">
            <span class="material-symbols-outlined"> mail </span
            >{{ party?.email }}
          </p>
        </div>
        <khatapana-divider class="w-full"></khatapana-divider>
      </section>
      <!-- Amounts and due info -->
      <section class="flex flex-col">
        <!-- OUT AMOUNT -->
        <div>
          <div class="cash-card">
            <div class="image">
              <img src="assets/icons/home/total_paid.svg" />
            </div>
            <div class="flex flex-col">
              <p class="title">{{ "common.out" | translate }}</p>
              <p class="money text-[#E11900]">
                {{ party?.transaction_amount?.out | currency }}
              </p>
            </div>
          </div>
          <khatapana-divider class="w-full"></khatapana-divider>
        </div>
        <!-- IN AMOUNT -->
        <div>
          <div class="cash-card">
            <div class="image">
              <img src="assets/icons/home/total_received.svg" />
            </div>
            <div class="flex flex-col">
              <p class="title">{{ "common.in" | translate }}</p>
              <p class="money text-[#00AA6C]">
                {{ party?.transaction_amount?.in | currency }}
              </p>
            </div>
          </div>
          <khatapana-divider class="w-full"></khatapana-divider>
        </div>
        <!-- PAY DUE AMOUNT -->
        <div>
          <div class="flex justify-between items-center mr-4">
            <div class="cash-card">
              <div class="image">
                <img src="assets/icons/home/due.svg" />
              </div>
              <div class="flex flex-col">
                <p class="title">{{ "common.payable_due" | translate }}</p>
                <p class="money text-[#545454]">
                  {{ party?.transaction_amount?.payable_due | currency }}
                </p>
              </div>
            </div>
            <div class="float-right">
              <khatapana-button
                *ngIf="party?.transaction_amount?.payable_due! > 0"
                (onClick)="
                  settleDue(true, party?.transaction_amount?.payable_due!)
                "
                color="primary-light"
                variant="extra-small"
                border="circle"
                >{{ "common.settle" | translate }}</khatapana-button
              >
            </div>
          </div>
        </div>
        <khatapana-divider class="w-full"></khatapana-divider>
        <!-- RECIEVE DUE AMOUNT -->
        <div>
          <div class="flex justify-between items-center mr-4">
            <div class="cash-card">
              <div class="image">
                <img src="assets/icons/home/due.svg" />
              </div>
              <div class="flex flex-col">
                <p class="title">{{ "common.receivable_sue" | translate }}</p>
                <p class="money text-[#545454]">
                  {{ party?.transaction_amount?.receivable_due | currency }}
                </p>
              </div>
            </div>
            <div class="float-right">
              <khatapana-button
                *ngIf="party?.transaction_amount?.receivable_due! > 0"
                (onClick)="
                  settleDue(false, party?.transaction_amount?.receivable_due!)
                "
                color="primary-light"
                variant="extra-small"
                border="circle"
                >{{ "common.settle" | translate }}</khatapana-button
              >
            </div>
          </div>
        </div>
      </section>
    </khatapana-card>
    <div class="flex gap-6 w-full justify-between">
      <div class="w-3/6">
        <khatapana-card [class]="'bg-white'" [border]="true">
          <div class="button-button">
            <span class="material-symbols-outlined">notification_add </span>
            <p>{{ "party.send_reminder" | translate }}</p>
          </div>
        </khatapana-card>
      </div>
      <div class="w-3/6">
        <khatapana-card [class]="'bg-white'" [border]="true">
          <div class="button-button" (click)="downloadReport()">
            <span class="material-symbols-outlined"> insert_chart </span>
            <p>{{ "common.download_report" | translate }}</p>
          </div>
        </khatapana-card>
      </div>
    </div>
  </div>
</div>

<khatapana-loader *ngIf="loading" [full]="true" [size]="100"></khatapana-loader>
