import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthComponent } from './auth.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { LoginPasswordComponent } from './pages/login-password/login-password.component';
import { LoginComponent } from './pages/login/login.component';
import { OtpComponent } from './pages/otp/otp.component';
import { SignupComponent } from './pages/signup/signup.component';

export const AUTH_ROUTES = {
  LOGIN: '/auth/login',
  REGISTER: '/auth/register',
  OTP: '/auth/otp',
  PASSWORD: '/auth/password',
  FORGOTPASSWORD: '/auth/forgot',
};

const routes: Routes = [
  {
    path: '',
    component: AuthComponent,
    children: [
      { path: 'login', component: LoginComponent },
      { path: 'otp', component: OtpComponent },
      {
        path: 'register',
        component: SignupComponent,
      },
      { path: 'password', component: LoginPasswordComponent },
      { path: 'forgot', component: ForgotPasswordComponent },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthRoutingModule {}
