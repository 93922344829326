export const TRANSACTIONMODESFILTER = [
  {
    key: '',
    value: 'All',
  },
  {
    key: 'cash',
    value: 'Cash',
  },
  {
    key: 'bank',
    value: 'Bank',
  },
  {
    key: 'wallet',
    value: 'Wallet',
  },
  {
    key: 'credit',
    value: 'Credit',
  },
  {
    key: 'transfer',
    value: 'Transfer',
  },
];
