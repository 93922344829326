import { Injectable, ViewContainerRef } from '@angular/core';
import { ConfirmModalComponent } from '../components/confirm-modal/confirm-modal.component';
import { ConfirmModal } from '../components/confirm-modal/confirm.modal';

@Injectable({
  providedIn: 'root',
})
export class ConfirmModalService {
  public viewContainerRef!: ViewContainerRef;
  constructor() {}

  set setviewContainerRef(vcr: ViewContainerRef) {
    this.viewContainerRef = vcr;
  }

  clear() {
    this.viewContainerRef.clear();
  }

  error(modal: ConfirmModal) {
    let createModal = new ConfirmModal();
    modal.onCancelClicked = () => {
      this.viewContainerRef.clear();
    };
    modal.type = 'error';
    Object.assign(createModal, modal);
    const ref = this.viewContainerRef.createComponent(ConfirmModalComponent);
    ref.instance.modal = createModal;
  }

  success(modal: ConfirmModal) {
    let createModal = new ConfirmModal();
    createModal.onCancelClicked = () => {
      this.viewContainerRef.clear();
    };
    modal.type = 'check_circle';
    Object.assign(createModal, modal);
    const ref = this.viewContainerRef.createComponent(ConfirmModalComponent);
    ref.instance.modal = createModal;
  }

  info(modal: ConfirmModal) {
    let createModal = new ConfirmModal();
    createModal.onCancelClicked = () => {
      this.viewContainerRef.clear();
    };
    modal.type = 'error';
    Object.assign(createModal, modal);
    const ref = this.viewContainerRef.createComponent(ConfirmModalComponent);
    ref.instance.modal = createModal;
  }
}
