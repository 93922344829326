import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LocalStorageService } from 'src/app/services/local.storage.service';
import { ValidationService } from '../../../shared/services/validation.service';
import { AUTH_ROUTES } from '../../auth-routing.module';
import { AuthService } from '../../shared/services/auth.service';

@Component({
  selector: 'khatapana-forgot-password',
  templateUrl: './forgot-password.component.html',
})
export class ForgotPasswordComponent implements OnInit {
  AUTH_ROUTES = AUTH_ROUTES;

  disableNext = false;
  forgotForm!: FormGroup;
  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private localStorage: LocalStorageService
  ) {
    this.forgotForm = this.fb.group({
      otp: [this.localStorage.otp],
      password: [
        '',
        [Validators.required, ValidationService.passwordValidator],
      ],
    });
  }

  get password() {
    return this.forgotForm.get('password');
  }

  ngOnInit(): void {
    this.loadUser();
  }

  loadUser() {
    let username = this.localStorage.username;
    let otp = this.localStorage.otp;
    if (!username || !otp) {
      this.router.navigateByUrl(AUTH_ROUTES.LOGIN);
    }
  }

  submit() {
    const otp = this.localStorage.otp;
    const username = this.localStorage.username;
    this.disableNext = true;
    this.authService
      .forgotPassword(username, otp, this.password?.value)
      .subscribe({
        next: () => {
          this.disableNext = false;
          this.localStorage.removeItem('token');
          this.router.navigate(['/auth/login']);
        },
        error: (err: HttpErrorResponse) => {
          // If Invalid token error
          if (err.error.token) {
            this.router.navigate(['/auth/otp?from=forgot-password']);
          }
          this.disableNext = false;
        },
        complete: () => {
          this.disableNext = false;
        },
      });
  }
}
