import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { ACCOUNT_ROUTES } from 'src/app/modules/account/account-routing.module';
import { AUTH_ROUTES } from 'src/app/modules/auth/auth-routing.module';
import { AuthService } from 'src/app/modules/auth/shared/services/auth.service';
import { LocalStorageService } from 'src/app/services/local.storage.service';
import { HOME_ROUTE } from '../../home/home-routing.module';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private localStorage: LocalStorageService,
    private authService: AuthService,
    private router: Router
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    let token = this.localStorage.token;

    if (!token) {
      this.authService.refreshToken().subscribe({
        next(value) {
          window.location.reload();
        },
      });
      token = this.localStorage.token;
    }

    // check if user is already logged in
    if (token && this.authService.validateToken(token)) {
      // Checking token and user subscriptions: If both exists then only show dashboard //
      if (!this.localStorage.user?.user) {
        this.authService.loadUserToLocalStorage();
      }
      if (this.localStorage.user?.active_subscription) {
        this.authService.loadUserToLocalStorage();
        this.router.navigate([HOME_ROUTE.DASHBOARD]);
        return false;
      }
      // If User not registered and not social user
      if (!this.authService.isUserRegister && !this.authService.isSocialUser) {
        this.router.navigate([AUTH_ROUTES.REGISTER]);
        return false;
      }
      // If User not subscribed
      if (!this.localStorage.user?.active_subscription) {
        this.router.navigate([ACCOUNT_ROUTES.CHOOSE]);
        return false;
      }

      return false;
    }
    this.localStorage.resetLocalStorage();
    //if in register page return to login page
    if (state.url.includes('register')) {
      this.router.navigate([AUTH_ROUTES.LOGIN]);
      return false;
    }
    return true;

    // Checking token and user subscriptions: If both exists then only show dashboard //
    if (
      this.localStorage.token &&
      this.localStorage.user?.active_subscription
    ) {
      this.authService.loadUserToLocalStorage();
      return true;
    } else {
      // If User not loggedIn
      if (!this.localStorage.token) {
        this.router.navigate([AUTH_ROUTES.LOGIN]);
      }
      // If User not registered
      if (!this.authService.isUserRegister) {
        this.router.navigate([AUTH_ROUTES.REGISTER]);
      }
      // If User not registered
      if (
        this.authService.isUserRegister &&
        !this.localStorage.user?.active_subscription
      ) {
        this.router.navigate([ACCOUNT_ROUTES.CHOOSE]);
      }

      return false;
    }
  }
}
