import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SubscriptionPlan } from '../../../account/shared/models/subscriptions.model';

@Component({
  selector: 'khatapana-plan-type-tab',
  templateUrl: './plan-type-tab.component.html',
})
export class PlanTypeTabComponent implements OnInit {
  @Input() selectedTab: any;
  @Input() subscriptionPlans: SubscriptionPlan[] = [];

  @Output() changeTab = new EventEmitter<SubscriptionPlan>();

  constructor() {}

  ngOnInit(): void {}
}
