import { Component, OnInit } from '@angular/core';
import { HOME_ROUTE } from 'src/app/modules/home/home-routing.module';

@Component({
  selector: 'khatapana-completed',
  templateUrl: './completed.component.html',
  styles: [
    `
      ::ng-deep.card-width {
        width: 650px !important;
      }
    `,
  ],
})
export class CompletedComponent implements OnInit {
  HOME_ROUTE = HOME_ROUTE;
  constructor() {}

  ngOnInit(): void {}
}
