<khatapana-modal
  [width]="600"
  [backgroundDark]="false"
  [FullWidthOnMobile]="true"
>
  <!-- Header -->
  <header
    class="flex justify-start gap-10 text-2xl items-center font-semibold p-6"
  >
    <khatapana-button
      (onClick)="close.emit(true)"
      border="circle"
      color="basic"
      [link]="true"
      variant="no-padding"
    >
      <div
        class="text-lg font-semibold h-12 w-12 flex justify-center items-center"
      >
        <i class="fa fa-arrow-left"></i></div
    ></khatapana-button>
    <h1>{{ "transaction.choose_purpose" | translate }}</h1>
  </header>
  <!-- Body  -->
  <section
    class="flex flex-col p-6 mb-5 gap-1 max-h-[500px] overflow-y-auto max-sm:p-3"
    *ngIf="!loader; else loaderTemplate"
  >
    <ng-container *ngFor="let category of categories; let i = index">
      <div class="flex flex-col">
        <!-- Panel -->
        <div
          [ngClass]="{ 'bg-[#F5F5F5]': selectedPanel === i }"
          class="w-full flex gap-2 p-3 pl-8 hover:bg-[#F5F5F5] rounded-full cursor-pointer max-sm:pl-3 items-center"
          (click)="tooglePanelSelection(i)"
        >
          <!-- Icon -->
          <div class="w-[12%]">
            <div
              class="bg-[#d6ceff] flex h-12 w-12 rounded-lg items-center justify-center max-sm:h-8 max-sm:w-8"
            >
              <p class="text-3xl font-semibold max-sm:text-xl">
                {{ category.name[0] | titlecase }}
              </p>
            </div>
          </div>
          <div class="flex justify-between items-start w-[88%] max-sm:w-full">
            <!-- Content -->
            <div class="flex flex-col">
              <h1 class="font-semibold text-lg">
                {{ category.name | titlecase }}
              </h1>
              <p
                class="text-sm text-[#545454] whitespace-nowrap overflow-hidden overflow-ellipsis max-w-[300px] max-sm:max-w-[180px]"
              >
                {{
                  getCombinedSubCategory(category.sub_categories) | titlecase
                }}
              </p>
            </div>
            <!-- Expand Arrow -->
            <div>
              <i
                [ngClass]="
                  selectedPanel == i && panelExpanded
                    ? 'fa-chevron-up'
                    : 'fa-chevron-down'
                "
                class="fa text-lg p-3 px-4 font-semibold"
              ></i>
            </div>
          </div>
        </div>
        <!-- Panel Expansion -->
        <ng-container *ngIf="selectedPanel == i && panelExpanded">
          <div
            class="w-full flex flex-col gap-1 py-2"
            *ngFor="let subCategory of category.sub_categories"
          >
            <khatapana-button
              color="simple"
              [link]="true"
              border="circle"
              (onClick)="savePurpose(subCategory, category.party_mandatory)"
              [fullWidth]="true"
            >
              <div class="flex w-full py-2 pl-8">
                <div class="w-[12%]"></div>
                <div
                  class="w-[88%] justify-between items-center flex text-[#545454] text-lg font-normal"
                >
                  <p class="text-left">
                    {{ subCategory.name | titlecase }}
                  </p>
                  <i
                    *ngIf="selectedPurpose == subCategory.id"
                    class="fa fa-check-circle text-xl mr-3 text-[#7B61FF]"
                  ></i>
                </div>
              </div>
            </khatapana-button>
          </div>
        </ng-container>
      </div>
    </ng-container>
  </section>
  <ng-template #loaderTemplate>
    <section class="flex flex-col p-6 mb-5">
      <khatapana-loader></khatapana-loader>
    </section>
  </ng-template>
</khatapana-modal>
