<div #modal>
  <khatapana-button
    border="circle"
    variant="no-padding"
    [outline]="true"
    color="basic"
    (onClick)="openProfileNav()"
    ><span class="flex w-full justify-center items-center gap-5 py-1 px-2 pr-3">
      <div
        class="rounded-full overflow-hidden bg-[#EEEEEE] h-10 w-10 flex items-center justify-center"
      >
        <img
          [ngClass]="
            (user$ | async)?.profile_image
              ? 'h-10 w-10 object-cover'
              : 'h-7 w-7'
          "
          class="overflow-hidden"
          [src]="
            (user$ | async)?.profile_image ?? 'assets/icons/home/profile.svg'
          "
        />
      </div>
      <!-- {{ (user$ | async)?.first_name }} {{ (user$ | async)?.last_name }} -->
      <i class="fa fa-chevron-down"></i>
    </span>
    <khatapana-capsule
      *ngIf="
        (user$ | async)?.active_subscription?.subscription_plan
          ?.subscription_type != 'Khatapana Standard'
      "
      class="absolute right-4 top-2"
      [color]="
        (user$ | async)?.active_subscription?.is_trial
          ? 'outline-purple'
          : 'dark-purple'
      "
      [label]="
        (user$ | async)?.active_subscription?.is_trial
          ? 'Trial'
          : (user$ | async)?.active_subscription?.subscription_plan
              ?.subscription_type == 'Khatapana Pro'
          ? 'Pro'
          : 'Plus'
      "
    ></khatapana-capsule>
  </khatapana-button>

  <!-- Profile Dropdown Modal -->
  <div
    *ngIf="showProfileDropdownTab"
    class="absolute rounded-xl shadow-md overflow-hidden m-2 items-start bg-[#fff] w-[360px] right-0 rounded-t-sm top-20 flex flex-col"
  >
    <!-- Profile info tab -->
    <div class="flex px-3 py-5 flex-col gap-6 items-center w-full">
      <div
        class="rounded-full overflow-hidden bg-[#EEEEEE] h-16 w-16 flex items-center justify-center"
      >
        <img
          [ngClass]="
            (user$ | async)?.profile_image
              ? 'h-16 w-16 object-cover'
              : 'h-10 w-10'
          "
          class="overflow-hidden"
          [src]="
            (user$ | async)?.profile_image ?? 'assets/icons/home/profile.svg'
          "
        />
      </div>
      <khatapana-capsule
        *ngIf="
          (user$ | async)?.active_subscription?.subscription_plan
            ?.subscription_type != 'Khatapana Standard'
        "
        class="fixed mt-12 border-[3px] border-white rounded-full"
        [color]="
          (user$ | async)?.active_subscription?.is_trial
            ? 'outline-purple'
            : 'dark-purple'
        "
        [label]="
          (user$ | async)?.active_subscription?.is_trial
            ? 'Trial'
            : (user$ | async)?.active_subscription?.subscription_plan
                ?.subscription_type == 'Khatapana Pro'
            ? 'Pro'
            : 'Plus'
        "
      ></khatapana-capsule>
      <div class="flex flex-col items-center">
        <h5 class="text-xl font-semibold">
          {{ (user$ | async)?.first_name }} {{ (user$ | async)?.last_name }}
        </h5>
        <p class="text-[#0000008c]">{{ (user$ | async)?.mobile_number }}</p>
        <p class="text-[#0000008c]">{{ (user$ | async)?.email }}</p>
      </div>
      <khatapana-button
        (onClick)="GOTO(SETTING_ROUTES.PROFILE)"
        variant="extra-small"
        [outline]="true"
        border="circle"
      >
        <span class="text-sm px-6">{{
          "setting.view_edit_profile" | translate
        }}</span>
      </khatapana-button>
    </div>
    <khatapana-divider class="w-full"></khatapana-divider>
    <!-- Other Menu -->
    <div class="flex flex-col w-full">
      <khatapana-button
        (onClick)="GOTO(SETTING_ROUTES.ACCOUNT)"
        variant="big"
        color="simple"
        [fullWidth]="true"
      >
        <span class="font-normal flex w-full justify-between items-center"
          >{{ "setting_account_settings" | translate
          }}<span class="text-sm text-gray-600 font-semibold"
            >V2.0.5</span
          ></span
        >
      </khatapana-button>
      <khatapana-button
        *ngIf="
          (user$ | async)?.active_subscription?.subscription_plan
            ?.subscription_type != 'Khatapana Standard'
        "
        (onClick)="GOTO(HOME_ROUTES.SUBSCRIPTION)"
        variant="big"
        color="simple"
        [fullWidth]="true"
      >
        <span class="font-normal">{{
          "common.subscriptions" | translate
        }}</span>
      </khatapana-button>
      <khatapana-button
        (onClick)="GOTO(HOME_ROUTES.FEEDBACK)"
        variant="big"
        color="simple"
        [fullWidth]="true"
      >
        <span class="font-normal">{{
          "common.help_and_feedback" | translate
        }}</span>
      </khatapana-button>
    </div>
    <!-- Sign Out menu -->
    <khatapana-divider class="w-full"></khatapana-divider>
    <div class="flex justify-center w-full p-4">
      <khatapana-button
        variant="small"
        (onClick)="signOut()"
        color="basic"
        [outline]="true"
        border="circle"
      >
        <span class="px-5">{{ "common.sign_out" | translate }}</span>
      </khatapana-button>
    </div>
  </div>
</div>
