import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LocalStorageService } from './local.storage.service';


const BASE_URL = 'https://v2.dev.api.khatapana.com';
// const BASE_URL = 'https://v2.api.khatapana.com';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  options: any = {
    headers: new HttpHeaders().set('Content-Type', 'application/json'),
    withCredentials: false,
  };
  isRefreshing = false;
  formDataOptions: any;
  constructor(
    private httpClient: HttpClient,
    private localStorage: LocalStorageService
  ) {}

  inititalizeToken() {
    if (this.localStorage.token) {
      this.options = {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/json')
          .set('Authorization', `Token ${this.localStorage.token}`),
      };

      this.formDataOptions = {
        headers: new HttpHeaders().set(
          'Authorization',
          `Token ${this.localStorage.token}`
        ),
      };
    }
  }

  public get(
    path: string,
    params: HttpParams = new HttpParams(),
    auth: boolean = true
  ): Observable<any> {
    if (auth) {
      this.inititalizeToken();
    }
    this.options.params = params;
    return this.httpClient
      .get(BASE_URL + path, this.options)
      .pipe(catchError(this.formatErrors));
  }
  public getNoURL(
    path: string,
    params: HttpParams = new HttpParams(),
    auth: boolean = true
  ): Observable<any> {
    if (auth) {
      this.inititalizeToken();
    }
    this.options.params = params;
    return this.httpClient
      .get(path, this.options)
      .pipe(catchError(this.formatErrors));
  }

  public post(
    path: string,
    body: object = {},
    auth: boolean = true
  ): Observable<any> {
    if (auth) {
      this.inititalizeToken();
    }
    // this.inititalizeToken();
    return this.httpClient
      .post(BASE_URL + path, JSON.stringify(body), this.options)
      .pipe(catchError(this.formatErrors));
  }
  public put(path: string, body: object = {}): Observable<any> {
    this.inititalizeToken();
    return this.httpClient
      .put(BASE_URL + path, body, this.options)
      .pipe(catchError(this.formatErrors));
  }
  public patch(path: string, body: object = {}): Observable<any> {
    this.inititalizeToken();
    return this.httpClient
      .patch(BASE_URL + path, body, this.options)
      .pipe(catchError(this.formatErrors));
  }
  public delete(path: string): Observable<any> {
    this.inititalizeToken();
    return this.httpClient
      .delete(BASE_URL + path, this.options)
      .pipe(catchError(this.formatErrors));
  }

  public putFile(path: string, body: any): Observable<any> {
    this.inititalizeToken();
    return this.httpClient
      .put(BASE_URL + path, body, this.formDataOptions)
      .pipe(catchError(this.formatErrors));
  }
  public patchFile(path: string, body: any): Observable<any> {
    this.inititalizeToken();
    return this.httpClient
      .patch(BASE_URL + path, body, this.formDataOptions)
      .pipe(catchError(this.formatErrors));
  }
  public postFile(path: string, body: any): Observable<any> {
    this.inititalizeToken();
    return this.httpClient
      .post(BASE_URL + path, body, this.formDataOptions)
      .pipe(catchError(this.formatErrors));
  }

  public formatErrors(error: any): Observable<any> {
    return throwError(() => error);
  }
}
