import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BusinessHomeComponent } from './business-home.component';
import { BusinessPartyComponent } from './pages/business-party/business-party.component';
import { BustinessInOutTransactionsComponent } from './pages/bustiness-in-out-transactions/bustiness-in-out-transactions.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { InOutComponent } from './pages/in-out/in-out.component';
import { PurchasesComponent } from './pages/purchases/purchases.component';
import { SalePurchaseFormComponent } from './pages/sale-purchase-form/sale-purchase-form.component';
import { SalesComponent } from './pages/sales/sales.component';

export const BUSINESS_HOME_ROUTE = {
  DASHBOARD: '/business/dashboard',
  TRANSACTIONS: '/business/transaction',
  SALES: '/business/sales',
  SALES_PARTY: '/business/sales/party',
  PURCHASES: '/business/purchase',
  PURCHASES_PARTY: '/business/purchase/party',
  SALES_PURCHASE_ADD: '/business/sp/add',
  IN_OUT_ADD: '/business/io/add',
};

const routes: Routes = [
  {
    path: '',
    component: BusinessHomeComponent,
    children: [
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      {
        path: 'dashboard',
        component: DashboardComponent,
      },
      {
        path: 'transaction',
        component: BustinessInOutTransactionsComponent,
      },
      {
        path: 'sales',
        component: SalesComponent,
      },
      {
        path: ':type/party',
        component: BusinessPartyComponent,
      },
      {
        path: 'purchase',
        component: PurchasesComponent,
      },
      {
        path: 'sp/add',
        component: SalePurchaseFormComponent,
      },
      {
        path: 'io/add',
        component: InOutComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class BusinessRoutingModule {}
