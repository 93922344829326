import { Component } from '@angular/core';
import { NavigationTopService } from '../../shared/services/navigation-top.service';

@Component({
  selector: 'khatapana-purchases',
  templateUrl: './purchases.component.html',
})
export class PurchasesComponent {
  constructor(private navigationTopService: NavigationTopService) {
    this.navigationTopService.type$.next('purchase');
    this.navigationTopService.mode$.next('transaction');
  }
}
