import { Component, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AccountSettingComponent } from './components/account-setting/account-setting.component';
import { PasswordSettingComponent } from './components/password-setting/password-setting.component';
import { ProfileSettingComponent } from './components/profile-setting/profile-setting.component';
import { SettingComponent } from './setting.component';

export const SETTING_ROUTES = {
  PROFILE: '/app/setting/profile',
  ACCOUNT: '/app/setting/account',
  PASSWORD: '/app/setting/password',
};

const routes: Routes = [
  {
    path: '',
    component: SettingComponent,
    children: [
      { path: '', redirectTo: 'profile', pathMatch: 'full' },
      { path: 'profile', component: ProfileSettingComponent },
      { path: 'account', component: AccountSettingComponent },
      { path: 'password', component: PasswordSettingComponent },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SettingRoutingModule {}
