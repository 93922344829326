import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'khatapana-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
})
export class DatePickerComponent {
  @Input() model = new NgbDate(
    new Date().getFullYear(),
    new Date().getMonth() + 1,
    new Date().getDate()
  );

  @Output() onChange = new EventEmitter<NgbDate>();
  @Input() full = false;
  @ViewChild('d') d?: any;

  changed() {
    this.d.toggle();
    this.onChange.emit(this.model);
  }
}
