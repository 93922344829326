import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalStorageService } from 'src/app/services/local.storage.service';
import { AUTH_ROUTES } from '../../auth/auth-routing.module';
import { SharedModule } from '../../shared/shared.module';

@Component({
  selector: 'khatapana-referral',
  templateUrl: './referral.component.html',
  standalone: true,
  imports: [SharedModule],
})
export class ReferralComponent {
  referralCode: string = '';
  constructor(
    private _route: ActivatedRoute,
    private router: Router,
    private localStorage: LocalStorageService
  ) {
    this.referralCode = this._route.snapshot.queryParamMap.get('referrer')!;
    if (this.referralCode) {
      this.localStorage.referral_code = this.referralCode;
      this.router.navigateByUrl(AUTH_ROUTES.LOGIN);
    }
  }
}
