import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { TranslateLoader } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type HashMap<T = any> = Record<string, T>;
type Translation = HashMap;

@Injectable({
  providedIn: 'root',
})
export class I18nHttpLoaderService implements TranslateLoader {
  constructor(private http: HttpClient) {}

  getTranslation(lang: string): Observable<Translation> {
    return this.http
      .get<Translation>(`/assets/i18n/${lang}.json`)
      .pipe(map((translation) => this.filterTranslations(translation)));
  }

  // filter translations that has empty strings
  private filterTranslations(translation: Translation) {
    const hasProperty = Object.prototype.hasOwnProperty.bind(translation);
    const newTranslation: Translation = {};

    for (const key in translation) {
      if (hasProperty(key)) {
        if (typeof translation[key] === 'object') {
          newTranslation[key] = this.filterTranslations(translation[key]);
        } else if (typeof translation[key] === 'string' && translation[key] === '') {
          // do not copy empty strings
        } else {
          newTranslation[key] = translation[key];
        }
      }
    }
    return newTranslation;
  }
}
