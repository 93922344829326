<khatapana-card
  *ngIf="getScreenWidth > 700; else mobileTemplate"
  [border]="true"
  [class]="'bg-white m-6 overflow-hidden'"
>
  <nav class="px-6 py-3 flex justify-between items-center">
    <h1 class="font-semibold">{{ "party.parties" | translate }}</h1>
    <khatapana-button
      (onClick)="showForm = true"
      [link]="true"
      variant="extra-small"
      border="circle"
      ><span class="items-center flex gap-3"
        ><i class="fa fa-plus"></i>{{ "party.add_party" | translate }}</span
      ></khatapana-button
    >
  </nav>
  <khatapana-divider></khatapana-divider>
  <!-- List Table -->
  <table>
    <thead>
      <tr>
        <th class="w-3/12">{{ "party.th_name" | translate }}</th>
        <th class="w-3/12">{{ "party.th_phone" | translate }}</th>
        <th class="w-6/12">{{ "party.th_email" | translate }}</th>
        <th width="10px"></th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let party of partyList">
        <khatapana-divider class="w-full"></khatapana-divider>
        <tr (click)="gotoDetail(party.id!)">
          <td class="text-start text-[#333333] w-3/12">
            <span class="flex gap-6 items-center"
              >{{ party.name | titlecase }}
              <img
                class="h-6"
                *ngIf="party.khata_user"
                src="../../../../../assets/icons/home/khatapana-party.svg"
            /></span>
          </td>
          <td class="text-start text-[#333333] w-3/12">
            {{ party.phone }}
          </td>
          <td class="text-start text-[#333333] w-6/12">
            {{ party.email }}
          </td>
          <td
            width="10px"
            (click)="$event.stopPropagation(); selectedParty = party.id"
          >
            <span class="action-menu material-symbols-outlined text-[#545454]">
              more_vert
            </span>
          </td>
          <khatapana-menu-items
            *ngIf="selectedParty === party.id"
            (clickedEdit)="editParty(party.id!)"
            (clickedDelete)="deleteParty(party.id!)"
          ></khatapana-menu-items>
        </tr>
      </ng-container>
    </tbody>
  </table>
</khatapana-card>

<!-- Mobile Template -->
<ng-template #mobileTemplate>
  <khatapana-card
    [border]="true"
    [class]="'bg-white m-6 overflow-hidden max-lg:mb-[90px]'"
  >
    <nav class="px-6 py-3 flex justify-between items-center">
      <khatapana-button
        (onClick)="showForm = true"
        [link]="true"
        variant="extra-small"
        border="circle"
        ><span class="items-center flex gap-3"
          ><i class="fa fa-plus"></i
          >{{ "party.add_new_party" | translate }}</span
        ></khatapana-button
      >
    </nav>
    <!-- List Of Parties -->

    <div
      *ngFor="let party of partyList"
      class="p-3 px-5 flex gap-4 items-center border-b border-[#E2E2E2] last:border-none"
    >
      <div
        class="flex w-12 h-12 rounded-full bg-[#7b61ff28] justify-center items-center text-lg font-semibold"
      >
        {{ party.name ? (party.name[0] | titlecase) : ("O" | titlecase) }}
      </div>
      <!-- Name & Numbers -->
      <div
        class="flex justify-between w-[80%] gap-2"
        (click)="gotoDetail(party.id!)"
      >
        <div class="flex flex-col">
          <span class="text-lg flex gap-3"
            >{{ party.name | titlecase }}
            <img
              class="h-6"
              *ngIf="party.khata_user"
              src="../../../../../assets/icons/home/khatapana-party.svg"
          /></span>
          <span *ngIf="party.phone" class="text-sm">{{ party.phone }}</span>
        </div>
        <div
          width="10px"
          (click)="$event.stopPropagation(); selectedParty = party.id"
        >
          <span class="action-menu material-symbols-outlined text-[#545454]">
            more_vert
          </span>
        </div>
        <khatapana-menu-items
          *ngIf="selectedParty === party.id"
          (clickedEdit)="editParty(party.id!)"
          (clickedDelete)="deleteParty(party.id!)"
        ></khatapana-menu-items>
      </div>
    </div>
  </khatapana-card>
</ng-template>

<!-- Add Party -->
<khatapana-party-form
  (closed)="showForm = false"
  (added)="partyAdded($event)"
  [businessTransaction]="businessTransaction"
  *ngIf="showForm"
></khatapana-party-form>
