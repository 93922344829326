<khatapana-card
  [border]="true"
  [class]="'bg-white m-6 overflow-hidden max-lg:mb-[70px]'"
>
  <ng-container *ngFor="let notification of notificationList">
    <div class="p-4 pb-2 text-lg font-semibold">{{ notification.title }}</div>
    <div class="px-6 pb-3 text-blue-700">{{ notification.content }}</div>
    <khatapana-divider></khatapana-divider>
  </ng-container>
</khatapana-card>
