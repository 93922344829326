<div
  *ngIf="
    (dashboardData$ | async)?.have_transaction === true;
    else noTransactionTemplate
  "
  class="flex w-full p-6 gap-6 max-xl:flex-col max-xl:w-full max-lg:mb-[70px]"
>
  <div class="flex w-[64%] gap-6 flex-col max-xl:w-full">
    <khatapana-dashbaord-report
      (reportingTypeChanged)="reportingType?.setValue($event)"
      (transactionModeChanged)="transactionMode?.setValue($event)"
      [reportingType]="reportingType?.value"
      [transactionMode]="transactionMode?.value"
    ></khatapana-dashbaord-report>
    <!-- Summary container for mobile -->
    <div
      class="flex w-[36%] gap-6 flex-col max-xl:flex-row min-[1280px]:hidden max-xl:w-full max-sm:flex-col"
    >
      <ng-template [ngTemplateOutlet]="summaryContainer"></ng-template>
    </div>

    <khatapana-card
      class="flex w-full flex-col"
      [class]="'bg-white'"
      [border]="true"
    >
      <header class="flex justify-between w-full px-6 py-3 items-center">
        <h1 class="text-xl font-semibold">
          {{ "dashboard.recent_transaction" | translate }}
        </h1>
        <khatapana-button
          [routerLink]="HOME_ROUTES.TRANSACTION_LIST"
          [link]="true"
          variant="extra-small"
          border="circle"
          ><span class="items-center flex gap-5"
            ><span class="max-sm:hidden">{{
              "common.view_All" | translate
            }}</span
            ><i class="fa fa-chevron-right"></i></span
        ></khatapana-button>
      </header>
      <khatapana-divider></khatapana-divider>
      <khatapana-transaction-table
        class="w-full"
        [dashboardPage]="true"
        [giveBorder]="false"
        [mode]="transactionMode?.value.id"
        (transactionListChanged)="transactionList = $event"
      ></khatapana-transaction-table>
    </khatapana-card>
  </div>
  <!-- Summary Container -->
  <div class="flex w-[36%] gap-6 flex-col max-xl:hidden">
    <ng-template [ngTemplateOutlet]="summaryContainer"></ng-template>
  </div>
</div>

<!-- If User don't have any transaction -->
<ng-template #noTransactionTemplate>
  <div
    class="flex w-full h-10 p-6 gap-6 max-xl:flex-col max-xl:h-auto max-lg:mb-[70px]"
    *ngIf="(dashboardData$ | async)?.have_transaction === false"
  >
    <khatapana-welcome-no-transaction
      (updatedOpening)="loadDashboard()"
      [dashboardData$]="dashboardData$"
      class="flex w-[64%] max-xl:w-full"
    ></khatapana-welcome-no-transaction>
    <khatapana-simplify-khata
      class="flex w-[36%] max-xl:w-full"
    ></khatapana-simplify-khata>
  </div>
</ng-template>

<khatapana-loader *ngIf="loading" [full]="true" [size]="100"></khatapana-loader>

<ng-template #summaryContainer>
  <khatapana-dashboard-summary
    class="max-xl:w-full khatapana-transaction-table"
    (downloadClicked)="downloadReport()"
    [reportingType]="reportingType?.value"
    [transactionMode]="transactionMode?.value"
  ></khatapana-dashboard-summary>
  <khatapana-dashboard-expense-summary
    class="max-xl:w-full"
    [reportingType]="reportingType?.value"
    [transactionMode]="transactionMode?.value"
  ></khatapana-dashboard-expense-summary>
</ng-template>
