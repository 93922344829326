<div class="absolute flex w-80 z-[1] max-sm:ml-20">
  <khatapana-card class="bg-white w-full" [border]="true" [elevation]="true">
    <section class="max-h-48 overflow-y-auto first:rounded-t-lg last:rounded-b-lg overflow-hidden">
      <khatapana-button (onClick)="showForm = true" [fullWidth]="true" color="simple" [link]="true">
        <div class="text-[#7B61FF] flex gap-5 px-3 items-center">
          <!-- Icon -->
          <div class="flex justify-center items-center h-10 w-10 rounded-full bg-[#f5f5f5]">
            <span class="material-symbols-outlined"> person_add </span>
          </div>
          <div>Add new party to list</div>
        </div>
      </khatapana-button>
      <ng-container *ngFor="let party of partyList" class="party-option">
        <khatapana-button (onClick)="changeParty(party)" [fullWidth]="true"
          [color]="selectedParty?.id !== party.id ? 'simple' : 'primary'" [link]="selectedParty?.id !== party.id">
          <div class="w-full flex justify-between items-center mr-3">
            <!-- Icon -->
            <div class="flex gap-5 px-3 items-center">
              <div [ngClass]="
                  selectedParty?.id !== party.id
                    ? 'bg-[#7B61FF] text-white'
                    : 'bg-white text-[#141414]'
                " class="flex justify-center items-center h-10 w-10 rounded-full">
                <span>{{party?.name?.[0] | titlecase}}</span>
              </div>
              <div class="flex flex-col items-start">
                <span>{{ party.name | titlecase }}</span><span class="text-xs font-normal">{{ party.phone }}</span>
              </div>
            </div>
            <khatapana-capsule *ngIf="party.transaction_amount?.due_amount! > 0" color="yellow"
              label="Due"></khatapana-capsule>
          </div>
        </khatapana-button>
      </ng-container>
    </section>
  </khatapana-card>
</div>

<!-- Add Party -->
<khatapana-party-form [businessTransaction]="businessTransaction" (closed)="showForm = false"
  (added)="partyAdded($event)" *ngIf="showForm"></khatapana-party-form>