<div class="p-5 pb-7">
  <header class="text-xl pb-8 font-semibold">
    {{ "referral.faqs.title" | translate }}
  </header>
  <section>
    <ng-container *ngFor="let faq of FAQS">
      <!-- Question -->
      <div
        class="flex justify-between cursor-pointer p-4 hover:bg-[#f3f3f3]"
        (click)="faq.expanded = !faq.expanded"
      >
        <h1 class="font-semibold text-[#787878]">
          {{ faq.question | translate }}
        </h1>
        <span class="material-symbols-outlined cursor-pointer">{{
          faq.expanded ? "remove" : "add"
        }}</span>
      </div>
      <!-- Answer -->
      <div
        class="pb-6 pr-6 pl-4"
        *ngIf="faq.expanded"
        [innerHTML]="faq.answers | translate"
      ></div>
      <khatapana-divider></khatapana-divider>
    </ng-container>
    <h1></h1>
  </section>
</div>
