import { Component, Input } from '@angular/core';

@Component({
  selector: 'khatapana-card',
  templateUrl: './card.component.html',
})
export class CardComponent {
  @Input() radius: 'small' | 'medium' | 'big' | 'none' = 'medium';
  @Input() border = false;
  @Input() elevation = false;
  @Input() class = '';
}
