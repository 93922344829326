import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UUID } from 'angular2-uuid';
import {
  AccountService,
  BANK_MODE_ID,
} from 'src/app/modules/account/shared/services/account.service';
import { LocalStorageService } from 'src/app/services/local.storage.service';
import { amount_accepting_zero_not_starting_with_zero } from '../../regex/amount.regex';
import { AlertService } from '../../services/alert.service';

@Component({
  selector: 'khatapana-add-bank-modal',
  templateUrl: './add-bank-modal.component.html',
})
export class AddBankModalComponent implements OnInit {
  bankForm!: FormGroup;
  banks: any[] = [];

  @Input() businessTransaction = false;
  @Output() close = new EventEmitter<boolean>();
  @Output() added = new EventEmitter<boolean>();
  constructor(
    private fb: FormBuilder,
    private alertService: AlertService,
    private accountService: AccountService,
    private localStorage: LocalStorageService
  ) {
    this.initBankForm();
  }

  ngOnInit() {
    this.loadAllBanks();
  }

  //#region  Getters for form
  get id() {
    return this.bankForm?.get('id');
  }

  get account_holder() {
    return this.bankForm?.get('account_holder');
  }
  get balance() {
    return this.bankForm?.get('balance');
  }
  get account_number() {
    return this.bankForm?.get('account_number');
  }
  get payment_gateway() {
    return this.bankForm?.get('payment_gateway');
  }
  //#endregion

  loadAllBanks() {
    this.accountService
      .gettPaymentOptionsAccordingToTransactionMode(BANK_MODE_ID)
      .subscribe({
        next: (res) => {
          res.results.forEach((bank: any) => {
            this.banks.push({ id: bank.id, name: bank.name });
          });
          this.banks = [...this.banks];
          this.payment_gateway?.setValue(this.banks[0]);
        },
      });
  }

  initBankForm() {
    this.bankForm = this.fb.group({
      id: [null],
      account_holder: [
        this.localStorage.user.first_name +
          ' ' +
          this.localStorage.user.last_name,
        Validators.required,
      ],
      balance: [
        0,
        [
          Validators.required,
          Validators.pattern(amount_accepting_zero_not_starting_with_zero),
        ],
      ],
      account_number: [UUID.UUID()],
      payment_gateway: [''],
    });
  }

  changeGateway(event: any) {
    this.payment_gateway?.setValue(this.banks.find((x) => x.id == event));
  }

  saveBank() {
    this.payment_gateway?.enable();
    const { value } = this.bankForm;
    value.payment_gateway = value.payment_gateway.id;
    if (this.businessTransaction) {
      value.business_account = this.localStorage.business_account;
    }
    this.accountService.addBankBalance(this.bankForm.value).subscribe({
      next: () => {
        this.added.emit(true);
      },
      error: (err: HttpErrorResponse) => {
        this.alertService.error(
          this.alertService.getFormattedErrorMessage(err.error)
        );
      },
    });
  }

  closeBankModal() {
    this.close.emit();
  }
}
