<khatapana-divider></khatapana-divider>
<div
  class="w-full bg-[#EEEEEE] h-20 flex justify-between items-center p-6 max-sm:px-2"
>
  <div class="text-[#545454]">{{ totalData }} {{ totalLabel | translate }}</div>
  <div class="flex items-center gap-6 max-sm:gap-2">
    <khatapana-button
      border="little"
      color="simple"
      [disabled]="!hasPrev"
      (onClick)="selectedPage.id > 1 ? onPrevClicked.emit(true) : null"
      ><span class="flex gap-3 items-center"
        ><i class="fa fa-chevron-left"></i
        ><span class="max-sm:hidden">{{ prevLabel | translate }}</span></span
      ></khatapana-button
    >
    <khatapana-select
      (onChange)="changePage($event)"
      variant="mild"
      [selectClass]="'px-2 gap-5 z-10'"
      [list]="pageList"
      [appendTo]="'body'"
      [selected]="selectedPage"
    ></khatapana-select>
    <div>of {{ totalPage }}</div>
    <khatapana-button
      border="little"
      color="simple"
      [disabled]="!hasNext"
      (onClick)="selectedPage.id < totalPage ? onNextClicked.emit(true) : null"
      ><span class="flex gap-3 items-center">
        <span class="max-sm:hidden"> {{ nextLabel | translate }}</span
        ><i class="fa fa-chevron-right"></i></span
    ></khatapana-button>
  </div>
</div>
