import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'khatapana-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent implements OnInit {
  // Input Events
  @Input() color:
    | 'basic'
    | 'primary'
    | 'secondary'
    | 'primary-light'
    | 'white'
    | 'red'
    | 'green'
    | 'simple' = 'primary';
  @Input() variant: 'small' | 'medium' | 'big' | 'extra-small' | 'no-padding' =
    'small';
  @Input() disabled = false;
  @Input() fullWidth = false;
  @Input() loading = false;
  @Input() border: 'none' | 'circle' | 'little' = 'none';
  @Input() outline = false;
  @Input() link = false;
  @Input() shadow = false;

  //Output Events
  @Output() onClick = new EventEmitter<boolean>();

  constructor() {}

  ngOnInit(): void {}
}
