<div
  class="p-5 rounded-md flex items-center justify-between border-[#EEEEEE] border-[2px]"
>
  <div class="flex items-center gap-4">
    <div class="bg-black rounded-full flex items-center justify-center">
      <span class="material-symbols-outlined text-white p-3">
        {{ icon }}
      </span>
    </div>

    <div class="flex flex-col">
      <p class="font-semibold text-xl">{{ title | translate }}</p>
      <p class="text-base text-[#545454]">
        {{ subTitle | translate }}
      </p>
    </div>
  </div>
  <div>
    <div
      [ngClass]="{
        'border-[#757575] border-[3px]': !selected,
        'border-[#7B61FF] border-[9px]': selected
      }"
      class="h-6 w-6 rounded-full cursor-pointer"
      (click)="select.emit(true)"
    ></div>
  </div>
</div>
