import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SubscriptionDialogComponent } from 'src/app/modules/home/shared/components/subscription-dialog/subscription-dialog.component';
import { LocalStorageService } from 'src/app/services/local.storage.service';
import { ConfirmModalService } from '../../services/confirm.modal.service';

@Component({
  selector: 'khatapana-transaction-mode-button',
  templateUrl: './transaction-mode-button.component.html',
})
export class TransactionModeButtonComponent implements OnInit {
  @Input() icon = '';
  @Input() label = 'Cash';
  @Input() active = false;

  @Output() onClick = new EventEmitter<boolean>();

  isStandard = false;
  constructor(
    private localStorage: LocalStorageService,
    private modalService: ConfirmModalService
  ) {}

  ngOnInit() {
    this.isStandard = this.localStorage.isStandard;
  }

  openSubscriptionDialog() {
    this.modalService.viewContainerRef.createComponent(
      SubscriptionDialogComponent
    );
  }
}
