import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { UserCoin } from '../models/coin';
import { CoinTransactionResponse } from '../models/coin.transaction';

@Injectable({
  providedIn: 'root',
})
export class ReferralService {
  constructor(private api: ApiService) {}

  getReferralCode() {
    return this.api.get('/get-referral-code/');
  }

  getUserCoins(): Observable<UserCoin[]> {
    return this.api.get('/api/coin/get-user-coin/');
  }

  getUserCoinTransactions(): Observable<CoinTransactionResponse> {
    return this.api.get('/api/coin/get-user-coin-transactions/');
  }
}
