import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { LocalStorageService } from 'src/app/services/local.storage.service';
import { Party, PartyResponse } from '../models/party';

@Injectable({
  providedIn: 'root',
})
export class PartyService {
  constructor(
    private api: ApiService,
    private localStorage: LocalStorageService
  ) {}

  addParty(item: Party) {
    return this.api.post('/api/transaction/party/', item);
  }

  editParty(item: Party, id: number) {
    return this.api.patch(`/api/transaction/party/${id}/`, item);
  }

  getParties(forBusiness = false) {
    return this.api
      .get(`/api/transaction/party/?business_account__isnull=${!forBusiness}`)
      .pipe(
        map((parties: PartyResponse[]) => {
          if (forBusiness) {
            return parties.filter(
              (x) => x.business_account === this.localStorage.business_account
            );
          } else {
            return parties;
          }
        })
      );
  }
  deleteParty(itemId: number) {
    return this.api.delete(`/api/transaction/party/${itemId}/`);
  }
  getSingleParty(id: number) {
    return this.api.get(`/api/transaction/party/${id}/`);
  }
}
