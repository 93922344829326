<div
  class="flex p-6 w-full gap-6 max-lg max-lg:mb-[70px] max-md:flex-col-reverse"
>
  <div class="flex w-[60%] max-md:w-full">
    <khatapana-card class="w-full" [class]="'bg-white '" [border]="true">
      <div class="flex px-6 py-4 justify-between items-center">
        <h5 class="font-semibold text-xl">
          {{ "expense_summary.title" | translate }}
        </h5>
        <div class="align-self-center cursor-pointer">
          <khatapana-select
            [list]="reportings"
            [selected]="this.reportings[2]"
            (onChange)="handleChange($event)"
            border="medium"
            variant="mild"
          ></khatapana-select>
        </div>
      </div>
      <khatapana-divider></khatapana-divider>
      <div class="p-6 flex flex-col gap-1">
        <p class="text-xl text-[#545454] font-semibold">
          {{ "expense_summary.your_total_expense" | translate }}
          {{ reportingType?.slice(0, -2) }}
        </p>
        <p class="text-3xl font-semibold">
          {{ expenseReport?.total_net_expenses | currency : "Rs. " }}
        </p>
        <p class="text-[#757575]">
          {{ startDate | date }} - {{ endDate | date }}
        </p>
        <div
          class="py-4 px-6 gap-3 flex items-center mt-4 rounded-lg"
          [ngClass]="
            expense_percentage_difference > 0
              ? 'bg-[#00aa6c15]'
              : 'bg-[#d4433315]'
          "
        >
          <img
            src="assets/icons/home/{{
              expense_percentage_difference > 0
                ? 'arrow_circle_down.svg'
                : 'arrow_circle_up.svg'
            }}"
          />
          <p class="text-lg font-semibold">
            {{ myMath.abs(expense_percentage_difference) | number : "1.0-2" }}%
            {{ expense_percentage_difference > 0 ? "lower" : "higher" }} from
            last
            {{ reportingType?.slice(0, -2) }}
          </p>
        </div>
      </div>
      <khatapana-divider></khatapana-divider>
      <div class="px-6">
        <div class="py-4 text-xl font-semibold">
          {{ "expense_summary.expenses" | translate }}
        </div>
        <div
          class="flex flex-col mb-4"
          *ngIf="
            expenseReport?.expenses && (expenseReport?.expenses)!.length > 0;
            else emptyContainer
          "
        >
          <div
            class="flex gap-6 items-center h-16 border-b-[1px] last:border-none"
            *ngFor="let expense of expenseReport?.expenses; let i = index"
          >
            <img
              class="rounded-full"
              [src]="sanitizeImageUrl(colorList[i], expense!.purpose__name[0])"
            />
            <div class="flex justify-between w-full">
              <div class="font-semibold">
                {{ expense!.purpose__name | titlecase }}
              </div>
              <div class="text-[#545454]">
                {{ expense!.total_amount | currency : "Rs. " }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </khatapana-card>
  </div>
  <div class="w-[40%] max-md:w-full">
    <khatapana-card
      class="w-full relative"
      [class]="'bg-white '"
      [border]="true"
    >
      <ngx-charts-pie-chart
        class="flex w-full justify-center py-6 max-xl:scale-[0.6] max-xl:py-1"
        [view]="[400, 400]"
        [scheme]="colorSchemePie"
        [results]="pieChartData"
        [doughnut]="true"
        [arcWidth]="0.2"
      >
      </ngx-charts-pie-chart>
      <div
        class="flex w-[10%] h-[10%] flex-col gap-1 items-center absolute top-[35%] left-[45%]"
      >
        <div class="text-3xl font-semibold">
          {{ expense_percentage | number : "1.0-2" }}%
        </div>
        <div>{{ "common.spent" | translate }}</div>
      </div>

      <div class="flex w-full flex-col">
        <khatapana-divider></khatapana-divider>
        <div class="p-6 flex gap-2 flex-col">
          <div class="text-3xl font-semibold">
            {{ expenseReport?.total_net_expenses | currency : "Rs. " }}
          </div>
          <div class="text-xl text-[#545454] font-semibold">
            of {{ expenseReport?.income_amount | currency : "Rs. " }} of your
            income
          </div>
        </div>
      </div>
    </khatapana-card>

    <div class="flex gap-2 flex-col mt-7 max-md:hidden">
      <span class="material-symbols-outlined">info</span>
      <div>
        {{ "expense_summary.calcumation_detail_text" | translate }}
      </div>
    </div>
  </div>
</div>

<ng-template #emptyContainer>
  <khatapana-empty-container></khatapana-empty-container>
</ng-template>
