<khatapana-modal>
  <khatapana-card>
    <div class="w-full flex flex-col">
      <h1
        class="p-3 justify-center items-center flex w-full text-2xl font-semibold"
      >
        {{ "referral.share_your_code" | translate }}
        <span
          (click)="closed.emit(true)"
          class="material-symbols-outlined cursor-pointer fixed right-6"
        >
          cancel
        </span>
      </h1>
      <khatapana-divider></khatapana-divider>
      <div class="flex gap-3 p-8 pb-2 justify-center">
        <a
          target="_blank"
          class="cursor-pointer hover:grayscale-0"
          href="https://www.facebook.com/sharer/sharer.php?u={{ referralLink }}"
          ><img height="40" width="40" src="assets/icons/social/messenger.svg"
        /></a>
        <!-- <a target="_blank" class="cursor-pointer hover:grayscale-0">
          <img height="40" width="40" src="assets/icons/social/facebook.svg" />
        </a> -->
        <a
          href="https://wa.me/?text={{ referralLink }}"
          target="_blank"
          class="cursor-pointer hover:grayscale-0"
        >
          <img height="40" width="40" src="assets/icons/social/whatsapp.svg" />
        </a>
        <!-- <a target="_blank" class="cursor-pointer hover:grayscale-0">
          <img height="40" width="40" src="assets/icons/social/instagram.svg" />
        </a> -->
        <a
          href="https://www.linkedin.com/sharing/share-offsite/?url={{
            referralLink
          }}"
          target="_blank"
          class="cursor-pointer hover:grayscale-0"
        >
          <img height="40" width="40" src="assets/icons/social/linkedin.svg" />
        </a>
        <a
          target="_blank"
          href="https://twitter.com/intent/tweet?text={{ referralLink }}"
          class="cursor-pointer hover:grayscale-0"
        >
          <img height="40" width="40" src="assets/icons/social/twitter.svg" />
        </a>
        <!-- <a target="_blank" class="cursor-pointer hover:grayscale-0">
          <img height="40" width="40" src="assets/icons/social/viber.svg" />
        </a> -->
        <a
          href="https://mail.google.com/mail/u/0/?view=cm&to&su=My+Referral+code!&body={{
            referralLink
          }}%0A&bcc&cc&fs=1&tf=1"
          target="_blank"
          class="cursor-pointer hover:grayscale-0"
        >
          <img
            height="40"
            width="40"
            src="assets/icons/social/google-plus.svg"
          />
        </a>
      </div>
      <div class="p-6">
        <div
          class="h-12 border-[1px] overflow-clip w-full rounded-md justify-between border-[#E2E2E2] flex items-center pl-6"
        >
          <h1 class="text-sm text-[#545454] font-semibold">
            {{ referralLink }}
          </h1>
          <khatapana-button
            color="primary"
            variant="small"
            (onClick)="copyLink()"
            >{{ "common.copy_link" | translate }}</khatapana-button
          >
        </div>
      </div>
    </div>
  </khatapana-card>
</khatapana-modal>
