<div>
  <div
    *ngIf="
      (dashboardData$ | async)?.have_transaction === true;
      else noTransactionTemplate
    "
    class="flex flex-col w-full p-6 gap-6"
  >
    <div class="flex w-full gap-6 flex-col">
      <khatapana-store-report></khatapana-store-report>
    </div>
    <div class="flex w-full gap-6 max-xl:flex-col-reverse">
      <div class="w-4/6 max-xl:w-full">
        <khatapana-card
          class="flex w-full flex-col"
          [class]="'bg-white'"
          [border]="true"
        >
          <header class="flex flex-col gap-3 w-full px-6 py-3">
            <h1 class="text-xl font-semibold">Recent Transactions</h1>
            <!-- Buttons Filter -->
            <div class="flex gap-3">
              <khatapana-button
                color="basic"
                variant="no-padding"
                border="circle"
                [link]="transactionType != 'sales'"
                (onClick)="transactionType = 'sales'"
                ><span class="px-3 py-1">Sales</span></khatapana-button
              >
              <khatapana-button
                color="basic"
                variant="no-padding"
                border="circle"
                (onClick)="transactionType = 'purchase'"
                [link]="transactionType != 'purchase'"
                ><span class="px-3 py-1">Purchase</span></khatapana-button
              >
              <khatapana-button
                color="basic"
                variant="no-padding"
                border="circle"
                (onClick)="transactionType = 'inout'"
                [link]="transactionType != 'inout'"
                ><span class="px-3 py-1">In/Out</span></khatapana-button
              >
            </div>
          </header>
          <khatapana-divider></khatapana-divider>
          <khatapana-transaction-table
            [businessTransaction]="true"
            [transactionType]="transactionType"
            [inOut]="false"
            [dashboardPage]="true"
            [giveBorder]="false"
          ></khatapana-transaction-table>
          <khatapana-divider></khatapana-divider>
          <khatapana-button
            variant="big"
            [border]="'circle'"
            (onClick)="viewMoreTransactions()"
            color="white"
            [fullWidth]="true"
            ><span class="flex w-full justify-center gap-4 text-[#545454]"
              >View more transactions
              <span class="material-symbols-outlined">
                chevron_right
              </span></span
            >
          </khatapana-button>
        </khatapana-card>
      </div>
      <div class="w-2/6 max-xl:w-full">
        <khatapana-business-dashboard-setting></khatapana-business-dashboard-setting>
      </div>
    </div>
  </div>

  <!-- If User don't have any transaction -->
  <ng-template #noTransactionTemplate>
    <div class="flex w-full h-10 p-6 gap-6 max-xl:flex-col">
      <khatapana-welcome-no-transaction
        class="flex w-[65%] max-xl:w-full"
        [dashboardData$]="dashboardData$"
      ></khatapana-welcome-no-transaction>
      <khatapana-business-dashboard-setting
        class="flex w-[35%] max-xl:w-full"
      ></khatapana-business-dashboard-setting>
    </div>
  </ng-template>
</div>
