<div class="h-20 flex w-full justify-between px-6 items-center bg-white">
  <khatapana-logo
    class="max-[950px]:scale-[1]"
    [ngClass]="{
      'w-auto max-[950px]:scale-[0.8] origin-left': chooseAccount,
      'w-[40%] max-[950px]:scale-[1]': !chooseAccount
    }"
  ></khatapana-logo>
  <div class="flex gap-3 items-center w-[60%] justify-end max-sm:w-[50%]">
    <khatapana-language-switcher
      class="max-[950px]:scale-[1]"
    ></khatapana-language-switcher>
    <khatapana-button
      [border]="'circle'"
      [variant]="'extra-small'"
      [color]="'basic'"
    >
      <div class="flex gap-2 max-xl:w-5 max-xl:h-5">
        <img src="assets/icons/auth/headphone.svg" />
        <p class="max-xl:hidden">
          {{ "common.get_help" | translate }}
        </p>
      </div>
    </khatapana-button>
  </div>
</div>
<khatapana-divider></khatapana-divider>
