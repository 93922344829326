import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AUTH_ROUTES } from '../modules/auth/auth-routing.module';
import { AuthService } from '../modules/auth/shared/services/auth.service';
import { LocalStorageService } from '../services/local.storage.service';

@Injectable({
  providedIn: 'root',
})
export class HomeGuard implements CanActivate {
  constructor(
    private localStorage: LocalStorageService,
    private authService: AuthService,
    private router: Router
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const token = this.localStorage.token;
    if (
      this.localStorage.user?.active_subscription &&
      this.authService.validateToken(token)
    ) {
      // Checking token and user subscriptions: If both exists then only show dashboard //
      this.authService.loadUserToLocalStorage();
      return true;
    } else {
      // If User not loggedIn
      if (
        !this.localStorage.token ||
        !this.authService.validateToken(this.localStorage.token)
      ) {
        this.localStorage.resetLocalStorage();
        this.router.navigate([AUTH_ROUTES.LOGIN]);
        return false;
      }

      if (state.url.includes('login')) {
        return true;
      }
      this.router.navigate([AUTH_ROUTES.LOGIN]);
      return false;
    }
  }
}
