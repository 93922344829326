<div
  class="fixed bottom-4 z-[10000] m-auto bg-transparent max-w-[400px] max-md:w-[95%] max-md:mx-auto"
  style="left: 50%; transform: translateX(-50%)"
>
  <div class="shadow-xl rounded-md overflow-clip bg-gray-800 text-white w-auto">
    <div class="flex gap-3 items-center h-full p-4">
      <span
        class="material-symbols-outlined"
        [ngClass]="{
          'text-[#ffa5a5]': type === 'error',
          'text-[#a6ffa6]': type === 'check_circle',
          'text-[#a7a7ff]': type === 'info'
        }"
      >
        {{ type }}
      </span>
      <p class="text-sm max-w-[300px] max-md:w-full max-md:mx-2">
        {{ message }}
      </p>
      <khatapana-button
        [link]="true"
        border="little"
        variant="extra-small"
        (onClick)="this?.removeAlert()"
        >Okay</khatapana-button
      >
    </div>
  </div>
</div>
