<div
  (click)="d.toggle()"
  [ngClass]="full ? 'flex items-center w-full h-full' : ''"
>
  <ng-content></ng-content>
</div>
<input
  class="outline-none w-0 h-0"
  placeholder="yyyy-mm-dd"
  name="dp"
  (dateSelect)="changed()"
  [(ngModel)]="model"
  ngbDatepicker
  #d="ngbDatepicker"
/>
