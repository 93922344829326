<div class="absolute flex w-[253px] z-[100000] backdrop" [ngClass]="destination?'max-sm:right-2':''">
  <khatapana-card class="bg-white w-full" [border]="true" [elevation]="true">
    <section>
      <!-- Cash -->
      <section class="p-4 flex gap-3 cursor-pointer" [ngClass]="'font-medium'" (click)="selectTransferMedium(true)">
        <span class="material-symbols-outlined"> payments </span>
        <div class="flex w-full justify-between items-center">
          Cash
          <span *ngIf="medium?.isCash" class="material-symbols-outlined text-[#7B61FF]">
            check
          </span>
        </div>
      </section>
      <khatapana-divider></khatapana-divider>
      <!-- Bank -->
      <section class="flex flex-col" [ngClass]="showBanks ? 'font-semibold' : 'font-normal'">
        <div class="p-4 flex gap-3 cursor-pointer">
          <span class="material-symbols-outlined"> account_balance </span>
          <div class="flex justify-between w-full" (click)="showBanks = !showBanks">
            Bank
            <!-- Expand Icon -->
            <div class="flex items-center justify-center border-[#E2E2E2] border-[1px] text-sm rounded-full">
              <span class="material-symbols-outlined">
                {{ showBanks ? "expand_less" : "expand_more" }}
              </span>
            </div>
          </div>
        </div>
        <!-- Showing Bank List -->
        <section *ngIf="showBanks">
          <section *ngFor="let bank of userBanks">
            <khatapana-divider></khatapana-divider>
            <div class="p-4 cursor-pointer" (click)="
                selectTransferMedium(false, true, false, bank, undefined)
              ">
              <div class="flex justify-between cursor-pointer">
                {{ bank.payment_gateway.name }}
                <span *ngIf="medium?.selectedBank?.id == bank.id" class="material-symbols-outlined text-[#7B61FF]">
                  check
                </span>
              </div>
            </div>
          </section>
          <div class="flex w-full px-4 pb-4" *ngIf="userBanks.length < 3">
            <khatapana-button (onClick)="bankAdd.emit()" border="circle" variant="extra-small" [fullWidth]="true">
              <span class="flex gap-2 items-center"><span class="material-symbols-outlined"> add </span> Add New
                Bank</span>
            </khatapana-button>
          </div>
        </section>
      </section>
      <khatapana-divider></khatapana-divider>
      <!-- Wallet -->
      <section class="flex flex-col" [ngClass]="showWallets ? 'font-semibold' : 'font-normal'">
        <div class="p-4 flex gap-3 cursor-pointer">
          <span class="material-symbols-outlined">
            account_balance_wallet
          </span>
          <div class="flex justify-between w-full" (click)="showWallets = !showWallets">
            Wallet
            <!-- Expand Icon -->
            <div class="flex items-center justify-center border-[#E2E2E2] border-[1px] text-sm rounded-full">
              <span class="material-symbols-outlined">
                {{ showWallets ? "expand_less" : "expand_more" }}
              </span>
            </div>
          </div>
        </div>
        <!-- Showing Wallet List -->
        <section *ngIf="showWallets">
          <section *ngFor="let wallet of userWallets">
            <khatapana-divider></khatapana-divider>
            <div class="p-4" (click)="
                selectTransferMedium(false, false, true, undefined, wallet)
              ">
              <div class="flex justify-between cursor-pointer">
                {{ wallet.payment_gateway.name }}
                <span *ngIf="medium?.selectedWallet?.id == wallet.id" class="material-symbols-outlined text-[#7B61FF]">
                  check
                </span>
              </div>
            </div>
          </section>
          <div class="flex w-full px-4 pb-4" *ngIf="userWallets.length < 3">
            <khatapana-button (click)="walletAdd.emit()" border="circle" variant="extra-small" [fullWidth]="true">
              <span class="flex gap-2 items-center"><span class="material-symbols-outlined"> add </span> Add New
                Wallet</span>
            </khatapana-button>
          </div>
        </section>
      </section>
    </section>
  </khatapana-card>
</div>