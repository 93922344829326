import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { HOME_ROUTE } from 'src/app/modules/home/home-routing.module';
import { NotificationService } from 'src/app/modules/home/shared/services/notification.service';
import { Notification } from '../../models/notification.model';

@Component({
  selector: 'khatapana-notification-tab',
  templateUrl: './notification-tab.component.html',
})
export class NotificationTabComponent implements OnInit {
  showNotificationTab = false;

  HOME_ROUTES = HOME_ROUTE;
  @ViewChild('notificationModal') notificationModal!: ElementRef;

  notificationList: Notification[] = [];
  notViewedCount = 0;

  constructor(
    private router: Router,
    private notificationService: NotificationService
  ) {}

  ngOnInit(): void {
    this.loadAllNotification();
  }

  loadAllNotification() {
    this.notificationService.getNotifications().subscribe({
      next: (res: { results: Notification[] }) => {
        this.notificationList = res.results.slice(0, 5);
        this.notViewedCount = res.results.filter(
          (x: any) => x.is_viewed != true
        ).length;
      },
    });
  }

  openNotificationTab() {
    this.showNotificationTab = !this.showNotificationTab;
  }

  @HostListener('document:click', ['$event', '$event.target'])
  public onClick(event: MouseEvent, targetElement: HTMLElement): void {
    if (!targetElement) {
      return;
    }
    const clickedInside =
      this.notificationModal?.nativeElement.contains(targetElement);
    if (!clickedInside) {
      this.showNotificationTab = false;
    }
  }

  GOTO(route: string) {
    this.showNotificationTab = false;
    this.notificationService.markAllAsViewed().subscribe({
      next: () => {
        this.router.navigate([route]);
      },
    });
  }
}
