import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'khatapana-modal',
  templateUrl: './modal.component.html',
})
export class ModalComponent implements OnInit {
  @Input() width = 540;
  @Input() backgroundDark = true;
  @Input() FullWidthOnMobile = false;
  constructor() {}

  ngOnInit(): void {}
}
