<div class="w-full p-6 max-lg:mb-[70px]">
  <khatapana-card [class]="'bg-white'" [border]="true">
    <section>
      <header class="p-5 px-8 flex gap-4 max-sm:px-4">
        <khatapana-navigation-button
          [link]="SETTING_ROUTES.PROFILE"
          [label]="'common.profile' | translate"
          variant="small"
        ></khatapana-navigation-button>
        <khatapana-navigation-button
          [link]="SETTING_ROUTES.ACCOUNT"
          [label]="'common.account' | translate"
          variant="small"
        ></khatapana-navigation-button>
        <khatapana-navigation-button
          [link]="SETTING_ROUTES.PASSWORD"
          [label]="'common.password' | translate"
          variant="small"
        ></khatapana-navigation-button>
      </header>
      <khatapana-divider></khatapana-divider>
      <section class="p-6">
        <router-outlet></router-outlet>
      </section>
    </section>
  </khatapana-card>
</div>
