import { Component, Input } from '@angular/core';

@Component({
  selector: 'khatapana-capsule',
  templateUrl: './capsule.component.html',
})
export class CapsuleComponent {
  @Input() label: string = 'Pro';
  @Input() color:
    | 'purple'
    | 'white'
    | 'gray'
    | 'yellow'
    | 'dark-purple'
    | 'outline-purple' = 'purple';
  @Input() capsuleClass = '';
}
