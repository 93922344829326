<div class="flex flex-col py-8 gap-8 w-4/6 mx-auto max-md:w-[85%]">
  <h4 class="text-2xl font-semibold max-md:hidden">
    {{ "login.welcome_back" | translate }}
  </h4>

  <form class="flex flex-col gap-4" [formGroup]="loginForm">
    <khatapana-input
      type="password"
      [placeholder]="'login.password_placeholder' | translate"
      formControlName="password"
      [disabled]="disableNext"
      [label]="'login.password_label' | translate"
    ></khatapana-input>
  </form>

  <div class="flex flex-col mb-10 max-md:mb-0">
    <khatapana-button
      [color]="'basic'"
      [border]="'circle'"
      [variant]="'medium'"
      type="submit"
      [routerLink]="AUTH_ROUTES.OTP"
      [disabled]="disableNext"
      [queryParams]="{ from: 'forgot-password' }"
      >{{ "login.forgot_password" | translate }}
    </khatapana-button>
  </div>

  <div class="flex justify-between">
    <khatapana-button
      [color]="'basic'"
      [border]="'circle'"
      [routerLink]="AUTH_ROUTES.LOGIN"
      [variant]="'big'"
      type="submit"
      ><i class="fa-solid fa-arrow-left text-base"></i
    ></khatapana-button>
    <khatapana-button
      [color]="'basic'"
      [border]="'circle'"
      [variant]="'big'"
      (onClick)="submit()"
      [disabled]="disableNext"
      [loading]="disableNext"
      type="submit"
      ><span class="text-center"
        >{{ "common.next" | translate }}
        <i class="fa-solid fa-arrow-right text-base pl-1"></i></span
    ></khatapana-button>
  </div>
</div>
