import { Injectable } from '@angular/core';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

const LANGUAGE_KEY = 'language';

const LANGUAGES_LABELS = {
  en: 'English',
  ne: 'Nepali (Coming Soon)',
  hi: 'Hindi (Coming Soon)',
};

export interface LanguageLabel {
  id: any;
  name: any;
}

@Injectable({
  providedIn: 'root',
})
export class I18nService {
  defaultLanguage!: string;

  supportedLanguages!: string[];

  private langChangeSubscription!: Subscription;

  constructor(private translateService: TranslateService) {}

  /**
   * Initializes i18n for the application.
   * Loads language from local storage if present, or sets default language.
   * @param defaultLanguage The default language to use.
   * @param supportedLanguages The list of supported languages.
   */
  init(defaultLanguage: string, supportedLanguages: string[]) {
    this.defaultLanguage = defaultLanguage;
    this.supportedLanguages = supportedLanguages;
    this.translateService.setDefaultLang(this.defaultLanguage);
    this.translateService.addLangs(this.supportedLanguages);

    this.language = '';

    // Warning: this subscription will always be alive for the app's lifetime
    this.langChangeSubscription = this.translateService.onLangChange.subscribe(
      (event: LangChangeEvent) => {
        localStorage.setItem(LANGUAGE_KEY, event.lang);
      }
    );
  }

  /**
   * Cleans up language change subscription.
   */
  destroy() {
    if (this.langChangeSubscription) {
      this.langChangeSubscription.unsubscribe();
    }
  }

  /**
   * Sets the current language.
   * Note: The current language is saved to the local storage.
   * If no parameter is specified, the language is loaded from local storage (if present).
   * @param language The IETF language code to set.
   */
  set language(language: string) {
    let newLanguage = language || localStorage.getItem(LANGUAGE_KEY) || '';
    let isSupportedLanguage = this.supportedLanguages.includes(newLanguage);

    // If no exact match is found
    if (newLanguage && !isSupportedLanguage) {
      newLanguage =
        this.supportedLanguages.find((supportedLanguage) =>
          supportedLanguage.startsWith(newLanguage)
        ) || '';
      isSupportedLanguage = Boolean(newLanguage);
    }

    // Fallback if language is not supported
    if (!newLanguage || !isSupportedLanguage) {
      newLanguage = this.defaultLanguage;
    }

    this.translateService.use(newLanguage);
  }

  /**
   * Gets the current language.
   * @return The current language code.
   */
  get language(): string {
    return this.translateService.currentLang;
  }

  /**
   * Gets supported languages list of objects with key and label
   */
  get languages(): LanguageLabel[] {
    return this.supportedLanguages.map((lang) => {
      const label = LANGUAGES_LABELS[lang as 'en' | 'ne' | 'hi'];
      return { id: lang, name: label };
    });
  }
}
