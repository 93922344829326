import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'khatapana-menu-items',
  templateUrl: './menu-items.component.html',
})
export class MenuItemsComponent {
  @Input() showView = false;
  @Output() clickedEdit = new EventEmitter<boolean>();
  @Output() clickedView = new EventEmitter<boolean>();
  @Output() clickedDelete = new EventEmitter<boolean>();
}
