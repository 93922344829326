import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';

@Injectable({
  providedIn: 'root',
})
export class TransactionService {
  constructor(private api: ApiService) {}
  createTransaction(formData: FormData) {
    return this.api.postFile('/api/transaction/', formData);
  }
  getAllTransactions(params: any, forBusiness = false) {
    return this.api.get(
      `/api/transaction/?business_account__isnull=${!forBusiness}`,
      params
    );
  }
  doCreditSettlement(body: any) {
    return this.api.postFile('/api/transaction/settlement/', body);
  }
  editTransaction(id: number, formData: FormData) {
    return this.api.patchFile(
      `/api/transaction/transaction-update/${id}/`,
      formData
    );
  }

  deleteTransaction(id: number) {
    return this.api.delete(`/api/transaction/transaction-trash/${id}/`);
  }

  editTransfer(id: number, formData: FormData) {
    return this.api.patchFile(
      `/api/transaction/transfer-update/${id}/`,
      formData
    );
  }

  createTransfer(formData: FormData) {
    return this.api.postFile('/api/transaction/transfer-create/', formData);
  }
}
