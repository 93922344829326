<div
  [class]="class"
  [ngClass]="{
    'border-gray-200 border-[1px]': border,
    rounded: radius == 'small',
    'rounded-lg': radius == 'medium',
    'rounded-2xl': radius == 'big',
    'shadow-md': elevation
  }"
>
  <ng-content></ng-content>
</div>
