import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'khatapana-plus-account-type',
  templateUrl: './plus-account-type.component.html',
})
export class PlusAccountTypeComponent {
  @Input() title: string = '';
  @Input() subTitle: string = '';
  @Input() icon: string = '';
  @Input() selected: boolean = false;

  @Output() select = new EventEmitter<boolean>();
}
