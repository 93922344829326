<div
  class="flex flex-col py-8 gap-8 w-4/6 max-xl:w-[80%] mx-auto max-md:w-[85%]"
>
  <h4 class="text-2xl max-md:text-xl font-semibold max-md:text-center">
    {{ "login.register_detail" | translate }}
  </h4>
  <form class="flex flex-col gap-6" [formGroup]="signupForm">
    <div class="flex gap-6">
      <khatapana-input
        [placeholder]="'login.firstname_placeholder' | translate"
        formControlName="firstName"
        [label]="'login.firstname_label' | translate"
        id="firstname"
        [errorControl]="signupForm.get('firstName')!"
      ></khatapana-input>
      <khatapana-input
        [placeholder]="'login.lastname_placeholder' | translate"
        formControlName="lastName"
        [label]="'login.lastname_label' | translate"
        id="lastname"
        [errorControl]="signupForm.get('lastName')!"
      ></khatapana-input>
    </div>
    <khatapana-input
      *ngIf="!emailVerified; else verifiedTemplate"
      [placeholder]="'login.email_placeholder' | translate"
      formControlName="email"
      [label]="'common.email' | translate"
      id="email"
      [errorControl]="signupForm.get('email')!"
    ></khatapana-input>
    <ng-template #verifiedTemplate>
      <div
        class="w-full flex bg-green-200 rounded-md justify-center items-center p-2 gap-6"
      >
        <span class="material-symbols-outlined"> check_circle </span
        >{{ "login.email_verified" | translate }}
      </div>
    </ng-template>
    <khatapana-input
      [type]="'password'"
      [placeholder]="'login.password_placeholder' | translate"
      formControlName="password"
      [label]="'login.password_label' | translate"
      id="password"
      [errorControl]="signupForm.get('password')!"
    ></khatapana-input>
    <p class="p-0 text-sm" [innerHTML]="terms"></p>
  </form>
  <khatapana-button
    [color]="'secondary'"
    [border]="'little'"
    (onClick)="email?.value ? sendOTPEmailVerification() : updateUserInfo()"
    [disabled]="submitButtonClicked"
    [loading]="submitButtonClicked"
    [fullWidth]="true"
    [variant]="'big'"
  >
    <span class="flex w-full justify-center items-center relative"
      >{{ "common.next" | translate }}
      <i class="fa fa-arrow-right absolute right-[2%]"></i>
    </span>
  </khatapana-button>
</div>

<!-- Email verification Modal -->
<khatapana-modal *ngIf="showOTPModal">
  <!-- Header -->
  <div class="py-3 px-4">
    <h4
      class="text-center text-xl font-semibold w-full flex justify-between items-center gap-x-8"
    >
      <p>Verify your email</p>
      <span
        (click)="showOTPModal = false"
        class="material-symbols-outlined cursor-pointer"
      >
        close
      </span>
    </h4>
  </div>
  <khatapana-divider></khatapana-divider>
  <!-- Body -->
  <form class="p-6 flex flex-col gap-5" [formGroup]="otpForm">
    <label class="text-center font-semibold text-[#141414]">{{
      "otp.enter_otp" | translate
    }}</label>
    <ng-otp-input
      [config]="otpConfig"
      (onInputChange)="otpChanged($event)"
      [formCtrl]="otp"
    ></ng-otp-input>
  </form>
  <div class="flex flex-col gap-6 px-6 pb-6">
    <khatapana-button
      [color]="'basic'"
      [border]="'circle'"
      [variant]="'medium'"
      [disabled]="startTimimg"
      (onClick)="resendOTP()"
      type="submit"
      ><span *ngIf="!startTimimg; else timer" class="text-center">{{
        "otp.resend_code" | translate
      }}</span>
      <ng-template #timer
        >{{ "common.resend" | translate }} 00:{{ timeCountdown < 10 ? "0" : ""
        }}{{ timeCountdown }}</ng-template
      >
    </khatapana-button>
    <div class="flex justify-between">
      <khatapana-button
        [color]="'basic'"
        [border]="'circle'"
        [variant]="'big'"
        [disabled]="disableNext"
        type="submit"
        (onClick)="verifyEmail()"
        ><span class="text-center"
          >{{ "common.next" | translate }}
          <i class="fa-solid fa-arrow-right text-base pl-1"></i></span
      ></khatapana-button>
    </div>
  </div>
</khatapana-modal>
