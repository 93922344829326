<div
  *ngIf="full; else notFullLoader"
  class="flex fixed z-[1000] top-0 left-0 w-full h-full items-center justify-center pointer-events-auto bg-[#0000004f]"
>
  <ng-template [ngTemplateOutlet]="notFullLoader"></ng-template>
</div>
<ng-template #notFullLoader>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    style="margin: auto; display: block; shape-rendering: auto"
    [style.width.px]="size"
    [style.height.px]="size"
    viewBox="0 0 100 100"
    preserveAspectRatio="xMidYMid"
  >
    <circle
      cx="50"
      cy="50"
      fill="none"
      stroke="#7b61ff"
      stroke-width="10"
      r="35"
      stroke-dasharray="164.93361431346415 56.97787143782138"
    >
      <animateTransform
        attributeName="transform"
        type="rotate"
        repeatCount="indefinite"
        dur="1s"
        values="0 50 50;360 50 50"
        keyTimes="0;1"
      ></animateTransform>
    </circle>
    <!-- [ldio] generated by https://loading.io/ -->
  </svg>
</ng-template>
