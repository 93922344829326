import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'khatapana-plan-detail-item',
  templateUrl: './plan-detail-item.component.html',
})
export class PlanDetailItemComponent implements OnInit {
  @Input() title?: string = '';
  @Input() subtitle?: string = '';
  @Input() color: 'primary' | 'black' = 'black';
  @Input() space: 'big' | 'little' = 'big';
  @Input() bottomLine = true;
  @Input() small = false;
  constructor() {}

  ngOnInit(): void {}
}
