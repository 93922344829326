import { Component } from '@angular/core';
import { NavigationTopService } from '../../shared/services/navigation-top.service';

@Component({
  selector: 'khatapana-sales',
  templateUrl: './sales.component.html',
})
export class SalesComponent {
  constructor(private navigationTopService: NavigationTopService) {
    this.navigationTopService.type$.next('sales');
    this.navigationTopService.mode$.next('transaction');
  }
}
