import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { NgOtpInputConfig } from 'ng-otp-input';
import { LoginRequest } from 'src/app/modules/auth/shared/models/login-request';
import { VerifyEmail } from 'src/app/modules/auth/shared/models/verify-otp';
import { AuthService } from 'src/app/modules/auth/shared/services/auth.service';
import { AlertService } from 'src/app/modules/shared/services/alert.service';
import { LocalStorageService } from 'src/app/services/local.storage.service';
import { DEFAULT_ERROR_MESSAGE } from 'src/constants';

@Component({
  selector: 'khatapana-profile-setting',
  templateUrl: './profile-setting.component.html',
})
export class ProfileSettingComponent {
  user$ = this.localStorage.currentUser$;
  basicForm: FormGroup;
  otpForm: FormGroup;

  submitButtonClicked = false;
  showOTPModal = false;
  otpConfig: NgOtpInputConfig = {
    length: 6,
    containerClass: 'flex gap-6 justify-center flex-wrap',
  };
  disableNext = true;
  startTimimg = false;
  timeCountdown = 14;

  oldEmail = '';

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private alertService: AlertService,
    private localStorage: LocalStorageService
  ) {
    this.basicForm = this.formBuilder.group({
      full_name: ['', Validators.required],
      email: ['', Validators.email],
    });
    this.otpForm = this.formBuilder.group({
      otp: ['', [Validators.required, Validators.minLength(6)]],
    });
    this.loadProfileData();
  }

  get otp() {
    return this.otpForm.get('otp') as FormControl;
  }

  loadProfileData() {
    this.authService.getUserProfile().subscribe({
      next: (res: any) => {
        this.oldEmail = res.user.email;
        this.basicForm.patchValue({
          full_name: res.first_name + ' ' + res.last_name,
          email: res.user.email,
        });
        this.localStorage.user = {
          active_subscription: res.active_subscription,
          email: res.user.email,
          first_name: res.first_name,
          last_name: res.last_name,
          mobile_number: res.user.mobile_number,
          profile_image: res.profile_image,
        };
      },
      error: (err: HttpErrorResponse) => {
        this.alertService.error(
          this.alertService.getFormattedErrorMessage(err.error) ??
            DEFAULT_ERROR_MESSAGE
        );
      },
    });
  }

  updateProfile() {
    var first_name = this.basicForm.value['full_name'].toString().split(' ')[0];
    var last_name = this.basicForm.value['full_name'].toString().split(' ')[1];
    this.authService
      .updateProfileWithUsefulField({
        first_name: first_name,
        last_name: last_name,
      })
      .subscribe({
        next: (res) => {
          this.alertService.success('Profile updated successfully');
          this.loadProfileData();
        },
        error: (err: HttpErrorResponse) => {
          this.alertService.error(
            this.alertService.getFormattedErrorMessage(err.error) ??
              DEFAULT_ERROR_MESSAGE
          );
        },
      });
  }

  // Email verification steps
  sendOTPEmailVerification() {
    if (this.basicForm.invalid) {
      this.basicForm.markAllAsTouched();
      return;
    }
    const loginRequest: LoginRequest = {
      username: this.basicForm.value['email'],
    };
    this.authService.sendOTPForUpdate(loginRequest).subscribe({
      next: (res) => {
        this.showOTPModal = true;
        this.alertService.success('OTP sent successfully');
      },
      error: (err: HttpErrorResponse) => {
        this.alertService.error(err.error.message ?? DEFAULT_ERROR_MESSAGE);
      },
    });
  }

  startTimer() {
    this.startTimimg = true;
    this.timeCountdown = 16;
    const interval = setInterval(() => {
      this.timeCountdown--;
      if (this.timeCountdown == 0) {
        this.startTimimg = false;
        this.timeCountdown = 16;
        clearInterval(interval);
      }
    }, 1000);
  }

  otpChanged(value: any) {
    this.disableNext = value.length == 6 ? false : true;
  }

  resendOTP() {
    const loginRequest: LoginRequest = {
      username: this.basicForm.value['email'],
    };
    this.authService.sendOTPForUpdate(loginRequest).subscribe({
      next: (res: any) => {
        this.alertService.success('OTP resent successfully');
        this.startTimer();
      },
      error: (err: HttpErrorResponse) => {
        this.alertService.error(
          this.alertService.getFormattedErrorMessage(err.error) ??
            DEFAULT_ERROR_MESSAGE
        );
      },
    });
  }

  verifyEmail() {
    if (this.otpForm.invalid) return;
    const verifyOTPRequest: VerifyEmail = {
      otp: this.otp?.value,
      email: this.basicForm.value['email'],
    };
    this.disableNext = true;
    this.authService.verifyEmail(verifyOTPRequest).subscribe({
      next: (res) => {
        this.showOTPModal = false;
        this.updateProfile();
      },
      error: (err: HttpErrorResponse) => {
        this.alertService.error(
          this.alertService.getFormattedErrorMessage(err.error)
        );
      },
    });
  }
}
