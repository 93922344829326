import { Component } from '@angular/core';
import { NotificationService } from '../../shared/services/notification.service';
import { PageTitleService } from '../../shared/services/page-title.service';
import { Notification } from '../../../shared/models/notification.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'khatapana-notification',
  templateUrl: './notification.component.html',
})
export class NotificationComponent {
  notificationList: Notification[] = [];

  constructor(
    private pageTitleService: PageTitleService,
    private translate: TranslateService,
    private notificationService: NotificationService
  ) {}

  ngOnInit(): void {
    this.translate
      .get('navbar.notifications')
      .subscribe((v) => this.pageTitleService.pageTitle$.next(v));
    this.pageTitleService.showBackButton$.next(true);
    this.loadAllNotification();
  }

  loadAllNotification() {
    this.notificationService.getNotifications().subscribe({
      next: (res) => {
        this.notificationList = res.results;
      },
    });
  }
}
