<khatapana-card [class]="'bg-white'" [border]="true">
  <!-- Header -->
  <header class="flex gap-10 text-xl items-center font-semibold p-2">
    <khatapana-button
      *ngIf="toEditTransactionDetail; else backToInOutTemplate"
      border="circle"
      (onClick)="close.emit()"
      color="basic"
      [link]="true"
      variant="no-padding"
    >
      <div
        class="text-lg font-semibold h-12 w-12 flex justify-center items-center"
      >
        <i class="fa fa-arrow-left"></i>
      </div>
    </khatapana-button>
    <ng-template #backToInOutTemplate>
      <khatapana-button
        border="circle"
        [routerLink]="HOME_ROUTES.TRANSACTION_ADD"
        color="basic"
        [link]="true"
        variant="no-padding"
      >
        <div
          class="text-lg font-semibold h-12 w-12 flex justify-center items-center"
        >
          <i class="fa fa-arrow-left"></i>
        </div>
      </khatapana-button>
    </ng-template>
    <h1 class="flex w-[70%] justify-center">
      {{ toEditTransactionDetail ? ("common.edit" | translate) : "" }}
      {{ "common.transfer" | translate }}
    </h1>
  </header>
  <khatapana-divider></khatapana-divider>
  <!-- Body -->
  <section
    class="px-6 py-42overflow-y-scroll scroll-smooth max-h-[450px] flex flex-col"
    style="height: calc(74vh - 120px)"
  >
    <div class="px-10 w-full max-sm:px-0">
      <span
        class="text-2xl font-semibold ml-8 top-[3.3rem] relative max-sm:text-lg max-sm:top-[2.8rem]"
        >{{ "common.rupee" | translate }}</span
      >
      <input
        [(ngModel)]="transactionAmount"
        placeholder="Enter amount"
        class="khatapana-transaction-input text-4xl max-sm:text-xl max-sm:font-semibold"
        appAllownumbersonly
      />
    </div>
    <!-- From To -->
    <div class="flex py-9 gap-3 items-center">
      <!-- From -->
      <div class="w-3/6" #sourceModal>
        <div
          class="p-3 flex cursor-pointer font-semibold gap-3 rounded-md border-[1px] border-[#E2E2E2] max-sm:flex-col"
          (click)="showSourceModal = true"
        >
          <div class="flex gap-3">
            <span class="material-symbols-outlined fill">
              arrow_circle_down
            </span>
            {{ "common.from" | translate }}
          </div>
          <span
            *ngIf="fromMedium"
            class="bg-red-400 text-white px-2 rounded-full py-1 text-xs text-center"
          >
            {{
              fromMedium.isCash
                ? "Cash"
                : fromMedium.isBank
                ? fromMedium.selectedBank.payment_gateway.name
                : fromMedium.selectedWallet.payment_gateway.name
            }}</span
          >
        </div>
        <khatapana-transfer-source-destination-dropdown
          (selected)="fromSourceMedium($event)"
          [medium]="fromMedium!"
          *ngIf="showSourceModal"
          (closed)="showSourceModal = false"
          (bankAdd)="newBankAdded()"
          (walletAdd)="newWalletAdded()"
        ></khatapana-transfer-source-destination-dropdown>
      </div>
      <!-- Icon -->
      <div
        class="w-6 h-6 bg-[#F6F6F6] flex items-center justify-center rounded-full"
      >
        <span class="material-symbols-outlined text-base font-semibold">
          swap_horiz
        </span>
      </div>
      <!-- To -->
      <div class="w-3/6" #destinationModal>
        <div
          class="p-3 cursor-pointer font-semibold flex gap-3 rounded-md border-[1px] border-[#E2E2E2] max-sm:flex-col"
          (click)="showDestinationModal = true"
        >
          <div class="flex gap-3">
            <span class="material-symbols-outlined fill">
              arrow_circle_down
            </span>
            {{ "common.to" | translate }}
          </div>
          <span
            *ngIf="toMedium"
            class="bg-green-400 text-white px-2 rounded-full py-1 text-xs text-center"
            >{{
              toMedium.isCash
                ? "Cash"
                : toMedium.isBank
                ? toMedium.selectedBank.payment_gateway.name
                : toMedium.selectedWallet.payment_gateway.name
            }}</span
          >
        </div>
        <khatapana-transfer-source-destination-dropdown
          (bankAdd)="newBankAdded()"
          (walletAdd)="newWalletAdded()"
          (selected)="toSourceMedium($event)"
          [medium]="toMedium!"
          *ngIf="showDestinationModal"
          [destination]="true"
          (closed)="showDestinationModal = false"
        ></khatapana-transfer-source-destination-dropdown>
      </div>
    </div>
    <!-- Description -->
    <div
      class="pl-5 flex items-center border-[#E2E2E2] border-[1px] rounded-md mb-3"
    >
      <div class="w-[80px] text-[#545454] font-semibold">
        {{ "transfer.detail" | translate }}
      </div>
      <div class="flex gap-5 items-center w-full">
        <span class="material-symbols-outlined">drive_file_rename_outline</span>
        <input
          [(ngModel)]="transferDetail"
          class="w-full p-5 outline-none"
          placeholder="Enter details"
        />
      </div>
    </div>

    <!-- Attachments -->
    <div
      [ngClass]="{
        'pointer-events-none': showDestinationModal || showSourceModal
      }"
      class="attachment jcc"
      *ngIf="
        transactionFile!.length === 0 && transactionFileName != 'Document';
        else uploadedTemplate
      "
    >
      <div class="flex flex-col items-center p-1 text-center">
        <span class="material-symbols-outlined text-[#7B61FF]">add</span>
        <input
          type="file"
          multiple
          #fileInput
          accept=".png,.jpg,.pdf,.jpeg"
          (change)="fileBrowseHandler($event)"
        />
        <div>{{ transactionFileName }}</div>
      </div>
    </div>
    <ng-template #uploadedTemplate>
      <div class="attachment">
        <div
          *ngIf="transactionFile!.length > 0"
          class="flex gap-3 justify-center items-center py-4"
        >
          <div class="each-file gap-4" *ngFor="let file of transactionFile">
            <p class="mb-0">{{ file.name }}</p>
            <i (click)="removeFile(file)" class="fa-solid fa-close"></i>
          </div>
          <div class="add-attachement gap-3" (click)="fileInput.click()">
            <i class="fa-solid fa-plus"></i>
            <p class="mb-0">{{ "transfer.add_attachment" | translate }}</p>
          </div>
          <input
            style="display: none"
            type="file"
            #fileInput
            (change)="fileBrowseHandler($event)"
          />
        </div>
      </div>
    </ng-template>
  </section>

  <!-- Footer -->
  <section
    class="px-6 pt-4 flex flex-col relative"
    style="box-shadow: 0px -7px 20px 0px #0000000f"
  >
    <khatapana-button
      [fullWidth]="true"
      border="little"
      color="red"
      (onClick)="saveTransfer()"
      variant="medium"
      ><span class="flex w-full justify-center">{{
        "transfer.save_transaction" | translate
      }}</span></khatapana-button
    >
    <section class="flex p-3 justify-between">
      <!-- Transfer From -->
      <section *ngIf="from">
        <div class="text-sm text-[#757575]">
          {{ from | titlecase }} {{ "common.balance" | translate }}
        </div>
        <div class="font-semibold">{{ fromBalance | currency }}</div>
      </section>
      <!-- Transfer To -->
      <section *ngIf="to">
        <div class="text-sm text-[#757575]">
          {{ to | titlecase }} {{ "common.balance" | translate }}
        </div>
        <div class="font-semibold">{{ toBalance | currency }}</div>
      </section>
    </section>
  </section>
</khatapana-card>

<khatapana-add-bank-modal
  class="pointer-events-auto"
  (close)="showBankAddEditModal = false"
  (added)="showBankAddEditModal = false"
  *ngIf="showBankAddEditModal"
></khatapana-add-bank-modal>

<khatapana-add-wallet-modal
  (close)="showWalletAddEditModal = false"
  (added)="showWalletAddEditModal = false"
  *ngIf="showWalletAddEditModal"
></khatapana-add-wallet-modal>
