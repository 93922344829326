<div
  class="relative z-[10000]"
  aria-labelledby="modal-title"
  role="dialog"
  aria-modal="true"
>
  <div
    [ngClass]="
      backgroundDark
        ? 'bg-gray-500'
        : FullWidthOnMobile
        ? 'max-sm:bg-gray-500'
        : ''
    "
    class="fixed inset-0 bg-opacity-75 transition-opacity"
  ></div>

  <div class="fixed inset-0 z-120 overflow-y-auto">
    <div
      class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0"
    >
      <div
        [style.width.px]="!FullWidthOnMobile ? width : 'undefined'"
        [ngClass]="{
          'w-[600px]': FullWidthOnMobile
        }"
        class="relative transform rounded-lg bg-white text-left shadow-xl transition-all"
      >
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</div>
