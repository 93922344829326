import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UUID } from 'angular2-uuid';
import {
  AccountService,
  BANK_MODE_ID,
  WALLET_MODE_ID,
} from 'src/app/modules/account/shared/services/account.service';
import { WalletResponse } from 'src/app/modules/home/shared/models/wallet';
import { AmountService } from 'src/app/modules/home/shared/services/amount.service';
import { keyValue } from 'src/app/modules/shared/models/keyvalue';
import { amount_accepting_zero_not_starting_with_zero } from 'src/app/modules/shared/regex/amount.regex';
import { AlertService } from 'src/app/modules/shared/services/alert.service';
import { LocalStorageService } from 'src/app/services/local.storage.service';

@Component({
  selector: 'khatapana-edit-wallet-modal',
  templateUrl: './edit-wallet-modal.component.html',
})
export class EditWalletModalComponent implements OnInit {
  @Input() businessTransaction = false;

  @Output() close = new EventEmitter<boolean>();
  @Output() updatedOpening = new EventEmitter<boolean>();

  userWallets: WalletResponse[] = [];
  wallets: keyValue[] = [];
  walletForm!: FormGroup;

  showWalletAddEditModal = false;
  isAdd = true;
  constructor(
    private fb: FormBuilder,
    private accountService: AccountService,
    private localStorage: LocalStorageService,
    private alertService: AlertService,
    private amountService: AmountService
  ) {}

  ngOnInit(): void {
    this.loadWallets();
    this.loadUserWallets();
  }

  initForm() {
    this.walletForm = this.fb.group({
      id: [null],
      wallet_id: ['', Validators.required],
      balance: [
        0,
        [
          Validators.required,
          Validators.pattern(amount_accepting_zero_not_starting_with_zero),
        ],
      ],
      payment_gateway: [''],
    });
    this.payment_gateway?.setValue(this.wallets[0]);
  }

  //#region  Getters for form
  get id() {
    return this.walletForm?.get('id');
  }

  get wallet_id() {
    return this.walletForm?.get('wallet_id');
  }
  get balance() {
    return this.walletForm?.get('balance');
  }
  get payment_gateway() {
    return this.walletForm?.get('payment_gateway');
  }
  //#endregion

  changeGateway(event: any) {
    this.payment_gateway?.setValue(this.wallets.find((x) => x.id == event));
  }

  loadUserWallets() {
    this.amountService.getWalletBalance(this.businessTransaction).subscribe({
      next: (res: { results: WalletResponse[]; total_wallet_balance: any }) => {
        this.userWallets = res.results;
      },
    });
  }

  loadWallets() {
    this.accountService
      .gettPaymentOptionsAccordingToTransactionMode(WALLET_MODE_ID)
      .subscribe({
        next: (res) => {
          res.results.forEach((wallet: keyValue) => {
            this.wallets.push({ id: wallet.id, name: wallet.name });
          });
        },
      });
  }

  addNewWallet() {
    this.initForm();
    this.isAdd = true;
    this.showWalletAddEditModal = true;
    this.payment_gateway?.enable();
  }

  editWallet(id: number) {
    this.initForm();
    this.isAdd = false;
    this.showWalletAddEditModal = true;
    const account = this.userWallets.find((x) => x.id === id);
    this.id?.setValue(id);
    this.wallet_id?.setValue(account?.wallet_id);
    this.balance?.setValue(account?.balance);
    this.payment_gateway?.setValue(account?.payment_gateway);
    this.payment_gateway?.disable();
  }

  editSomeWallet() {
    this.isAdd = false;
    this.showWalletAddEditModal = true;
  }

  saveWallet() {
    this.payment_gateway?.enable();
    const { value } = this.walletForm;
    value.payment_gateway = value.payment_gateway.id;
    if (this.businessTransaction) {
      value.business_account = this.localStorage.business_account;
    }
    if (value.id === null) {
      this.accountService.addWalletBalance(this.walletForm.value).subscribe({
        next: () => {
          this.showWalletAddEditModal = false;
          this.loadUserWallets();
          this.updatedOpening.emit(true);
        },
        error: (err: HttpErrorResponse) => {
          this.alertService.error(
            this.alertService.getFormattedErrorMessage(err.error)
          );
        },
      });
    } else {
      this.accountService.editWalletBalance(this.walletForm.value).subscribe({
        next: () => {
          this.showWalletAddEditModal = false;
          this.loadUserWallets();
          this.updatedOpening.emit(true);
        },
        error: (err: HttpErrorResponse) => {
          this.alertService.error(JSON.stringify(err.error));
        },
      });
    }
  }
}
