import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { HOME_ROUTE } from 'src/app/modules/home/home-routing.module';
import { AlertService } from 'src/app/modules/shared/services/alert.service';
import { LocalStorageService } from 'src/app/services/local.storage.service';
import { DEFAULT_ERROR_MESSAGE } from 'src/constants';
import { AUTH_ROUTES } from '../../auth-routing.module';
import { AuthService } from '../../shared/services/auth.service';
import { CookieService } from 'ngx-cookie-service';
import { jwtDecode } from 'jwt-decode';

@Component({
  selector: 'khatapana-login-password',
  templateUrl: './login-password.component.html',
})
export class LoginPasswordComponent implements OnInit {
  AUTH_ROUTES = AUTH_ROUTES;

  disableNext = false;
  loginForm!: FormGroup;
  username?: any;
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private alertService: AlertService,
    private localStorage: LocalStorageService,
    private authService: AuthService,
    private cookieService: CookieService
  ) {
    this.loginForm = this.fb.group({
      password: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    this.loadUser();
  }

  loadUser() {
    this.username = this.localStorage.username;
    if (!this.username) {
      this.router.navigateByUrl(AUTH_ROUTES.LOGIN);
    }
  }

  get password() {
    return this.loginForm.controls['password'].value;
  }

  submit() {
    if (this.disableNext) {
      return;
    }
    this.disableNext = true;
    const payload = {
      phone: this.username,
      password: this.loginForm.get('password')?.value,
    };
    this.authService.loginWithPassword(payload).subscribe({
      next: (res: any) => {
        this.localStorage.token = res.token;
        this.localStorage.refresh_token = res.refresh_token;
        this.localStorage.user = res;
        this.disableNext = false;
        this.router.navigate([HOME_ROUTE.DASHBOARD]);
      },
      error: (err: HttpErrorResponse) => {
        this.disableNext = false;
        this.alertService.error(
          this.alertService.getFormattedErrorMessage(err.error) ??
            DEFAULT_ERROR_MESSAGE
        );
        this.loadUser();
      },
    });
  }
}
