import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { amount_accepting_zero_not_starting_with_zero } from 'src/app/modules/shared/regex/amount.regex';
import { AmountService } from '../../../shared/services/amount.service';

@Component({
  selector: 'khatapana-adjust-balance-modal',
  templateUrl: './adjust-balance-modal.component.html',
})
export class AdjustBalanceModalComponent {
  onOkClicked!: () => any;
  onCancelClicked!: () => any;

  prevBalance = 0;
  cashForm!: FormGroup;
  constructor(private amountService: AmountService, private fb: FormBuilder) {}

  ngOnInit() {
    this.initForm();
    this.loadCashOpening();
  }

  initForm() {
    this.cashForm = this.fb.group({
      amount: [
        this.prevBalance,
        [
          Validators.required,
          Validators.pattern(amount_accepting_zero_not_starting_with_zero),
        ],
      ],
    });
  }

  get amount() {
    return this.cashForm?.get('amount');
  }

  loadCashOpening() {
    this.amountService.getCashBalance().subscribe({
      next: (cash) => {
        this.prevBalance = cash.results[0]?.balance || 0;
        this.initForm();
      },
    });
  }
}
