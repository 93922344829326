import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { keyValue } from 'src/app/modules/shared/models/keyvalue';
import { LocalStorageService } from 'src/app/services/local.storage.service';
import { DEFAULT_ERROR_MESSAGE } from 'src/constants';
import {
  BusinessAccount,
  BusinessAccountResponse,
} from '../../../account/shared/models/business.account';
import { AccountService } from '../../../account/shared/services/account.service';
import { AlertService } from '../../services/alert.service';
import { ConfirmModalService } from '../../services/confirm.modal.service';

@Component({
  selector: 'khatapana-create-business-store',
  templateUrl: './create-business-store.component.html',
})
export class CreateBusinessStoreComponent implements OnInit {
  storeForm!: FormGroup;
  categoryList: keyValue[] = [];

  @Input() fromDashboard = false;
  isLoading = false;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private modalServive: ConfirmModalService,
    private alertService: AlertService,
    private accountService: AccountService,
    private localStorage: LocalStorageService
  ) {
    this.storeForm = this.fb.group({
      category: [null],
      name: [
        this.localStorage.user.first_name +
          ' ' +
          this.localStorage.user.last_name,
        Validators.required,
      ],
      address: ['', Validators.required],
      business_type: ['', Validators.required],
    });
  }

  get category() {
    return this.storeForm.get('category');
  }

  get name() {
    return this.storeForm.get('name');
  }

  get address() {
    return this.storeForm.get('address');
  }

  get business_type() {
    return this.storeForm.get('business_type');
  }

  ngOnInit() {
    // this.loadBusinessCategories();
  }

  // loadBusinessCategories() {
  //   this.accountService.getBusinessCategory().subscribe({
  //     next: (data) => {
  //       this.categoryList = data;
  //       this.category?.setValue(this.categoryList[0]?.id);
  //     },
  //   });
  // }

  createBusinessStore() {
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;
    const payload: BusinessAccount = {
      name: this.name?.value,
      address: this.address?.value,
      business_type: this.business_type?.value,
    };
    this.accountService.createBusinessAccount(payload).subscribe({
      next: (result: BusinessAccountResponse) => {
        this.alertService.success('Business store created succesfully.');
        this.localStorage.setItem('business_account', result.id);
        this.closeModal();
        this.isLoading = false;
        this.router.navigateByUrl(
          this.fromDashboard ? '' : '/account/completed'
        );
      },
      error: (err: HttpErrorResponse) => {
        this.isLoading = false;
        console.log(err);
        this.alertService.error(
          this.alertService.getFormattedErrorMessage(err.error) ??
            DEFAULT_ERROR_MESSAGE
        );
      },
      complete: () => {
        this.isLoading = false;
      },
    });
  }

  skipModal() {
    this.closeModal();
    this.router.navigateByUrl(this.fromDashboard ? '' : '/account/completed');
  }

  closeModal() {
    this.modalServive.viewContainerRef.clear();
  }
}
