<div [class]="capsuleClass" class="font-semibold rounded-full px-2" [ngClass]="{
    'border-[#E2E2E2] border-[1px] bg-white ': color === 'white',
    'bg-[#E2E2E2]': color === 'gray',
    'bg-[#C2B6FF]': color === 'purple',
    'bg-[#7B61FF] text-white ': color === 'dark-purple',
    'text-[#7B61FF] bg-[#F2EFFF] border-[1px] border-[#C2B6FF] px-3':
      color === 'outline-purple',
    'bg-[rgba(255,192,67,0.16)]': color === 'yellow'
  }">
  {{ label }}
</div>