import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertService } from 'src/app/modules/shared/services/alert.service';
import { LocalStorageService } from 'src/app/services/local.storage.service';
import { DEFAULT_ERROR_MESSAGE } from 'src/constants';
import { Party, PartyResponse } from '../../../home/shared/models/party';
import { PartyService } from '../../../home/shared/services/party.service';
import { amount_accepting_zero_not_starting_with_zero } from '../../regex/amount.regex';

@Component({
  selector: 'khatapana-party-form',
  templateUrl: './party-form.component.html',
})
export class PartyFormComponent implements OnInit {
  @Input() businessTransaction = false;

  @Output() closed = new EventEmitter<boolean>();
  @Output() added = new EventEmitter<Party>();
  partyForm!: FormGroup;

  isReceivable = true;

  toEdit = false;
  editValue!: PartyResponse;
  today = new Date();

  constructor(
    private fb: FormBuilder,
    private partyService: PartyService,
    private localStorage: LocalStorageService,
    private alertService: AlertService
  ) {}

  ngOnInit(): void {
    this.partyForm = this.fb.group({
      id: [],
      name: ['', Validators.required],
      phone: [''],
      email: ['', Validators.email],
      starting_date: [
        `${this.today.getFullYear()}-${
          this.today.getMonth() + 1
        }-${this.today.getDate()}`,
      ],
      amount: [
        0,
        Validators.pattern(amount_accepting_zero_not_starting_with_zero),
      ],
    });
    if (this.toEdit) {
      this.partyForm.patchValue(this.editValue);
    }
  }

  get id() {
    return this.partyForm.get('id');
  }
  get name() {
    return this.partyForm.get('name');
  }
  get phone() {
    return this.partyForm.get('phone');
  }
  get email() {
    return this.partyForm.get('email');
  }
  get starting_date() {
    return this.partyForm.get('starting_date');
  }
  get amount() {
    return this.partyForm.get('amount');
  }

  selectedStartingDate(event: any) {
    this.starting_date?.setValue(`${event.year}-${event.month}-${event.day}`);
  }

  addParty() {
    if (this.partyForm.invalid) {
      this.partyForm.markAllAsTouched();
      return;
    }
    const countryCode = document
      .getElementsByClassName('selected-flag')[0]
      .getAttribute('title')
      ?.split('+')[1];
    const finalNumber = `+${countryCode}${this.phone?.value}`;

    // Personal Party
    let partyPayload: Party = {
      email: this.email?.value,
      name: this.name?.value,
      phone: this.phone?.value ? finalNumber : '',
    };

    // Business Party
    if (this.businessTransaction) {
      partyPayload.business_account = this.localStorage.business_account;
      partyPayload.start_date = this.starting_date?.value;
      partyPayload.amount = Number(this.amount?.value);
      partyPayload.amount_type = this.isReceivable ? 'receivable' : 'payable';
    }
    if (partyPayload.email === '') {
      delete partyPayload.email;
    }

    // Add or Edit
    if (this.id?.value) {
      this.partyService.editParty(partyPayload, this.id.value).subscribe({
        next: (result: Party) => {
          this.added.emit(result);
        },
        error: (err: HttpErrorResponse) => {
          this.alertService.error(
            this.alertService.getFormattedErrorMessage(err.error) ??
              DEFAULT_ERROR_MESSAGE
          );
        },
      });
    } else {
      this.partyService.addParty(partyPayload).subscribe({
        next: (result: Party) => {
          this.alertService.success('Party added successfully');
          this.added.emit(result);
          this.closed.emit(true);
        },
        error: (err: HttpErrorResponse) => {
          this.alertService.error(
            this.alertService.getFormattedErrorMessage(err.error) ??
              DEFAULT_ERROR_MESSAGE
          );
        },
      });
    }
  }
}
