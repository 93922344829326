import {
  createAction,
  createFeatureSelector,
  createSelector,
  props,
} from '@ngrx/store';
import { User } from 'src/app/modules/auth/shared/models/user.model';
import { UserState } from './user.state';

export const setUserDetails = createAction(
  '[User] Set User Details',
  props<User>()
);
export const resetUserDetails = createAction('[User] Reset User Details');

export const selectUserState = createFeatureSelector<UserState>('user');

export const getUser = createAction('[User] Get User');

export const selectUserDetails = createSelector(
  selectUserState,
  (state) => state.currentUser
);
// userDetails$ = this.store.select(selectUserDetails);

export const setUser = createAction('[User] Set User', props<{ user: User }>());
export const clearUser = createAction('[User] Clear User');
