<khatapana-button
  variant="no-padding"
  [color]="isStandard && label != 'Cash' ? 'basic' : 'secondary'"
  [outline]="!active"
  (onClick)="onClick.emit(true)"
  (click)="
    isStandard && label != 'Cash'
      ? openSubscriptionDialog()
      : onClick.emit(true)
  "
  border="circle"
  ><span class="material-symbols-outlined p-4 cursor-pointer">
    {{ icon }}
  </span>
</khatapana-button>

<p
  [ngClass]="{
    'text-[#141414]': active,
    'text-[#757575]': !active,
    'text-[#AFAFAF]': isStandard && label != 'Cash'
  }"
  class="text-center text-sm font-semibold"
>
  {{ label }}
</p>

<!-- Showing Pro Label -->
<div class="relative -mt-20 ml-6" *ngIf="isStandard && label != 'Cash'">
  <khatapana-capsule
    [capsuleClass]="'scale-75'"
    label="Pro"
  ></khatapana-capsule>
</div>
