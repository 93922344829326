import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgOtpInputConfig } from 'ng-otp-input/public_api';
import { HOME_ROUTE } from 'src/app/modules/home/home-routing.module';
import { AlertService } from 'src/app/modules/shared/services/alert.service';
import { DEFAULT_ERROR_MESSAGE } from 'src/constants';
import { LocalStorageService } from '../../../../services/local.storage.service';
import { AUTH_ROUTES } from '../../auth-routing.module';
import {
  LoginRequest,
  PasswordResetRequest,
} from '../../shared/models/login-request';
import { VerifyOTP, VerifyOTPResponse } from '../../shared/models/verify-otp';
import { AuthService } from '../../shared/services/auth.service';

@Component({
  selector: 'khatapana-otp',
  templateUrl: './otp.component.html',
  styleUrls: ['./otp.component.scss'],
})
export class OtpComponent implements OnInit {
  username = '';
  disableNext = true;
  startTimimg = false;
  timeCountdown = 120;
  isLoading = false;

  otpConfig: NgOtpInputConfig = {
    length: 6,
    containerClass: 'flex gap-6 justify-center flex-wrap',
  };

  otpForm!: FormGroup;
  AUTH_ROUTES = AUTH_ROUTES;
  fromPage: string | null = null;
  fromForgotPassword = false;

  newUser = false;

  constructor(
    private localStorage: LocalStorageService,
    private authService: AuthService,
    private fb: FormBuilder,
    private router: Router,
    private translate: TranslateService,
    private alertService: AlertService,
    private route: ActivatedRoute
  ) {
    this.otpForm = this.fb.group({
      otp: ['', [Validators.required, Validators.minLength(6)]],
    });
  }

  ngOnInit(): void {
    this.newUser = this.localStorage.newUser;
    this.fromPage = this.route.snapshot.queryParamMap.get('from') ?? null;
    this.fromForgotPassword =
      this.fromPage === 'forgot-password' ? true : false;
    this.loadUser();
    if (this.fromForgotPassword) {
      this.resendPasswordVerifyOTP();
    }
    this.startTimer();
  }

  get otp() {
    return this.otpForm.get('otp') as FormControl;
  }

  loadUser() {
    this.username = this.localStorage.username;
    if (!this.username) {
      this.router.navigateByUrl(AUTH_ROUTES.LOGIN);
    }
  }

  startTimer() {
    this.startTimimg = true;
    this.timeCountdown = 60;
    const interval = setInterval(() => {
      this.timeCountdown--;
      if (this.timeCountdown == 0) {
        this.startTimimg = false;
        this.timeCountdown = 60;
        clearInterval(interval);
      }
    }, 1000);
  }

  otpChanged(value: any) {
    this.disableNext = value.length == 6 ? false : true;
    if (value.length == 6) {
      this.verifyOTP();
    }
  }

  resendOTP() {
    if (this.isLoading || !this.username) {
      return;
    }
    if (this.fromForgotPassword) {
      this.resendPasswordVerifyOTP();
      return;
    }
    this.isLoading = true;
    const resendOTPRequest: LoginRequest = {
      username: this.username,
    };
    this.authService.sendOTP(resendOTPRequest).subscribe({
      next: (res: any) => {
        this.alertService.success('OTP resent successfully');
        this.startTimer();
        this.isLoading = false;
      },
      error: (err: HttpErrorResponse) => {
        this.alertService.error(
          this.alertService.getFormattedErrorMessage(err.error) ??
            DEFAULT_ERROR_MESSAGE
        );
        this.isLoading = false;
      },
    });
  }

  isPhoneNumber(value: string): boolean {
    return /^\+?\d+$/.test(value);
  }

  resendPasswordVerifyOTP() {
    if (this.isLoading || !this.username) {
      return;
    }
    this.isLoading = true;
    const resendOTPRequest: PasswordResetRequest = {};
    if (this.isPhoneNumber(this.username)) {
      resendOTPRequest.phone = this.username;
    } else {
      resendOTPRequest.email = this.username;
    }

    this.authService.resetPassword(resendOTPRequest).subscribe({
      next: (res: any) => {
        this.alertService.success(this.translate.instant('otp.otp_success'));
        this.startTimer();
        this.isLoading = false;
      },
      error: (err: HttpErrorResponse) => {
        this.alertService.error(
          this.alertService.getFormattedErrorMessage(err.error) ??
            DEFAULT_ERROR_MESSAGE
        );
        this.isLoading = false;
      },
    });
  }

  verifyOTP() {
    if (this.otpForm.invalid || this.isLoading) return;
    // For Forgot Password Page
    if (this.fromForgotPassword) {
      this.localStorage.otp = this.otp.value;
      this.router.navigateByUrl(AUTH_ROUTES.FORGOTPASSWORD);
    } else {
      this.isLoading = true;
      const verifyOTPRequest: VerifyOTP = {
        otp: this.otp?.value,
        username: this.username,
      };
      this.disableNext = true;
      this.authService.verifyOTP(verifyOTPRequest).subscribe({
        next: (res: VerifyOTPResponse) => {
          this.disableNext = false;
          this.localStorage.token = res.token;
          this.localStorage.refresh_token = res.refresh_token;
          this.localStorage.user = res.user;
          this.isLoading = false;
          if (this.authService.isUserRegister) {
            this.router.navigateByUrl(HOME_ROUTE.DASHBOARD);
          } else {
            this.router.navigateByUrl(AUTH_ROUTES.REGISTER);
          }
        },
        error: (err: HttpErrorResponse) => {
          this.disableNext = false;
          this.startTimer();
          this.isLoading = false;
          this.alertService.error(
            this.alertService.getFormattedErrorMessage(err.error) ??
              DEFAULT_ERROR_MESSAGE
          );
        },
      });
    }
  }
  pressBackButton() {
    this.router.navigateByUrl(
      this.fromForgotPassword ? AUTH_ROUTES.PASSWORD : AUTH_ROUTES.LOGIN
    );
  }
}
