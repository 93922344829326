<khatapana-modal>
  <ng-container *ngIf="showWalletAddEditModal; else showTemplate">
    <!-- Header -->
    <div class="py-3 px-4">
      <h4
        class="text-center text-xl font-semibold w-full flex justify-center items-center"
      >
        <span
          class="material-symbols-outlined fixed left-5 cursor-pointer"
          (click)="showWalletAddEditModal = false"
        >
          arrow_back
        </span>
        <p>
          {{
            isAdd ? "Add new wallet and balance" : "Edit current wallet balance"
          }}
        </p>
      </h4>
    </div>
    <!-- Body -->
    <div class="px-6 pt-4 flex flex-col gap-5" [formGroup]="walletForm">
      <khatapana-select
        [list]="wallets"
        [fullWidth]="true"
        [fontWeight]="400"
        border="little"
        [errorControl]="payment_gateway!"
        [required]="true"
        (onChange)="changeGateway($event)"
        formControlName="payment_gateway"
        [disabled]="payment_gateway?.disabled!"
        [height]="55"
        variant="dark"
        [label]="'account.choose_wallet' | translate"
        [placeHolder]="'account.choose_wallet_placeholder' | translate"
      >
      </khatapana-select>
      <khatapana-input
        [label]="'account.wallet_id_label' | translate"
        formControlName="wallet_id"
        [errorControl]="wallet_id!"
      ></khatapana-input>
      <khatapana-input
        [label]="'account.wallet_balance_label' | translate"
        formControlName="balance"
        [errorControl]="balance!"
        [preIcon]="'fa fa-rupee-sign'"
        type="number"
      ></khatapana-input>
    </div>

    <!-- Footer -->
    <div class="flex flex-col gap-4 p-6">
      <khatapana-button
        [fullWidth]="true"
        color="secondary"
        variant="big"
        border="little"
        (onClick)="saveWallet()"
        ><span class="flex justify-center w-full">{{
          "common.save" | translate
        }}</span></khatapana-button
      >
      <khatapana-button
        [fullWidth]="true"
        color="basic"
        variant="big"
        border="little"
        (onClick)="close.emit(true)"
        ><span class="flex justify-center w-full">{{
          "common.cancel" | translate
        }}</span></khatapana-button
      >
    </div>
  </ng-container>
  <ng-template #showTemplate>
    <!-- Header -->
    <div class="py-3 px-4">
      <h4
        class="text-center text-xl font-semibold w-full flex justify-center items-center"
      >
        <p>{{ "account.edit_wallet_balance" | translate }}</p>
      </h4>
    </div>
    <!-- Body -->
    <div class="px-6 pt-4 flex flex-col gap-5">
      <!-- List of wallets -->
      <ng-container *ngFor="let wallet of userWallets">
        <div
          class="p-2 px-5 border-[#E2E2E2] border-[1px] rounded-md flex items-center justify-between"
        >
          <!-- Icon and other detail-->
          <div class="flex gap-6 items-center">
            <!-- Icon -->
            <div
              class="bg-[#E2E2E2] rounded-full h-10 w-10 flex items-center justify-center"
            >
              <i class="fa fa-money-bill text-[#141414]"></i>
            </div>
            <!-- Name & Balance -->
            <div class="flex flex-col gap-1">
              <h1 class="text-xl font-semibold">
                {{ wallet.payment_gateway.name }}
              </h1>
              <p class="text-sm text-[#545454]">
                {{ wallet.balance | currency }}
              </p>
            </div>
          </div>
          <!-- Edit button-->
          <div class="">
            <khatapana-button
              variant="no-padding"
              border="circle"
              color="basic"
              (onClick)="editWallet(wallet.id)"
              [outline]="true"
              ><span
                class="flex justify-center gap-3 px-3 text-sm py-1 items-center"
                ><i class="material-symbols-outlined text-base">edit</i
                >{{ "common.edit" | translate }}</span
              ></khatapana-button
            >
          </div>
        </div>
      </ng-container>
      <khatapana-button
        *ngIf="userWallets.length < 3"
        variant="extra-small"
        color="basic"
        (onClick)="addNewWallet()"
        border="circle"
      >
        <span class="flex gap-3 items-center"
          ><i class="material-symbols-outlined">add</i
          >{{
            userWallets.length > 0
              ? ("account.add_more_wallet" | translate)
              : ("account.add_wallet" | translate)
          }}</span
        >
      </khatapana-button>
    </div>
    <!-- Footer -->
    <div class="flex flex-col gap-4 p-6">
      <khatapana-button
        [fullWidth]="true"
        color="basic"
        variant="big"
        border="little"
        (onClick)="close.emit(true)"
        ><span class="flex justify-center w-full">{{
          "common.cancel" | translate
        }}</span></khatapana-button
      >
    </div>
  </ng-template>
</khatapana-modal>
