import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { UserService } from 'src/app/modules/home/shared/services/user.service';
import { AlertService } from 'src/app/modules/shared/services/alert.service';
import { ToasterService } from 'src/app/modules/shared/services/toaster.service';
import { ValidationService } from 'src/app/modules/shared/services/validation.service';
import { DEFAULT_ERROR_MESSAGE } from 'src/constants';

@Component({
  selector: 'khatapana-password-setting',
  templateUrl: './password-setting.component.html',
})
export class PasswordSettingComponent {
  passForm!: FormGroup;
  constructor(
    private fb: FormBuilder,
    private toastService: ToasterService,
    private alertService: AlertService,
    private userService: UserService
  ) {
    this.passForm = this.fb.group({
      oldPassword: ['', [Validators.required]],
      newPassword: [
        '',
        [Validators.required, ValidationService.passwordValidator],
      ],
      confirmPassword: [
        '',
        [Validators.required, ValidationService.passwordValidator],
      ],
    });
  }

  get oldPassword() {
    return this.passForm.get('oldPassword') as FormControl;
  }
  get newPassword() {
    return this.passForm.get('newPassword') as FormControl;
  }
  get confirmPassword() {
    return this.passForm.get('confirmPassword') as FormControl;
  }

  changePassword() {
    if (this.passForm.invalid) {
      this.passForm.markAllAsTouched();
      return;
    }
    if (this.newPassword?.value !== this.confirmPassword?.value) {
      this.toastService.error('Password do not matches');
      return;
    }
    this.userService
      .updatePassword(this.oldPassword.value, this.newPassword.value)
      .subscribe({
        next: () => {
          this.alertService.success('Password changed successfully.');
        },
        error: (err: HttpErrorResponse) => {
          this.alertService.error(
            this.alertService.getFormattedErrorMessage(err.error) ??
              DEFAULT_ERROR_MESSAGE
          );
        },
      });
  }
}
