<khatapana-modal>
  <header class="px-6 pt-6" *ngIf="modal?.showHeader">
    <h1
      class="text-[#141414] text-xl items-center flex gap-3 font-semibold"
      [ngClass]="{
        'text-[#E11900]': modal?.type === 'error',
        'text-[green]': modal?.type === 'check_circle',
        'text-[blue]': modal?.type === 'info'
      }"
    >
      {{ modal?.title }}
    </h1>
  </header>
  <section class="p-6 font-medium text-xl">
    {{ modal?.content }}
  </section>
  <footer class="p-4 flex gap-3 justify-end">
    <khatapana-button
      *ngIf="modal?.showCancel"
      (onClick)="modal?.onCancelClicked()"
      variant="no-padding"
      border="little"
      [link]="true"
      color="basic"
      ><span class="px-3 py-2">{{ modal?.cancelText }}</span></khatapana-button
    >
    <khatapana-button
      (onClick)="modal?.onOkClicked()"
      variant="no-padding"
      border="little"
      color="basic"
      ><span
        class="min-w-[80px] py-2"
        [ngClass]="{
          'text-[#E11900]': modal?.type === 'error',
          'text-[green]': modal?.type === 'check_circle',
          'text-[blue]': modal?.type === 'info'
        }"
        >{{ modal?.okText }}</span
      ></khatapana-button
    >
  </footer>
</khatapana-modal>
