<div class="p-6 w-[650px] m-auto max-sm:w-[98%] max-lg:mb-[70px]">
  <khatapana-card [class]="'bg-white'" [border]="true">
    <header class="flex justify-between w-full px-6 py-5 items-center">
      <h1 class="text-xl font-semibold">{{ "feedback.title" | translate }}</h1>
    </header>
    <khatapana-divider></khatapana-divider>
    <section class="p-6 flex flex-col gap-6">
      <div class="bg-[#f8f8f8] border-[1px] border-[#E2E2E2] rounded-md p-3">
        {{ "feedback.subtitle" | translate }}
      </div>
      <div class="flex flex-col gap-1">
        <p>{{ "feedback.comment" | translate }}</p>
        <textarea
          [(ngModel)]="comment"
          rows="4"
          class="border-[1px] p-3 border-[#E2E2E2] rounded-md focus:border-[#7b61ff] outline-none"
        ></textarea>
      </div>
      <div
        class="attachment jcc"
        *ngIf="
          transactionFile!.length === 0 && transactionFileName != 'Document';
          else uploadedTemplate
        "
      >
        <div class="flex flex-col items-center p-1">
          <span class="material-symbols-outlined text-[#7B61FF]">add</span>
          <input
            type="file"
            multiple
            #fileInput
            (change)="fileBrowseHandler($event)"
          />
          <div>{{ transactionFileName }}</div>
        </div>
      </div>
      <ng-template #uploadedTemplate>
        <div class="attachment">
          <div
            *ngIf="transactionFile!.length > 0"
            class="flex gap-3 justify-center items-center"
          >
            <div class="each-file gap-4" *ngFor="let file of transactionFile">
              <p class="mb-0">{{ file.name }}</p>
              <i (click)="removeFile(file)" class="fa-solid fa-close"></i>
            </div>
            <div class="add-attachement gap-3" (click)="fileInput.click()">
              <i class="fa-solid fa-plus"></i>
              <p class="mb-0">{{ "feedback.add_attachment" | translate }}</p>
            </div>
            <input
              style="display: none"
              type="file"
              #fileInput
              (change)="fileBrowseHandler($event)"
            />
          </div>
        </div>
      </ng-template>
    </section>
    <khatapana-divider></khatapana-divider>
    <footer class="flex gap-4 p-6 py-3 justify-end">
      <khatapana-button
        variant="extra-small"
        border="little"
        [outline]="true"
        color="basic"
        [routerLink]="HOME_ROUTES.DASHBOARD"
        >{{ "common.close" | translate }}</khatapana-button
      >
      <khatapana-button
        variant="extra-small"
        border="little"
        [disabled]="comment ? false : true"
        (onClick)="sendFeedback()"
        >{{ "common.send" | translate }}</khatapana-button
      >
    </footer>
  </khatapana-card>
</div>
