import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(private api: ApiService) {}

  getNotifications() {
    return this.api.get('/notifications/');
  }

  markAllAsViewed() {
    return this.api.post('/notifications/mark_all_as_viewed/');
  }

  markAsViewed(id: any) {
    return this.api.post(`/notifications/${id}/`, { is_viewed: true });
  }
}
