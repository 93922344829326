<khatapana-card [class]="'bg-white'" [border]="true">
  <!-- Header -->
  <section
    *ngIf="!toEditTransactionDetail; else editTemplate"
    class="flex w-full"
  >
    <div
      (click)="changeTransactionType('out')"
      [ngClass]="{
        'out-selected': selectedTransactionType === 'out',
        'border-b-[1px]': selectedTransactionType === 'in',
        'transaction-type border-r-[1px]': true
      }"
    >
      {{ "common.out" | translate }}
    </div>
    <div
      (click)="changeTransactionType('in')"
      [ngClass]="{
        'in-selected': selectedTransactionType === 'in',
        'border-b-[1px]': selectedTransactionType === 'out',
        'transaction-type': true
      }"
    >
      {{ "common.in" | translate }}
    </div>
  </section>
  <ng-template #editTemplate>
    <header class="flex gap-10 text-xl items-center font-semibold p-2">
      <khatapana-button
        border="circle"
        (onClick)="close.emit(true)"
        color="basic"
        [link]="true"
        variant="no-padding"
      >
        <div
          class="text-lg font-semibold h-12 w-12 flex justify-center items-center"
        >
          <i class="fa fa-arrow-left"></i>
        </div>
      </khatapana-button>
      <h1 class="flex w-[70%] justify-center">
        {{ "transaction.edit_transaction" | translate }}
      </h1>
    </header>
    <khatapana-divider></khatapana-divider>
  </ng-template>
  <!-- Body -->
  <section
    class="px-4 py-8 overflow-y-scroll scroll-smooth max-h-[450px]"
    style="height: calc(78vh - 120px)"
  >
    <!--  Amount Input Box -->
    <div class="px-10 w-full max-sm:px-0">
      <span
        class="text-2xl font-semibold ml-8 top-[3.3rem] relative max-sm:text-lg max-sm:top-[2.8rem]"
        >{{ "common.rupee" | translate }}</span
      >
      <input
        placeholder="Enter amount"
        class="khatapana-transaction-input text-4xl max-sm:text-xl max-sm:font-semibold"
        [(ngModel)]="transactionAmount"
        appAllownumbersonly
        [ngClass]="{
          in: selectedTransactionType === 'in',
          out: selectedTransactionType === 'out'
        }"
      />
    </div>
    <!-- Transaction Mode Selection -->
    <div class="flex flex-col w-full px-10 max-sm:px-0">
      <!-- Transfer button -->
      <div class="flex w-full justify-end py-6">
        <div>
          <khatapana-button
            variant="extra-small"
            color="basic"
            [disabled]="isStandard"
            border="circle"
            (click)="isStandard ? openSubscriptionDialog() : gotoTransfer()"
            ><span class="flex items-center gap-3"
              ><span class="material-symbols-outlined"> change_circle </span
              >Transfer</span
            ></khatapana-button
          >
          <khatapana-capsule
            *ngIf="isStandard"
            class="relative"
            [capsuleClass]="'absolute scale-75 ml-24 -mt-12'"
            label="Pro"
          ></khatapana-capsule>
        </div>
      </div>

      <div class="flex w-full justify-between">
        <khatapana-transaction-mode-button
          (onClick)="changeTransactionMode(mode.id)"
          *ngFor="let mode of transactionModes"
          [icon]="mode.icon"
          [label]="mode.label"
          [active]="mode.id === selectedTransactionMode"
        ></khatapana-transaction-mode-button>
      </div>
    </div>
    <!-- Main Form -->
    <div class="main-form">
      <ng-container *ngIf="!isDueSettlement">
        <!-- Purpose Field -->
        <div class="pl-5 flex items-center">
          <div class="w-[80px] text-[#545454] font-semibold">For*</div>
          <div class="flex gap-5 items-center w-full max-sm:gap-1">
            <span class="material-symbols-outlined">playlist_add</span>
            <p
              (click)="showPurposeModal = true"
              class="w-full p-5 outline-none max-sm:p-3"
            >
              <span *ngIf="selectedPurpose; else noPurpose">{{
                selectedPurpose?.name | titlecase
              }}</span>
              <ng-template #noPurpose>
                <span class="text-gray-500">
                  {{ "transaction.choose_purpose" | translate }}
                </span>
              </ng-template>
            </p>
          </div>
          <khatapana-choose-purpose
            [transactionType]="selectedTransactionType"
            [isCredit]="isCredit"
            [selectedPurpose]="selectedPurpose?.id"
            (close)="showPurposeModal = false"
            (purposeSelected)="savePurpose($event)"
            *ngIf="showPurposeModal"
            [businessTransaction]="businessTransaction"
          ></khatapana-choose-purpose>
          <!-- Purpose Modal -->
        </div>
        <khatapana-divider></khatapana-divider>
        <!-- Party Field -->
        <div class="pl-5 flex items-center" #partyModal>
          <div class="w-[80px] text-[#545454] font-semibold">
            {{ "transaction.select_party" | translate }}
          </div>
          <div class="flex gap-5 items-center w-full max-sm:gap-1">
            <span class="material-symbols-outlined">person_add</span>
            <p
              (click)="showPartyModal = true"
              class="w-full p-5 outline-none flex justify-between max-sm:p-3"
            >
              <span
                class="flex w-full justify-between items-center"
                *ngIf="selectedParty; else noParty"
                >{{ selectedParty.name | titlecase }}
                <khatapana-capsule
                  *ngIf="selectedParty?.transaction_amount?.due_amount! > 0"
                  color="yellow"
                  label="Due"
                ></khatapana-capsule>
              </span>

              <ng-template #noParty>
                <span class="text-gray-500">
                  {{ "transaction.select_party_label" | translate }}
                </span>
              </ng-template>
            </p>
          </div>
          <khatapana-choose-party
            [businessTransaction]="businessTransaction"
            (closed)="showPartyModal = false"
            [selectedParty]="selectedParty!"
            (partySelected)="saveParty($event)"
            [isCredit]="isCredit"
            class="relative -left-96 top-3"
            *ngIf="showPartyModal"
          ></khatapana-choose-party>
        </div>
        <khatapana-divider></khatapana-divider>
      </ng-container>
      <!-- Detail field -->
      <div class="pl-5 flex items-center">
        <div class="w-[80px] text-[#545454] font-semibold">
          {{ "transaction.detail" | translate }}
        </div>
        <div class="flex gap-5 items-center w-full max-sm:gap-1">
          <span class="material-symbols-outlined"
            >drive_file_rename_outline</span
          >
          <input
            [(ngModel)]="transactionDetail"
            class="w-full p-5 outline-none max-sm:p-3"
            placeholder="Enter details"
          />
        </div>
      </div>
    </div>
    <!-- Attachments -->
    <div
      [ngClass]="{
        'pointer-events-none':
          showPartyModal ||
          showPurposeModal ||
          showBanks ||
          showWallets ||
          showBankAddEditModal ||
          showWalletAddEditModal ||
          showCreditSettlementModal ||
          showDueDateModal
      }"
      class="attachment jcc"
      *ngIf="
        transactionFile!.length === 0 && transactionFileName != 'Document';
        else uploadedTemplate
      "
    >
      <div class="flex flex-col items-center p-1 text-center">
        <span class="material-symbols-outlined text-[#7B61FF]">add</span>
        <input
          type="file"
          multiple
          #fileInput
          accept=".png,.jpg,.pdf,.jpeg"
          (change)="fileBrowseHandler($event)"
        />
        <div>{{ transactionFileName }}</div>
      </div>
    </div>
    <ng-template #uploadedTemplate>
      <div class="attachment">
        <div
          *ngIf="transactionFile!.length > 0"
          class="flex gap-3 justify-center items-center py-4"
        >
          <div class="each-file gap-4" *ngFor="let file of transactionFile">
            <p class="mb-0">{{ file.name }}</p>
            <i (click)="removeFile(file)" class="fa-solid fa-close"></i>
          </div>
          <div class="add-attachement gap-3" (click)="fileInput.click()">
            <i class="fa-solid fa-plus"></i>
            <p class="mb-0">{{ "transaction.add_attachment" | translate }}</p>
          </div>
          <input
            style="display: none"
            type="file"
            #fileInput
            (change)="fileBrowseHandler($event)"
          />
        </div>
      </div>
    </ng-template>
  </section>
  <!-- Footer -->
  <section
    class="px-6 py-4 flex flex-col relative gap-3"
    style="box-shadow: 0px -7px 20px 0px #0000000f"
  >
    <khatapana-button
      [fullWidth]="true"
      border="little"
      (onClick)="saveTransaction()"
      [color]="selectedTransactionType === 'out' ? 'red' : 'green'"
      variant="medium"
      ><span class="flex w-full justify-center">{{
        "transaction.save_transaction" | translate
      }}</span></khatapana-button
    >
    <div
      *ngIf="!isCredit"
      class="flex justify-between text-[#757575] font-semibold"
    >
      <h1>{{ "common.total_balance" | translate }}</h1>
      <span>{{ totalBalance | currency }}</span>
    </div>
  </section>
</khatapana-card>

<!-- Bank Modals when user have more than 1 bank, If not the 1 bank will be selected by default-->
<khatapana-modal class="shadow-md" *ngIf="showBanks" [backgroundDark]="false">
  <!-- Header -->
  <header
    class="flex justify-start gap-10 text-2xl items-center font-semibold p-6"
  >
    <khatapana-button
      (onClick)="showBanks = false"
      border="circle"
      color="basic"
      [link]="true"
      variant="no-padding"
    >
      <div
        class="text-lg font-semibold h-12 w-12 flex justify-center items-center"
      >
        <i class="fa fa-arrow-left"></i>
      </div>
    </khatapana-button>
    <h1>{{ "transaction.choose_bank" | translate }}</h1>
  </header>
  <!-- Body  -->
  <section
    class="flex flex-col p-6 pb-0 mb-5 gap-1 max-h-[300px] overflow-y-auto"
  >
    <khatapana-button
      color="basic"
      border="circle"
      class="mb-4"
      *ngIf="userBanks.length < 3"
      (onClick)="showBankAddEditModal = true"
    >
      <span class="flex gap-3 items-center pr-4"
        ><i class="material-symbols-outlined">add</i
        >{{
          userBanks.length > 0
            ? ("account.add_more_bank" | translate)
            : ("account.add_bank" | translate)
        }}</span
      ></khatapana-button
    >
    <ng-container *ngFor="let bank of userBanks">
      <khatapana-button
        (onClick)="bankChanged(bank)"
        [fullWidth]="true"
        [color]="bank?.id !== selectedBankId ? 'simple' : 'secondary'"
        [link]="bank.id !== selectedBankId"
      >
        <div class="flex gap-5 px-3 items-center">
          <!-- Icon -->
          <div
            [ngClass]="
              bank.id !== selectedBankId
                ? 'bg-[#7B61FF] text-white'
                : 'bg-white text-[#141414]'
            "
            class="flex justify-center items-center h-10 w-10 rounded-full"
          >
            <span>{{ bank.payment_gateway.name[0] | titlecase }}</span>
          </div>
          <div class="flex flex-col items-start">
            <span>{{ bank.payment_gateway.name | titlecase }}</span
            ><span class="text-xs font-normal"
              >{{ "common.balance" | translate }}:
              {{ bank.balance | currency }}</span
            >
          </div>
        </div>
      </khatapana-button>
    </ng-container>
  </section>
</khatapana-modal>

<!-- Wallet Modals when user have more than 1 wallet, If not the 1 wallet will be selected by default-->
<khatapana-modal class="shadow-md" *ngIf="showWallets" [backgroundDark]="false">
  <!-- Header -->
  <header
    class="flex justify-start gap-10 text-2xl items-center font-semibold p-6 pb-0"
  >
    <khatapana-button
      (onClick)="showWallets = false"
      border="circle"
      color="basic"
      [link]="true"
      variant="no-padding"
    >
      <div
        class="text-lg font-semibold h-12 w-12 flex justify-center items-center"
      >
        <i class="fa fa-arrow-left"></i>
      </div>
    </khatapana-button>
    <h1>{{ "transaction.choose_wallet" | translate }}</h1>
  </header>
  <!-- Body  -->
  <section class="flex flex-col p-6 gap-1 max-h-[300px] overflow-y-auto">
    <khatapana-button
      *ngIf="userWallets.length < 3"
      color="basic"
      border="circle"
      class="mb-4"
      (onClick)="showWalletAddEditModal = true"
    >
      <span class="flex gap-3 items-center pr-4"
        ><i class="material-symbols-outlined">add</i
        >{{
          userWallets.length > 0
            ? ("account.add_more_wallet" | translate)
            : ("account.add_wallet" | translate)
        }}</span
      ></khatapana-button
    >
    <ng-container *ngFor="let wallet of userWallets">
      <khatapana-button
        [fullWidth]="true"
        (onClick)="walletChanged(wallet)"
        [color]="wallet?.id !== selectedWalletId ? 'simple' : 'secondary'"
        [link]="wallet.id !== selectedWalletId"
      >
        <div class="flex gap-5 px-3 items-center">
          <!-- Icon -->
          <div
            [ngClass]="
              wallet.id !== selectedWalletId
                ? 'bg-[#7B61FF] text-white'
                : 'bg-white text-[#141414]'
            "
            class="flex justify-center items-center h-10 w-10 rounded-full"
          >
            <span>{{ wallet.payment_gateway.name[0] | titlecase }}</span>
          </div>
          <div class="flex flex-col items-start">
            <span>{{ wallet.payment_gateway.name | titlecase }}</span
            ><span class="text-xs font-normal"
              >{{ "common.balance" }}: {{ wallet.balance | currency }}</span
            >
          </div>
        </div>
      </khatapana-button>
    </ng-container>
  </section>
</khatapana-modal>

<!-- Credit Modal for Adding due date -->
<khatapana-modal *ngIf="showDueDateModal">
  <header
    class="flex justify-center gap-10 text-2xl items-center font-semibold p-6 overscroll-none"
  >
    <h1>{{ "transaction.credit_detail" | translate }}</h1>
  </header>
  <khatapana-divider></khatapana-divider>
  <section class="flex flex-col gap-12 p-8">
    <div class="w-full flex flex-col">
      <div class="font-semibold text-sm pb-1">
        {{ "transaction.credit_due_Date" | translate }}
      </div>
      <div
        class="w-full h-full border-[1px] border-[#545454] rounded-[4px] flex items-center justify-between p-3"
      >
        <khatapana-date-picker
          [full]="false"
          class="h-full flex cursor-pointer"
          (onChange)="changeCreditDueDate($event)"
        >
          {{ creditDueDate ?? "Select due date" }}
        </khatapana-date-picker>

        <khatapana-date-picker
          class="h-full flex cursor-pointer items-center"
          (onChange)="changeCreditDueDate($event)"
        >
          <div class="flex cursor-pointer items-center">
            <span class="material-symbols-outlined"> calendar_month </span>
          </div>
        </khatapana-date-picker>
      </div>
    </div>
    <div class="flex justify-between">
      <p class="text-lg font-medium">
        {{ "transaction.credit_reminder" | translate }}
      </p>
      <label class="switch">
        <input type="checkbox" [(ngModel)]="remindForDue" />
        <span class="slider round"></span>
      </label>
    </div>
    <div class="flex justify-end gap-5">
      <khatapana-button
        (onClick)="showDueDateModal = false"
        [link]="true"
        color="simple"
        border="little"
      >
        <p class="px-4">{{ "common.cancel" | translate }}</p>
      </khatapana-button>
      <khatapana-button
        (onClick)="AddCreditDueDate()"
        color="secondary"
        border="little"
      >
        <p class="px-4">{{ "common.save" | translate }}</p>
      </khatapana-button>
    </div>
  </section>
</khatapana-modal>

<!-- Credit Settlement Modal -->
<khatapana-modal *ngIf="showCreditSettlementModal">
  <header
    class="flex justify-center gap-10 text-xl items-center font-semibold p-6 py-3 overscroll-none"
  >
    <h1>{{ "transaction.due_settlement" | translate }}</h1>
  </header>
  <khatapana-divider></khatapana-divider>
  <section class="flex flex-col gap-8 p-8">
    <div class="w-full flex flex-col">
      {{ "transaction.due_settlement_question" | translate }}
    </div>
    <div class="flex w-full gap-6 justify-between">
      <khatapana-button
        (onClick)="addNewTransaction()"
        color="basic"
        border="little"
      >
        <p class="px-4">{{ "transaction.due_settlement_no" | translate }}</p>
      </khatapana-button>
      <khatapana-button
        (onClick)="settleDue()"
        color="secondary"
        border="little"
      >
        <p class="px-4">{{ "transaction.due_settlement_yes" | translate }}</p>
      </khatapana-button>
    </div>
  </section>
</khatapana-modal>

<khatapana-add-bank-modal
  (close)="showBankAddEditModal = false"
  (added)="newBankAdded()"
  [businessTransaction]="businessTransaction"
  *ngIf="showBankAddEditModal"
></khatapana-add-bank-modal>

<khatapana-add-wallet-modal
  (close)="showWalletAddEditModal = false"
  (added)="newWalletAdded()"
  [businessTransaction]="businessTransaction"
  *ngIf="showWalletAddEditModal"
></khatapana-add-wallet-modal>
