<div class="flex w-full p-6 gap-6 max-xl:flex-col max-lg:mb-[70px]">
  <div class="flex w-[64%] gap-6 flex-col max-xl:w-full">
    <khatapana-card
      class="flex w-full flex-col"
      [class]="'bg-white'"
      [border]="true"
    >
      <div class="p-6 flex justify-center items-center flex-col gap-2">
        <img
          width="75"
          src="../../../../../assets/illustrations/{{
            (user$ | async)?.active_subscription?.subscription_plan
              ?.subscription_type == 'Khatapana Pro'
              ? 'pro-sub.svg'
              : 'plus-sub.svg'
          }}"
        />
        <div class="text-xl font-semibold mb-1">
          {{
            (user$ | async)?.active_subscription?.subscription_plan
              ?.subscription_type
          }}
        </div>
        <khatapana-button
          variant="no-padding"
          border="circle"
          [outline]="true"
          [color]="
            (user$ | async)?.active_subscription?.is_trial
              ? 'secondary'
              : 'primary'
          "
          ><span class="px-3 py-1 text-sm">{{
            (user$ | async)?.active_subscription?.is_trial
              ? "1 month free trial"
              : "Active"
          }}</span></khatapana-button
        >
      </div>

      <!-- Show info about Trial or subscription -->
      <section
        class="px-12"
        *ngIf="(user$ | async)?.active_subscription?.is_trial"
      >
        <div class="flex justify-between w-full py-4">
          <span class="font-medium">{{
            "subscription.trial_started" | translate
          }}</span>
          <span class="text-[#545454]">{{
            (user$ | async)?.active_subscription?.start_date | date
          }}</span>
        </div>
        <khatapana-divider class="w-full"></khatapana-divider>
        <div class="flex justify-between w-full py-4">
          <span class="font-medium">{{
            "subscription.trial_end_date" | translate
          }}</span>
          <span class="text-[#545454]">{{
            (user$ | async)?.active_subscription?.end_date | date
          }}</span>
        </div>
        <khatapana-divider class="w-full"></khatapana-divider>
        <div class="flex justify-between w-full py-4">
          <span class="font-medium">{{
            "subscription.amount" | translate
          }}</span>
          <span class="text-[#545454]">{{
            "subscription.one_month_free" | translate
          }}</span>
        </div>
      </section>

      <!-- Show infor about non-trial subscription -->
      <section
        class="px-12"
        *ngIf="(user$ | async)?.active_subscription?.is_trial === false"
      >
        <div class="flex justify-between w-full py-4">
          <span class="font-medium"
            >{{ "common.switched_to" | translate }}
            {{
              (user$ | async)?.active_subscription?.subscription_plan
                ?.subscription_type
            }}</span
          >
          <span class="text-[#545454]">{{
            (user$ | async)?.active_subscription?.start_date | date
          }}</span>
        </div>
        <khatapana-divider class="w-full"></khatapana-divider>
        <div class="flex justify-between w-full py-4">
          <span class="font-medium">{{
            "subscription.renewal_date" | translate
          }}</span>
          <span class="text-[#545454]">{{
            (user$ | async)?.active_subscription?.end_date | date
          }}</span>
        </div>
        <khatapana-divider class="w-full"></khatapana-divider>
        <div class="flex justify-between w-full py-4">
          <span class="font-medium">{{
            "subscription.amount" | translate
          }}</span>
          <span class="text-[#545454]">{{
            (user$ | async)?.active_subscription?.subscription_plan?.price
              | currency
          }}</span>
        </div>
      </section>
      <khatapana-divider class="w-full px-8 py-4"></khatapana-divider>

      <!-- Manage Memberships -->
      <section class="flex flex-col px-12">
        <div class="font-medium text-xl">
          {{ "subscription.manage_membership" | translate }}
        </div>
        <div class="flex gap-4 items-center pt-6">
          <span class="material-symbols-outlined"> receipt_long </span>
          <div class="flex flex-col">
            <h1 class="font-semibold">
              {{ "subscription.plan_period" | translate }}
            </h1>
            <p class="text-xs">
              {{
                (user$ | async)?.active_subscription?.subscription_plan
                  ?.subscription_plan | titlecase
              }}
              @
              {{
                (user$ | async)?.active_subscription?.subscription_plan?.price
                  | currency
              }}
            </p>
            <p class="text-[#7B61FF] text-xs">
              {{ "subscription.saving" | translate }}
            </p>
          </div>
          <khatapana-button
            *ngIf="(user$ | async)?.active_subscription?.is_trial === true"
            (onClick)="showUpgradePlans()"
            variant="extra-small"
            border="circle"
            ><span class="text-sm font-medium">
              {{ "subscription.upgrade_now" | translate }}
            </span></khatapana-button
          >
          <khatapana-button
            *ngIf="(user$ | async)?.active_subscription?.is_trial === false"
            (onClick)="showChangePlans()"
            variant="extra-small"
            border="circle"
            ><span class="text-sm font-medium">
              {{ "subscription.change_plan" | translate }}
            </span></khatapana-button
          >
        </div>
      </section>
      <khatapana-divider class="w-full px-8 py-4"></khatapana-divider>

      <!-- Your benefit section -->
      <section class="flex flex-col px-12">
        <div class="font-medium text-xl">
          {{ "subscription.your_benefit" | translate }}
        </div>
        <div class="py-4 pt-6">
          <div
            *ngFor="
              let detail of (user$ | async)?.active_subscription
                ?.subscription_plan?.subscription_type == 'Khatapana Pro'
                ? proDetails
                : plusDetails;
              let i = index
            "
            class="pb-2"
          >
            <khatapana-plan-detail-item
              [subtitle]="detail.title"
              color="primary"
              [bottomLine]="i + 1 != proDetails.length"
            ></khatapana-plan-detail-item>
          </div>
        </div>
      </section>
    </khatapana-card>
  </div>
  <div
    class="flex w-[36%] gap-6 flex-col max-xl:w-full max-xl:flex-row max-md:flex-col"
  >
    <khatapana-simplify-business
      class="max-xl:w-3/6 max-md:w-full"
      *ngIf="
        (user$ | async)?.active_subscription?.subscription_plan
          ?.subscription_type == 'Khatapana Pro'
      "
    ></khatapana-simplify-business>
    <khatapana-reward-system
      class="max-xl:w-3/6 max-md:w-full"
    ></khatapana-reward-system>
  </div>
</div>
