import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { AbstractControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { keyValue } from '../../models/keyvalue';
import { ValidationService } from '../../services/validation.service';

@Component({
  selector: 'khatapana-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectComponent),
      multi: true,
    },
  ],
})
export class SelectComponent implements OnInit {
  // Input Events
  @Input()
  public errorControl!: AbstractControl;
  @Input()
  public errorLabelName?: string;
  @Input() selected?: any = null;
  @Input() appendTo = '';
  @Input() placeHolder = 'Select an item';
  @Input() list: keyValue[] = [];
  @Input() disabled = false;
  @Input() clearable = false;
  @Input() fullWidth = false;
  @Input() fontWeight = 600;
  @Input() height = 41;
  @Input() selectClass = '';
  @Input() icon: any = null;
  @Input() label: any = null;
  @Input() border: 'none' | 'circle' | 'little' | 'medium' = 'none';
  @Input() variant: 'none' | 'dark' | 'mild' = 'none';

  // Component variables

  @Output() onChange = new EventEmitter<any>();

  onTouched: any = () => {};

  writeValue(value: any): void {
    this.selected = value;
  }

  registerOnChange(fn: any): void {}

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  constructor() {}

  ngOnInit(): void {}

  compareWith(item: any, selected: any) {
    return item.id === selected.id;
  }

  get errorMessage(): any {
    if (this.errorControl) {
      for (const propertyName in this.errorControl.errors) {
        if (this.errorControl.errors.hasOwnProperty(propertyName)) {
          if (
            this.errorControl.invalid &&
            (this.errorControl.touched || this.errorControl.dirty)
          ) {
            const vv = ValidationService.getValidationErrorMessage(
              propertyName,
              this.errorControl.errors[propertyName],
              this.errorLabelName
            );
            return vv;
          }
        }
      }
    }

    return false;
  }
}
