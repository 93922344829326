<tbody class="w-full justify-center flex">
  <tr
    class="p-8 flex justify-center gap-8 flex-col text-[#cfcfcf] items-center pointer-events-none"
  >
    <span class="material-symbols-outlined text-6xl"> table </span>
    <span
      class="font-semibold text-center"
      *ngIf="!message; else messageTemplate"
      >No data available
      <span class="font-normal">(with given parameters)</span></span
    >
    <ng-template #messageTemplate>
      <span>{{ message }}</span>
    </ng-template>
  </tr>
</tbody>
