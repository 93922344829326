import { Component } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { AlertService } from 'src/app/modules/shared/services/alert.service';
import { DEFAULT_ERROR_MESSAGE } from 'src/constants';
import { ExpenseReport } from '../../shared/models/expense.report';
import { REPORTING_TYPES } from '../../shared/models/reports.data';
import { ExpenseReportService } from '../../shared/services/expense.service';
import { PageTitleService } from '../../shared/services/page-title.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'khatapana-expense-report',
  templateUrl: './expense-report.component.html',
})
export class ExpenseReportComponent {
  reportings = [...REPORTING_TYPES];

  colorList = ['99dbb4', 'ffa6a6', 'f5d38e', 'c39efa', '84aaf0', 'b5b5b5'];
  reportingType?: string;
  startDate = '';
  endDate = '';
  today = new Date();
  expense_percentage_difference = 0;

  expense_percentage = 0;
  public myMath = Math;

  // Doughnut Chart
  colorSchemePie: any = {
    domain: [
      'rgba(0, 170, 108, 0.68)',
      'rgba(123, 97, 255, 0.68)',
      'rgba(255, 102, 102, 0.68)',
      'rgba(255, 192, 67, 0.68)',
      'rgba(39, 110, 241, 0.6)',
      '#EEEEEE',
    ],
  };
  pieChartData: any = [];

  expenseReport?: ExpenseReport;

  constructor(
    private pageTitleService: PageTitleService,
    private alertService: AlertService,
    private translate: TranslateService,
    private expenseService: ExpenseReportService,
    private sanitizer: DomSanitizer
  ) {}

  sanitizeImageUrl(color: string, letter: string): SafeUrl {
    return this.sanitizer.bypassSecurityTrustUrl(
      `https://via.placeholder.com/30x30/${color}/000000.png?text=${letter.toUpperCase()}`
    );
  }

  ngOnInit(): void {
    this.reportings.splice(0, 1);
    this.reportingType = this.reportings[2]?.id;
    this.pageTitleService.showBackButton$.next(true);
    this.translate
      .get('navbar.expense_report')
      .subscribe((v) => this.pageTitleService.pageTitle$.next(v));
    this.getExpenseReport();
  }

  getExpenseReport() {
    this.expenseService
      .getExpenseReport(this.reportingType!)
      .then((result: any) => {
        this.startDate = result.start_date;
        this.endDate = result.end_date;
        this.pieChartData = result.pie_chart_data;
        this.expenseReport = result.expense_report!;
        this.expense_percentage_difference =
          result.expense_percentage_difference;
        this.expense_percentage = result.expense_percentage;
      })
      .catch(() => {
        this.alertService.error(DEFAULT_ERROR_MESSAGE);
      });
  }

  handleChange(value: any) {
    this.reportingType = value;
    this.getExpenseReport();
  }
}
