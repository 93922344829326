<div
  [ngClass]="{
    'gap-8': space === 'big',
    'gap-3': space === 'little'
  }"
  class="flex flex-row items-center"
>
  <i
    class="fa fa-check-circle text-xl mb-3"
    [ngClass]="{
      'text-[#7B61FF]': color === 'primary',
      'text-[#545454]': color !== 'primary',
      'text-sm': small,
      'text-xl': !small
    }"
  ></i>
  <div class="w-full">
    <div class="mb-3">
      <h1
        *ngIf="title"
        class="font-semibold"
        [ngClass]="{
          'text-sm': small,
          'text-base': !small
        }"
      >
        {{ title | translate }}
      </h1>
      <p
        *ngIf="subtitle"
        [ngClass]="{
          'font-[450]': space === 'big'
        }"
        class="text-[#545454]"
        [ngClass]="!title ? 'py-1' : ''"
        [ngClass]="{
          'text-sm text-[#141414]': small,
          'text-base': !small
        }"
      >
        {{ subtitle | translate }}
      </p>
    </div>
    <khatapana-divider *ngIf="bottomLine"></khatapana-divider>
  </div>
</div>
