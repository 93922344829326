export const SUBSCRIPTION_ID = {
  standard: 1,
  pro: 2,
  plus: 3,
};

export interface Subscribe {
  subscription_plan: number;
  is_trial: boolean;
  referral_code?: string;
}

export interface SubscriptionType {
  id: number;
  name: string;
  created_date: string;
  description: string;
  is_active: boolean;
  is_recommended: boolean;
  updated_date: string;
}

export interface SubscriptionPlan {
  id: number;
  activation_period: number;
  created_date: string;
  is_active: boolean;
  is_standard: boolean;
  price: number;
  subscription_plan: any;
  subscription_type: string;
  trial_days: number;
  updated_date: string;
}
