import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NavigationTopService } from '../../shared/services/navigation-top.service';

@Component({
  selector: 'khatapana-business-party',
  templateUrl: './business-party.component.html',
})
export class BusinessPartyComponent implements OnInit {
  transactionType: string = '';
  constructor(
    private navigationTopService: NavigationTopService,
    private _route: ActivatedRoute
  ) {
    this.navigationTopService.type$.next('sales');
    this.navigationTopService.mode$.next('party');
  }

  ngOnInit(): void {
    this.transactionType = this._route.snapshot.paramMap.get('type') ?? 'none';
    this.navigationTopService.type$.next(this.transactionType);
  }
}
