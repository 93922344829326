import { Injectable, ViewContainerRef } from '@angular/core';
import { ToasterComponent } from '../components/toaster/toaster.component';

@Injectable({
  providedIn: 'root',
})
export class ToasterService {
  message: string = 'Toaster Service';
  duration: number = 2000;
  public viewContainerRef!: ViewContainerRef;
  constructor() {}

  set setviewContainerRef(vcr: ViewContainerRef) {
    this.viewContainerRef = vcr;
  }

  error(message = this.message, duration = this.duration) {
    const ref = this.viewContainerRef.createComponent(ToasterComponent);
    ref.instance.message = message;
    ref.instance.type = 'error';
    setTimeout(() => {
      this.viewContainerRef.clear();
      ref.instance.ngOnDestroy();
    }, duration);
  }
  success(message = this.message, duration = this.duration) {
    const ref = this.viewContainerRef.createComponent(ToasterComponent);
    ref.instance.message = message;
    ref.instance.type = 'check_circle';
    setTimeout(() => {
      this.viewContainerRef.clear();
      ref.instance.ngOnDestroy();
    }, duration);
  }
  info(message = this.message, duration = this.duration) {
    const ref = this.viewContainerRef.createComponent(ToasterComponent);
    ref.instance.message = message;
    ref.instance.type = 'info';
    setTimeout(() => {
      this.viewContainerRef.clear();
      ref.instance.ngOnDestroy();
    }, duration);
  }
}
