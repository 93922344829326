import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { NgOtpInputConfig } from 'ng-otp-input';
import { AlertService } from 'src/app/modules/shared/services/alert.service';
import { LocalStorageService } from 'src/app/services/local.storage.service';
import { DEFAULT_ERROR_MESSAGE } from 'src/constants';
import { ACCOUNT_ROUTES } from '../../../account/account-routing.module';
import { ValidationService } from '../../../shared/services/validation.service';
import { LoginRequest } from '../../shared/models/login-request';
import { User } from '../../shared/models/user.model';
import { VerifyEmail } from '../../shared/models/verify-otp';
import { AuthService } from '../../shared/services/auth.service';

@Component({
  selector: 'khatapana-signup',
  templateUrl: './signup.component.html',
})
export class SignupComponent implements OnInit {
  signupForm!: FormGroup;
  otpForm!: FormGroup;
  user$ = this.localStorage.currentUser$;
  terms = `By proceeding, I agree to Khatapana's
  <a class="text-[#7B61FF] font-[450]" href="https://khatapana.com/terms">Terms of Use</a>
  and acknowledge that I have read the
  <a class="text-[#7B61FF] font-[450]">Privacy Policy</a>.`;
  showPassword = false;
  submitButtonClicked = false;
  showOTPModal = false;
  otpConfig: NgOtpInputConfig = {
    length: 6,
    containerClass: 'flex gap-6 justify-center flex-wrap',
  };
  disableNext = true;
  startTimimg = false;
  timeCountdown = 60;

  emailVerified = false;
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private alertService: AlertService,
    private authService: AuthService,
    private localStorage: LocalStorageService
  ) {
    this.signupForm = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: [''],
      password: [
        '',
        [Validators.required, ValidationService.passwordValidator],
      ],
    });
    this.otpForm = this.fb.group({
      otp: ['', [Validators.required, Validators.minLength(6)]],
    });
  }

  ngOnInit(): void {
    // this.startCheckingEmailVerification();
    this.user$.subscribe((user) => {
      this.emailVerified = !user?.email ? false : true;
    });
  }

  startCheckingEmailVerification() {
    setInterval(() => {
      this.authService.getUserProfile().subscribe((data: { user: User }) => {
        if (
          data.user.email == data.user.unverified_email &&
          data.user.email != null
        ) {
          this.emailVerified = true;
        }
      });
    }, 2000);
  }

  get email() {
    return this.signupForm.get('email');
  }

  get firstName() {
    return this.signupForm.get('firstName');
  }

  get lastName() {
    return this.signupForm.get('lastName');
  }

  get password() {
    return this.signupForm.get('password');
  }

  get otp() {
    return this.otpForm.get('otp') as FormControl;
  }

  updateUserInfo() {
    if (this.signupForm.invalid) {
      this.signupForm.markAllAsTouched();
      return;
    }
    this.submitButtonClicked = true;
    this.authService
      .updateProfileWithUsefulField({
        first_name: this.firstName?.value,
        last_name: this.lastName?.value,
        password: this.password?.value,
      })
      .subscribe({
        next: () => {
          this.submitButtonClicked = true;
          this.getUser();
        },
        error: (err: HttpErrorResponse) => {
          this.submitButtonClicked = false;
          this.alertService.error(
            this.alertService.getFormattedErrorMessage(err.error) ??
              DEFAULT_ERROR_MESSAGE
          );
        },
        complete: () => (this.submitButtonClicked = false),
      });
  }

  getUser() {
    this.authService.loadUserToLocalStorage();
    this.router.navigateByUrl(ACCOUNT_ROUTES.CHOOSE);
  }

  // Email verification steps
  sendOTPEmailVerification() {
    this.emailVerified = false;
    if (this.signupForm.invalid) {
      this.signupForm.markAllAsTouched();
      return;
    }
    const loginRequest: LoginRequest = {
      username: this.email?.value,
    };
    this.authService.sendOTPForUpdate(loginRequest).subscribe({
      next: (res) => {
        // this.showOTPModal = true;
        this.alertService.success(res.message);
        this.updateUserInfo();
      },
      error: (err: HttpErrorResponse) => {
        this.alertService.error(err.error.message ?? DEFAULT_ERROR_MESSAGE);
      },
    });
  }

  startTimer() {
    this.startTimimg = true;
    this.timeCountdown = 60;
    const interval = setInterval(() => {
      this.timeCountdown--;
      if (this.timeCountdown == 0) {
        this.startTimimg = false;
        this.timeCountdown = 60;
        clearInterval(interval);
      }
    }, 1000);
  }

  otpChanged(value: any) {
    this.disableNext = value.length == 6 ? false : true;
  }

  resendOTP() {
    if (this.startTimimg) return;
    const loginRequest: LoginRequest = {
      username: this.email?.value,
    };
    this.authService.sendOTPForUpdate(loginRequest).subscribe({
      next: (res: any) => {
        this.alertService.success('OTP resent successfully');
        this.startTimer();
      },
      error: (err: HttpErrorResponse) => {
        this.alertService.error(
          this.alertService.getFormattedErrorMessage(err.error) ??
            DEFAULT_ERROR_MESSAGE
        );
      },
    });
  }

  verifyEmail() {
    if (this.otpForm.invalid) return;
    const verifyOTPRequest: VerifyEmail = {
      otp: this.otp?.value,
      email: this.email?.value,
    };
    this.disableNext = true;
    this.authService.verifyEmail(verifyOTPRequest).subscribe({
      next: (res) => {
        this.updateUserInfo();
      },
      error: (err: HttpErrorResponse) => {
        this.alertService.error(
          this.alertService.getFormattedErrorMessage(err.error)
        );
      },
    });
  }
}
