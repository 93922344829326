import { Injectable, ViewContainerRef } from '@angular/core';
import { AlertComponent } from '../components/alert/alert.component';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  duration: number = 2000;
  public viewContainerRef!: ViewContainerRef;
  constructor() {}

  set setviewContainerRef(vcr: ViewContainerRef) {
    this.viewContainerRef = vcr;
  }

  error(
    message = `Operation didn't completed successfully.`,
    duration = this.duration
  ) {
    const ref = this.viewContainerRef.createComponent(AlertComponent);
    ref.instance.message = message;
    ref.instance.type = 'error';
    ref.instance.title = 'Error';
    ref.instance.removeAlert = () => {
      this.viewContainerRef.clear();
    };
    setTimeout(() => {
      this.viewContainerRef.clear();
      ref.instance.ngOnDestroy();
    }, duration);
  }

  success(
    message = `Operation completed successfully`,
    duration = this.duration
  ) {
    const ref = this.viewContainerRef.createComponent(AlertComponent);
    ref.instance.message = message;
    ref.instance.type = 'check_circle';
    ref.instance.title = 'Success';
    ref.instance.removeAlert = () => {
      this.viewContainerRef.clear();
    };
    setTimeout(() => {
      this.viewContainerRef.clear();
      ref.instance.ngOnDestroy();
    }, duration);
  }

  info(
    message = `Operation returned some infomation.`,
    duration = this.duration
  ) {
    const ref = this.viewContainerRef.createComponent(AlertComponent);
    ref.instance.message = message;
    ref.instance.type = 'info';
    ref.instance.title = 'Info';
    ref.instance.removeAlert = () => {
      this.viewContainerRef.clear();
    };
    setTimeout(() => {
      this.viewContainerRef.clear();
      ref.instance.ngOnDestroy();
    }, duration);
  }

  getFormattedErrorMessage(error: any) {
    var message = JSON.stringify(error);
    var message2 = Object.values(error)[0];
    message =
      error?.message ||
      error?.details ||
      error?.detail ||
      error?.error ||
      message2 ||
      'Something went wrong!';
    return message;
  }
}
