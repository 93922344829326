<div class="flex flex-col" *ngIf="label; else template">
  <label class="font-semibold text-sm pb-1">{{ label }}</label>
  <ng-template [ngTemplateOutlet]="template"></ng-template>
</div>
<ng-template #template>
  <div
    [style.height.px]="height"
    class="flex items-center border-[1px] justify-center pr-2"
    [ngClass]="{
      'rounded-full': border === 'circle',
      'rounded-lg': border === 'medium',
      'border-[#757575]': variant === 'dark',
      'border-[#E2E2E2]': variant === 'mild',
      'border-[#EEEEEE]': variant === 'none',
      rounded: border === 'little',
      icon: icon,
      'bg-[#f9f9f9]': disabled,
      'pl-4': icon,
      '': !icon
    }"
  >
    <img *ngIf="icon" [src]="icon" />
    <ng-select
      [ngClass]="{
        circle: border === 'circle',
        little: border === 'little',
        medium: border === 'medium',
        icon: icon,
        'w-full': fullWidth
      }"
      [disabled]="disabled"
      [(ngModel)]="selected"
      [items]="list"
      [class]="selectClass"
      bindLabel="name"
      bindValue="id"
      [appendTo]="appendTo"
      [compareWith]="compareWith"
      (change)="onChange.emit($event.id)"
      [selectOnTab]="true"
      [searchable]="false"
      [clearable]="clearable"
      [placeholder]="placeHolder"
      [style.fontWeight]="fontWeight"
    >
    </ng-select>
  </div>
  <small *ngIf="errorMessage" class="text-[#E11900] font-[450] text-sm pt-1">
    {{ errorMessage }}
  </small>
</ng-template>
