import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AccountComponent } from './account.component';
import { BusinessTransactionComponent } from './pages/business/business-transaction/business-transaction.component';
import { ChoosePlusTypeComponent } from './pages/business/choose-plus-type/choose-plus-type.component';
import { StorePageComponent } from './pages/business/store-page/store-page.component';
import { CompletedComponent } from './pages/completed/completed.component';
import { PlusComponent } from './pages/plus/plus.component';
import { ProComponent } from './pages/pro/pro.component';
import { StandardComponent } from './pages/standard/standard.component';
import { TransactionComponent } from './pages/transaction/transaction.component';
import { EsewaComponent } from './shared/services/esewa/esewa.component';

export const ACCOUNT_ROUTES = {
  CHOOSE: '/account/choose',
  STANDARD: '/account/standard',
  PRO: '/account/pro',
  PLUS: '/account/plus',
  TRANSACTION: '/account/transaction',
  BUSINESS_TRANSACTION: '/account/setup',
  COMPLETED: '/account/completed',
  PLUS_TYPE: '/account/plus/choose',
  CREATE_STORE: '/account/business',
};

const routes: Routes = [
  {
    path: '',
    component: AccountComponent,
    children: [
      // {
      //   path: 'choose',
      //   component: ChooseAccountComponent,
      // },
      {
        path: 'choose',
        component: ChoosePlusTypeComponent,
      },
      // {
      //   path: 'standard',
      //   component: StandardComponent,
      // },
      // {
      //   path: 'pro',
      //   component: ProComponent,
      // },
      // {
      //   path: 'plus',
      //   component: PlusComponent,
      // },
      // {
      //   path: 'plus/choose',
      //   component: ChoosePlusTypeComponent,
      // },
      {
        path: 'business',
        component: StorePageComponent,
      },
      // {
      //   path: 'transaction',
      //   component: TransactionComponent,
      // },
      // {
      //   // path: 'business/transaction',
      //   path: 'setup',
      //   component: BusinessTransactionComponent,
      // },
      {
        path: 'completed',
        component: CompletedComponent,
      },
      {
        path: 'esewa',
        component: EsewaComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AccountRoutingModule {}
