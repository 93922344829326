<button
  [disabled]="disabled"
  (click)="onClick.emit(true)"
  [ngClass]="{
    'w-full': fullWidth,
    'pointer-events-none': loading || disabled,
    disabled: disabled,
    outlined: outline,
    link: link,
    basic: color === 'basic',
    white: color === 'white',
    simple: color === 'simple',
    primary: color === 'primary',
    secondary: color === 'secondary',
    red: color === 'red',
    green: color === 'green',
    'primary-light': color === 'primary-light',
    'rounded-full': border === 'circle',
    rounded: border === 'little',
    'p-3': variant === 'small',
    'py-2 px-3': variant === 'extra-small',
    'py-3.5 px-4': variant === 'medium',
    'py-4 px-6': variant === 'big',
    'p-0': variant === 'no-padding',
    shadowed: shadow
  }"
>
  <div class="flex w-full justify-center" *ngIf="loading">
    <khatapana-loader [size]="24"></khatapana-loader>
  </div>
  <ng-content *ngIf="!loading"></ng-content>
</button>
