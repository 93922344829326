<khatapana-modal>
  <khatapana-card>
    <div class="p-7 w-full flex flex-col">
      <h1 class="justify-center flex w-full text-2xl font-semibold">
        {{ "party.add_new_party" | translate }}
      </h1>
      <form [formGroup]="partyForm" class="flex flex-col gap-5 py-11 px-2">
        <khatapana-input
          label="{{ 'party.party_name_label' | translate }}"
          [errorControl]="name!"
          formControlName="name"
          placeholder="{{ 'party.party_name_placeholder' | translate }}"
        ></khatapana-input>
        <khatapana-input
          [type]="'tel'"
          [id]="'phoneintl'"
          label="{{ 'party.party_number_label' | translate }}"
          formControlName="phone"
          placeholder="{{ 'party.party_number_placeholder' | translate }}"
        ></khatapana-input>
        <ng-container *ngIf="businessTransaction">
          <!-- Recievable / Paybale -->
          <div class="bg-[#F6F6F6] flex justify-between px-4 py-3 rounded-sm">
            <!-- Recievable -->
            <div class="flex gap-2">
              <div
                [ngClass]="{
                  'border-[#757575] border-[3px]': !isReceivable,
                  'border-[#7B61FF] border-[8px]': isReceivable
                }"
                class="h-5 w-5 rounded-full cursor-pointer"
                (click)="isReceivable = true"
              ></div>
              <span class="text-sm font-semibold">{{
                "party.receivable" | translate
              }}</span>
            </div>
            <!-- Payable -->
            <div class="flex gap-2">
              <div
                [ngClass]="{
                  'border-[#757575] border-[3px]': isReceivable,
                  'border-[#7B61FF] border-[8px]': !isReceivable
                }"
                class="h-5 w-5 rounded-full cursor-pointer"
                (click)="isReceivable = false"
              ></div>
              <span class="text-sm font-semibold">{{
                "party.payable" | translate
              }}</span>
            </div>
          </div>
          <!-- Opening / Start date -->
          <div class="flex justify-between gap-6">
            <khatapana-input
              [label]="
                isReceivable
                  ? ('party.receivable_opening' | translate)
                  : ('party.payable_opening' | translate)
              "
              formControlName="amount"
              [errorControl]="amount!"
              type="number"
              appAllownumbersonly
              placeholder="{{ 'party.opening_amount_placeholder' | translate }}"
            ></khatapana-input>
            <div class="w-full flex flex-col">
              <div class="font-semibold text-sm pb-1">
                {{ "party.starting_date" | translate }}
              </div>
              <khatapana-date-picker
                class="w-full h-full border-[1px] border-[#545454] rounded-[4px] flex items-center justify-start"
                (onChange)="selectedStartingDate($event)"
              >
                <div
                  class="flex px-4 w-full h-full items-center cursor-pointer"
                >
                  {{
                    starting_date?.value ? starting_date?.value : "Select date"
                  }}
                </div>
              </khatapana-date-picker>
            </div>
          </div>
        </ng-container>
        <khatapana-input
          [label]="'common.email' | translate"
          formControlName="email"
          [errorControl]="email!"
          placeholder="{{ 'party.email_placeholder' | translate }}"
        ></khatapana-input>
      </form>
      <footer class="flex gap-5 px-2 w-full">
        <khatapana-button
          variant="big"
          [fullWidth]="true"
          class="w-3/6"
          border="little"
          (onClick)="closed.emit(true)"
          [link]="true"
          ><span class="flex justify-center w-full">{{
            "common.cancel" | translate
          }}</span></khatapana-button
        >
        <khatapana-button
          variant="big"
          [fullWidth]="true"
          class="w-3/6"
          border="little"
          (onClick)="addParty()"
          ><span class="flex justify-center w-full">{{
            "common.save" | translate
          }}</span></khatapana-button
        >
      </footer>
    </div>
  </khatapana-card>
</khatapana-modal>
