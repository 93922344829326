import { Component } from '@angular/core';
import { NavigationTopService } from '../../shared/services/navigation-top.service';

@Component({
  selector: 'khatapana-sale-purchase-form',
  templateUrl: './sale-purchase-form.component.html',
})
export class SalePurchaseFormComponent {
  constructor(private navigationTopService: NavigationTopService) {
    this.navigationTopService.type$.next('none');
  }
}
