import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { jwtDecode } from 'jwt-decode';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, Observable } from 'rxjs';
import { KHATAPANA_ACCOUNT } from '../model/khatapana.account';
import { BusinessAccountResponse } from '../modules/account/shared/models/business.account';
import { User } from '../modules/auth/shared/models/user.model';
import { clearUser, setUser } from '../store/user/user.actions';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  _currentUser = new BehaviorSubject<User>({});
  currentUser$ = this._currentUser.asObservable();

  _currentBusiness = new BehaviorSubject<BusinessAccountResponse | any>({});
  currentBusiness$ = this._currentBusiness.asObservable();

  constructor(private cookieService: CookieService, private store: Store) {
    this.user = this.user;
    this.currentUser$ != this.getCurrentUser$();
    this.currentBusiness$ != this.getCurrentBusiness$();
  }

  getCurrentUser$(): Observable<User | undefined> {
    return this.currentUser$;
  }

  getCurrentBusiness$(): Observable<BusinessAccountResponse | undefined> {
    return this.currentBusiness$;
  }

  set business(business: BusinessAccountResponse) {
    this._currentBusiness.next(business);
    this.setItem('business', business);
  }

  public setItem(key: string, value: any) {
    value = JSON.stringify(value);
    localStorage.setItem(key, value);
    return true;
  }

  public getItem(key: string) {
    const value = localStorage.getItem(key);
    try {
      return JSON.parse(value!);
    } catch (e) {
      return null;
    }
  }

  public removeItem(key: string) {
    localStorage.removeItem(key);
  }

  get username() {
    return localStorage.getItem('username');
  }

  set newUser(newUser: boolean) {
    this.setItem('newUser', newUser);
  }

  get newUser() {
    return this.getItem('newUser');
  }

  set social(social: boolean) {
    this.setItem('social', social);
  }

  get social() {
    return this.getItem('social');
  }

  set username(username: any) {
    localStorage.setItem('username', username);
  }

  get otp() {
    return this.getItem('otp');
  }

  set otp(otp: string) {
    this.setItem('otp', otp);
  }

  get token() {
    if (this.check_cookie('token')) {
      return this.cookieService.get('token');
    }
    return '';
  }

  set token(token: string) {
    this.cookieService.set('token', token, {
      expires: this.getTokenExp(token),
      path: '/',
      // domain: 'khatapana.com',
      secure: true,
      sameSite: 'Strict',
    });
    // this.setItem('token', token);
  }

  get refresh_token() {
    // return this.getItem('refresh_token');
    if (this.check_cookie('refresh_token')) {
      return this.cookieService.get('refresh_token');
    }
    return '';
  }

  check_cookie(name: string) {
    return this.cookieService.check(name);
  }

  set refresh_token(token: string) {
    this.cookieService.set('refresh_token', token, {
      expires: this.getTokenExp(token),
      path: '/',
      // domain: 'khatapana.com',
      secure: true,
      sameSite: 'Strict',
    });
    // this.setItem('refresh_token', token);
  }

  get user() {
    return this.getItem('user');
  }

  set user(user: User) {
    this._currentUser.next(user);
    this.store.dispatch(setUser({ user }));
    this.setItem('user', user);
    // console.log(this._currentUser.getValue());
  }

  get social_user() {
    return this.getItem('social_user');
  }

  set social_user(social_user: any) {
    this._currentUser.next(social_user);
    this.setItem('social_user', social_user);
  }

  get business_account() {
    return this.getItem('business_account');
  }

  set business_account(business_account: number) {
    this.setItem('business_account', business_account);
  }

  get plus_type() {
    return this.getItem('plus_type');
  }

  set plus_type(plus_type: string) {
    this.setItem('plus_type', plus_type);
  }

  get isStandard() {
    var user = this.getItem('user') as User;
    return user.active_subscription.subscription_plan.subscription_type ==
      'Khatapana Standard'
      ? true
      : false;
  }

  get plan() {
    var user = this.getItem('user') as User;
    let plan = '';
    switch (user.active_subscription.subscription_plan.subscription_type) {
      case 'Khatapana Standard':
        plan = KHATAPANA_ACCOUNT.STANDARD;
        break;
      case 'Khatapana Pro':
        plan = KHATAPANA_ACCOUNT.PRO;
        break;
      case 'Khatapana Plus':
        plan = KHATAPANA_ACCOUNT.PLUS;
        break;
    }
    return plan;
  }

  get referral_code() {
    return this.getItem('referral_code');
  }

  set referral_code(referral_code: string) {
    this.setItem('referral_code', referral_code);
  }

  get calendar() {
    return this.getItem('calendar');
  }

  set calendar(calendar: string) {
    this.setItem('calendar', calendar);
  }

  get opening_balance_reminder() {
    return this.getItem('opening_balance_reminder');
  }

  set opening_balance_reminder(opening_balance_reminder: string) {
    this.setItem('opening_balance_reminder', opening_balance_reminder);
  }

  resetLocalStorage() {
    this.removeItem('user');
    this.cookieService.delete('refresh_token');
    this.cookieService.delete('token');
    this.removeItem('refresh_token');
    this.removeItem('token');
    this.removeItem('social');
    this.removeItem('username');
    this.removeItem('social_user');
    this._currentUser.next({});
    this._currentBusiness.next({});
    this.store.dispatch(clearUser());

    // this.googleService?.signOut();
    // this.authService.googleSignOut();
  }

  getTokenExp(token: any) {
    const decoded: any = jwtDecode(token);

    const exp = decoded.exp * 1000;

    return new Date(exp);
  }
}
