import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule, Type } from '@angular/core';
import { I18nModule } from './i18n/i18n.module';

export const CORE_MODULES: Type<unknown>[] = [
  CommonModule,
  HttpClientModule,
  I18nModule,
];

@NgModule({
  declarations: [],
  imports: [...CORE_MODULES],
  providers: [],
  exports: [...CORE_MODULES],
})
export class CoreModule {}
