import { HttpErrorResponse } from '@angular/common/http';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { AlertService } from 'src/app/modules/shared/services/alert.service';
import { ConfirmModalService } from 'src/app/modules/shared/services/confirm.modal.service';
import { DEFAULT_ERROR_MESSAGE } from 'src/constants';
import { HOME_ROUTE } from '../../../home/home-routing.module';
import { TablePrintComponent } from '../../../home/pages/transaction-list/table-print/table-print.component';
import {
  TransactionListResponse,
  TransactionResponse,
} from '../../../home/shared/models/transaction';
import { TRANSACTIONMODESFILTER } from '../../../home/shared/models/transaction-modes';
import { TRANSACTIONTYPESFILTER } from '../../../home/shared/models/transaction-types';
import { TransactionService } from '../../../home/shared/services/transaction.service';

@Component({
  selector: 'khatapana-transaction-table',
  styles: [],
  templateUrl: './transaction-table.component.html',
})
export class TransactionTableComponent implements OnInit, OnChanges {
  @Input() partyPage = false;
  @Input() dashboardPage = false;
  @Input() giveBorder = true;
  @Input() mode = '';
  @Input() partyId = 0;

  @Input() businessTransaction = false;
  @Input() transactionType: string = 'inout';
  @Input() inOut: boolean = true;

  @Output() transactionListChanged = new EventEmitter<any>();

  // Constants
  HOME_ROUTES = HOME_ROUTE;
  modeTypes = TRANSACTIONMODESFILTER;
  transactionTypes = TRANSACTIONTYPESFILTER;
  selectedModeType = this.modeTypes[0];
  selectedtransactionType = this.transactionTypes[0];
  fromDate: any;
  toDate: any;

  //Params
  params: any = {
    limit: 10,
    offset: 0,
    transaction_mode: this.selectedModeType.key,
    transaction_type: this.selectedtransactionType.id,
    ordering: 'created_date',
    search: '',
  };
  selectedPage = 1;
  totalPages = 1;
  loading = false;

  // Responses
  transactionResponse: TransactionListResponse | undefined;
  transactionList: TransactionResponse[] = [];

  // Menu related
  selectedTransaction: any;

  // Edit Related
  showEditModal = false;
  showEditTransferModal = false;
  toEditTransactionDetail?: TransactionResponse;

  // Responsivenss
  public getScreenWidth: any;
  public getScreenHeight: any;

  constructor(
    private changeDetector: ChangeDetectorRef,
    private transactionService: TransactionService,
    private alertService: AlertService,
    private confirmModalService: ConfirmModalService
  ) {}

  ngOnInit() {
    this.getScreenWidth = window.innerWidth;
    this.getScreenHeight = window.innerHeight;
    if (this.partyPage) {
      this.params['party'] = this.partyId;
    }
    if (this.dashboardPage) {
      this.params.transaction_mode = this.mode;
      this.params.limit = 5;
    }

    this.getAllTransactions();
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.getScreenWidth = window.innerWidth;
    this.getScreenHeight = window.innerHeight;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['mode'] || changes['transactionType']) {
      if (this.dashboardPage) {
        this.params.transaction_mode = this.mode;
        this.params.limit = 5;
      }
      this.params.transaction_type = this.transactionType;

      this.getAllTransactions();
    }
  }

  clearSearch() {
    this.params.search = [...''];
    this.changeDetector.detectChanges();
    this.params.search = '';
    this.getAllTransactions();
  }

  getAllTransactions() {
    this.transactionService
      .getAllTransactions(this.params, this.businessTransaction)
      .subscribe({
        next: (data: TransactionListResponse) => {
          this.transactionList = data.results;
          this.transactionResponse = data;
          const count =
            data.results.length < 1
              ? 1
              : Math.ceil(data.count / this.params.limit);
          this.totalPages = Math.max(count || 0, 1);
          // For download report in Dashboard page
          this.transactionListChanged.emit(this.transactionList);
        },
        error: (err: HttpErrorResponse) => {
          this.alertService.error(
            this.alertService.getFormattedErrorMessage(err.error) ??
              DEFAULT_ERROR_MESSAGE
          );
        },
      });
  }

  changedPage(selected: number) {
    this.selectedPage = selected;
    this.params.offset = (this.selectedPage - 1) * this.params.limit;
    this.getAllTransactions();
  }

  modeChanged(mode: any) {
    this.selectedModeType = mode;
    this.params.transaction_mode = mode.key;
    this.params.offset = 0;
    this.getAllTransactions();
  }

  typeChanged(type: string) {
    this.transactionType = type;
    this.params.transaction_type = type;
    this.getAllTransactions();
  }

  changedFromDate(event: any) {
    this.fromDate = `${event.year}-${event.month}-${event.day}`;
    this.params.start_date = this.fromDate;
    this.getAllTransactions();
  }

  changedToDate(event: any) {
    this.toDate = `${event.year}-${event.month}-${event.day}`;
    var toDatePlusOne = `${event.year}-${event.month}-${event.day + 1}`;
    this.params.end_date = toDatePlusOne;
    this.getAllTransactions();
  }

  getDocumentInfo(doc: string) {
    var docName = doc.split('/', -1).pop();
    var extension = docName?.split('.').pop() as string;
    var isImage = false;
    if (['jpg', 'png', 'jpeg'].includes(extension?.toLowerCase())) {
      isImage = true;
    }
    return { name: docName, extension: extension, isImage: isImage };
  }

  downloadReport() {
    if (this.transactionList.length === 0) {
      this.alertService.error(
        `You don't have any transactions report to download.`
      );
      return;
    }
    this.loading = true;
    const ref =
      this.confirmModalService.viewContainerRef.createComponent(
        TablePrintComponent
      );
    ref.instance.transactionList = [...this.transactionList];
    setTimeout(() => {
      this.loading = false;
      window.print();
    }, 2000);
  }

  //#region MENU
  @HostListener('document:click', ['$event', '$event.target'])
  public onClick(event: MouseEvent, targetElement: HTMLElement): void {
    if (!targetElement) {
      return;
    }
    const clickedInside = targetElement.className.includes('action-menu');
    if (!clickedInside) {
      this.selectedTransaction = undefined;
    }
  }
  //#endregion

  editTransaction(id: number) {
    this.showEditModal = true;
    this.toEditTransactionDetail = this.transactionList.find((x) => x.id == id);
  }

  editTransfer(id: number) {
    this.showEditTransferModal = true;
    this.toEditTransactionDetail = this.transactionList.find((x) => x.id == id);
  }

  deleteTransaction(id: number) {
    this.confirmModalService.error({
      showHeader: false,
      content: 'Are you sure you want to delete this transaction?',
      okText: 'Delete',
      onOkClicked: () => {
        this.confirmModalService.clear();
        this.loading = true;
        this.transactionService.deleteTransaction(id).subscribe({
          next: () => {
            this.loading = false;
            this.alertService.success('Transaction deleted successfully.');
            this.getAllTransactions();
          },
          error: (err: HttpErrorResponse) => {
            this.loading = false;
            this.alertService.error(
              this.alertService.getFormattedErrorMessage(err.error) ??
                DEFAULT_ERROR_MESSAGE
            );
          },
        });
      },
    });
  }
}
