import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PageTitleService {
  pageTitle$ = new BehaviorSubject<any>('');
  showBackButton$ = new BehaviorSubject<boolean>(false);

  constructor() {}
}
