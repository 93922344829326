import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UUID } from 'angular2-uuid';
import {
  AccountService,
  BANK_MODE_ID,
} from 'src/app/modules/account/shared/services/account.service';
import { BankResponse } from 'src/app/modules/home/shared/models/bank';
import { AmountService } from 'src/app/modules/home/shared/services/amount.service';
import { keyValue } from 'src/app/modules/shared/models/keyvalue';
import { amount_accepting_zero_not_starting_with_zero } from 'src/app/modules/shared/regex/amount.regex';
import { AlertService } from 'src/app/modules/shared/services/alert.service';
import { LocalStorageService } from 'src/app/services/local.storage.service';

@Component({
  selector: 'khatapana-edit-bank-modal',
  templateUrl: './edit-bank-modal.component.html',
})
export class EditBankModalComponent implements OnInit {
  @Input() businessTransaction = false;

  @Output() close = new EventEmitter<boolean>();
  @Output() updatedOpening = new EventEmitter<boolean>();

  userBanks: BankResponse[] = [];
  banks: keyValue[] = [];
  bankForm!: FormGroup;

  showBankAddEditModal = false;
  isAdd = true;
  constructor(
    private fb: FormBuilder,
    private accountService: AccountService,
    private localStorage: LocalStorageService,
    private alertService: AlertService,
    private amountService: AmountService
  ) {}

  ngOnInit(): void {
    this.loadBanks();
    this.loadUserBanks();
  }

  initForm() {
    this.bankForm = this.fb.group({
      id: [null],
      account_holder: [
        this.localStorage.user.first_name +
          ' ' +
          this.localStorage.user.last_name,
        Validators.required,
      ],
      balance: [
        0,
        [
          Validators.required,
          Validators.pattern(amount_accepting_zero_not_starting_with_zero),
        ],
      ],
      account_number: [UUID.UUID()],
      payment_gateway: [''],
    });
    this.payment_gateway?.setValue(this.banks[0]);
  }

  //#region  Getters for form
  get id() {
    return this.bankForm?.get('id');
  }

  get account_holder() {
    return this.bankForm?.get('account_holder');
  }
  get balance() {
    return this.bankForm?.get('balance');
  }
  get account_number() {
    return this.bankForm?.get('account_number');
  }
  get payment_gateway() {
    return this.bankForm?.get('payment_gateway');
  }
  //#endregion

  changeGateway(event: any) {
    this.payment_gateway?.setValue(this.banks.find((x) => x.id == event));
  }

  loadUserBanks() {
    this.amountService.getBankBalance(this.businessTransaction).subscribe({
      next: (res: { results: BankResponse[]; total_bank_balance: any }) => {
        this.userBanks = res.results;
      },
    });
  }

  loadBanks() {
    this.accountService
      .gettPaymentOptionsAccordingToTransactionMode(BANK_MODE_ID)
      .subscribe({
        next: (res) => {
          res.results.forEach((bank: keyValue) => {
            this.banks.push({ id: bank.id, name: bank.name });
          });
        },
      });
  }

  addNewBank() {
    this.initForm();
    this.isAdd = true;
    this.showBankAddEditModal = true;
    this.payment_gateway?.enable();
  }

  editBank(id: number) {
    this.initForm();
    this.isAdd = false;
    this.showBankAddEditModal = true;
    const account = this.userBanks.find((x) => x.id === id);
    this.id?.setValue(id);
    this.account_holder?.setValue(account?.account_holder);
    this.account_number?.setValue(account?.account_number);
    this.balance?.setValue(account?.balance);
    this.payment_gateway?.setValue(account?.payment_gateway);
    this.payment_gateway?.disable();
  }

  editSomeBank() {
    this.isAdd = false;
    this.showBankAddEditModal = true;
  }

  saveBank() {
    this.payment_gateway?.enable();
    const { value } = this.bankForm;
    value.payment_gateway = value.payment_gateway.id;
    if (this.businessTransaction) {
      value.business_account = this.localStorage.business_account;
    }
    if (value.id === null) {
      this.accountService.addBankBalance(this.bankForm.value).subscribe({
        next: () => {
          this.showBankAddEditModal = false;
          this.loadUserBanks();
          this.updatedOpening.emit(true);
        },
        error: (err: HttpErrorResponse) => {
          this.alertService.error(
            this.alertService.getFormattedErrorMessage(err.error)
          );
        },
      });
    } else {
      this.accountService.editBankBalance(this.bankForm.value).subscribe({
        next: () => {
          this.showBankAddEditModal = false;
          this.loadUserBanks();
          this.updatedOpening.emit(true);
        },
        error: (err: HttpErrorResponse) => {
          this.alertService.error(JSON.stringify(err.error));
        },
      });
    }
  }
}
