<div
  class="flex flex-col py-8 gap-8 w-4/6 max-xl:w-[80%] mx-auto max-md:w-[85%]"
>
  <h4 class="text-2xl max-md:text-xl font-semibold max-md:text-center">
    {{ "login.enter_new_password" | translate }}
  </h4>

  <form class="flex flex-col gap-4 mb-16" [formGroup]="forgotForm">
    <khatapana-input
      type="password"
      [placeholder]="'login.enter_new_password' | translate"
      formControlName="password"
      [errorControl]="forgotForm.get('password')!"
      [label]="'common.new_password' | translate"
    ></khatapana-input>
  </form>

  <div class="flex justify-between">
    <khatapana-button
      [color]="'basic'"
      [border]="'circle'"
      [routerLink]="AUTH_ROUTES.OTP"
      [queryParams]="{ from: 'forgot-password' }"
      [variant]="'big'"
      type="submit"
      ><i class="fa-solid fa-arrow-left text-base"></i
    ></khatapana-button>
    <khatapana-button
      [color]="'basic'"
      [border]="'circle'"
      [variant]="'big'"
      (onClick)="submit()"
      [disabled]="disableNext"
      [loading]="disableNext"
      type="submit"
      ><span class="text-center"
        >{{ "common.next" | translate }}
        <i class="fa-solid fa-arrow-right text-base pl-1"></i></span
    ></khatapana-button>
  </div>
</div>
