import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AlertService } from 'src/app/modules/shared/services/alert.service';
import { Clipboard } from '@angular/cdk/clipboard';

@Component({
  selector: 'khatapana-reffer-modal',
  templateUrl: './reffer-modal.component.html',
})
export class RefferModalComponent {
  @Input() referralLink = '';
  @Output() closed = new EventEmitter<boolean>();
  constructor(
    private clipboard: Clipboard,
    private alertService: AlertService
  ) {}
  copyLink() {
    this.clipboard.copy(this.referralLink);
    this.alertService.success('Link copied successfully');
  }
}
