<div class="flex w-full gap-8 p-2">
  <div class="w-[40%] max-lg:hidden">
    <h1 class="text-xl font-semibold">{{ "common.password" | translate }}</h1>
    <p>{{ "setting.change_password" | translate }}</p>
  </div>
  <div class="w-[60%] flex flex-col gap-8 max-lg:w-full" [formGroup]="passForm">
    <khatapana-input
      label="{{ 'setting.current_password_label' | translate }}"
      placeholder="{{ 'setting.current_password_placeholder' | translate }}"
      type="password"
      id="oldPassword"
      formControlName="oldPassword"
      [errorControl]="oldPassword!"
    ></khatapana-input>
    <khatapana-input
      label="{{ 'setting.new_password_label' | translate }}"
      placeholder="{{ 'setting.new_password_placeholder' | translate }}"
      type="password"
      id="newPassword"
      formControlName="newPassword"
      [errorControl]="newPassword!"
    ></khatapana-input>
    <khatapana-input
      label="{{ 'setting.confirm_password_label' | translate }}"
      placeholder="{{ 'setting.confirm_password_placeholder' | translate }}"
      type="password"
      id="confirmPassword"
      formControlName="confirmPassword"
      [errorControl]="confirmPassword!"
    ></khatapana-input>
    <khatapana-button
      [fullWidth]="true"
      (onClick)="changePassword()"
      border="little"
      variant="big"
      color="secondary"
      ><span class="flex justify-center w-full">{{
        "setting.change_password_btn" | translate
      }}</span></khatapana-button
    >
  </div>
</div>
