<div class="flex w-full gap-8 p-2">
  <div class="w-[40%] max-lg:hidden">
    <h1 class="text-xl font-semibold">{{ "setting.language" | translate }}</h1>
    <p>{{ "setting.choose_language" | translate }}</p>
  </div>
  <div class="w-[60%] flex flex-col gap-8 max-lg:w-full">
    <khatapana-select
      [label]="'setting.language_label' | translate"
      [list]="languages!"
      [selected]="selectedLanguage"
      [fullWidth]="true"
      [variant]="'dark'"
      [height]="56"
      [fontWeight]="400"
      [border]="'little'"
      (onChange)="handleLanguageChange($event)"
    ></khatapana-select>

    <khatapana-select
      [label]="'setting.calendar_type' | translate"
      [list]="calendars!"
      [selected]="selectedCalendar"
      [fullWidth]="true"
      [variant]="'dark'"
      [height]="56"
      [fontWeight]="400"
      [border]="'little'"
      (onChange)="handleCalendarChange($event)"
    ></khatapana-select>

    <khatapana-button
      [fullWidth]="true"
      border="little"
      variant="big"
      (onClick)="updateLanguauge()"
      color="secondary"
      ><span class="flex justify-center w-full">{{
        "setting.update_account_setting" | translate
      }}</span></khatapana-button
    >
  </div>
</div>
