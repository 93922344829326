import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { ACCOUNT_ROUTES } from '../modules/account/account-routing.module';
import { AUTH_ROUTES } from '../modules/auth/auth-routing.module';
import { AuthService } from '../modules/auth/shared/services/auth.service';
import { HOME_ROUTE } from '../modules/home/home-routing.module';
import { LocalStorageService } from '../services/local.storage.service';

@Injectable({
  providedIn: 'root',
})
export class AccountGuard implements CanActivate {
  constructor(
    private localStorage: LocalStorageService,
    private authService: AuthService,
    private router: Router
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const token = this.localStorage.token;
    // check if user is already logged in
    if (token && this.authService.validateToken(token)) {
      // Checking token and user subscriptions: If both exists then only show dashboard //
      // if (this.localStorage.user?.active_subscription) {
      //   this.authService.loadUserToLocalStorage();
      //   return true;
      // }
      // If User not registered and not social user
      if (!this.authService.isUserRegister && !this.authService.isSocialUser) {
        // this.router.navigate([AUTH_ROUTES.REGISTER]);
        // return false;
        return this.router.createUrlTree([AUTH_ROUTES.REGISTER]);
      }
      // If User not subscribed
      if (!this.localStorage.user?.active_subscription) {
        if (state.url.includes('choose')) {
          return true;
        }
        // this.router.navigate([ACCOUNT_ROUTES.CHOOSE]);
        // return false;
        return this.router.createUrlTree([ACCOUNT_ROUTES.CHOOSE]);
      }
      this.authService.loadUserToLocalStorage();
      this.router.navigate([HOME_ROUTE.DASHBOARD]);
      return false;
    }
    this.handleUnauthorizedAccess();
    return false;
  }

  private handleUnauthorizedAccess(): void {
    this.router.navigate([AUTH_ROUTES.LOGIN]);
  }
}
