import { Component, Input, OnInit } from '@angular/core';
import {
  I18nService,
  LanguageLabel,
} from 'src/app/modules/core/i18n/i18n.service';

@Component({
  selector: 'khatapana-language-switcher',
  templateUrl: './language-switcher.component.html',
})
export class LanguageSwitcherComponent implements OnInit {
  @Input() size: 'small' | 'icon' | 'big' = 'big';
  languages: LanguageLabel[] = [];

  selectedLanguage?: LanguageLabel;
  constructor(private langService: I18nService) {}

  ngOnInit(): void {
    this.languages = this.langService.languages;
    this.selectedLanguage = this.languages.find(
      ({ id }) => id === this.langService.language
    );
  }

  handleLanguageChange(language: any) {
    this.selectedLanguage = this.languages.find((x) => x.id == language);
    this.langService.language = this.selectedLanguage?.id;
  }
}
