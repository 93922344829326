import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppleCallbackComponent } from './components/apple-callback/apple-callback.component';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';
import { AccountGuard } from './guards/account.guard';
import { HomeGuard } from './guards/home.guard';
import { AuthGuard } from './modules/auth/guards/auth.guard';
import { ReferralComponent } from './modules/referral/referral/referral.component';

export const APP_ROUTES = {
  UNAUTHORORIZED: 'unauthorized',
};
const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () =>
      import('./modules/auth/auth.module').then((x) => x.AuthModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'account',
    canActivate: [AccountGuard],
    loadChildren: () =>
      import('./modules/account/account.module').then((x) => x.AccountModule),
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'app',
  },
  {
    path: 'app',
    canActivate: [HomeGuard],
    loadChildren: () =>
      import('./modules/home/home.module').then((x) => x.HomeModule),
  },
  {
    path: 'business',
    loadChildren: () =>
      import('./modules/business/business.module').then(
        (x) => x.BusinessModule
      ),
  },
  {
    path: 'referral',
    component: ReferralComponent,
  },
  {
    path: 'unauthorized',
    component: UnauthorizedComponent,
  },
  {
    path: '*',
    redirectTo: 'auth/login',
  },
  { path: 'accounts/callbacks/apple', component: AppleCallbackComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
