import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NavigationTopService {
  type$ = new BehaviorSubject<'none' | 'sales' | 'purchase' | any>('none');
  mode$ = new BehaviorSubject<'transaction' | 'party'>('party');
}
