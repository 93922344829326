import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  AccountService,
  WALLET_MODE_ID,
} from 'src/app/modules/account/shared/services/account.service';
import { LocalStorageService } from 'src/app/services/local.storage.service';
import { amount_accepting_zero_not_starting_with_zero } from '../../regex/amount.regex';
import { AlertService } from '../../services/alert.service';

@Component({
  selector: 'khatapana-add-wallet-modal',
  templateUrl: './add-wallet-modal.component.html',
})
export class AddWalletModalComponent {
  walletForm!: FormGroup;
  wallets: any[] = [];

  @Input() businessTransaction = false;
  @Output() close = new EventEmitter<boolean>();
  @Output() added = new EventEmitter<boolean>();
  constructor(
    private fb: FormBuilder,
    private alertService: AlertService,
    private accountService: AccountService,
    private localStorage: LocalStorageService
  ) {
    this.initWalletForm();
  }

  ngOnInit() {
    this.loadAllWallets();
  }

  //#region  Getters for form
  get id() {
    return this.walletForm?.get('id');
  }
  get wallet_id() {
    return this.walletForm?.get('wallet_id');
  }
  get balance() {
    return this.walletForm?.get('balance');
  }

  get payment_gateway() {
    return this.walletForm?.get('payment_gateway');
  }
  //#endregion

  loadAllWallets() {
    this.accountService
      .gettPaymentOptionsAccordingToTransactionMode(WALLET_MODE_ID)
      .subscribe({
        next: (res) => {
          res.results.forEach((wallet: any) => {
            this.wallets.push({ id: wallet.id, name: wallet.name });
          });
          this.wallets = [...this.wallets];
          this.payment_gateway?.setValue(this.wallets[0]);
        },
      });
  }

  initWalletForm() {
    this.walletForm = this.fb.group({
      id: [null],
      wallet_id: [this.localStorage.user.mobile_number, Validators.required],
      balance: [
        0,
        [
          Validators.required,
          Validators.pattern(amount_accepting_zero_not_starting_with_zero),
        ],
      ],
      payment_gateway: [''],
    });
  }

  changeGateway(event: any) {
    this.payment_gateway?.setValue(this.wallets.find((x) => x.id == event));
  }

  saveWallet() {
    this.payment_gateway?.enable();
    const { value } = this.walletForm;
    value.payment_gateway = value.payment_gateway.id;
    if (this.businessTransaction) {
      value.business_account = this.localStorage.business_account;
    }
    this.accountService.addWalletBalance(this.walletForm.value).subscribe({
      next: () => {
        this.added.emit(true);
      },
      error: (err: HttpErrorResponse) => {
        this.alertService.error(
          this.alertService.getFormattedErrorMessage(err.error)
        );
      },
    });
  }
}
