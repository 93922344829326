<div #notificationModal>
  <khatapana-button
    border="circle"
    color="basic"
    (onClick)="openNotificationTab()"
    [outline]="true"
    variant="small"
  >
    <span class="material-symbols-outlined"> notifications </span>
  </khatapana-button>
  <div
    *ngIf="notViewedCount > 0"
    class="fixed rounded-full ml-8 -mt-14 bg-red-500 w-5 h-5 flex justify-center font-semibold items-center text-xs text-white"
  >
    {{ notViewedCount }}
  </div>

  <!-- Notification Dropdown Modal -->
  <div
    *ngIf="showNotificationTab"
    class="absolute rounded-xl shadow-lg overflow-hidden m-2 items-start bg-[#fff] w-[360px] right-0 rounded-t-sm top-20 flex flex-col"
  >
    <div class="p-3 flex w-full flex-col gap-1">
      <!-- Notification Lists -->
      <ng-container *ngIf="notificationList.length > 0; else emptyTemplate">
        <div *ngFor="let notification of notificationList">
          <div
            [ngClass]="{
              'bg-[#fafcff]': !notification.is_viewed
            }"
            class="p-3 cursor-pointer"
          >
            <span class="font-semibold">{{ notification.title }}</span>
            <div class="text-blue-700">{{ notification.content }}</div>
          </div>
        </div>
        <div class="py-4 flex w-full">
          <khatapana-button
            (onClick)="GOTO(HOME_ROUTES.NOTIFICATION)"
            variant="extra-small"
            border="little"
            class="w-full"
            color="primary"
            [fullWidth]="true"
            ><span class="w-full flex justify-center"
              >View all</span
            ></khatapana-button
          >
        </div>
      </ng-container>
      <ng-template #emptyTemplate>
        <khatapana-empty-container
          message="You don't have any notification"
        ></khatapana-empty-container>
      </ng-template>
    </div>
  </div>
</div>
