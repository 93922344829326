import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'khatapana-loader',
  templateUrl: './loader.component.html',
})
export class LoaderComponent implements OnInit {
  @Input() size: number = 80;
  @Input() full: boolean = false;

  constructor() {}

  ngOnInit(): void {}
}
