<div class="absolute flex w-36 z-[1] right-0">
  <khatapana-card
    class="bg-transparent w-full"
    [border]="true"
    [elevation]="true"
  >
    <section
      class="max-h-48 bg-white overflow-y-auto first:rounded-t-lg last:rounded-b-lg overflow-hidden"
    >
      <khatapana-button
        *ngIf="showView"
        (onClick)="clickedView.emit(true)"
        color="simple"
        [fullWidth]="true"
      >
        <span class="flex gap-3 text-sm text-[#545454] font-medium"
          ><span class="material-symbols-outlined">visibility</span>View</span
        >
      </khatapana-button>
      <khatapana-button
        *ngIf="!showView"
        (onClick)="clickedEdit.emit(true)"
        color="simple"
        [fullWidth]="true"
      >
        <span class="flex gap-3 text-sm text-[#545454] font-medium"
          ><span class="material-symbols-outlined">edit</span
          >{{ "common.edit" | translate }}</span
        >
      </khatapana-button>
      <khatapana-button
        (onClick)="clickedDelete.emit(true)"
        color="simple"
        [fullWidth]="true"
      >
        <span class="flex gap-3 text-sm text-[#545454] font-medium"
          ><span class="material-symbols-outlined">delete_forever</span
          >{{ "common.delete" | translate }}</span
        >
      </khatapana-button>
    </section>
  </khatapana-card>
</div>
