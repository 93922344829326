import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { LocalStorageService } from 'src/app/services/local.storage.service';

@Injectable({
  providedIn: 'root',
})
export class AmountService {
  constructor(
    private api: ApiService,
    private localStorage: LocalStorageService
  ) {}

  getCashBalance(isBusiness = false) {
    const params = new HttpParams()
      .set(
        'business_account',
        isBusiness ? this.localStorage.business_account : ''
      )
      .set('business_account__isnull', !isBusiness);
    return this.api.get('/api/payment/cash/balance/', params);
  }

  getBankBalance(isBusiness = false) {
    const params = new HttpParams()
      .set(
        'business_account',
        isBusiness ? this.localStorage.business_account : ''
      )
      .set('business_account__isnull', !isBusiness);
    return this.api.get('/api/payment/bank/balance/', params);
  }

  getWalletBalance(isBusiness = false) {
    const params = new HttpParams()
      .set(
        'business_account',
        isBusiness ? this.localStorage.business_account : ''
      )
      .set('business_account__isnull', !isBusiness);
    return this.api.get(`/api/payment/wallet/balance/`, params);
  }
}
