<div class="fixed top-0 left-0 w-full h-full bg-white z-[1000] p-10">
  <h1 class="text-2xl font-semibold w-full flex justify-center">
    Your Transaction Report
  </h1>
  <table class="py-5">
    <thead>
      <tr class="bg-[#dddddd]">
        <th class="w-2/12 text-base font-semibold">Date</th>
        <th class="w-4/12 text-base font-semibold">Details</th>
        <th class="w-2/12 text-base font-semibold">Party</th>
        <th class="w-2/12 text-base font-semibold">In</th>
        <th class="w-2/12 text-base font-semibold">Out</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let transaction of transactionList">
        <td class="w-2/12 text-xs font-semibold">
          {{ transaction.created_date | date }}
        </td>
        <td class="w-4/12 text-xs font-semibold">
          <p class="text-[10px] text-[#e0e0e0]">
            {{ transaction.transaction_mode | titlecase }}
          </p>
          <p class="text-[10px] text-[#c4c4c4]">
            {{ transaction.purpose?.name | titlecase }}
          </p>
          <p class="text-[10px]">
            {{ transaction.description | titlecase }}
          </p>
        </td>
        <td class="w-2/12 text-xs font-semibold">
          {{ transaction.party?.name }}
        </td>
        <td class="w-2/12 text-[10px] font-semibold">
          {{
            transaction.transaction_type == "in"
              ? (transaction.amount | currency)
              : "-"
          }}
        </td>
        <td class="w-2/12 text-[10px] font-semibold">
          {{
            transaction.transaction_type == "out"
              ? (transaction.amount | currency)
              : "-"
          }}
        </td>
      </tr>
    </tbody>
  </table>
</div>
