import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'khatapana-account',
  templateUrl: './account.component.html',
  styles: [],
})
export class AccountComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
