import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { CoinTransaction } from '../../../shared/models/coin.transaction';

@Component({
  selector: 'khatapana-coin-balance',
  templateUrl: './coin-balance.component.html',
})
export class CoinBalanceComponent implements OnChanges {
  @Input() coinTransactions: CoinTransaction[] = [];
  referralCoin: number = 0;
  transactionCoin: number = 0;
  transactionList: CoinTransaction[] = [];
  referralList: CoinTransaction[] = [];

  showTransactions = false;
  showReferrals = false;

  ngOnChanges(changes: SimpleChanges) {
    if (changes['coinTransactions']) {
      this.coinTransactions?.forEach((x) => {
        if (x.earning_type == 'referral') {
          this.referralCoin = this.referralCoin + x.coin;
          this.referralList?.push(x);
        } else {
          this.transactionCoin = this.transactionCoin + x.coin;
          this.transactionList?.push(x);
        }
      });
      console.log(this.referralCoin, this.transactionCoin);
    }
  }
}
