import { keyValue } from 'src/app/modules/shared/models/keyvalue';

export const REPORTING_TYPES: keyValue[] = [
  { id: 'daily', name: 'Daily' },
  { id: 'weekly', name: 'Weekly' },
  { id: 'monthly', name: 'Monthly' },
  { id: 'yearly', name: 'Yearly' },
];

export const BUSINESS_REPORTING_TYPES: keyValue[] = [
  { id: 'this_week', name: 'This week' },
  { id: 'this_month', name: 'This month' },
  { id: 'this_year', name: 'This Year' },
];

export const TRANSACTION_MODES: keyValue[] = [
  { id: 'cash', name: 'Cash' },
  { id: 'bank', name: 'Bank' },
  { id: 'wallet', name: 'Wallet' },
];

export const DASHBOARD_TRANSACTION_MODES: keyValue[] = [
  { id: 'cash', name: 'Cash' },
  { id: 'bank', name: 'Bank' },
  { id: 'wallet', name: 'Wallet' },
  { id: 'credit', name: 'Credit' },
  { id: 'transfer', name: 'Transfer' },
  { id: '', name: 'All' },
];

export const BUSINESS_TRANSACTION_MODES: keyValue[] = [
  { id: 'cash', name: 'Cash' },
  { id: 'credit', name: 'Credit' },
];
