import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { ChoosePurposeComponent } from '../home/pages/transaction-form/choose-purpose/choose-purpose.component';
import { TransactionFormContentComponent } from '../home/pages/transaction-form/transaction-form-content/transaction-form-content.component';
import { AccountSwitcherComponent } from './components/account-switcher/account-switcher.component';
import { AlertComponent } from './components/alert/alert.component';
import { ButtonComponent } from './components/button/button.component';
import { CapsuleComponent } from './components/capsule/capsule.component';
import { CardComponent } from './components/card/card.component';
import { ChoosePartyComponent } from './components/choose-party/choose-party.component';
import { ConfirmModalComponent } from './components/confirm-modal/confirm-modal.component';
import { CreateBusinessStoreComponent } from './components/create-business-store/create-business-store.component';
import { DatePickerComponent } from './components/date-picker/date-picker.component';
import { DividerComponent } from './components/divider/divider.component';
import { EditBankModalComponent } from './components/edit-bank-modal/edit-bank-modal.component';
import { EditCashModalComponent } from './components/edit-cash-modal/edit-cash-modal.component';
import { EditWalletModalComponent } from './components/edit-wallet-modal/edit-wallet-modal.component';
import { EmptyContainerComponent } from './components/empty-container/empty-container.component';
import { HelpBarComponent } from './components/help-bar/help-bar.component';
import { InputComponent } from './components/input/input.component';
import { LanguageSwitcherComponent } from './components/language-switcher/language-switcher.component';
import { LoaderComponent } from './components/loader/loader.component';
import { LogoComponent } from './components/logo/logo.component';
import { MenuItemsComponent } from './components/menu-items/menu-items.component';
import { ModalComponent } from './components/modal/modal.component';
import { NavigationButtonComponent } from './components/navigation-button/navigation-button.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { PartyFormComponent } from './components/party-form/party-form.component';
import { PartyListTableComponent } from './components/party-list-table/party-list-table.component';
import { PaymentModalComponent } from './components/payment-modal/payment-modal.component';
import { PlanDetailItemComponent } from './components/plan-detail-item/plan-detail-item.component';
import { PlanTypeTabComponent } from './components/plan-type-tab/plan-type-tab.component';
import { ProfileDropdownTabComponent } from './components/profile-dropdown-tab/profile-dropdown-tab.component';
import { SelectComponent } from './components/select/select.component';
import { ToasterComponent } from './components/toaster/toaster.component';
import { TransactionModeButtonComponent } from './components/transaction-mode-button/transaction-mode-button.component';
import { TransactionTableComponent } from './components/transaction-table/transaction-table.component';
import { CurrencyDirective } from './directives/currency.directive';
import { AllownumbersonlyDirective } from './directives/number.only.directive';
import { NotificationTabComponent } from './components/notification-tab/notification-tab.component';
import { AddBankModalComponent } from './components/add-bank-modal/add-bank-modal.component';
import { AddWalletModalComponent } from './components/add-wallet-modal/add-wallet-modal.component';
import { TransferFormContentComponent } from '../home/pages/transfer/transfer-form-content/transfer-form-content.component';
import { TransferSourceDestinationDropdownComponent } from '../home/pages/transfer/transfer-source-destination-dropdown/transfer-source-destination-dropdown.component';

@NgModule({
  declarations: [
    LogoComponent,
    LanguageSwitcherComponent,
    ButtonComponent,
    SelectComponent,
    HelpBarComponent,
    InputComponent,
    DividerComponent,
    LoaderComponent,
    ModalComponent,
    PaymentModalComponent,
    CurrencyDirective,
    CapsuleComponent,
    CardComponent,
    PlanDetailItemComponent,
    PlanTypeTabComponent,
    NavigationButtonComponent,
    AllownumbersonlyDirective,
    PaginationComponent,
    EmptyContainerComponent,
    ToasterComponent,
    TransactionTableComponent,
    ConfirmModalComponent,
    AlertComponent,
    ChoosePartyComponent,
    PartyFormComponent,
    ChoosePurposeComponent,
    CreateBusinessStoreComponent,
    TransactionFormContentComponent,
    TransferFormContentComponent,
    TransactionModeButtonComponent,
    ProfileDropdownTabComponent,
    AccountSwitcherComponent,
    EditCashModalComponent,
    TransferSourceDestinationDropdownComponent,
    EditBankModalComponent,
    EditWalletModalComponent,
    MenuItemsComponent,
    DatePickerComponent,
    PartyListTableComponent,
    NotificationTabComponent,
    AddBankModalComponent,
    AddWalletModalComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    NgSelectModule,
    TranslateModule,
    NgbDatepickerModule,
    ReactiveFormsModule,
    FormsModule,
  ],
  exports: [
    LogoComponent,
    LanguageSwitcherComponent,
    ButtonComponent,
    SelectComponent,
    DividerComponent,
    CardComponent,
    PartyFormComponent,
    LoaderComponent,
    CapsuleComponent,
    ChoosePurposeComponent,
    NotificationTabComponent,
    CreateBusinessStoreComponent,
    ChoosePartyComponent,
    EditCashModalComponent,
    EditBankModalComponent,
    TransferSourceDestinationDropdownComponent,
    EditWalletModalComponent,
    TransactionFormContentComponent,
    TransactionModeButtonComponent,
    ProfileDropdownTabComponent,
    AccountSwitcherComponent,
    TransactionTableComponent,
    TransferFormContentComponent,
    ModalComponent,
    PlanTypeTabComponent,
    PlanDetailItemComponent,
    PaymentModalComponent,
    HelpBarComponent,
    AddBankModalComponent,
    AddWalletModalComponent,
    PartyListTableComponent,
    NavigationButtonComponent,
    PaginationComponent,
    ReactiveFormsModule,
    AllownumbersonlyDirective,
    EmptyContainerComponent,
    InputComponent,
    MenuItemsComponent,
    CurrencyDirective,
    DatePickerComponent,
    FormsModule,
  ],
})
export class SharedModule {}
