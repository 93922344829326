import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { jwtDecode } from 'jwt-decode';
import { Observable, tap } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { LocalStorageService } from 'src/app/services/local.storage.service';
import { AUTH_ROUTES } from '../../auth-routing.module';
import {
  LoginRequest,
  PasswordResetRequest,
  SocialRequest,
} from '../models/login-request';
import { User } from '../models/user.model';
import { VerifyEmail, VerifyOTP, VerifyPhone } from '../models/verify-otp';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private api: ApiService,
    private localStorage: LocalStorageService,
    private router: Router
  ) {}

  sendOTP(loginRequest: LoginRequest) {
    return this.api
      .post(`/user/send_otp/`, loginRequest, false)
      .pipe(tap((data) => {}));
  }

  sendOTPForUpdate(loginRequest: LoginRequest) {
    return this.api
      .post(`/user/send_otp_for_update/`, loginRequest)
      .pipe(tap((data) => {}));
  }

  verifyOTP(verifyOTP: VerifyOTP) {
    return this.api
      .post(`/user/verify_otp/`, verifyOTP, false)
      .pipe(tap((data) => {}));
  }

  verifyNumber(verifyPhone: VerifyPhone) {
    return this.api
      .patch(`/profile/update/`, verifyPhone)
      .pipe(tap((data) => {}));
  }

  verifyEmail(verifyEmail: VerifyEmail) {
    return this.api
      .patch(`/profile/update/`, verifyEmail)
      .pipe(tap((data) => {}));
  }

  loginWithPassword(body: any) {
    return this.api.post(`/password/login/`, body, false);
  }

  continueWithGoogle(token: SocialRequest) {
    return this.api.post('/user/social/', token, false);
  }

  continueWithApple(token: SocialRequest) {
    return this.api.post('/user/social/', token , false);
  }

  get isUserRegister(): boolean {
    const user = this.localStorage.user;
    if (!user?.password) return true;
    else return false;
  }

  get isSocialUser(): boolean {
    const social = this.localStorage.social;
    if (social) return true;
    else return false;
  }

  updateProfileWithUsefulField(user: any) {
    return this.api.patch('/profile/update/', user);
  }

  loadUserToLocalStorage() {
    this.getUserProfile().subscribe({
      next: (data: User) => {
        // let user: User = {
        //   active_subscription: data.active_subscription,
        //   email: data.user.email,
        //   first_name: data.first_name,
        //   last_name: data.last_name,
        //   mobile_number: data.user.mobile_number,
        //   profile_image: data.profile_image,
        //   unverified_email: data.user.unverified_email,
        // };
        this.localStorage.user = data;
      },
      error: () => {},
    });
  }

  getUserProfile(): Observable<any> {
    return this.api.get('/profile/update/');
  }

  resetPassword(loginRequest: PasswordResetRequest) {
    return this.api.post(`/password/reset/`, loginRequest, false);
  }

  forgotPassword(username: any, code: any, password: any) {
    let payload = {
      username,
      code,
      password,
    };
    return this.api.post(`/password/reset/verify/`, payload, false);
  }

  refreshToken(refresh: string = '') {
    if (!refresh) {
      refresh = this.localStorage.refresh_token;
    }
    let payload = {
      refresh,
    };

    return this.api.post(`/token/refresh/`, payload, false).pipe(
      tap((data) => {
        this.localStorage.refresh_token = data.refresh;
        this.localStorage.token = data.access;
      })
    );
  }

  revokeToken(refresh: string = '') {
    if (!refresh) {
      refresh = this.localStorage.refresh_token;
    }
    let payload = {
      refresh,
    };
    return this.api.post(`/token/revoke/`, payload, false);
  }

  validateToken(token: string): any | null {
    try {
      const decodedToken: any = jwtDecode(token);
      if (decodedToken.exp * 1000 < Date.now()) {
        // refresh token
        // this.localStorage.resetLocalStorage();
        this.router.navigate([AUTH_ROUTES.LOGIN]);
        return false;
      }
      return true;
    } catch (error) {
      // this.localStorage.resetLocalStorage();
      this.router.navigate([AUTH_ROUTES.LOGIN]);
      return false;
    }
  }

  logout() {
    this.revokeToken().subscribe();
    this.localStorage.resetLocalStorage();
  }

  getCookieParams(date: Date) {
    return {
      expires: date,
      path: '/',
      // domain: 'khatapana.com',
      secure: true,
      sameSite: 'Lax',
    };
  }

  getTokenExp(token: any) {
    const decoded: any = jwtDecode(token);

    const exp = decoded.exp * 1000;

    return new Date(exp);
  }
}
