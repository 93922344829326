<div class="flex flex-col">
  <label class="font-semibold text-sm pb-1">{{ label }}</label>
  <div
    class="flex relative items-center flex-row justify-center"
    *ngIf="type === 'password'; else inputTempalte"
  >
    <ng-template [ngTemplateOutlet]="inputTempalte"></ng-template>
    <i
      (click)="showPassword = !showPassword"
      class="fa cursor-pointer text-gray-500 absolute bottom-5 right-[2%] pr-1"
      [ngClass]="showPassword ? 'fa-eye-slash' : 'fa-eye'"
    ></i>
  </div>
  <ng-template #inputTempalte>
    <i
      *ngIf="preIcon"
      [class]="label != '' ? 'fixed mt-10 ml-3' : 'fixed mt-5 ml-3'"
      [ngClass]="preIcon"
    ></i>
    <input
      [type]="type == 'password' ? (showPassword ? 'text' : 'password') : type"
      [value]="value"
      [id]="id"
      [disabled]="disabled"
      class="input-text"
      [placeholder]="placeholder"
      [autocomplete]="autocomplete"
      (input)="onChange($any($event).target.value)"
      (blur)="onTouched()"
      class="khatapana-input"
      [ngClass]="{ 'pl-9 pr-4': preIcon, 'px-4': !preIcon, noborder: !border }"
      [name]="id"
      [class]="inputClass"
    />
  </ng-template>

  <small *ngIf="errorMessage" class="text-[#E11900] font-[450] text-sm pt-1">
    {{ errorMessage }}
  </small>
</div>
