import { Component, OnInit } from '@angular/core';
import { UserCoin } from '../../shared/models/coin';
import { CoinTransaction } from '../../shared/models/coin.transaction';
import { PageTitleService } from '../../shared/services/page-title.service';
import { ReferralService } from '../../shared/services/referral.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'khatapana-referral',
  templateUrl: './referral.component.html',
})
export class ReferralComponent implements OnInit {
  referralCode: string = '';
  coinTransactions: CoinTransaction[] = [];
  userCoin: UserCoin = { balance: 0, opening_balance: 0 };

  constructor(
    private pageTitleService: PageTitleService,
    private translate: TranslateService,
    private referralService: ReferralService
  ) {
    this.translate
      .get('navbar.referral')
      .subscribe((v) => this.pageTitleService.pageTitle$.next(v));
    this.pageTitleService.showBackButton$.next(false);
  }

  ngOnInit(): void {
    this.getReferralCode();
    this.getUserCoinTransactions();
    this.getUserCoins();
  }

  getReferralCode() {
    this.referralService.getReferralCode().subscribe({
      next: (res: { referral_code: string }) => {
        this.referralCode = res.referral_code;
      },
    });
  }

  getUserCoinTransactions() {
    this.referralService.getUserCoinTransactions().subscribe({
      next: (res) => {
        this.coinTransactions = res.results;
      },
    });
  }

  getUserCoins() {
    this.referralService.getUserCoins().subscribe({
      next: (res) => {
        res.forEach((element) => {
          this.userCoin.balance = this.userCoin.balance + element.balance;
        });
      },
    });
  }
}
