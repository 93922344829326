export interface BusinessAccount {
  name: string;
  category?: string;
  address: string;
  business_type: string;
}

export interface BusinessAccountResponse extends BusinessAccount {
  id: number;
}

export const BUSINESS_ACCOUNT = {
  BOTH: 'both',
  BUSINESS_ONLY: 'business',
  PERSONAL: 'personal',
};
